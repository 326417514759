import axios from 'axios';
import { Config } from '../../Config';
import { TransactionFields } from './TransactionFields';

export class TransactionController {
    // TODO: Error Boundary
    public async getTransactions(
        storeId: string, startTime: Date, endTime: Date,
    ) {
        const response = await axios.get(`${Config.SERVER_URL}/transaction`, {
            params: {
                [TransactionFields.storeId]: storeId,
                [TransactionFields.startTime]: startTime,
                [TransactionFields.endTime]: endTime,
            },
        });
        // Status: 200
        if (response.status === 200) {
            return response.data;
        }
        return null;
    }
}
