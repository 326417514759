import axios from 'axios';
import { Config } from '../../Config';
import { IStore, IStoreMetasResponse, IStoreResponse } from '../../model/storeGroup/store/IStore';
import { AxiosController } from '../AxiosController';
import { StoreFields } from './StoreFields';

export class StoreController extends AxiosController {
    // TODO: Error Boundary
    public async getById(storeId: string) : Promise<IStoreResponse> {
        const response = await this.axiosGET<IStoreResponse>(
            `${Config.SERVER_URL}/store`,
            {
                [StoreFields.storeId]: storeId,
            },
        );

        return response;
    }

    public static async postImage(storeId: string, blob: Blob) : Promise<string> {
        const data = new FormData();
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        data.append('storeId', storeId);
        data.append('image', blob);

        const resp = await axios.post(`${Config.SERVER_URL}/store/image`, data, config);

        return resp.data;
    }

    public async getStoreMetasByCorpId(
        corpId: string,
    ) : Promise<IStoreMetasResponse> {
        const storeMetasResponse = await this.axiosGET<IStoreMetasResponse>(
            `${Config.SERVER_URL}/store/corp`,
            {
                corpId,
            },
        );

        return storeMetasResponse;
    }
}
