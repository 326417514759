/* eslint-disable jsx-a11y/label-has-associated-control */
import i18next from 'i18next';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../../../../model/helpers/Dict';
import { IIngrLogMeta } from '../../../../../../../model/IngrGroup/IngrLog/IIngrLog';
import { IOptionElemRef } from '../../../../../../../model/menuGroup/option/optionElem/IOptionElemRef';
import IngredientSelector from '../../ItemIngrModule/IngredientSelector/IngredientSelector';
import './OptionElemCard.scss';

interface OptionElemCardProps {
    name: string;
    ingrLogMetas: IIngrLogMeta[];
    optionElemRef: IOptionElemRef;
    optIdRef: string;
    removeOptionElem: (tmpOptId: string, optionElemId: string) => void;
    changeOptionElemHidden: (
        e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string, optionElemId: string
    ) => void;
    changeOptionElemIsDflt: (
        e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string, optionElemId: string
    ) => void;
    changeOptionElemPrice: (
        e: React.ChangeEvent<HTMLInputElement>,
        tmpOptId: string, optionElemId: string, priceKey: string,
    ) => void;
    changeOptionElemIngrMap: (
        tmpOptId: string, optionElemId: string, tIngrMap: Dict<number>,
    )=> void;
}

const OptionElemCard: React.FC<OptionElemCardProps> = ({
    name, ingrLogMetas, optIdRef, optionElemRef, removeOptionElem,
    changeOptionElemHidden, changeOptionElemIsDflt, changeOptionElemPrice,
    changeOptionElemIngrMap,
}) => {
    const { t, i18n } = useTranslation();
    const { store } = useContext(StoreContext)!;
    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const updateItemIngr = (ingrMap: Dict<number>) => {
        changeOptionElemIngrMap(optIdRef, optionElemRef.optionElemId, ingrMap);
    };

    const getIngr = (ingrId: string) => {
        const target = ingrLogMetas.find((iLM) => iLM._id === ingrId);
        if (target === undefined) throw new Error('[generate] Cannot find ingr');
        return target;
    };

    return (

        <div className="optionElemCardContainer">
            <IngredientSelector
                open={modalOpen}
                closeModal={closeModal}
                updateItemIngr={updateItemIngr}
                ingrLogMetas={ingrLogMetas}
                itemIngrMap={optionElemRef.ingrMap}
            />
            <h3>{name}</h3>
            <div className="inputBox">
                <label htmlFor={`${optionElemRef.optionElemId}_hidden`}>Hidden</label>
                <input
                    id={`${optionElemRef.optionElemId}_hidden`}
                    type="checkbox"
                    checked={optionElemRef.hidden}
                    onChange={(e) => changeOptionElemHidden(
                        e, optIdRef, optionElemRef.optionElemId,
                    )}
                />
            </div>
            {/* <div className="inputBox">
                <label htmlFor={`${optionElemRef.optionElemId}_isDflt`}>Pre-selected</label>
                <input
                    id={`${optionElemRef.optionElemId}_isDflt`}
                    type="checkbox"
                    checked={optionElemRef.isDflt}
                    onChange={(e) => changeOptionElemIsDflt(
                        e, optIdRef, optionElemRef.optionElemId,
                    )}
                />
            </div> */}
            <div className="priceKeys">
                {store.priceKeys.map((pK) => {
                    const val = optionElemRef.oePrices.find((oePrice) => (
                        oePrice.priceKeyId === pK._id
                    ));
                    if (val === undefined) throw new Error('Cannot find option elem price');
                    return (
                        <div className="optionElemPriceKey">
                            <p>{pK.mName}</p>
                            <input
                                type="number"
                                value={val.amount}
                                onChange={(e) => changeOptionElemPrice(
                                    e, optIdRef, optionElemRef.optionElemId, pK._id,
                                )}
                            />
                        </div>
                    );
                })}
            </div>
            <div className="ingrList">
                <h4>Ingredients</h4>
                {Object.keys(optionElemRef.ingrMap).map((ingrId) => {
                    const ingr = getIngr(ingrId);
                    const val = optionElemRef.ingrMap[ingrId];

                    return (
                        <div className="ingr">
                            <p className="left">{ingr.name[i18n.language]}</p>
                            <p className="right">{val}</p>
                        </div>
                    );
                })}
                <button type="button" onClick={openModal}>Add Ingredients</button>
            </div>
            <button
                className="removeOptionElem"
                type="button"
                onClick={() => removeOptionElem(optIdRef, optionElemRef.optionElemId)}
            >
                X
            </button>
        </div>

    );
};

export default OptionElemCard;
