import React, { useReducer } from 'react';
import { IStaff } from '../../model/staffGroup/IStaff';

// https://stackoverflow.com/questions/49949099/react-createcontext-point-of-defaultvalue
export const StaffContext = React.createContext<
    { staff: IStaff; setStaff: React.Dispatch<IStaff>; } | undefined
>(undefined);

const reducer = (state: IStaff, pair: IStaff) => ({ ...state, ...pair });

interface StaffProviderProps {
    staffProp: IStaff;
    children: React.ReactNode;
}

export const StaffProvider: React.FC<StaffProviderProps> = ({
    staffProp, children,
}) => {
    const [staff, setStaff] = useReducer(reducer, staffProp);
    return (
        <StaffContext.Provider value={{ staff: staff, setStaff: setStaff }}>
            {children}
        </StaffContext.Provider>
    );
};
