import { Config } from '../../Config';
import { IGala } from '../../model/galaGroup/gala/iGala';
import { IGalaAttendee } from '../../model/galaGroup/galaAttendee/iGalaAttendee';
import { IGalaDonation } from '../../model/galaGroup/galaDonation/iGalaDonation';
import { IGalaLive } from '../../model/galaGroup/galaLive/IGalaLive';
import { IGalaTable } from '../../model/galaGroup/galaTable/IGalaTable';
import { GalaTicketPaymentStatus, IGalaTicketSale } from '../../model/galaGroup/galaTicketSale/iGalaTicketSale';
import { AxiosController, IResponseSchema } from '../AxiosController';

interface GalaResponse extends IResponseSchema {
    data: {
        gala: IGala,
    },
}

interface GalaLiveResponse extends IResponseSchema {
    data: {
        galaLive: IGalaLive,
    },
}

interface GalaArrayResponse extends IResponseSchema {
    data: {
        galas: IGala[],
    },
}

interface GalaAttendeeResponse extends IResponseSchema {
    data: {
        galaAttendee: IGalaAttendee,
    },
}

interface GalaAttendeeArrayResponse extends IResponseSchema {
    data: {
        galaAttendees: IGalaAttendee[],
    },
}

interface GalaTicketSaleArrayResponse extends IResponseSchema {
    data: {
        galaTicketSales: IGalaTicketSale[],
    },
}

interface GalaTicketSaleResponse extends IResponseSchema {
    data: {
        galaTicketSale: IGalaTicketSale,
    },
}

interface GalaTableArrayResponse extends IResponseSchema {
    data: {
        galaTables: IGalaTable[],
    }
}

interface GalaDonationResponse extends IResponseSchema {
    data: {
        galaDonations: IGalaDonation[],
    }
}

interface GalaTableResponse extends IResponseSchema {
    data: {
        galaTable: IGalaTable,
    }
}

export class GalaController extends AxiosController {
    public async getGalasByStoreId(
        storeId: string,
    ) : Promise<IGala[]> {
        const resp : GalaArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/store`, {
            storeId: storeId,
        });
        const galas: IGala[] = resp.data.galas;
        return galas;
    }

    public async getGalaLiveByGalaId(
        galaId: string,
    ) : Promise<IGalaLive> {
        const resp : GalaLiveResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/live`, {
            galaId: galaId,
        });
        const galaLive: IGalaLive = resp.data.galaLive;
        return galaLive;
    }

    public async getGalaAttendeesByIds(
        galaAttendeeIds: string[],
    ) : Promise<IGalaAttendee[]> {
        const resp : GalaAttendeeArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/attendee/ids`, {
            galaAttendeeIds: galaAttendeeIds,
        });
        const galaAttendees: IGalaAttendee[] = resp.data.galaAttendees;
        return galaAttendees;
    }

    public async getGalaAttendeesByGalaId(
        galaId: string,
    ) : Promise<IGalaAttendee[]> {
        const resp : GalaAttendeeArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/attendee/gala`, {
            galaId: galaId,
        });
        const galaAttendees: IGalaAttendee[] = resp.data.galaAttendees;
        return galaAttendees;
    }

    public async getCheckedInAttendees(
        galaId: string,
    ) : Promise<IGalaAttendee[]> {
        const resp : GalaAttendeeArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/attendee/checkedIn`, {
            galaId: galaId,
        });
        const galaAttendees: IGalaAttendee[] = resp.data.galaAttendees;
        return galaAttendees;
    }

    public async getNotCheckInAttendees(
        galaId: string,
    ) : Promise<IGalaAttendee[]> {
        const resp : GalaAttendeeArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/attendee/notCheckedIn`, {
            galaId: galaId,
        });
        const galaAttendees: IGalaAttendee[] = resp.data.galaAttendees;
        return galaAttendees;
    }

    public async getGalaTicketSalesByGalaId(
        galaId: string,
    ) : Promise<IGalaTicketSale[]> {
        const resp : GalaTicketSaleArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/ticketSale/gala`, {
            galaId: galaId,
        });
        const galaTicketSales: IGalaTicketSale[] = resp.data.galaTicketSales;
        return galaTicketSales;
    }

    public async payTicketSale(
        galaTicketSaleId: string,
        paymentStatus: GalaTicketPaymentStatus,
        notes: string,
    ) : Promise<IGalaTicketSale> {
        const resp : GalaTicketSaleResponse = await this.axiosPOST(`${Config.SERVER_URL}/gala/ticketSale/pay`, {
            galaTicketSaleId: galaTicketSaleId,
            paymentStatus: paymentStatus,
        });
        const galaTicketSale: IGalaTicketSale = resp.data.galaTicketSale;
        return galaTicketSale;
    }

    public async getGalaAttendeeByIds(
        attendeeIds: string[],
    ) : Promise<IGalaAttendee[]> {
        const resp : GalaAttendeeArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/attendee/ids`, {
            galaAttendeeIds: attendeeIds,
        });
        const galaAttendees: IGalaAttendee[] = resp.data.galaAttendees;
        return galaAttendees;
    }

    public async editAttendee(
        galaAttendeeId: string,
        registered: boolean,
        fName: string,
        pName: string,
        lName: string,
        address: string,
        postalCode: string,
        city: string,
        province: string,
        country: string,
        email: string,
        phone: string,
        mealChoice: string,
        dietRestriction: string,
        seatingNotes: string,
        notes: string,
        sponsor: string,
    ) : Promise<IGalaAttendee> {
        const resp : GalaAttendeeResponse = await this.axiosPUT(`${Config.SERVER_URL}/gala/attendee`, {
            galaAttendeeId: galaAttendeeId,
            registered: registered,
            fName: fName,
            pName: pName,
            lName: lName,
            address: address,
            postalCode: postalCode,
            city: city,
            province: province,
            country: country,
            email: email,
            phone: phone,
            mealChoice: mealChoice,
            dietRestriction: dietRestriction,
            seatingNotes: seatingNotes,
            notes: notes,
            sponsor: sponsor,
        });
        const galaAttendee: IGalaAttendee = resp.data.galaAttendee;
        return galaAttendee;
    }

    public async getGalaTicketSaleByAttendeeId(
        attendeeId: string,
    ) : Promise<IGalaTicketSale> {
        const resp : GalaTicketSaleResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/ticketSale/attendee`, {
            galaAttendeeId: attendeeId,
        });
        const galaTicketSale: IGalaTicketSale = resp.data.galaTicketSale;
        return galaTicketSale;
    }

    public async editGalaTicketSale(
        galaTicketSaleId: string,
        fName: string,
        lName: string,
        address: string,
        postalCode: string,
        city: string,
        province: string,
        country: string,
        email: string,
        phone: string,
        sponsor: string,
    ) : Promise<IGalaTicketSale> {
        const resp : GalaTicketSaleResponse = await this.axiosPUT(`${Config.SERVER_URL}/gala/ticketSale`, {
            galaTicketSaleId: galaTicketSaleId,
            fName: fName,
            lName: lName,
            address: address,
            postalCode: postalCode,
            city: city,
            province: province,
            country: country,
            email: email,
            phone: phone,
            sponsor: sponsor,
        });
        const galaTicketSale: IGalaTicketSale = resp.data.galaTicketSale;
        return galaTicketSale;
    }

    public async sendTicketSaleEmail(
        galaTicketSaleId: string,
    ) : Promise<IGalaTicketSale> {
        const resp : GalaTicketSaleResponse = await this.axiosPOST(`${Config.SERVER_URL}/gala/ticketSale/sendTickets`, {
            galaTicketSaleId: galaTicketSaleId,
        });
        const galaTicketSale: IGalaTicketSale = resp.data.galaTicketSale;
        return galaTicketSale;
    }

    public async getGalaTableById(
        galaTableId: string,
    ) : Promise<IGalaTable> {
        const resp : GalaTableResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/table`, {
            galaTableId: galaTableId,
        });
        const galaTable: IGalaTable = resp.data.galaTable;
        return galaTable;
    }

    public async getGalaTablesByGalaId(
        galaId: string,
    ) : Promise<IGalaTable[]> {
        const resp : GalaTableArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/table/gala`, {
            galaId: galaId,
        });
        const galaTables: IGalaTable[] = resp.data.galaTables;
        return galaTables;
    }

    public async createGalaTable(
        tableName: string,
        galaId: string,
        galaAttendeeIds: string[],
        notes: string,
    ) : Promise<IGalaTable> {
        const resp : GalaTableResponse = await this.axiosPOST(`${Config.SERVER_URL}/gala/table`, {
            tableName: tableName,
            galaId: galaId,
            galaAttendeeIds: galaAttendeeIds,
            notes: notes,
        });
        const galaTable: IGalaTable = resp.data.galaTable;
        return galaTable;
    }

    public async editGalaTable(
        galaTableId: string,
        tableName: string,
        galaAttendeeIds: string[],
        notes: string,
    ) : Promise<IGalaTable> {
        const resp : GalaTableResponse = await this.axiosPUT(`${Config.SERVER_URL}/gala/table`, {
            galaTableId: galaTableId,
            tableName: tableName,
            galaAttendeeIds: galaAttendeeIds,
            notes: notes,
        });
        const galaTable: IGalaTable = resp.data.galaTable;
        return galaTable;
    }

    public async getGalaDonations(
        galaId: string,
    ) : Promise<IGalaDonation[]> {
        const resp : GalaDonationResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/live/donations`, {
            galaId: galaId,
        });
        const galaDonations: IGalaDonation[] = resp.data.galaDonations;
        return galaDonations;
    }
}
