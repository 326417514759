import { Config } from '../../Config';
import { ICorpResponse } from '../../model/corpGroup/ICorp';
import { Dict } from '../../model/helpers/Dict';
import { ICorpRawMat, ICorpRawMatMeta } from '../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { MeasurementEnum } from '../../model/IngrGroup/IngrLog/MeasurementEnum';
import { AxiosController, IResponseSchema } from '../AxiosController';

export class CorpController extends AxiosController {
    private PATH = 'corp';

    public async getById(
        corpId: string,
    ) : Promise<ICorpResponse> {
        const resp = await this.axiosGET<ICorpResponse>(
            `${Config.SERVER_URL}/${this.PATH}`,
            {
                corpId,
            },
        );
        return resp;
    }
}
