import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { ICorpRawMatMeta } from '../../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { ICorpRawMatReport } from '../../../../model/IngrGroup/CorpRawMat/ICorpRawMatReport';
import { amountToString } from '../../../../utils/global';
import './CorpRawMatReportModal.scss';

ReactModal.setAppElement('#root');
interface CorpRawMatReportModalProps {
    open: boolean;
    corpRawMatMeta: ICorpRawMatMeta | null;
    closeModal: () => void;
}

const CorpRawMatReportModal: React.FC<CorpRawMatReportModalProps> = ({
    open, corpRawMatMeta, closeModal,
}) => {
    const { i18n } = useTranslation();

    // Get the report
    const [corpRawMatLogReport, setIngrLogReport] = useState<ICorpRawMatReport | null>(null);
    const [queryDate, setQueryDate] = useState<[Date, Date] | null>(null);

    const getReport = async () => {
        if (corpRawMatMeta === null) return;
        const startTime : Date | null = queryDate ? queryDate[0] : null;
        const endTime : Date | null = queryDate ? queryDate[1] : null;
        const report : ICorpRawMatReport = await Factory.CorpRawMatController
            .getCorpRawMatReport(corpRawMatMeta._id, startTime, endTime);
        setIngrLogReport(report);
    };
    // Load report
    useEffect(() => {
        getReport();
    }, [queryDate]);

    useEffect(() => {
        if (open) getReport();
        setQueryDate(null);
    }, [open]);

    // The current info
    const [currInfo, setCurrInfo] = useState<'adj' | 'stock' | 'usage'>('adj');

    // AdjInfo
    const getAdjInfos = () => {
        if (corpRawMatLogReport === null || corpRawMatMeta === null) return <></>;
        if (corpRawMatLogReport.adjInfos.length === 0) return <h3>No Adjustment Information Found</h3>;
        return (
            <table>
                <tr>
                    <th>Time</th>
                    <th>Staff Name</th>
                    <th>Units Adjusted</th>
                    <th>Notes</th>
                </tr>
                {corpRawMatLogReport.adjInfos.map((adjInfo) => (
                    <tr>
                        <td>{new Date(adjInfo.createdAt).toLocaleTimeString()}</td>
                        <td>{adjInfo.staffNickname}</td>
                        <td>{`${adjInfo.unitsAdj}${corpRawMatMeta.unitOfM}`}</td>
                        <td>{adjInfo.notes}</td>
                    </tr>
                ))}
            </table>
        );
    };

    const getStockInfos = () => {
        if (corpRawMatLogReport === null || corpRawMatMeta === null) return <></>;
        if (corpRawMatLogReport.stockInfos.length === 0) return <h3>No Stock Information Found</h3>;
        return (
            <table>
                <tr>
                    <th>Time</th>
                    <th>Staff Name</th>
                    <th>Cost of Stock</th>
                    <th>Unit Stocked</th>
                    <th>Notes</th>
                </tr>
                {corpRawMatLogReport.stockInfos.map((stockInfo) => (
                    <tr>
                        <td>{new Date(stockInfo.createdAt).toLocaleTimeString()}</td>
                        <td>{stockInfo.staffNickname}</td>
                        <td>{`${stockInfo.costOfStock / 100} / ${corpRawMatMeta.unitOfM}`}</td>
                        <td>{`${stockInfo.unitsStocked}${corpRawMatMeta.unitOfM}`}</td>
                        <td>{stockInfo.notes}</td>
                    </tr>
                ))}
            </table>
        );
    };

    const getUsageInfos = () => {
        if (corpRawMatLogReport === null || corpRawMatMeta === null) return <></>;
        if (corpRawMatLogReport.usageInfos.length === 0) return <h3>No Usage Information Found</h3>;
        return (
            <table>
                <tr>
                    <th>Created Time</th>
                    <th>Store Name</th>
                    <th>Starting Units</th>
                    <th>Units Used</th>
                    <th>Raw Material Cost</th>
                    <th>Price Charged</th>
                    <th>Notes</th>
                </tr>
                {corpRawMatLogReport.usageInfos.map((usageInfo) => (
                    <tr>
                        <td>{new Date(usageInfo.createdAt).toLocaleString()}</td>
                        <td>{new Date(usageInfo.storeName[i18n.language]).toLocaleTimeString()}</td>
                        <td>{usageInfo.startingUnits}</td>
                        <td>{usageInfo.unitsUsed}</td>
                        <td>{`${amountToString(usageInfo.costOfUsed)}`}</td>
                        <td>{amountToString(usageInfo.priceCharged)}</td>
                        <td>{usageInfo.notes}</td>
                    </tr>
                ))}
            </table>
        );
    };

    const showCurrInfo = () => {
        if (currInfo === 'adj') return getAdjInfos();
        if (currInfo === 'stock') return getStockInfos();
        return getUsageInfos();
    };

    if (corpRawMatMeta === null || corpRawMatLogReport === null) return <></>;

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="ingrReportModal"
            overlayClassName="ingrReportModalOverlay"
        >
            <h2>
                {`Report for ${corpRawMatMeta.name.en} - ${
                    queryDate ? `${queryDate[0].toDateString()} to ${queryDate[1].toDateString()}` : 'All Time'}
                `}
            </h2>

            <div className="row ingrReport">
                <div className="column">
                    <button
                        type="button"
                        className={`viewAll ${queryDate === null ? 'viewAllActive' : ''}`}
                        onClick={() => setQueryDate(null)}
                    >
                        View All Time
                    </button>
                    <Calendar
                        // @ts-ignore
                        onChange={(val: [Date, Date]) => { setQueryDate(val); }}
                        value={queryDate}
                        defaultValue={undefined}
                        selectRange
                        returnValue="range"
                    />
                </div>

                <div className="column reportStats">
                    <header className="viewSwitcher">
                        <button
                            type="button"
                            className={`viewStocks ${currInfo === 'stock' ? 'active' : ''}`}
                            onClick={() => setCurrInfo('stock')}
                        >
                            View Stocks
                        </button>
                        <button
                            type="button"
                            className={`viewAdj ${currInfo === 'adj' ? 'active' : ''}`}
                            onClick={() => setCurrInfo('adj')}
                        >
                            View Adjustments
                        </button>
                        <button
                            type="button"
                            className={`viewUsages ${currInfo === 'usage' ? 'active' : ''}`}
                            onClick={() => setCurrInfo('usage')}
                        >
                            View Usages
                        </button>
                    </header>
                    {showCurrInfo()}
                </div>
            </div>
        </ReactModal>
    );
};

export default CorpRawMatReportModal;
