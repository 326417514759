import { AxiosError } from 'axios';
import { History } from 'history';
import { Config } from '../../Config';
import { IStaff } from '../../model/staffGroup/IStaff';
import { IStoreStaff, IStoreStaffArrResponse } from '../../model/storeGroup/store/storeStaff/IStoreStaff';
import { AxiosController, IResponseSchema } from '../AxiosController';
import { HttpStatusCode } from '../HttpStatusCodes';
import { ToastController } from '../ToastController';
import { StaffFields } from './StaffFields';

interface IMsgResponse extends IResponseSchema {
    msg: string;
}

interface ITokenResponse extends IResponseSchema {
    token: string | null;
}

interface IStaffResponse extends IResponseSchema {
    data : {
        staff: IStaff,
    }
}

export class StaffController extends AxiosController {
    private PATH = 'staff';

    /**
     * Sends code request to server to customer's phone
     * @param phone : string
     * @returns : Boolean
     */
    public async requestCode(
        phone: string,
    ) : Promise<IMsgResponse> {
        const msgResponse = await this.axiosPOST<IMsgResponse>(
            `${Config.SERVER_URL}/${this.PATH}/getCode`,
            {
                [StaffFields.phone]: phone,
            },
        );

        return msgResponse;
    }

    /**
     * Sends login details and receives a promise for a boolean
     * @param phone :string - AreaCode + Phone
     * @param code :string - 6 digit code
     * @returns :Promise<boolean>
     */
    public async login(
        phone: string,
        code: string,
    ) : Promise<ITokenResponse> {
        const tokenResponse = await this.axiosPOST<ITokenResponse>(
            `${Config.SERVER_URL}/${this.PATH}/loginWithCode`,
            {
                [StaffFields.phone]: phone,
                [StaffFields.code]: code,
            },
        );
        if (!tokenResponse.twilSuccess) {
            ToastController.error('Invalid verification code. Please try again');
        }
        if (tokenResponse.twilSuccess && tokenResponse.data.token === null) {
            ToastController.error('User is not registered with a store.');
        }
        return tokenResponse;
    }

    public async loginPassword(
        email: string,
        password: string,
    ) : Promise<ITokenResponse> {
        const tokenResponse = await this.axiosPOST<ITokenResponse>(
            `${Config.SERVER_URL}/${this.PATH}/loginPassword`,
            {
                [StaffFields.email]: email,
                [StaffFields.password]: password,
            },
        );
        // if (!tokenResponse.twilSuccess) {
        //     ToastController.error('Invalid verification code. Please try again');
        // }
        if (tokenResponse.data.token === null) {
            ToastController.error('User is not registered with a store.');
        }
        // console.log(tokenResponse);
        return tokenResponse;
    }

    /**
     * Sends jwt to server to validate
     * @param history : History from react-router-dom
     * @returns : Promise<boolean>
     */
    public async validateJWT(history: History) : Promise<boolean> {
        const token = localStorage.getItem('token');
        if (token) {
            let tokenResponse = null;
            try {
                tokenResponse = await this.axiosGET<ITokenResponse>(
                    `${Config.SERVER_URL}/${this.PATH}/validate`,
                    {
                        [StaffFields.token]: token,
                    },
                );
            } catch (error) {
                const err = error as AxiosError;
                if (err.isAxiosError && err.response?.status !== HttpStatusCode.UNAUTHORIZED) {
                    throw error;
                }
            }

            if (tokenResponse === null) {
                history.push('/login');
                localStorage.removeItem('token');
                return false;
            }
            return true;
        }
        history.push('/login');
        return false;
    }

    public async getByToken() : Promise<IStaff> {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No Token Found, please login again');

        const staffResponse : IStaffResponse = await this.axiosGET<IStaffResponse>(
            `${Config.SERVER_URL}/${this.PATH}`,
            {
                [StaffFields.token]: token,
            },
        );

        return staffResponse.data.staff;
    }

    public async getByPhone(
        phone: string,
    ) : Promise<IStaffResponse> {
        const staffResponse : IStaffResponse = await this.axiosGET<IStaffResponse>(
            `${Config.SERVER_URL}/${this.PATH}/phone`,
            {
                [StaffFields.phone]: phone,
            },
        );

        return staffResponse;
    }

    public async createStaff(
        fName: string,
        lName: string,
        phone: string,
        email: string,
        code: string,
    ) : Promise<IStaffResponse> {
        const staffResponse : IStaffResponse = await this.axiosPOST<IStaffResponse>(
            `${Config.SERVER_URL}/${this.PATH}`,
            {
                [StaffFields.fName]: fName,
                [StaffFields.lName]: lName,
                [StaffFields.phone]: phone,
                [StaffFields.email]: email,
                [StaffFields.code]: code,
            },
        );

        return staffResponse;
    }
}
