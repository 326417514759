import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip,
} from 'chart.js';
import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { ISeshOptionElemStat } from '../../../../model/analyticsGroup/seshReport/seshOptionElemStat/ISeshOptionElemStat';
import { Dict } from '../../../../model/helpers/Dict';
import { amountToString, calcChartDimensions, generateChartOptions } from '../../../../utils/global';
import './OptionStatModal.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);
ReactModal.setAppElement('#root');
interface OptionStatModalProps {
    open: boolean;
    optionStat: ISeshOptionElemStat | null;
    closeModal: () => void;
}

const OptionStatModal: React.FC<OptionStatModalProps> = ({
    open, closeModal, optionStat,
}) => {
    const { i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;

    const [weekdayKey, setWeekdayKey] = useState<number | undefined>(undefined);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    useEffect(() => {
        setWeekdayKey(undefined);
    }, [open]);
    const generateDailyData = (weekUnitMap: Dict<number[]>) => {
        const keys = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 7; i++) {
            if (i.toString() in weekUnitMap) {
                keys.push(i);
            }
        }

        return ({
            labels: keys.map((key) => days[key]),
            datasets: [{
                label: 'Count',
                data: keys.map((key) => (
                    weekUnitMap[key.toString()].reduce(
                        (agg, hourCount) => agg + hourCount,
                        0,
                    )
                )),
                backgroundColor: '#E4545F9A',
            }],
        });
    };

    const handleDailyChartClick = (evt: any, element: any) => {
        if (element.length > 0) {
            if (optionStat === null) throw new Error('[Daily Click] No optionStat data');
            const weekdayIdx = element[0].index;
            const keys = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < 7; i++) {
                if (i.toString() in optionStat.weekUnitMap) {
                    keys.push(i);
                }
            }

            setWeekdayKey(keys[weekdayIdx]);
        }
    };

    const toTwoDigits = (x: number) => {
        const s = x.toString();
        if (s.length === 2) return s;
        return `0${s}`;
    };
    const toHourString = (hour: number, minOffset: number) => `${hour.toString()}:${toTwoDigits(minOffset)}`;

    const generateHourlyData = (preTransHours: number[]) => {
        const postTransHours : number[] = [...preTransHours];
        const totalOffset = new Date().getTimezoneOffset();
        const offset = Math.floor(totalOffset / 60);
        const offsetMin = Math.abs(totalOffset % 60);

        if (offset > 0) {
            const shifted = postTransHours.splice(0, offset);
            postTransHours.concat(shifted);
        } else {
            const shifted = postTransHours.splice(0, postTransHours.length + offset);
            postTransHours.concat(shifted);
        }
        return ({
            labels: Array.from(Array(24).keys()).map((idx) => toHourString(idx, offsetMin)),
            datasets: [{
                label: 'Count',
                data: postTransHours,
                backgroundColor: '#E4545F9A',
            }],
        });
    };

    if (optionStat === null) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="optionStatModal"
            overlayClassName="optionStatModalOverlay"
        >
            <header>
                <div className="optionStats">
                    <h2>{optionStat.name[i18n.language]}</h2>
                    <h3>{`Qty: ${optionStat.qty}`}</h3>
                    <h3>{`Revenue: ${amountToString(optionStat.revenue)}`}</h3>
                </div>
            </header>
            <Bar
                data={generateDailyData(optionStat.weekUnitMap)}
                style={calcChartDimensions(Object.keys(optionStat.weekUnitMap), 0)}
                options={generateChartOptions('Daily Statistics', handleDailyChartClick)}
            />
            {weekdayKey !== undefined && (
                <Bar
                    data={generateHourlyData(optionStat.weekUnitMap[weekdayKey])}
                    style={calcChartDimensions(optionStat.weekUnitMap[weekdayKey], 0)}
                    options={generateChartOptions('Hourly Statistics')}
                />
            )}
        </ReactModal>
    );
};

export default OptionStatModal;
