import React from 'react';
import { useTranslation } from 'react-i18next';
import { IIngrLogMeta } from '../../../../model/IngrGroup/IngrLog/IIngrLog';
import './IngrLogCard.scss';

interface IngrLogProps{
    ingrLogMeta: IIngrLogMeta;
    selectIngrLog: () => void;
    openIngrStockModal: () => void;
    openIngrAdjModal: () => void;
    openIngrReportModal: () => void;
}

const IngrLogCard: React.FC<IngrLogProps> = ({
    ingrLogMeta, selectIngrLog, openIngrStockModal, openIngrAdjModal, openIngrReportModal,
}) => {
    const { t, i18n } = useTranslation();

    const getCardName = () => {
        let stockStr : string = '';
        if (ingrLogMeta.unitsAvail <= 0) stockStr = ' (Out of Stock)';
        else if (ingrLogMeta.unitsAvail <= ingrLogMeta.warningUnits) stockStr = ' (Stock Low)';
        return `${ingrLogMeta.name[i18n.language]}${stockStr}`;
    };

    const generateCardStyle = () => {
        if (ingrLogMeta.unitsAvail <= 0) return 'ingrLogCardContainer ingrLogCardOutOfStock';
        if (ingrLogMeta.unitsAvail <= ingrLogMeta.warningUnits) return 'ingrLogCardContainer ingrLogCardWarning';
        return 'ingrLogCardContainer';
    };

    return (

        <div
            className={generateCardStyle()}
            aria-hidden
        >
            <div className="info">
                <h3>{getCardName()}</h3>
                <div className="currentStock">
                    <div className="desc">{ingrLogMeta.desc[i18n.language]}</div>
                    <div className="desc">{`Current Stock: ${ingrLogMeta.unitsAvail}${ingrLogMeta.unitOfM}`}</div>
                </div>
            </div>
            <div className="ingrLogRow">
                <button
                    type="button"
                    onClick={() => selectIngrLog()}
                >
                    Edit
                </button>
                <button
                    type="button"
                    onClick={() => openIngrStockModal()}
                >
                    Add Stock
                </button>
                <button
                    type="button"
                    onClick={() => openIngrAdjModal()}
                >
                    Add Adjustments
                </button>
                <button
                    type="button"
                    onClick={() => openIngrReportModal()}
                >
                    View Report
                </button>
            </div>
        </div>

    );
};

export default IngrLogCard;
