export enum MenuLocale {
    menuEditor = 'menu.menuEditor',
    newMenu = 'menu.menuEditor',

    newItem = 'menu.fullMenuSelect.itemSelect.newItem',
    editItem = 'menu.fullMenuSelect.itemSelect.editItem',

    itemName = 'menu.createItem.itemName',
    itemPrice = 'menu.createItem.itemPrice',
    itemDescription = 'menu.createItem.itemDescription',
    cancel = 'menu.createItem.cancel',
    confirm = 'menu.createItem.confirm',
}
