import { Config } from '../../Config';
import { Dict } from '../../model/helpers/Dict';
import { IIngrLogReport } from '../../model/IngrGroup/IIngrLogReport';
import { IIngrLog, IIngrLogMeta } from '../../model/IngrGroup/IngrLog/IIngrLog';
import { MeasurementEnum } from '../../model/IngrGroup/IngrLog/MeasurementEnum';
import { AxiosController, IResponseSchema } from '../AxiosController';

interface ITwilResponse extends IResponseSchema {
    data: {
        msg: string,
    }
}

export class TwilioController extends AxiosController {
    public async createCode(
        phone: string,
    ) : Promise<ITwilResponse> {
        const resp : ITwilResponse = await this.axiosPOST(
            `${Config.SERVER_URL}/twil`, {
                phone,
            },
        );
        return resp;
    }
}
