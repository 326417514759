import React, { useState, useEffect, useContext } from 'react';
import { Factory } from '../../../controllers/ControllerFactory';
import { IGalaTicket } from '../../../model/galaGroup/gala/galaTicket/iGalaTicket';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { GalaTicketPaymentStatus, IGalaTicketSale } from '../../../model/galaGroup/galaTicketSale/iGalaTicketSale';
import { amountToString } from '../../../utils/global';
import './galaTicketSaleViewer.scss';
import TicketSaleModal from './TicketSaleModal';

interface GalaTicketSaleViewerProps{
    currGala: IGala;
}

enum PaymentStatus {
    paid = 'Paid',
    unpaid = 'Unpaid',
    all = 'All'
}

export const GalaTicketSaleViewer: React.FC<GalaTicketSaleViewerProps> = ({
    currGala,
}) => {
    const [ticketSales, setTicketSales] = useState<IGalaTicketSale[]>([]);
    const getTicketSales = async () => {
        const tTicketSales = await Factory.GalaController.getGalaTicketSalesByGalaId(currGala._id);
        setTicketSales(tTicketSales);
    };
    useEffect(() => {
        getTicketSales();
    }, []);

    const filterCardUnpaid = () => ticketSales.filter((tSale) => !(tSale.paymentType === 'CARD' && tSale.paymentStatus === GalaTicketPaymentStatus.unpaid));

    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.all);
    const filterTicketSales = () => {
        if (paymentStatus === PaymentStatus.paid) return ticketSales.filter((tSale) => tSale.paymentStatus === GalaTicketPaymentStatus.paid);
        if (paymentStatus === PaymentStatus.unpaid) return filterCardUnpaid().filter((tSale) => tSale.paymentStatus === GalaTicketPaymentStatus.unpaid);
        return filterCardUnpaid();
    };

    const [ticketSale, setTicketSale] = useState<IGalaTicketSale | null>(null);
    const [ticketSaleModalOpen, setTicketSaleModalOpen] = useState(false);
    const openModal = (tTicketSale: IGalaTicketSale) => {
        setTicketSale(tTicketSale);
        setTicketSaleModalOpen(true);
    };
    const closeModal = () => {
        setTicketSale(null);
        setTicketSaleModalOpen(false);
    };

    return (
        <div className="galaTicketSaleContainer">
            <TicketSaleModal
                open={ticketSaleModalOpen}
                closeModal={closeModal}
                ticketSale={ticketSale}
                refresh={getTicketSales}
            />
            <div className="paymentStatus">
                <h3>{`Payment Status : ${paymentStatus}`}</h3>
                <div className="row">
                    <button
                        type="button"
                        onClick={() => setPaymentStatus(PaymentStatus.all)}
                        className={paymentStatus === PaymentStatus.all ? 'active' : ''}
                    >
                        {`${PaymentStatus.all} Ticket Sales: ${filterCardUnpaid().length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setPaymentStatus(PaymentStatus.paid)}
                        className={paymentStatus === PaymentStatus.paid ? 'active' : ''}
                    >
                        {`${PaymentStatus.paid} Ticket Sales: ${ticketSales.filter((tSale) => tSale.paymentStatus === GalaTicketPaymentStatus.paid).length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setPaymentStatus(PaymentStatus.unpaid)}
                        className={paymentStatus === PaymentStatus.unpaid ? 'active' : ''}
                    >
                        {`${PaymentStatus.unpaid} Ticket Sales: ${filterCardUnpaid().filter((tSale) => tSale.paymentStatus === GalaTicketPaymentStatus.unpaid).length}`}
                    </button>
                </div>
            </div>
            <div className="headerRow">
                <div className="small">Time</div>
                <div className="small">Method</div>
                <div className="small">Total</div>
                <div className="small">Price</div>
                <div className="small">Donation</div>
                <div className="mid">Name</div>
                <div />
                {/* <div className="mid">Email</div>
                <div className="mid">Tickets</div>
                <div className="small">Pay</div> */}
            </div>
            <div>
                {filterTicketSales().map((tTicketSale: IGalaTicketSale) => (
                    <div className="ticketSaleRow">
                        <div className="small">{new Date(tTicketSale.saleTime).toLocaleDateString()}</div>
                        <div className="small">{tTicketSale.paymentType}</div>
                        <div className="small">{amountToString(tTicketSale.total)}</div>
                        <div className="small">{amountToString(tTicketSale.price)}</div>
                        <div className="small">{amountToString(tTicketSale.donationAmount)}</div>
                        <div className="mid">{`${tTicketSale.fName} ${tTicketSale.lName}`}</div>
                        <div>
                            <button type="button" onClick={() => openModal(tTicketSale)}>View</button>
                        </div>
                        {/* <div className="mid">{ticketSale.email}</div>
                        <div className="mid">Unfinished</div>
                        <div className="small">Unfinished</div> */}
                    </div>
                ))}
            </div>
        </div>
    );
};
