import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './ItemViewer.scss';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import CategoryCard from './CategoryCard/CategoryCard';
import { ICategory } from '../../../../model/menuGroup/category/ICategory';
import ItemCard from './ItemCard/ItemCard';
import Spinner from '../../../../components/Spinner/Spinner';
import { IItem } from '../../../../model/menuGroup/item/IItem';
import { IMenu } from '../../../../model/menuGroup/menu/IMenu';
import AddEditItem from './AddEditItem/AddEditItem';
import AddEditCategory from './AddEditCategory/AddEditCategory';
import AddBar from '../AddBar/AddBar';
import { IItemTypes } from '../../../../model/menuGroup/item/IItemTypes';

interface ItemViewerProps{
    menuId: string;
}

const ItemViewer: React.FC<ItemViewerProps> = ({
    menuId,
}) => {
    const { t } = useTranslation();
    const { store } = useContext(StoreContext)!;

    const [currMenu, setCurrMenu] = useState<IMenu | null>(null);

    const [currCategoryId, setCurrCategoryId] = useState<string | null>(null);
    const selectCategory = (categoryId: string) => setCurrCategoryId(categoryId);

    const [currItem, setCurrItem] = useState<IItem | null | undefined>(null);
    const selectItem = (item: IItem | null | undefined) => setCurrItem(item);
    const deselectItem = (categId: string) => {
        setCurrItem(null);
        if (categId !== undefined) selectCategory(categId);
    };

    const [categoryIds, setCategoryIds] = useState<string[]>([]);

    const getCategory = (categoryId: string) => store.categories.find(
        (categ) => categ._id === categoryId,
    );

    const [categoryModalOpen, setCategoryModalOpen] = useState(false);
    const openCategoryModal = () => setCategoryModalOpen(true);
    const closeCategoryModal = () => setCategoryModalOpen(false);

    useEffect(() => {
        const cMenu = store.menus.find((menu) => menu._id === menuId);
        if (cMenu === undefined) throw new Error('Cannot find menu with id');
        setCurrMenu(cMenu);
        const tmpCategoryIds = cMenu.categoryIds;

        setCategoryIds(tmpCategoryIds);
        const currCateg = getCategory(tmpCategoryIds[0])!;
        if (currCateg.categoryType === 'MORE') {
            setCurrCategoryId('');
        } else {
            setCurrCategoryId(tmpCategoryIds[0]);
        }
    }, [menuId, store]);

    const generateItemCards = () => {
        if (currCategoryId !== null && currCategoryId !== '' && currMenu !== null) {
            const category: ICategory | undefined = getCategory(currCategoryId);
            return category!.itemIds.map((itemId: string) => {
                const item = store.items.find((tmpItem) => tmpItem._id === itemId);
                if (item === undefined) throw new Error('Item is undefiend!');
                if (item.itemType === IItemTypes.GIFTCREDIT
                    || item.itemType === IItemTypes.CUSTOM) return <></>;
                return (
                    <ItemCard
                        item={item}
                        selectItem={selectItem}
                        priceKeyId={currMenu.priceKeyId}
                    />
                );
            });
        }
        return <></>;
    };

    if (currCategoryId === null) return (<Spinner />);

    // console.log(store);
    return (

        <div className="itemViewerContainer">
            <AddEditCategory
                open={categoryModalOpen}
                closeModal={closeCategoryModal}
                menu={currMenu}
            />
            <header>
                { currItem !== null && <button type="button" onClick={() => setCurrItem(null)}>&#8249;</button>}
                <h2 className="screenTitle">
                    {`Menu Editor > ${currMenu?.name.en}
                        ${currCategoryId !== null && currCategoryId !== '' ? `> ${getCategory(currCategoryId)?.name.en}` : ''}
                        ${currItem !== null && currItem !== undefined ? `> ${currItem.name.en}` : ''}
                        ${currItem === undefined ? '> Add Item' : ''}

                    `}
                </h2>
            </header>
            <div className="itemViewerContent">
                {currItem === null
                && (
                    <>
                        <div className="categoryTabs">
                            { categoryIds.map((tmpCategoryId: string) => {
                                const category: ICategory | undefined = getCategory(tmpCategoryId);
                                if (category === undefined) throw new Error('Cannot find category with id');
                                if (category.categoryType === 'MORE') return (<></>);
                                return (
                                    <>
                                        <CategoryCard
                                            category={category}
                                            currCategoryId={currCategoryId}
                                            selectCategory={selectCategory}
                                        />
                                    </>
                                );
                            })}
                            <div
                                className="addCategory categoryContainer"
                                aria-hidden
                                onClick={openCategoryModal}
                            >
                                <h2>+</h2>
                                <p>Add Category</p>
                            </div>
                        </div>
                        <div className="itemContainer">
                            {currCategoryId !== ''
                                && (
                                    <AddBar handleClick={() => selectItem(undefined)} text="Edit Category Items" />
                                )}
                            {generateItemCards()}
                        </div>
                    </>
                )}
                {currItem !== null
                    && (
                        <AddEditItem
                            currCategoryId={currCategoryId}
                            item={currItem}
                            deselectItem={deselectItem}
                        />
                    )}
            </div>
        </div>

    );
};

export default ItemViewer;
