import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './AddBar.scss';

interface AddBarProps {
    handleClick: () => void;
    text: string;
}

const AddBar: React.FC<AddBarProps> = ({
    handleClick, text,
}) => {
    const { t } = useTranslation();

    return (

        <div
            className="addBarContainer"
            aria-hidden
            onClick={handleClick}
        >
            <h2>+</h2>
            <p>{text}</p>
        </div>

    );
};

export default AddBar;
