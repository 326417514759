export enum TableFields {
    name = 'name',
    x = 'x',
    y = 'y',
    w = 'w',
    h = 'h',
    rotation = 'rotation',
    defaultSeat = 'defaultSeat',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',

    // Routes
    storeId = 'storeId',
    tables = 'tables',
}
