import axios, { AxiosResponse } from 'axios';
import { Dict } from '../model/helpers/Dict';
import { ToastController } from './ToastController';

export interface IResponseSchema {

    data: {
        [key : string] : any,
    },
    twilSuccess? : boolean;

}

interface EategoAxiosResp<T extends IResponseSchema> extends AxiosResponse<T> {}

export abstract class AxiosController {
    protected async axiosGET<T extends IResponseSchema>(
        path: string, params: Dict<any>, showErr?: boolean,
    ) : Promise<T> {
        const axiosResp : EategoAxiosResp<T> = await axios.get(path, {
            params: params,
        }).catch((error) => {
            if (showErr !== false) ToastController.error(error.response.data.message);
            throw error;
        });

        const respData : T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosPOST<T extends IResponseSchema>(
        path: string, body: Dict<any>, showErr?: boolean,
    ) : Promise<T> {
        const axiosResp : EategoAxiosResp<T> = await axios.post(path, body).catch((error) => {
            if (showErr !== false) ToastController.error(error.response.data.message);
            throw error;
        });

        const respData : T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosPUT<T extends IResponseSchema>(
        path: string, body: Dict<any>, showErr?: boolean,
    ) : Promise<T> {
        const axiosResp : EategoAxiosResp<T> = await axios.put(path, body).catch((error) => {
            if (showErr !== false) ToastController.error(error.response.data.message);
            throw error;
        });

        const respData : T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosDELETE<T extends IResponseSchema>(
        path: string, params: Dict<any>, showErr?: boolean,
    ) : Promise<T> {
        const axiosResp : EategoAxiosResp<T> = await axios.delete(path, {
            params: params,
        }).catch((error) => {
            if (showErr !== false) ToastController.error(error.response.data.message);
            throw error;
        });

        const respData : T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    private loadAxiosResp<T extends IResponseSchema>(axiosResp: EategoAxiosResp<T>) : T {
        // Resp Data
        const respData : T = axiosResp.data;

        // Check if response has twil
        const twilSuccess : boolean | undefined = axiosResp.data.twilSuccess;
        if (twilSuccess !== undefined) respData.twilSuccess = twilSuccess;

        return respData;
    }
}
