import { Dict } from '../../helpers/Dict';

export enum GalaTicketPaymentStatus {

    unpaid = 0,
    paid = 1,
    free = 2,

}

export interface IGalaTicketSale {

    _id: string;
    galaId: string;
    galaTicketListingMap: Dict<number>;
    galaAttendeeIds: string[];
    paymentType: string;
    clientSecret: string | null;
    donationAmount: number;
    price: number;
    total: number;
    taxReceiptAmount: number;
    saleTime: Date;
    paidTime: Date | null;
    paymentStatus: GalaTicketPaymentStatus;
    fName: string;
    lName: string;
    address: string;
    postalCode: string;
    city: string;
    province: string;
    country: string;
    email: string;
    phone: string;
    notes: string;
    sponsor: string;
    createdAt: Date;
    updatedAt: Date;

}
