/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../model/helpers/Dict';
import { ICorpRawMatMeta } from '../../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { IIngrLogMeta } from '../../../../model/IngrGroup/IngrLog/IIngrLog';
import { MeasurementEnum } from '../../../../model/IngrGroup/IngrLog/MeasurementEnum';
import './AddEditRawMatLog.scss';
import { CorpContext } from '../../../../controllers/CorpGroup/CorpContext';
import { IRawMatLogMeta } from '../../../../model/IngrGroup/RawMatLog/IRawMatLog';
import { IStore } from '../../../../model/storeGroup/store/IStore';

ReactModal.setAppElement('#root');
interface AddEditRawMatLogProps {
    open: boolean;
    rawMatMeta: IRawMatLogMeta | null;
    corpRawMatMetas: ICorpRawMatMeta[];
    setRawMatMetas: React.Dispatch<React.SetStateAction<IRawMatLogMeta[]>>;
    closeModal: () => void;

    store: IStore;
}

const AddEditRawMatLog: React.FC<AddEditRawMatLogProps> = ({
    open, rawMatMeta, corpRawMatMetas, setRawMatMetas, closeModal, store,
}) => {
    const { i18n } = useTranslation();

    const [ingrName, setIngrName] = useState<Dict<string>>({});
    const changeIngrName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpTaxName = e.target.value;
        const cLang = i18n.language;
        setIngrName((prevState) => ({
            ...prevState,
            [cLang]: tmpTaxName,
        }));
    };
    const [ingrMName, setIngrMName] = useState('');
    const changeIngrMName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIngrMName(e.target.value);
    };

    const [ingrDesc, setIngrDesc] = useState<Dict<string>>({});
    const changeIngrDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setIngrDesc((prevState) => ({
            ...prevState,
            [i18n.language]: val,
        }));
    };
    const [ingrMDesc, setIngrMDesc] = useState('');
    const changeIngrMDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIngrMDesc(e.target.value);
    };

    const [warningUnits, setWarningUnits] = useState(0);
    const changeWarningUnits = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setWarningUnits(value);
    };

    // TODO select from a drop down please
    const [unitOfM, setUnitOfM] = useState(MeasurementEnum.g);
    const changeUnitOfM = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as MeasurementEnum;
        setUnitOfM(value);
    };

    const [corpRawMatId, setCorpMatId] = useState<string | null>(null);
    const changeCorpRawMatId = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value;
        if (val === '') {
            setCorpMatId(null);
            return;
        }
        setCorpMatId(val);
    };

    const [contactStaffIds, setContactStaffIds] = useState<string[]>([]);

    const validStaffList = store.storeStaffRefs.map((staff) => ({
        value: staff.staffId,
        label: staff.nickName,
    }));
    const changeContactStaffIds = (staffIds: any[]) => {
        const tmpStaffIds = staffIds.map((multiValue) => multiValue.value);
        setContactStaffIds(tmpStaffIds);
    };

    const [autoReq, setAutoReq] = useState(false);
    const updateAutoReq = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAutoReq(e.target.checked);
    };

    const [autoReqUnits, setAutoReqUnits] = useState(0);
    const changeAutoReqUnits = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setAutoReqUnits(value);
    };

    const getStaffByStaffId = (staffId: string) => {
        const target = store.storeStaffRefs.find((staff) => staff.staffId === staffId);
        if (target === undefined) throw new Error('staff not found');
        return target.nickName;
    };

    const editRawMatLog = async () => {
        if (rawMatMeta === null) throw new Error('cannot edit new raw mat log');
        const rawMatLogResponse = await Factory.RawMatLogController.editRawMat(
            rawMatMeta._id,
            store._id,
            corpRawMatId, // select corp raw mat log id
            ingrName,
            ingrDesc,
            ingrMName,
            ingrMDesc,
            unitOfM,
            warningUnits,
            contactStaffIds,
            autoReq,
            autoReqUnits,
        );
        closeModal();
        setRawMatMetas(rawMatLogResponse.data.rawMatLogMetas);
    };

    const createRawMatLog = async () => {
        if (rawMatMeta !== null) throw new Error('cannot recreate');
        const ingrLogResponse = await Factory.RawMatLogController.createRawMat(
            store._id,
            corpRawMatId, // select corp raw mat log id
            ingrName,
            ingrDesc,
            ingrMName,
            ingrMDesc,
            unitOfM,
            warningUnits,
            contactStaffIds,
            autoReq,
            autoReqUnits,
        );
        closeModal();
        setRawMatMetas(ingrLogResponse.data.rawMatLogMetas);
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleConfirm = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        try {
            if (rawMatMeta === null) await createRawMatLog();
            else await editRawMatLog();

            // Clear all settings
            setIngrName({
                [i18n.language]: '',
            });
            setIngrDesc({
                [i18n.language]: '',
            });
            setIngrMName('');
            setIngrMDesc('');
            setHCLoading(false);
        } catch (err) {
            setHCLoading(false);
        }
    };

    useEffect(() => {
        if (rawMatMeta === null) {
            setIngrName({
                [i18n.language]: '',
            });
            setIngrDesc({
                [i18n.language]: '',
            });
            setIngrMName('');
            setIngrMDesc('');
            setCorpMatId('');
            setWarningUnits(0);
            setContactStaffIds([]);

            setAutoReq(false);
            setAutoReqUnits(0);
        } else {
            setIngrName(rawMatMeta.name);
            setIngrDesc(rawMatMeta.desc);
            setIngrMName(rawMatMeta.mName);
            setIngrMDesc(rawMatMeta.mDesc);
            setWarningUnits(rawMatMeta.warningUnits);
            setCorpMatId(rawMatMeta.corpRawMatId);
            setContactStaffIds(rawMatMeta.contactStaffIds);

            setAutoReq(rawMatMeta.autoReq);
            setAutoReqUnits(rawMatMeta.autoReqUnits);
        }
    }, [rawMatMeta]);

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditTaxModal"
            overlayClassName="addEditTaxModalOverlay"
        >
            <h2>Add/Edit Raw Material</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Raw Material Name</h4>
                    <input
                        value={ingrName[i18n.language]}
                        onChange={changeIngrName}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Raw Material Name</h4>
                    <input
                        type="text"
                        value={ingrMName}
                        onChange={changeIngrMName}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Raw Material Description</h4>
                    <input
                        value={ingrDesc[i18n.language]}
                        onChange={changeIngrDesc}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Raw Material Description</h4>
                    <input
                        type="text"
                        value={ingrMDesc}
                        onChange={changeIngrMDesc}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Measurement Units</h4>
                    {/* <h4>{unitOfM}</h4> */}
                    <select
                        // value={unitOfM}
                        onChange={changeUnitOfM}
                    >
                        {Object.keys(MeasurementEnum).map((mE) => (
                            <option value={mE} selected={mE === unitOfM}>{mE}</option>
                        ))}
                    </select>
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Warning Units</h4>
                    <input
                        value={warningUnits}
                        onChange={changeWarningUnits}
                        type="number"
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Link to corporate raw material (optional)</h4>
                    <select
                        onChange={changeCorpRawMatId}
                    >
                        <option value="">N/A</option>
                        {corpRawMatMetas.map((CRMM) => (
                            <option value={CRMM._id} selected={CRMM._id === corpRawMatId}>
                                {CRMM.name[i18n.language]}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Contact Staffs</h4>
                    <Select
                        isMulti
                        className="contactStaffSelector"
                        options={validStaffList}
                        defaultValue={contactStaffIds.map((staffId) => ({
                            value: staffId,
                            label: getStaffByStaffId(staffId),
                        }))}
                        onChange={(e: any) => changeContactStaffIds(e)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="inputCheckBox">
                    <input id="autoRequest" type="checkbox" checked={autoReq} onChange={updateAutoReq} />
                    <label htmlFor="autoRequest">Auto Request Units</label>
                </div>
                <div className="space" />
                <div className="inputBox">
                    <input
                        disabled={!autoReq}
                        type="number"
                        placeholder="Auto request units"
                        value={autoReqUnits}
                        onChange={changeAutoReqUnits}
                    />
                </div>
            </div>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={handleConfirm} disabled={HCLoading}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default AddEditRawMatLog;
