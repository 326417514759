import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { CorpContext } from '../../../../controllers/CorpGroup/CorpContext';
import { ToastController } from '../../../../controllers/ToastController';
import { ICorpRawMatMeta } from '../../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { IRawMatLogMeta } from '../../../../model/IngrGroup/RawMatLog/IRawMatLog';
import { IRawMatReq } from '../../../../model/IngrGroup/RawMatReq/IRawMatReq';
import { IStore, IStoreMeta } from '../../../../model/storeGroup/store/IStore';
import './CorpReqMaterials.scss';
import SendModal from './SendModal/SendModal';

interface CorpReqMaterialsProps {
    open: boolean;
    closeModal: () => void;
    rawMatReqArr: IRawMatReq[];
    corpRawMatMetas: ICorpRawMatMeta[];
    storeMetas: IStoreMeta[];
    updateRawMatReqArr: (arr: IRawMatReq[]) => void;
}

export enum RawMatReqStatus {
    CANCELED = -1,
    OPEN = 0,
    FULFILLED = 1,
}

const CorpReqMaterials:React.FC<CorpReqMaterialsProps> = ({
    open, closeModal, rawMatReqArr, corpRawMatMetas, storeMetas, updateRawMatReqArr,
}) => {
    const { i18n } = useTranslation();
    const { corp } = useContext(CorpContext)!;

    const [sendModalOpen, setSendModalOpen] = useState(false);
    const [selectedReq, setSelectedReq] = useState<IRawMatReq | null>(null);
    const openSendModal = (req: IRawMatReq) => {
        setSelectedReq(req);
        setSendModalOpen(true);
    };
    const closeSendModal = () => {
        setSendModalOpen(false);
        setSelectedReq(null);
    };

    const getCorpRawMetaById = (metaId: string) => {
        const target = corpRawMatMetas.find((meta) => meta._id === metaId);
        if (target === undefined) throw new Error('Cannot find corp raw mat');
        return target;
    };

    const getStoreMetaById = (storeId: string) => {
        const target = storeMetas.find((storeMeta) => storeMeta._id === storeId);
        if (target === undefined) throw new Error('Cannot find Store');
        return target;
    };

    const handleUpdateStatus = async (reqId: string, reqStatus: number) => {
        const corpReqArr = await Factory.RawMatReqController.updateStatus(
            reqId, reqStatus,
        );
        if (reqStatus === -1) ToastController.success('Successfully cancelled request');
        if (reqStatus === 1) ToastController.success('Successfully sent request');
        updateRawMatReqArr(corpReqArr.data.rawMatReqs);
    };

    const renderActions = (req: IRawMatReq) => {
        if (req.reqStatus === 0) {
            return (
                <>
                    <button
                        type="button"
                        className="cancel"
                        onClick={() => handleUpdateStatus(req._id, -1)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="send"
                        onClick={() => openSendModal(req)}
                    >
                        Send
                    </button>
                </>
            );
        }
        if (req.reqStatus === -1) {
            return (
                <button
                    type="button"
                    className="cancel"
                    disabled
                >
                    Cancelled
                </button>
            );
        }
        return (
            <button
                type="button"
                className="send"
                disabled
            >
                Sent
            </button>
        );
    };

    if (!open) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="CorpReqMaterialsModal"
            overlayClassName="CorpReqMaterialsModalOverlay"
        >
            <SendModal
                open={sendModalOpen}
                closeModal={closeSendModal}
                handleUpdateStatus={handleUpdateStatus}
                selectedReq={selectedReq}
            />
            <h2>Raw Material Requests</h2>
            {/* <div className="" */}
            {rawMatReqArr.map((req) => {
                const corpMat = getCorpRawMetaById(req.corpRawMatId);
                return (
                    <div className="request">
                        <h2>{getStoreMetaById(req.storeId).name[i18n.language]}</h2>
                        {corpMat.name[i18n.language]}
                        <p>{`${req.unitsReqed} units`}</p>
                        <p>{req.updateSentDates[0].toLocaleString()}</p>
                        <div className="actions">
                            {renderActions(req)}
                        </div>
                    </div>
                );
            })}
        </ReactModal>
    );
};

export default CorpReqMaterials;
