/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Factory } from '../../../controllers/ControllerFactory';
import { ToastController } from '../../../controllers/ToastController';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { IGalaAttendee } from '../../../model/galaGroup/galaAttendee/iGalaAttendee';
import { AuctionStatusEnum, AuctionTypeEnum } from '../../../model/galaGroup/galaAuction/galaAuctionFields';
import { IGalaAuction } from '../../../model/galaGroup/galaAuction/IGalaAuction';
import { IGalaAuctionBid } from '../../../model/galaGroup/galaAuction/IGalaAuctionBid';
import { amountToString } from '../../../utils/global';
import './AuctionModal.scss';

interface AuctionModalProps {
    open: boolean;
    closeModal: () => void;
    auction: IGalaAuction | null;
    currGala: IGala;
    refresh: () => Promise<void>;
}

const AuctionModal: React.FC<AuctionModalProps> = ({
    open, closeModal, auction, currGala, refresh,
}) => {
    const a = 1;
    const galaAuctionController = Factory.GalaAuctionController;

    // Get attendees
    const [attendees, setAttendees] = useState<IGalaAttendee[]>([]);
    const getAttendees = async () => {
        const tAttendees = await Factory.GalaController.getGalaAttendeesByGalaId(currGala._id);
        setAttendees(tAttendees);
    };
    useEffect(() => {
        getAttendees();
    }, []);

    const [isEdit, setIsEdit] = useState(false);

    const [uniqueLabel, setUniqueLabel] = useState('');
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [imgUrls, setImgUrls] = useState<string[]>([]);

    const [startPrice, setStartPrice] = useState(0);
    const [minIncrease, setMinIncrease] = useState(0);

    const onChangeVal = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, fn: React.Dispatch<React.SetStateAction<string>>) => {
        const val = e.target.value;
        fn(val);
    };

    const onChangeUrlVal = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, idx: number) => {
        const val = e.target.value;
        const newImgUrls = new Array(...imgUrls);
        newImgUrls[idx] = val;
        setImgUrls(newImgUrls);
    };

    const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, fn: React.Dispatch<React.SetStateAction<number>>) => {
        const dollarCents = e.target.value.split('.');
        if (dollarCents.length === 2 && dollarCents[1].length > 2) {
            e.preventDefault();
        } else fn(parseFloat(e.target.value));
    };

    const editSave = async () => {
        if (isEdit) {
            // save
            if (auction === null) {
                await galaAuctionController.createAuction(
                    currGala._id,
                    uniqueLabel,
                    AuctionTypeEnum.silent,
                    { en: name },
                    { en: desc },
                    imgUrls,
                    startPrice * 100,
                    minIncrease * 100,
                );
                setIsEdit(false);
                await refresh();
                closeModal();
                return;
            }
            await galaAuctionController.editAuction(
                auction._id,
                uniqueLabel,
                { en: name },
                { en: desc },
                imgUrls,
                startPrice * 100,
                minIncrease * 100,
            );
            setIsEdit(false);
            await refresh();
            closeModal();
            return;
        }
        setIsEdit(true);
    };

    const closeAuction = async () => {
        if (auction === null) return;
        await galaAuctionController.close(
            auction._id,
        );
        setIsEdit(false);
        await refresh();
        closeModal();
    };

    const openAuction = async () => {
        if (auction === null) return;
        await galaAuctionController.open(
            auction._id,
        );
        setIsEdit(false);
        await refresh();
        closeModal();
    };

    const [deleteBuffer, setDeleteBuffer] = useState(4);
    const [deleteTimerStarted, setDeleteTimerStarted] = useState(false);

    const [HALoading, setHALoading] = useState(false);
    const handleDelete = async () => {
        if (HALoading || auction === null) return;
        if (deleteTimerStarted && deleteBuffer !== 0) return;
        if (deleteTimerStarted && deleteBuffer === 0) {
            setHALoading(true);
            try {
                await galaAuctionController.deleteAuction(auction._id);
                await refresh();
            } catch (err) {
                //
            }
            setHALoading(false);
            closeModal();
            return;
        }
        setDeleteTimerStarted(true);
    };

    useEffect(() => {
        if (deleteTimerStarted) {
            setDeleteBuffer((prev) => prev - 1);
        }
    }, [deleteTimerStarted]);

    useEffect(() => {
        if (deleteTimerStarted && deleteBuffer > 0) {
            setTimeout(() => {
                setDeleteBuffer((prev) => prev - 1);
            }, 1000);
        }
    }, [deleteBuffer]);

    const chooseWinner = async (winningBidId: string) => {
        if (auction === null) return;
        await galaAuctionController.chooseWinner(
            auction._id,
            winningBidId,
        );
        setIsEdit(false);
        await refresh();
        closeModal();
    };

    const notifyWinner = async () => {
        if (auction === null) return;
        await galaAuctionController.notifyWinner(
            auction._id,
        );
        ToastController.success('Winner is notified');
    };

    // useEffect(() => {
    //     const getTicketSale = async (attendeeId: string) => {
    //         const tTicketSale = await galaAuctionController.getGalaTicketSaleByAttendeeId(attendeeId);
    //         setTicketSale(tTicketSale);
    //     };
    //     if (auction !== null) {
    //         getTicketSale(auction._id);
    //     } else setTicketSale(null);
    // }, [auction]);

    useEffect(() => {
        setDeleteBuffer(4);
        setDeleteTimerStarted(false);
        if (auction !== null) {
            setUniqueLabel(auction.uniqueLabel);
            setName(auction.name.en);
            setDesc(auction.desc.en);
            setImgUrls(auction.imgUrls);
            setStartPrice(auction.startPrice / 100);
            setMinIncrease(auction.minIncrease / 100);
            setIsEdit(false);
        } else {
            setUniqueLabel('');
            setName('');
            setDesc('');
            setImgUrls([]);
            setStartPrice(0);
            setMinIncrease(0);
            setIsEdit(false);
        }
    }, [open]);

    const auctionStatusToName = (tAucStatus : AuctionStatusEnum) => {
        if (tAucStatus === AuctionStatusEnum.open) return 'Open';
        if (tAucStatus === AuctionStatusEnum.unopen) return 'Unopen';
        if (tAucStatus === AuctionStatusEnum.closed) return 'Closed';
        return 'All';
    };

    const getAttendeeName = (galaAttendeeId : string) => {
        const attendee = attendees.find((att) => att._id === galaAttendeeId);
        return `${attendee?.fName} ${attendee?.lName} (${attendee?.ticketNum})`;
    };

    const getWinner = () => {
        if (auction === null) return 'Something is wrong';
        const winningBid = auction.galaAuctionBids.find((aa) => aa._id === auction.winningBidId);
        if (!winningBid) return 'Something is wrong';
        return `Winner is ${getAttendeeName(winningBid.galaAttendeeId)} for ${amountToString(winningBid.amount)}`;
    };

    const addNewImage = () => {
        const newImgUrls: string[] = new Array(...imgUrls);
        newImgUrls.push('');
        setImgUrls(newImgUrls);
    };

    const prependNewImage = () => {
        const newImgUrls: string[] = ['', ...imgUrls];
        setImgUrls(newImgUrls);
    };

    const removeImage = (idx: number) => {
        const newImgUrls: string[] = new Array(...imgUrls);
        newImgUrls.splice(idx, 1);
        setImgUrls(newImgUrls);
    };

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="auctionModal"
            overlayClassName="optionStatModalOverlay"
        >
            <header>
                <h2>
                    {`${auction === null ? 'Create New Silent Auction' : `Editing ${auction.name.en} : ${auctionStatusToName(auction.auctionStatus)}`}`}
                </h2>
                {!(auction !== null && auction.auctionStatus !== AuctionStatusEnum.unopen) && (
                    <button type="button" onClick={editSave}>
                        {(isEdit || auction === null) ? 'Save' : 'Edit'}
                    </button>
                )}
            </header>
            {(auction?.auctionStatus === AuctionStatusEnum.closed) && (
                <div>
                    <div className="winnerInfo">
                        {(auction.winningBidId === null) ? 'Winner Is Not Selected' : getWinner()}
                    </div>
                    <div className="buttonRow">
                        <button type="button" onClick={() => notifyWinner()}>
                            Notify Winner
                        </button>
                    </div>
                </div>
            )}
            {(auction !== null && auction.winningBidId === null) && (
                <div className="buttonRow">
                    {auction.auctionStatus !== AuctionStatusEnum.open && (
                        <button type="button" onClick={() => openAuction()}>
                            Open Auction
                        </button>
                    )}
                    {auction.auctionStatus === AuctionStatusEnum.open && (
                        <button type="button" onClick={() => closeAuction()}>
                            Close Auction
                        </button>
                    )}
                </div>
            )}
            {(auction !== null && auction.galaAuctionBids.length === 0) && (
                <div className="buttonRow">
                    <button type="button" className="deleteButton" onClick={() => handleDelete()} disabled={HALoading}>
                        {/* Delete Auction */}
                        {!deleteTimerStarted ? 'Delete Auction' : (
                            deleteBuffer === 0 ? (HALoading ? 'Loading ...' : 'Confirm') : deleteBuffer
                        )}
                    </button>
                </div>
            )}
            {(!isEdit && auction !== null) && (
                <>
                    <div className="infoWrapper">
                        <div className="info">
                            <p>Unique Label</p>
                            <p>{auction.uniqueLabel}</p>
                        </div>
                        <div className="info">
                            <p>Name</p>
                            <p>{auction.name.en}</p>
                        </div>
                        <div className="info">
                            <p>Description</p>
                            <p>{auction.desc.en}</p>
                        </div>
                        <div className="info">
                            <p>Auction Type</p>
                            <p>{auction.auctionType}</p>
                        </div>
                        <div className="info">
                            <p>Auction Type</p>
                            <p>{auctionStatusToName(auction.auctionStatus)}</p>
                        </div>
                        <div className="info">
                            <p>Start Price</p>
                            <p>{amountToString(auction.startPrice)}</p>
                        </div>
                        <div className="info">
                            <p>Current Price</p>
                            <p>{amountToString(auction.currPrice)}</p>
                        </div>
                        <div className="info">
                            <p>Minimum Increase</p>
                            <p>{amountToString(auction.minIncrease)}</p>
                        </div>
                        <div className="info">
                            <p>Bids Placed</p>
                            <p>{auction.galaAuctionBids.length}</p>
                        </div>
                        {
                            auction.imgUrls.map((imgUrl, idx) => (
                                <div className="row">
                                    <img src={imgUrl} alt={`Img${idx}`} />
                                </div>
                            ))
                        }
                    </div>
                    <h2>Bids by guests</h2>
                    {auction.galaAuctionBids.map((auctionBid: IGalaAuctionBid) => (
                        <>
                            <div className="infoWrapper">
                                <div className="info">
                                    <p>{getAttendeeName(auctionBid.galaAttendeeId)}</p>
                                    <p>{amountToString(auctionBid.amount)}</p>
                                    {auction.auctionStatus === AuctionStatusEnum.closed && (
                                        <button type="button" onClick={() => chooseWinner(auctionBid._id)}>
                                            Choose as winner
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    ))}
                </>
            )}

            {(isEdit || auction === null) && (
                <div className="editContainer">
                    <div className="row">
                        <p>Unique Label</p>
                        <input value={uniqueLabel} onChange={(e) => onChangeVal(e, setUniqueLabel)} />
                    </div>
                    <div className="row">
                        <p>Name</p>
                        <input value={name} onChange={(e) => onChangeVal(e, setName)} />
                    </div>
                    <div className="row">
                        <p>Description</p>
                        <input value={desc} onChange={(e) => onChangeVal(e, setDesc)} />
                    </div>
                    {/* <div className="row">
                        <p>Address</p>
                        <input value={imgUrl} onChange={(e) => onChangeVal(e, setImgUrl)} />
                    </div> */}
                    <div className="row">
                        <p>Start Price</p>
                        <input value={startPrice} type="number" onChange={(e) => onChangeAmount(e, setStartPrice)} />
                    </div>
                    <div className="row">
                        <p>Minimum Increase</p>
                        <input value={minIncrease} type="number" onChange={(e) => onChangeAmount(e, setMinIncrease)} />
                    </div>
                    <div className="imgUrlsContainer">
                        <div className="urlRow">
                            <p>Image Urls</p>
                            <button type="button" onClick={() => prependNewImage()}>Prepend new image</button>
                            <button type="button" onClick={() => addNewImage()}>Add new image</button>
                        </div>
                        {imgUrls.map((currUrl, idx) => (
                            <div className="urlRow">
                                <p>{`Image ${idx + 1}`}</p>
                                <input value={currUrl} type="url" onChange={(e) => onChangeUrlVal(e, idx)} />
                                <button className="rejectButton" type="button" onClick={() => removeImage(idx)}>X</button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </ReactModal>
    );
};

export default AuctionModal;
