export enum ItemFields {
    _id = '_id',
    version = 'version',
    name = 'name',
    desc = 'desc',
    itemPrices = 'itemPrices',
    itemType = 'itemType',
    instruction = 'instruction',
    itemImg = 'itemImg',
    categoryIds = 'categoryIds',
    optionIds = 'optionIds',
    comboIds = 'comboIds',
    tagIds = 'tagIds',
    taxIds = 'taxIds',
    discountIds = 'discountIds',
    surchargeIds = 'surchargeIds',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    active = 'active',
    mName = 'mName',
    mDesc = 'mDesc',
    needPrep = 'needPrep',
    // Router Fields
    storeId = 'storeId',
    itemId = 'itemId',
    dfltRdConfigSchema = 'dfltRdConfigSchema',
    ingrMap = 'ingrMap',
    optionRefs = 'optionRefs',
    comboRefs = 'comboRefs',
    comboSugIds = 'comboSugIds',
    subItemIds = 'subItemIds',
    // image
    image = 'image',
    imgId = 'imgId',

}
