import { AnalyticsContoller } from './Analytics/AnalyticsController';
import { CorpController } from './CorpGroup/CorpController';
import { GalaAuctionController } from './GalaGroup/galaAuctionController';
import { GalaController } from './GalaGroup/galaController';
import { CorpRawMatController } from './IngrGroup/CorpRawMatController';
import { IngrLogController } from './IngrGroup/IngrLogController';
import { RawMatLogController } from './IngrGroup/RawMatLogController';
import { RawMatReqController } from './IngrGroup/RawMatLogReqController.ts';
import { MenuController } from './Menu/MenuController';
import { ShiftPlannerController } from './ShiftPlanner/ShiftPlannerController';
import { StaffController } from './Staff/StaffController';
import { StoreStaffController } from './Staff/StoreStaffController';
import { MultiplierController } from './Store/MultiplierController';
import { StoreController } from './Store/StoreController';
import { TableController } from './Tables/TableController';
import { TransactionController } from './Transaction/TransactionController';
import { TwilioController } from './Twilio/TwilioController';

export class ControllerFactory {
    private MenuController: MenuController;
    private StoreController: StoreController;
    private TableController: TableController;
    private StaffController: StaffController;
    private TransactionController: TransactionController;
    private AnalyticsContoller: AnalyticsContoller;
    private MultiplierController: MultiplierController;
    private ShiftPlannerController: ShiftPlannerController;
    private TwilioController: TwilioController;
    public CorpController: CorpController = new CorpController();
    public StoreStaffController: StoreStaffController = new StoreStaffController();
    public IngrLogController: IngrLogController = new IngrLogController();
    public CorpRawMatController: CorpRawMatController = new CorpRawMatController();
    public RawMatLogController: RawMatLogController = new RawMatLogController();
    public RawMatReqController: RawMatReqController = new RawMatReqController();
    public GalaController: GalaController = new GalaController();
    public GalaAuctionController: GalaAuctionController = new GalaAuctionController();

    constructor() {
        this.StoreController = new StoreController();
        this.MenuController = new MenuController();
        this.TableController = new TableController();
        this.StaffController = new StaffController();
        this.TransactionController = new TransactionController();
        this.AnalyticsContoller = new AnalyticsContoller();
        this.MultiplierController = new MultiplierController();
        this.ShiftPlannerController = new ShiftPlannerController();
        this.TwilioController = new TwilioController();
        // this.StoreStaffController = new
    }

    public getMenuController() : MenuController {
        return this.MenuController;
    }

    public getStoreController() : StoreController {
        return this.StoreController;
    }

    public getTableController() : TableController {
        return this.TableController;
    }

    public getStaffController() : StaffController {
        return this.StaffController;
    }

    public getTransactionController() : TransactionController {
        return this.TransactionController;
    }

    public getAnalyticsContoller() : AnalyticsContoller {
        return this.AnalyticsContoller;
    }

    public getMultiplierController() : MultiplierController {
        return this.MultiplierController;
    }

    public getShiftPlannerController() : ShiftPlannerController {
        return this.ShiftPlannerController;
    }

    public getTwilioController() : TwilioController {
        return this.TwilioController;
    }
}

export const Factory : ControllerFactory = new ControllerFactory();
