import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Factory } from '../../../controllers/ControllerFactory';
import { CorpContext } from '../../../controllers/CorpGroup/CorpContext';
import { StoreContext } from '../../../controllers/Store/StoreContext';
import { ICorpRawMatMeta } from '../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { IRawMatLogMeta } from '../../../model/IngrGroup/RawMatLog/IRawMatLog';
import { IStore } from '../../../model/storeGroup/store/IStore';
import AddBar from '../components/AddBar/AddBar';
import AddEditRawMatLog from './AddEditRawMatLog/AddEditRawMatLog';
import RawMatAdjModal from './RawMatAdjModal/RawMatAdjModal';
import RawMatCard from './RawMatCard/RawMatCard';

import './RawMaterials.scss';
import RawMatReportModal from './RawMatReportModal/RawMatReportModal';
import RawMatStockModal from './RawMatStockModal/RawMatStockModal';
import RequestMaterialsModal from './RequestMaterials/RequestMaterialsModal';

interface RawMaterialsProps{
    store?: IStore;
}

const RawMaterials: React.FC<RawMaterialsProps> = ({
    store,
}) => {
    const { t } = useTranslation();

    // Get store context
    const { store: storeFromContext } = useContext(StoreContext)!;

    const getStore = () => {
        if (store !== undefined) return store;
        return storeFromContext;
    };

    // Get ingr log
    const [rawMatMetas, setRawMatMetas] = useState<IRawMatLogMeta[]>([]);
    const [corpRawMatMetas, setCorpRawMatMetas] = useState<ICorpRawMatMeta[]>([]);

    useEffect(() => {
        const getRawMatMetas = async () => {
            const resp = await Factory.RawMatLogController.getRawMatLogMetas(
                getStore()._id,
            );
            setRawMatMetas(resp.data.rawMatLogMetas);
        };
        const getCorpRawMatMetas = async () => {
            const corpRawMatMetasResp = await Factory.CorpRawMatController.getCorpLogMetas(
                getStore().corpId,
            );
            setCorpRawMatMetas(corpRawMatMetasResp);
        };
        getRawMatMetas();
        getCorpRawMatMetas();
    }, []);

    const [currIngrLog, setCurrIngrLog] = useState<IRawMatLogMeta | null>(null);

    const [addEditIngrOpen, setAddEditIngrOpen] = useState(false);
    const openAddEditIngr = () => setAddEditIngrOpen(true);
    const closeAddEditIngr = () => setAddEditIngrOpen(false);

    const [ingrStockModalOpen, setIngrStockModalOpen] = useState(false);
    const openIngrStockModal = () => setIngrStockModalOpen(true);
    const closeIngrStockModal = () => setIngrStockModalOpen(false);

    const [ingrAdjModalOpen, setIngrAdjModalOpen] = useState(false);
    const openIngrAdjModal = () => setIngrAdjModalOpen(true);
    const closeIngrAdjModal = () => setIngrAdjModalOpen(false);

    const [reqMatModalOpen, setReqMatModalOpen] = useState(false);
    const openReqMatModal = () => setReqMatModalOpen(true);
    const closeReqMatModal = () => setReqMatModalOpen(false);

    const [ingrReportModalOpen, setIngrReportModalOpen] = useState(false);
    const openIngrReportModal = () => setIngrReportModalOpen(true);
    const closeIngrReportModal = () => setIngrReportModalOpen(false);

    const generateIngrLogCards = () => {
        if (rawMatMetas === null) return <></>;
        return rawMatMetas.map((meta: IRawMatLogMeta) => (
            <RawMatCard
                rawMatMeta={meta}
                selectIngrLog={() => {
                    setCurrIngrLog(meta);
                    openAddEditIngr();
                }}
                openIngrStockModal={() => {
                    setCurrIngrLog(meta);
                    openIngrStockModal();
                }}
                openIngrAdjModal={() => {
                    setCurrIngrLog(meta);
                    openIngrAdjModal();
                }}
                openIngrReportModal={() => {
                    setCurrIngrLog(meta);
                    openIngrReportModal();
                }}
            />
        ));
    };

    return (

        <div className="rawMaterialsContainer dashboardScreen">
            <AddEditRawMatLog
                rawMatMeta={currIngrLog}
                open={addEditIngrOpen}
                corpRawMatMetas={corpRawMatMetas}
                setRawMatMetas={setRawMatMetas}
                closeModal={closeAddEditIngr}
                store={getStore()}
            />
            <RawMatStockModal
                rawMatMeta={currIngrLog}
                open={ingrStockModalOpen}
                setRawMatMetas={setRawMatMetas}
                closeModal={closeIngrStockModal}
            />
            <RawMatAdjModal
                rawMatMeta={currIngrLog}
                open={ingrAdjModalOpen}
                setRawMatMetas={setRawMatMetas}
                closeModal={closeIngrAdjModal}
            />
            <RawMatReportModal
                rawMatMeta={currIngrLog}
                open={ingrReportModalOpen}
                closeModal={closeIngrReportModal}
            />
            <RequestMaterialsModal
                open={reqMatModalOpen}
                closeModal={closeReqMatModal}
                store={getStore()}
                rawMatMetas={rawMatMetas}
                corpRawMatMetas={corpRawMatMetas}
            />
            <header>
                <h2 className="screenTitle">
                    Raw Materials
                </h2>
                <button
                    type="button"
                    onClick={openReqMatModal}
                    className="requestRawMats"
                >
                    Request
                </button>
            </header>
            <AddBar
                handleClick={() => {
                    setCurrIngrLog(null);
                    openAddEditIngr();
                }}
                text="Add New Raw Material"
            />
            <div className="taxContainer">
                {generateIngrLogCards()}
            </div>
        </div>

    );
};

export default RawMaterials;
