import React, { useReducer } from 'react';
import { IStore } from '../../model/storeGroup/store/IStore';

// https://stackoverflow.com/questions/49949099/react-createcontext-point-of-defaultvalue
export const StoreContext = React.createContext<
    { store: IStore; setStore: React.Dispatch<IStore>; } | undefined
>(undefined);

const reducer = (state: IStore, pair: IStore) => ({ ...state, ...pair });

interface StoreProviderProps {
    storeProp: IStore;
    children: React.ReactNode;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({
    storeProp, children,
}) => {
    const [store, setStore] = useReducer(reducer, storeProp);
    return (
        <StoreContext.Provider value={{ store, setStore }}>
            {children}
        </StoreContext.Provider>
    );
};
