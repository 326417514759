import React, { useState, useEffect, useContext } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { AnalyticsLocale } from './AnalyticsLocale';

import './Analytics.scss';
import { Factory } from '../../controllers/ControllerFactory';
import { StoreContext } from '../../controllers/Store/StoreContext';
import { IAggregateReport } from '../../model/analyticsGroup/IAggregateReport';
import Spinner from '../../components/Spinner/Spinner';
import { amountToString } from '../../utils/global';
import Sales from './Sales/Sales';
import Transactions from './Transactions/Transactions';
import Orders from './Orders/Orders';
import Inventory from './Inventory/Inventory';
import { ISeshReport } from '../../model/analyticsGroup/seshReport/ISeshReport';
import Staff from './Staff/Staff';
import { IStore } from '../../model/storeGroup/store/IStore';

enum StatType {
    SALES = 'sales',
    TRANSACTIONS = 'transactions',
    ORDERS = 'orders',
    INVENTORY = 'inventory',
    STAFF = 'staff',
}

enum DataType {
    DATE = 'date',
    DATE_RANGE = 'date_range',
}

interface AnalyticsProps {
    store?: IStore;
}

const Analytics: React.FC<AnalyticsProps> = ({
    store,
}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const analyticsController = Factory.getAnalyticsContoller();

    const { store: storeFromContext } = useContext(StoreContext)!;

    const getStore = () => {
        if (store !== undefined) return store;
        return storeFromContext;
    };

    const today = new Date();
    const tomorrow = new Date();
    today.setHours(0, 0, 0, 0);
    tomorrow.setDate(today.getDate() + (86399 / 86400));
    const [date, setDate] = useState<[Date, Date]>([today, tomorrow]);

    const [statType, setStatType] = useState(StatType.SALES);
    const [dataType, setDataType] = useState(DataType.DATE);

    const [aggregateData, setAggregateData] = useState<IAggregateReport | null>(null);
    const [seshData, setSeshData] = useState<ISeshReport | null>(null);

    const selectDate = (dateRange: [Date, Date]) => {
        setDate(dateRange);
    };

    const selectSession = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const sessionId = e.target.value;
        if (sessionId === '') {
            setSeshData(null);
            return;
        }
        const seshResponse = await analyticsController.getSeshReport(
            getStore()._id,
            sessionId,
        );
        setSeshData(seshResponse.data.seshReport);
    };

    useEffect(() => {
        const getAggregate = async () => {
            setAggregateData(null);
            setStatType(StatType.SALES);
            const tmpAggData = await analyticsController.getAggregateReport(
                getStore()._id,
                date[0],
                date[1],
            );
            setAggregateData(tmpAggData.data.aggregateReport);
            setSeshData(null);
        };
        getAggregate();
    }, [date]);

    const getSubTitle = () => {
        if (seshData !== null) {
            const startTimeStr = new Date(seshData.startTime).toLocaleTimeString();
            const endTimeStr = seshData.endTime ? new Date(seshData.endTime).toLocaleTimeString() : 'Currently Open';
            return `Store Session: ${startTimeStr} to ${endTimeStr}`;
        }
        if (aggregateData !== null) {
            const startTimeStr = new Date(aggregateData.startTime).toLocaleDateString();
            const endTimeStr = new Date(aggregateData.endTime).toLocaleDateString();
            return `Aggregate Report: ${startTimeStr} to ${endTimeStr}`;
        }
        return 'Please Select Dates';
    };

    return (
        <div className="analyticsContainer dashboardScreen">
            <h2 className="screenTitle">{t(AnalyticsLocale.analytics)}</h2>
            <h3>{getSubTitle()}</h3>
            <div className="analytics">
                <div className="calendarContainer">
                    <div className="dataType">
                        <button
                            type="button"
                            className={`${dataType === DataType.DATE ? 'active' : ''}`}
                            onClick={() => setDataType(DataType.DATE)}
                        >
                            Date
                        </button>
                        <button
                            type="button"
                            className={`${dataType === DataType.DATE_RANGE ? 'active' : ''}`}
                            onClick={() => setDataType(DataType.DATE_RANGE)}
                        >
                            Date Range
                        </button>
                    </div>
                    <Calendar
                        // @ts-ignore
                        onChange={(val: [Date, Date]) => selectDate(val)}
                        value={date}
                        defaultValue={date}
                        selectRange={dataType === DataType.DATE_RANGE}
                        returnValue="range"
                    />
                </div>
                <div className="statsContainer">
                    {aggregateData !== null && (
                        <header>

                            <select className="sessionSelector" onChange={selectSession}>
                                <option value="">All sessions</option>
                                {Object.keys(aggregateData.seshReportMap).map((day: string) => {
                                    const seshMeta = aggregateData.seshReportMap[day];
                                    return (
                                        <option value={day}>
                                            {
                                                seshMeta.endTime ? new Date(seshMeta.startTime).toLocaleString() : 'Ongoing'
                                            }
                                        </option>
                                    );
                                })}
                            </select>

                            <div className="statSelector">
                                <button
                                    type="button"
                                    className={`${statType === StatType.SALES ? 'active' : ''}`}
                                    onClick={() => setStatType(StatType.SALES)}
                                >
                                    Overview
                                </button>
                                <button
                                    type="button"
                                    className={`${statType === StatType.TRANSACTIONS ? 'active' : ''}`}
                                    onClick={() => setStatType(StatType.TRANSACTIONS)}
                                >
                                    Transactions
                                </button>
                                <button
                                    type="button"
                                    className={`${statType === StatType.ORDERS ? 'active' : ''}`}
                                    onClick={() => setStatType(StatType.ORDERS)}
                                >
                                    Orders
                                </button>
                                <button
                                    type="button"
                                    className={`${statType === StatType.INVENTORY ? 'active' : ''}`}
                                    onClick={() => setStatType(StatType.INVENTORY)}
                                >
                                    Inventory
                                </button>
                                <button
                                    type="button"
                                    className={`${statType === StatType.STAFF ? 'active' : ''}`}
                                    onClick={() => setStatType(StatType.STAFF)}
                                >
                                    Staff
                                </button>
                            </div>
                        </header>
                    )}
                    {statType === StatType.SALES && (
                        <Sales aggregateData={aggregateData} seshData={seshData} />
                    )}
                    {statType === StatType.TRANSACTIONS && (
                        <Transactions aggregateData={aggregateData} seshData={seshData} />
                    )}
                    {statType === StatType.ORDERS && (
                        <Orders aggregateData={aggregateData} seshData={seshData} />
                    )}
                    {statType === StatType.INVENTORY && (
                        <Inventory aggregateData={aggregateData} seshData={seshData} />
                    )}
                    {statType === StatType.STAFF && (
                        <Staff aggregateData={aggregateData} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Analytics;
