import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import editImg from '../../../../../assets/edit.svg';
import './ItemCard.scss';
import { IItem } from '../../../../../model/menuGroup/item/IItem';
import { amountToString } from '../../../../../utils/global';
import { Factory } from '../../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../../controllers/Store/StoreContext';
import { IItemTypes } from '../../../../../model/menuGroup/item/IItemTypes';

interface ItemCardProps{
    item: IItem;
    priceKeyId: string;
    selectItem: (item: IItem) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({
    item, priceKeyId, selectItem,
}) => {
    const { t, i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;
    const menuController = Factory.getMenuController();

    const [itemPrice, setItemPrice] = useState<number>(0);

    const setItemActive = async () => {
        const itemResponse = await menuController.setItemActive(
            store._id,
            item._id,
            !item.active,
        );
        setStore(itemResponse.store);
    };

    useEffect(() => {
        if (item.itemType !== IItemTypes.REG && item.itemType !== IItemTypes.COMBO) return;
        const tItemPrice = item.itemPrices.find(
            (tmpItemPrice) => tmpItemPrice.priceKeyId === priceKeyId,
        );
        if (tItemPrice === undefined) throw new Error('item does not have item price key id');
        setItemPrice(tItemPrice.amount);
    });

    return (

        <div
            className="itemCardContainer"
        >
            <img className="itemImg" src="https://enjoyjava.com/wp-content/uploads/2018/01/How-to-make-strong-coffee.jpg" alt="src" />
            <div className="info">
                <h3>{item.name[i18n.language]}</h3>
                <div className="desc">{item.desc[i18n.language]}</div>
                {item.itemType !== IItemTypes.SUBCATEG && <p className="price">{amountToString(itemPrice)}</p>}
            </div>
            <div className="itemFnContainer">
                <div
                    className={`slider ${item.active ? '' : 'inactive'}`}
                    aria-hidden
                    onClick={setItemActive}
                >
                    <div className="knob" />
                </div>
                <div className="edit" aria-hidden onClick={() => selectItem(item)}>
                    <img src={editImg} alt="edit" />
                </div>
            </div>
        </div>

    );
};

export default ItemCard;
