import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../model/helpers/Dict';
import { IOption } from '../../../../model/menuGroup/option/IOption';
import { IOptionElem } from '../../../../model/menuGroup/option/optionElem/IOptionElem';
import './AddEditOption.scss';

ReactModal.setAppElement('#root');
interface AddEditOptionProps {
    open: boolean;
    option: IOption | null;
    closeModal: () => void;
    handleContinue?: (newOptId: string) => void;
}

const AddEditOption: React.FC<AddEditOptionProps> = ({
    open, closeModal, option, handleContinue,
}) => {
    const { i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;
    const menuController = Factory.getMenuController();

    const [optionName, setOptionName] = useState<Dict<string>>({});
    const [optionMName, setOptionMName] = useState('');
    const [optionDesc, setOptionDesc] = useState<Dict<string>>({});
    const [optionMDesc, setOptionMDesc] = useState('');

    const changeOptionName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpOptionName = e.target.value;
        const cLang = i18n.language;
        setOptionName((prevState) => ({
            ...prevState,
            [cLang]: tmpOptionName,
        }));
    };

    const changeOptionDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpOptionDesc = e.target.value;
        setOptionDesc((prevState) => ({
            ...prevState,
            [i18n.language]: tmpOptionDesc,
        }));
    };

    const [optionElems, setOptionElems] = useState<Dict<IOptionElem>>({});
    const addOptionElem = () => {
        setOptionElems((prevState) => ({
            ...prevState,
            [Date.now().toString()]: {
                name: {
                    [i18n.language]: '',
                },
                desc: {
                    [i18n.language]: '',
                },
                _id: null,
            },
        }));
    };
    const removeOptionElem = (id: string) => {
        const newState = { ...optionElems };
        delete newState[id];
        setOptionElems(newState);
    };

    const changeOptionElemName = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const tmpOptionElemName = e.target.value;
        const cLang = i18n.language;
        setOptionElems((prevState) => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                name: {
                    [cLang]: tmpOptionElemName,
                },
            },
        }));
    };

    const changeOptionElemDesc = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const tmpOptionElemDesc = e.target.value;
        const cLang = i18n.language;
        setOptionElems((prevState) => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                desc: {
                    [cLang]: tmpOptionElemDesc,
                },
            },
        }));
    };

    const createOption = async () => {
        if (option !== null) throw new Error('cannot add existing option');
        const menuResponse = await menuController.createOption(
            store._id,
            optionName,
            optionMName,
            optionDesc,
            optionMDesc,
            optionElems,
        );
        setStore(menuResponse.store);
        closeModal();
        return menuResponse.option;
    };

    const editOption = async () => {
        if (option === null) throw new Error('cannot edit new option');
        const menuResponse = await menuController.editOption(
            store._id,
            option._id,
            optionName,
            optionMName,
            optionDesc,
            optionMDesc,
            optionElems,
        );
        setStore(menuResponse.store);
        closeModal();
        return menuResponse.option;
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleConfirm = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        let tmpOpt: IOption;
        if (option === null) {
            tmpOpt = await createOption();
        } else {
            tmpOpt = await editOption();
        }
        if (handleContinue !== undefined) {
            handleContinue(tmpOpt._id);
        }
        setOptionName({
            [i18n.language]: '',
        });
        setOptionMName('');
        setOptionDesc({
            [i18n.language]: '',
        });
        setOptionMDesc('');
        setOptionElems({});
        setHCLoading(false);
    };

    useEffect(() => {
        if (option === null) {
            setOptionName({
                [i18n.language]: '',
            });
            setOptionMName('');
            setOptionDesc({
                [i18n.language]: '',
            });
            setOptionMDesc('');
            setOptionElems({});
        } else {
            setOptionName(option.name);
            setOptionMName(option.mName);
            setOptionDesc(option.desc);
            setOptionMDesc(option.mDesc);
            const localElems: Dict<IOptionElem> = {};
            option.optionElems.forEach((el, idx) => {
                localElems[idx.toString()] = {
                    ...el,
                    name: el.name,
                    desc: el.desc,

                };
            });
            setOptionElems(localElems);
        }
    }, [option]);

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditOptionModal"
            overlayClassName="addEditOptionModalOverlay"
        >
            <h2>Add Option</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Option Name</h4>
                    <input
                        value={optionName[i18n.language]}
                        onChange={changeOptionName}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Option Name</h4>
                    <input
                        value={optionMName}
                        onChange={(e) => setOptionMName(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Description</h4>
                    <input
                        value={optionDesc[i18n.language]}
                        onChange={changeOptionDesc}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Description</h4>
                    <input value={optionMDesc} onChange={(e) => setOptionMDesc(e.target.value)} />
                </div>
            </div>
            <h4 className="optionElemHeader">Option Elements</h4>
            { Object.keys(optionElems).map((optionElemId) => {
                const optionElem = optionElems[optionElemId];
                return (
                    <div className="row">
                        <div className="inputBox">
                            <h4>Option Element Name</h4>
                            <input
                                value={optionElem.name.en}
                                onChange={(e) => changeOptionElemName(e, optionElemId)}
                            />
                        </div>
                        <div className="space" />
                        <div className="inputBox">
                            <h4>Option Element Description</h4>
                            <input
                                value={optionElem.desc[i18n.language]}
                                onChange={(e) => changeOptionElemDesc(e, optionElemId)}
                            />
                        </div>
                        <button type="button" onClick={() => removeOptionElem(optionElemId)} className="removeOptionElem">
                            x
                        </button>
                    </div>
                );
            })}
            <div className="row">
                <button type="button" className="addOptionElem" onClick={addOptionElem}>+</button>
            </div>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={handleConfirm} disabled={HCLoading}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default AddEditOption;
