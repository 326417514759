import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Factory } from '../../../controllers/ControllerFactory';
import { CorpContext } from '../../../controllers/CorpGroup/CorpContext';
import { StoreContext } from '../../../controllers/Store/StoreContext';
import { ICorpRawMatMeta } from '../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { IIngrLogMeta } from '../../../model/IngrGroup/IngrLog/IIngrLog';
import AddBar from '../../Menu/components/AddBar/AddBar';
import IngrLogCard from '../../Menu/IngrLogEditor/IngrCard/IngrLogCard';
import AddEditCorpRawMatLog from './AddEditCorpRawMatLog/AddEditCorpRawMatLog';
import CorpRawMatAdjModal from './CorpRawMatAdjModal/CorpRawMatAdjModal';
import CorpRawMatCard from './CorpRawMatCard/CorpRawMatCard';
import CorpRawMatReportModal from './CorpRawMatReportModal/CorpRawMatReportModal';
import CorpRawMatStockModal from './CorpRawMatStockModal/CorpRawMatStockModal';
import './CorpRawMaterials.scss';
import { IRawMatReq } from '../../../model/IngrGroup/RawMatReq/IRawMatReq';
import CorpReqMaterials from './CorpReqMaterials/CorpReqMaterials';
import { IStoreMeta } from '../../../model/storeGroup/store/IStore';

interface CorpRawMaterialsProps{}

const CorpRawMaterials: React.FC<CorpRawMaterialsProps> = () => {
    const { t } = useTranslation();

    // Get store context
    const { corp } = useContext(CorpContext)!;

    // Get ingr log
    const [corpRawMatMetas, setCorpRawMatMetas] = useState<ICorpRawMatMeta[]>([]);
    const [rawMatReqArr, setRawMatReqArr] = useState<IRawMatReq[]>([]);
    const updateRawMatReqArr = (arr: IRawMatReq[]) => setRawMatReqArr(arr);
    const [storeMetas, setStoreMetas] = useState<IStoreMeta[]>([]);
    useEffect(() => {
        const getCorpRawMatMetas = async () => {
            const metas : ICorpRawMatMeta[] = await Factory.CorpRawMatController.getCorpLogMetas(
                corp._id,
            );
            setCorpRawMatMetas(metas);
        };
        const getRawMatReqArr = async () => {
            const arr = await Factory.RawMatReqController.getRawMatReqByCorpId(
                corp._id,
            );
            setRawMatReqArr(arr);
        };
        const getStoreMetas = async () => {
            const arr = await Factory.getStoreController().getStoreMetasByCorpId(
                corp._id,
            );
            setStoreMetas(arr.data.storeMetas);
        };
        getStoreMetas();
        getRawMatReqArr();
        getCorpRawMatMetas();
    }, []);

    const [currIngrLog, setCurrIngrLog] = useState<ICorpRawMatMeta | null>(null);

    const [addEditIngrOpen, setAddEditIngrOpen] = useState(false);
    const openAddEditIngr = () => setAddEditIngrOpen(true);
    const closeAddEditIngr = () => setAddEditIngrOpen(false);

    const [ingrStockModalOpen, setIngrStockModalOpen] = useState(false);
    const openIngrStockModal = () => setIngrStockModalOpen(true);
    const closeIngrStockModal = () => setIngrStockModalOpen(false);

    const [ingrAdjModalOpen, setIngrAdjModalOpen] = useState(false);
    const openIngrAdjModal = () => setIngrAdjModalOpen(true);
    const closeIngrAdjModal = () => setIngrAdjModalOpen(false);

    const [ingrReportModalOpen, setIngrReportModalOpen] = useState(false);
    const openIngrReportModal = () => setIngrReportModalOpen(true);
    const closeIngrReportModal = () => setIngrReportModalOpen(false);

    const [reqMatModalOpen, setReqMatModalOpen] = useState(false);
    const openReqMatModal = () => setReqMatModalOpen(true);
    const closeReqMatModal = () => setReqMatModalOpen(false);

    const generateIngrLogCards = () => {
        if (corpRawMatMetas === null) return <></>;
        return corpRawMatMetas.map((meta: ICorpRawMatMeta) => (
            <CorpRawMatCard
                corpRawMatMeta={meta}
                selectIngrLog={() => {
                    setCurrIngrLog(meta);
                    openAddEditIngr();
                }}
                openIngrStockModal={() => {
                    setCurrIngrLog(meta);
                    openIngrStockModal();
                }}
                openIngrAdjModal={() => {
                    setCurrIngrLog(meta);
                    openIngrAdjModal();
                }}
                openIngrReportModal={() => {
                    setCurrIngrLog(meta);
                    openIngrReportModal();
                }}
            />
        ));
    };

    return (

        <div className="corpRawMaterialsContainer dashboardScreen">
            <AddEditCorpRawMatLog
                corpRawMatMeta={currIngrLog}
                open={addEditIngrOpen}
                setCorpRawMatMetas={setCorpRawMatMetas}
                closeModal={closeAddEditIngr}
            />
            <CorpRawMatStockModal
                corpRawMatMeta={currIngrLog}
                open={ingrStockModalOpen}
                setCorpRawMatMetas={setCorpRawMatMetas}
                closeModal={closeIngrStockModal}
            />
            <CorpRawMatAdjModal
                corpRawMatMeta={currIngrLog}
                open={ingrAdjModalOpen}
                setCorpRawMatMetas={setCorpRawMatMetas}
                closeModal={closeIngrAdjModal}
            />
            <CorpRawMatReportModal
                corpRawMatMeta={currIngrLog}
                open={ingrReportModalOpen}
                closeModal={closeIngrReportModal}
            />

            <CorpReqMaterials
                open={reqMatModalOpen}
                closeModal={closeReqMatModal}
                rawMatReqArr={rawMatReqArr}
                corpRawMatMetas={corpRawMatMetas}
                storeMetas={storeMetas}
                updateRawMatReqArr={updateRawMatReqArr}
            />
            <header>
                <h2 className="screenTitle">
                    Corporation Raw Materials
                </h2>
                <button
                    type="button"
                    onClick={openReqMatModal}
                    className="requestRawMats"
                >
                    Requests
                </button>
            </header>
            <AddBar
                handleClick={() => {
                    setCurrIngrLog(null);
                    openAddEditIngr();
                }}
                text="Add New Raw Material"
            />
            <div className="taxContainer">
                {generateIngrLogCards()}
            </div>
        </div>

    );
};

export default CorpRawMaterials;
