import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../model/helpers/Dict';
import { IIngrLogMeta } from '../../../../model/IngrGroup/IngrLog/IIngrLog';
import { MeasurementEnum } from '../../../../model/IngrGroup/IngrLog/MeasurementEnum';
import { IRawMatLogMeta } from '../../../../model/IngrGroup/RawMatLog/IRawMatLog';
import './AddEditIngrLog.scss';

ReactModal.setAppElement('#root');
interface AddEditIngrLogProps {
    open: boolean;
    ingrLogMeta: IIngrLogMeta | null;
    rawMatLogMetas: IRawMatLogMeta[];
    setIngrLogMetas: React.Dispatch<React.SetStateAction<IIngrLogMeta[]>>;
    closeModal: () => void;
}

const AddEditIngrLog: React.FC<AddEditIngrLogProps> = ({
    open, ingrLogMeta, rawMatLogMetas, setIngrLogMetas, closeModal,
}) => {
    const { i18n } = useTranslation();
    const { store } = useContext(StoreContext)!;

    const [ingrName, setIngrName] = useState<Dict<string>>({});
    const changeIngrName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpTaxName = e.target.value;
        const cLang = i18n.language;
        setIngrName((prevState) => ({
            ...prevState,
            [cLang]: tmpTaxName,
        }));
    };
    const [ingrMName, setIngrMName] = useState('');
    const changeIngrMName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIngrMName(e.target.value);
    };

    const [ingrDesc, setIngrDesc] = useState<Dict<string>>({});
    const changeIngrDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpTaxDesc = e.target.value;
        setIngrDesc((prevState) => ({
            ...prevState,
            [i18n.language]: tmpTaxDesc,
        }));
    };
    const [ingrMDesc, setIngrMDesc] = useState('');
    const changeIngrMDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIngrMDesc(e.target.value);
    };

    const [warningUnits, setWarningUnits] = useState(0);
    const changeWarningUnits = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setWarningUnits(value);
    };

    const [unitOfM, setUnitOfM] = useState(MeasurementEnum.g);
    const changeUnitOfM = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as MeasurementEnum;
        setUnitOfM(value);
    };

    const [recipeMap, setRecipeMap] = useState<Dict<number>>({});

    const [recipeMatIds, setRecipeMatIds] = useState<string[]>([]);

    const updateRecipeMap = (
        key: string,
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const val = parseFloat(e.target.value);
        setRecipeMap((prevState) => ({
            ...prevState,
            [key]: val,
        }));
    };

    const validRawMats = rawMatLogMetas.map((meta) => ({
        value: meta._id,
        label: meta.name[i18n.language],
    }));
    const changeRecipeMatIds = (matId: any[]) => {
        const tmpMatIds = matId.map((multiValue) => multiValue.value);
        setRecipeMatIds(tmpMatIds);

        for (const tId of tmpMatIds) {
            if (!Object.keys(recipeMap).includes(tId)) {
                setRecipeMap((prevState) => ({
                    ...prevState,
                    [tId]: 0,
                }));
            }
        }

        for (const tId of Object.keys(recipeMap)) {
            if (!tmpMatIds.includes(tId)) {
                setRecipeMap((prevState) => {
                    const newObj = { ...prevState };
                    delete newObj[tId];
                    return newObj;
                });
            }
        }
    };

    const [recipeCost, setRecipeCost] = useState(0);
    const changeRecipeCost = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(e.target.value);
        setRecipeCost(val);
    };

    const editIngrLog = async () => {
        if (ingrLogMeta === null) throw new Error('cannot edit new ingredient');
        const ingrLogResponse = await Factory.IngrLogController.editIngrLog(
            ingrLogMeta._id,
            store._id,
            ingrName,
            ingrDesc,
            ingrMName,
            ingrMDesc,
            unitOfM,
            warningUnits,
            recipeCost,
            recipeMap,
        );
        closeModal();
        setIngrLogMetas(ingrLogResponse.data.ingrLogMetas);
    };

    const createIngrLog = async () => {
        if (ingrLogMeta !== null) throw new Error('cannot reCreate twice');
        const ingrLogResponse = await Factory.IngrLogController.createIngrLog(
            store._id,
            ingrName,
            ingrDesc,
            ingrMName,
            ingrMDesc,
            unitOfM,
            warningUnits,
            recipeCost,
            recipeMap,
        );
        closeModal();
        setIngrLogMetas(ingrLogResponse.data.ingrLogMetas);
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleConfirm = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        if (ingrLogMeta === null) await createIngrLog();
        else await editIngrLog();

        // Clear all settings
        setIngrName({
            [i18n.language]: '',
        });
        setIngrDesc({
            [i18n.language]: '',
        });
        setIngrMName('');
        setIngrMDesc('');
        setHCLoading(false);
    };

    const getRawMatNameById = (rawMatId: string) => {
        const target = rawMatLogMetas.find((tmp) => tmp._id === rawMatId);
        if (target === undefined) throw new Error('cannot find rawmat');
        return target.name[i18n.language];
    };

    useEffect(() => {
        if (ingrLogMeta === null) {
            setIngrName({
                [i18n.language]: '',
            });
            setIngrDesc({
                [i18n.language]: '',
            });
            setIngrMName('');
            setIngrMDesc('');
        } else {
            setIngrName(ingrLogMeta.name);
            setIngrDesc(ingrLogMeta.desc);
            setIngrMName(ingrLogMeta.mName);
            setIngrMDesc(ingrLogMeta.mDesc);
            setWarningUnits(ingrLogMeta.warningUnits);
            setRecipeMap(ingrLogMeta.recipeMap);
            setRecipeCost(ingrLogMeta.recipeCost);
        }
    }, [ingrLogMeta]);

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditTaxModal"
            overlayClassName="addEditTaxModalOverlay"
        >
            <h2>Add/Edit Ingredient</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Ingredient Name</h4>
                    <input
                        value={ingrName[i18n.language]}
                        onChange={changeIngrName}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Ingredient Name</h4>
                    <input
                        type="text"
                        value={ingrMName}
                        onChange={changeIngrMName}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Ingredient Description</h4>
                    <input
                        value={ingrDesc[i18n.language]}
                        onChange={changeIngrDesc}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Ingredient Description</h4>
                    <input
                        type="text"
                        value={ingrMDesc}
                        onChange={changeIngrMDesc}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Measurement Units</h4>
                    {/* <h4>{unitOfM}</h4> */}
                    <select
                        onChange={changeUnitOfM}
                    >
                        {Object.keys(MeasurementEnum).map((mE) => (
                            <option value={mE} selected={mE === unitOfM}>{mE}</option>
                        ))}
                    </select>
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Warning Units</h4>
                    <input
                        value={warningUnits}
                        onChange={changeWarningUnits}
                        type="number"
                    />
                </div>
            </div>

            <hr className="recipeDivider" />
            <h4 className="recipeSubheading">Recipe</h4>
            <div className="recipeRow row">
                <div className="inputBox">
                    <h4>Select Raw Materials</h4>
                    <Select
                        isMulti
                        className="contactStaffSelector"
                        options={validRawMats}
                        defaultValue={Object.keys(recipeMap).map((tMatId) => ({
                            value: tMatId,
                            label: getRawMatNameById(tMatId),
                        }))}
                        onChange={(e: any) => changeRecipeMatIds(e)}
                    />
                </div>
            </div>

            <div className="recipeMatWrapper">
                {Object.keys(recipeMap).map((matId) => {
                    const val = recipeMap[matId];
                    const name = getRawMatNameById(matId);
                    return (
                        <div className="subRow">
                            <input disabled value={name} />
                            <input type="number" value={val} onChange={(e) => updateRecipeMap(matId, e)} />
                        </div>
                    );
                })}
            </div>

            <div className="recipeCostRow row">
                <div className="inputBox">
                    <h4>Recipe Cost</h4>
                    <input
                        value={recipeCost}
                        onChange={changeRecipeCost}
                        type="number"
                    />
                </div>
            </div>

            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={handleConfirm} disabled={HCLoading}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default AddEditIngrLog;
