import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IMenu } from '../../../../model/menuGroup/menu/IMenu';

import './MenuViewer.scss';
import MenuButton from '../MenuButton/MenuButton';
import { MenuLocale } from '../../MenuLocale';
import AddEditMenu from './AddEditMenu/AddEditMenu';

interface MenuViewerProps{
    menus: IMenu[];
    selectMenu: (id: string) => void;
}

const MenuViewer: React.FC<MenuViewerProps> = ({
    menus, selectMenu,
}) => {
    const { t } = useTranslation();

    const [menuModalOpen, setMenuModalOpen] = useState(false);
    const openMenuModal = () => setMenuModalOpen(true);
    const closeMenuModal = () => setMenuModalOpen(false);

    return (
        <div className="menuViewerContainer">
            <AddEditMenu open={menuModalOpen} closeModal={closeMenuModal} />
            <h2 className="screenTitle">{t(MenuLocale.menuEditor)}</h2>
            <div
                className="createMenuButton"
                aria-hidden
                onClick={openMenuModal}
            >
                <h2>+</h2>
                <p>Create Menu</p>
            </div>
            { menus.map((menu: IMenu) => (
                <MenuButton menu={menu} selectMenu={selectMenu} />
            ))}
        </div>

    );
};

export default MenuViewer;
