import { Config } from '../../Config';
import { Dict } from '../../model/helpers/Dict';
import { ITaxResponse } from '../../model/storeGroup/tax/ITax';
import { AxiosController } from '../AxiosController';
import { ToastController } from '../ToastController';

export class MultiplierController extends AxiosController {
    public async createTax(
        storeId: string,
        name: Dict<string>,
        desc: Dict<string>,
        percent: number,
        flat: number,
    ) : Promise<ITaxResponse> {
        const resp = await this.axiosPOST<ITaxResponse>(
            `${Config.SERVER_URL}/store/multiplier/tax`, {
                storeId: storeId,
                name: name,
                desc: desc,
                percent: percent,
                flat: flat * 100,
            },
        );
        ToastController.success(`Created ${name.en}`);
        return resp;
    }

    public async editTax(
        storeId: string,
        taxId: string,
        name: Dict<string>,
        desc: Dict<string>,
        percent: number,
        flat: number,
    ) : Promise<ITaxResponse> {
        const resp = await this.axiosPUT<ITaxResponse>(
            `${Config.SERVER_URL}/store/multiplier/tax`, {
                storeId: storeId,
                taxId: taxId,
                name: name,
                desc: desc,
                percent: percent,
                flat: flat * 100,
            },
        );
        ToastController.success(`Edited ${name.en}`);
        return resp;
    }
}
