import React, {
    Component, useContext, useEffect, useRef, useState,
} from 'react';
import { DayPilot, DayPilotCalendar, DayPilotNavigator } from 'daypilot-pro-react';
import { StoreContext } from '../../../controllers/Store/StoreContext';
import AddEditEvent from './AddEditEvent/AddEditEvent';
import './Calendar.scss';
import { Factory } from '../../../controllers/ControllerFactory';
import { IShiftPlanMeta } from '../../../model/storeGroup/shiftPlanner/shiftPlanMeta/IShiftPlanMeta';
import { IShiftPlanner } from '../../../model/storeGroup/shiftPlanner/IShiftPlanner';
import { ToastController } from '../../../controllers/ToastController';
import { IStoreStaff } from '../../../model/storeGroup/store/storeStaff/IStoreStaff';

const Calendar:React.FC = () => {
    const { store } = useContext(StoreContext)!;
    const [events, setEvents] = useState<DayPilot.EventData[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalArgs, setModalArgs] = useState<DayPilot.CalendarTimeRangeSelectedArgs | null>(null);

    const [allowDateGrab, setAllowDateGrab] = useState(true);
    const [viewStart, setViewStart] = useState<Date | null>(null);
    const [viewEnd, setViewEnd] = useState<Date | null>(null);

    const [calendarEl, setCalendarEl] = useState<DayPilotCalendar | null>(null);

    const [storeStaffArr, setStoreStaffArr] = useState<IStoreStaff[]>([]);
    const storeStaffController = Factory.StoreStaffController;
    const getStoreStaffArr = async () => {
        const staffArrResp = await storeStaffController.getStoreStaffs(
            store._id,
        );
        setStoreStaffArr(staffArrResp.data.storeStaffs);
    };
    useEffect(() => {
        getStoreStaffArr();
    }, []);

    const shiftPlannerController = Factory.getShiftPlannerController();

    const getStaffColor = (staffId: string) => {
        const tStaff = storeStaffArr.find((sSR) => sSR.staffId === staffId);
        if (tStaff === undefined) throw new Error('cannot find staff');
        return tStaff.color;
    };

    const getStatusColor = (shiftMeta: IShiftPlanMeta) => {
        if (shiftMeta.realStart === null) return '#3b8aff';
        if (shiftMeta.realEnd !== null) return '#ff2929';
        return '#91fa49';
    };

    const saveShiftPlanner = (shiftPlanner: IShiftPlanner) => {
        setEvents(shiftPlanner.shiftMetas.map((sM) => ({
            id: sM.shiftId,
            start: new DayPilot.Date(new Date(sM.planStart), true),
            end: new DayPilot.Date(new Date(sM.planEnd), true),
            text: sM.nickName,
            tags: {
                staffId: sM.staffId,
            },
            // fontColor: getStaffColor(sM.staffId),
            fontColor: 'white',
            backColor: getStaffColor(sM.staffId),
            // barColor: getStaffColor(sM.staffId),
            barColor: getStatusColor(sM),
            // barColor
        })));
    };

    const getShiftPlanner = async () => {
        if (viewStart === null || viewEnd === null) throw new Error('Could not get viewStart/End');
        const shiftPlanner : IShiftPlanner = await shiftPlannerController.getShiftPlanner(
            store._id, viewStart, viewEnd,
        );
        saveShiftPlanner(shiftPlanner);
    };

    const openModal = (args: DayPilot.CalendarTimeRangeSelectedArgs) => {
        setModalArgs(args);
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
        setModalArgs(null);
    };

    const handleCreate = async (args: DayPilot.CalendarTimeRangeSelectedArgs) => {
        const now = new DayPilot.Date();
        const targetDate = args.start;
        if (now > targetDate) return;
        openModal(args);
    };

    const onEventDeleted = async (args: DayPilot.CalendarEventDeletedArgs) => {
        if (viewStart === null || viewEnd === null) throw new Error('Cannot find viewStart or viewEnd');
        const now = new DayPilot.Date();
        const targetDate = args.e.data.start;
        if (now > targetDate) {
            args.control.message(`Unable to delete shift: ${args.e.text()}`);
            await getShiftPlanner();
            return;
        }
        args.control.message(`Shift deleted: ${args.e.text()}`);
        try {
            const shiftPlanner = await shiftPlannerController.editShiftPlan(
                [args.e.data.tags.staffId], store._id, args.e.data.id,
                viewStart, viewEnd, null, null,
            );
            saveShiftPlanner(shiftPlanner);
        } catch (err) {
            await getShiftPlanner();
        }
    };

    const onEventMoved = async (args: DayPilot.CalendarEventMovedArgs) => {
        if (viewStart === null || viewEnd === null) throw new Error('Cannot find viewStart or viewEnd');
        const now = new DayPilot.Date();
        const targetDate: DayPilot.Date = args.e.data.start;
        if (now > targetDate) {
            args.control.message(`Unable to move shift: ${args.e.text()}`);
            await getShiftPlanner();
            return;
        }
        args.control.message(`Shift moved: ${args.e.text()}`);
        try {
            const shiftPlanner = await shiftPlannerController.editShiftPlan(
                [args.e.data.tags.staffId], store._id, args.e.data.id,
                viewStart, viewEnd,
                new Date(args.e.data.start.toString()), new Date(args.e.data.end.toString()),
            );
            saveShiftPlanner(shiftPlanner);
        } catch (err) {
            await getShiftPlanner();
        }
    };
    const onEventResized = async (args: any) => {
        if (viewStart === null || viewEnd === null) throw new Error('Cannot find viewStart or viewEnd');
        const now = new DayPilot.Date();
        const targetDate: DayPilot.Date = args.e.data.start;
        if (now > targetDate) {
            args.control.message(`Unable to resize shift: ${args.e.text()}`);
            await getShiftPlanner();
            return;
        }
        args.control.message(`Shift resized: ${args.e.text()}`);
        try {
            const shiftPlanner = await shiftPlannerController.editShiftPlan(
                [args.e.data.tags.staffId], store._id, args.e.data.id,
                viewStart, viewEnd,
                new Date(args.e.data.start.toString()), new Date(args.e.data.end.toString()),
            );
            saveShiftPlanner(shiftPlanner);
        } catch (err) {
            await getShiftPlanner();
        }
    };

    const onTimeRangeSelected = async (args: DayPilot.NavigatorTimeRangeSelectedArgs) => {
        setViewStart(args.start.toDateLocal());
        setViewEnd(args.end.toDateLocal());
        // console.log('set date to', args.start.toDateLocal());
    };

    // Export feature
    const sendCalendar = async () => {
        if (viewStart === null || viewEnd === null) return;
        await shiftPlannerController.sendShiftPlans(
            storeStaffArr.map((storeStaffRef) => storeStaffRef.staffId),
            store._id,
            viewStart,
            viewEnd,
        );
        ToastController.success('Timetables sent to employees via SMS!');
    };
    const exportCalendar = () => {
        if (calendarEl === null) return;
        // @ts-ignore
        // console.log(calendarEl.control.expo);
        calendarEl.control.exportAs('jpeg', { area: 'full' }).download();
    };
    // End Export feature

    // Flexible Calendar Height
    const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const [cHeight, setCHeight] = useState(0);

    const resizeCalendar = () => {
        const calTop = ref.current.getBoundingClientRect().top;
        const winHeight = window.innerHeight;

        setCHeight(winHeight - calTop - 100);
    };

    useEffect(() => {
        resizeCalendar();
        window.addEventListener('resize', resizeCalendar);
        return (() => window.removeEventListener('resize', resizeCalendar));
    }, []);
    // End Flexible Calendar Height

    // Getting date from calendar object.
    useEffect(() => {
        if (viewStart === null || viewEnd === null || storeStaffArr.length === 0) return;
        getShiftPlanner();
    }, [allowDateGrab]);

    useEffect(() => {
        if (!viewStart || !viewEnd || storeStaffArr.length === 0) return;
        getShiftPlanner();
    }, [viewStart, viewEnd, storeStaffArr]);

    return (
        <div className="shiftCalendarContainer" ref={ref}>
            <AddEditEvent
                args={modalArgs}
                open={modalOpen}
                viewStart={viewStart}
                viewEnd={viewEnd}
                closeModal={closeModal}
                getShiftPlanner={getShiftPlanner}
                storeStaffArr={storeStaffArr}
            />
            <div className="dayPilotWrapper">
                <DayPilotNavigator
                    selectMode="Week"
                    showMonths={2}
                    // skipMonths={3}
                    // startDate="2022-03-07"
                    selectionDay={viewStart ? new DayPilot.Date(viewStart) : new DayPilot.Date()}
                    onTimeRangeSelected={onTimeRangeSelected}
                />
                <div className="calendarWrapper">
                    <header className="staffLegend">
                        {storeStaffArr.map((storeStaffRef) => (
                            <div className="staff">
                                <span
                                    className="staffColor"
                                    style={{
                                        background: storeStaffRef.color,
                                    }}
                                />
                                {`${storeStaffRef.fName} ${storeStaffRef.lName ? storeStaffRef.lName : ''}`}
                            </div>
                        ))}
                    </header>

                    <DayPilotCalendar
                        ref={(component) => {
                            if (!component || !allowDateGrab) return;
                            setViewStart(component.control.visibleStart().toDate());
                            setViewEnd(component.control.visibleEnd().toDate());
                            setAllowDateGrab(false);
                            setCalendarEl(component);
                        }}
                        // Static
                        viewType="Week"
                        headerDateFormat="d MMMM yyyy"
                        eventArrangement="SideBySide"
                        eventDeleteHandling="Update"
                        eventMoveHandling="Update"
                        eventResizeHandling="Update"
                        eventClickHandling="Enabled"
                        timeRangeSelectedHandling="Enabled"
                        heightSpec="Fixed"
                        cellDuration={15}
                        // Handlers
                        onTimeRangeSelected={handleCreate}
                        onEventDeleted={onEventDeleted}
                        onEventMoved={onEventMoved}
                        onEventResized={onEventResized}
                        // Variable
                        events={events}
                        height={cHeight}
                        startDate={viewStart ? new DayPilot.Date(viewStart) : new DayPilot.Date()}
                    />
                </div>
            </div>
            <div className="exportRow">
                <button
                    type="button"
                    onClick={sendCalendar}
                >
                    Send Timetable
                </button>
                <button
                    type="button"
                    onClick={exportCalendar}
                >
                    Export
                </button>
            </div>
        </div>
    );
};

export default Calendar;
