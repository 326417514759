/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../../../model/helpers/Dict';
import { IIngrLogMeta } from '../../../../../../model/IngrGroup/IngrLog/IIngrLog';
import { IOptionRef } from '../../../../../../model/menuGroup/option/IOptionRef';
import './ItemOptionModule.scss';
import OptionElemCard from './OptionElemCard/OptionElemCard';

interface ItemOptionModuleProps {
    itemOptions: Dict<IOptionRef>;
    ingrLogMetas: IIngrLogMeta[];
    chooseOption: (
        e: React.ChangeEvent<HTMLSelectElement>,
        tmpOptId: string,
    ) => void;
    changeOptionMin: (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => void;
    changeOptionMax: (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => void;
    changeOptionImpt: (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => void;
    changeOptionHidden: (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => void;
    changeOptionHidePrice: (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => void;

    addOptionElem: (tmpOptId: string, optionId: string) => void;
    removeOptionElem: (tmpOptId: string, optionElemId: string) => void;
    changeOptionElemHidden: (
        e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string, optionElemId: string,
    )=> void;
    changeOptionElemIsDflt: (
        e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string, optionElemId: string,
    ) => void;
    changeOptionElemPrice: (
        e: React.ChangeEvent<HTMLInputElement>,
        tmpOptId: string, optionElemId: string, priceKey: string,
    )=> void;
    changeOptionElemIngrMap: (
        tmpOptId: string, optionElemId: string, tIngrMap: Dict<number>,
    )=> void;

    addOption: () => void;
    removeOption: (tmpOptId: string) => void;
}

const ItemOptionModule: React.FC<ItemOptionModuleProps> = ({
    itemOptions, ingrLogMetas, chooseOption, changeOptionMin, changeOptionMax, changeOptionImpt,
    changeOptionHidden, changeOptionHidePrice, changeOptionElemIngrMap, removeOptionElem,
    changeOptionElemHidden, changeOptionElemIsDflt, changeOptionElemPrice, addOptionElem,
    addOption, removeOption,
}) => {
    const { t, i18n } = useTranslation();
    const { store } = useContext(StoreContext)!;

    // Rendering
    const generateOptionElems = (tmpOptId: string, optRef: IOptionRef) => optRef.optionElemRefs.map(
        (optionElemRef) => {
            const option = store.options.find(
                (opt) => opt._id
                                === optRef.optionId,
            );
            if (option === undefined) throw new Error('Cannot find optionElem');
            const optionElem = option.optionElems.find((optElem) => (
                optElem._id === optionElemRef.optionElemId
            ));
            if (optionElem === undefined) throw new Error('cannot find optionelem from ref');
            return (
                <OptionElemCard
                    name={optionElem.name[i18n.language]}
                    ingrLogMetas={ingrLogMetas}
                    optionElemRef={optionElemRef}
                    optIdRef={tmpOptId}
                    removeOptionElem={removeOptionElem}
                    changeOptionElemHidden={changeOptionElemHidden}
                    changeOptionElemIsDflt={changeOptionElemIsDflt}
                    changeOptionElemPrice={changeOptionElemPrice}
                    changeOptionElemIngrMap={changeOptionElemIngrMap}
                />
            );
        },
    );
    return (

        <div className="options">
            <p>Options</p>

            {Object.keys(itemOptions).map((tmpOptId) => {
                const itemOpt = itemOptions[tmpOptId];
                return (
                    <div className="optionContainer">
                        <div className="row">
                            <div className="inputBox">
                                <select
                                    onChange={(e) => chooseOption(e, tmpOptId)}
                                >
                                    <option
                                        value=""
                                        selected={itemOpt.optionId === ''}
                                        disabled
                                        hidden
                                    >
                                        Choose option ...
                                    </option>
                                    {store.options.map((opt) => (
                                        <option
                                            value={opt._id}
                                            selected={
                                                opt._id === itemOpt.optionId
                                            }
                                            hidden={
                                                Object.values(itemOptions)
                                                    .find((optRef) => (
                                                        optRef.optionId === opt._id
                                                    )) !== undefined
                                            }
                                        >
                                            {`${opt.name[i18n.language]} (${
                                                opt.mName
                                            })`}
                                        </option>
                                    ))}
                                    <option value="">Add New</option>
                                </select>
                            </div>
                        </div>
                        {itemOpt.optionId !== '' && (
                            <>
                                <div className="row">
                                    <div className="minMax">
                                        <div className="inputBox half">
                                            <p>Min</p>
                                            <input
                                                type="number"
                                                value={itemOpt.min}
                                                onChange={(e) => changeOptionMin(e, tmpOptId)}
                                            />
                                        </div>
                                        <div className="space" />
                                        <div className="inputBox half">
                                            <p>Max</p>
                                            <input
                                                type="number"
                                                value={itemOpt.max}
                                                onChange={(e) => changeOptionMax(e, tmpOptId)}
                                            />
                                        </div>
                                    </div>
                                    <div className="space" />
                                    <div className="inputBox inputCheckBox half">
                                        <label htmlFor={`impt_${tmpOptId}`}>
                                            Important Text
                                        </label>
                                        <input
                                            id={`impt_${tmpOptId}`}
                                            type="checkbox"
                                            checked={itemOpt.impt}
                                            onChange={(e) => changeOptionImpt(e, tmpOptId)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="inputBox inputCheckBox half">
                                        <label htmlFor={`hidden_${tmpOptId}`}>
                                            Hide in Advanced Tab
                                        </label>
                                        <input
                                            id="hidden"
                                            type="checkbox"
                                            checked={itemOpt.hidden}
                                            onChange={(e) => changeOptionHidden(e, tmpOptId)}
                                        />
                                    </div>
                                    <div className="space" />
                                    <div className="inputBox inputCheckBox half">
                                        <label htmlFor={`hidePrice_${tmpOptId}`}>
                                            Hide Price from CFD
                                        </label>
                                        <input
                                            id={`hidePrice_${tmpOptId}`}
                                            type="checkbox"
                                            checked={itemOpt.hidePrice}
                                            onChange={(e) => changeOptionHidePrice(e, tmpOptId)}
                                        />
                                    </div>
                                </div>
                                <div className="optionElemList">
                                    {generateOptionElems(tmpOptId, itemOpt)}
                                    <div
                                        aria-hidden
                                        className="optionElemCardContainer addOptionElem"
                                        onClick={() => addOptionElem(
                                            tmpOptId,
                                            itemOpt.optionId,
                                        )}
                                    >
                                        +
                                    </div>
                                </div>
                            </>
                        )}
                        <div
                            className="removeOption"
                            aria-hidden
                            onClick={() => removeOption(tmpOptId)}
                        >
                            X
                        </div>
                    </div>
                );
            })}

            <button className="addOption" type="button" onClick={addOption}>
                +
            </button>
        </div>

    );
};

export default ItemOptionModule;
