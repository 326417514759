/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../../components/Spinner/Spinner';
import { Factory } from '../../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../../model/helpers/Dict';
import { ICombo } from '../../../../../model/menuGroup/combo/ICombo';
import { IComboRef } from '../../../../../model/menuGroup/combo/IComboRef';
import { IItem } from '../../../../../model/menuGroup/item/IItem';
import { IItemTypes } from '../../../../../model/menuGroup/item/IItemTypes';
import { IOption } from '../../../../../model/menuGroup/option/IOption';
import { IOptionRef } from '../../../../../model/menuGroup/option/IOptionRef';
import { IOptionElemRef } from '../../../../../model/menuGroup/option/optionElem/IOptionElemRef';
import { IOePrice } from '../../../../../model/menuGroup/option/optionElem/oePrice/IOePrice';
import AddEditCombo from '../../../ComboEditor/AddEditCombo/AddEditCombo';
import AddEditOption from '../../../OptionEditor/AddEditOption/AddEditOption';
import './AddEditItem.scss';
import ComboElemSelector from './ItemComboModule/ComboElemSelector/ComboElemSelector';
import ItemComboModule from './ItemComboModule/ItemComboModule';
import ItemOptionModule from './ItemOptionModule/ItemOptionModule';
import ItemSubItemModule from './ItemSubItemModule/ItemSubItemModule';
import OptionElemSelector from './ItemOptionModule/OptionElemSelector/OptionElemSelector';
import ItemIngrModule from './ItemIngrModule/ItemIngrModule';
import { IIngrLogMeta } from '../../../../../model/IngrGroup/IngrLog/IIngrLog';
import { MeasurementEnum } from '../../../../../model/IngrGroup/IngrLog/MeasurementEnum';

interface AddEditItemProps {
    item: IItem | undefined;
    currCategoryId: string;
    deselectItem: (categId: string) => void;
}

enum ScreenType {
    INIT = 'INIT',
    NEW_ITEM = 'NEW_ITEM',
    EXISTING_ITEM = 'EXISTING_ITEM',
}

const AddEditItem: React.FC<AddEditItemProps> = ({
    item,
    currCategoryId,
    deselectItem,
}) => {
    const { t } = useTranslation();

    const menuController = Factory.getMenuController();

    const { store, setStore } = useContext(StoreContext)!;
    const { i18n } = useTranslation();

    const [chooseIngrOpen, setChooseIngrOpen] = useState(false);
    const openChooseIngr = () => setChooseIngrOpen(true);
    const closeChooseIngr = () => setChooseIngrOpen(false);

    const [screenType, setScreenType] = useState<ScreenType>(ScreenType.INIT);
    const selectNewItem = () => setScreenType(ScreenType.NEW_ITEM);
    const selectExistingItem = () => setScreenType(ScreenType.EXISTING_ITEM);

    const [itemName, setItemName] = useState('');
    const changeItemName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemName(e.target.value);
    };
    const [itemMName, setItemMName] = useState('');
    const changeItemMName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemMName(e.target.value);
    };

    const [itemDesc, setItemDesc] = useState('');
    const changeItemDesc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setItemDesc(e.target.value);
    };
    const [itemMDesc, setItemMDesc] = useState('');
    const changeItemMDesc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setItemMDesc(e.target.value);
    };

    const [itemType, setItemType] = useState(IItemTypes.REG);
    const changeItemType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setItemType(e.target.value as IItemTypes);
    };

    const [itemPrices, setItemPrices] = useState<
        Dict<{
            amount: number;
            _id: string | null;
        }>
    >({});

    const changeItemPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dollarCents = e.target.value.split('.');
        if (parseInt(dollarCents[0], 10) > 10000) return;
        if (dollarCents.length === 2 && dollarCents[1].length > 2) {
            e.preventDefault();
        } else {
            const priceKeyId: string = e.target.getAttribute('data-priceKeyId')!;
            const value = parseFloat(e.target.value);
            setItemPrices((prevState) => ({
                ...prevState,
                [priceKeyId]: {
                    amount: value,
                    _id: prevState[priceKeyId]._id,
                },
            }));
        }
    };

    const [itemIns, setItemIns] = useState('');
    const changeItemIns = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setItemIns(e.target.value);
    };

    const [itemNeedPrep, setItemNeedPrep] = useState(true);
    const changeItemNeedPrep = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemNeedPrep(e.target.checked);
    };

    const [itemActive, setItemActive] = useState(true);
    const changeItemActive = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItemActive(e.target.checked);
    };

    const [itemTaxes, setItemTaxes] = useState<string[]>([]);
    const changeItemTaxes = (taxesList: any[]) => {
        const taxIds = taxesList.map((multiValue) => multiValue.value);
        setItemTaxes(taxIds);
    };

    const getTaxNameById = (taxId: string) => {
        const targetTax = store.taxes.find((tax) => tax._id === taxId);
        if (targetTax === undefined) throw new Error('cannot find tax by taxId');
        return targetTax.name[i18n.language];
    };

    const [existingItemIds, setExistingItemIds] = useState<string[]>([]);
    const changeExistingItemIds = (itemIds: any[]) => {
        const tmpItemIds = itemIds.map((multiValue) => multiValue.value);
        setExistingItemIds(tmpItemIds);
    };

    // ****************
    // Ingr Log
    // ****************

    const [itemIngrMap, setItemIngrMap] = useState<Dict<number>>({});

    const [ingrLogMetas, setIngrLogMetas] = useState<IIngrLogMeta[]>([]);
    const [ILMLoading, setILMLoading] = useState(true);
    useEffect(() => {
        const getIngrLogMetas = async () => {
            const metas : IIngrLogMeta[] = await Factory.IngrLogController.getIngrLogMetas(
                store._id,
            );
            setIngrLogMetas(metas);
            setILMLoading(false);
        };
        getIngrLogMetas();
    }, []);

    const updateItemIngr = (ingrMap: Dict<number>) => {
        setItemIngrMap(ingrMap);
    };

    // ****************
    // OPTIONS
    // ****************
    const [itemOptions, setItemOptions] = useState<Dict<IOptionRef>>({});
    const addOption = () => {
        const optTemplate: IOptionRef = {
            optionId: '',
            max: 0,
            min: 0,
            hidden: false,
            hidePrice: false,
            impt: false,
            optionElemRefs: [],
        };
        setItemOptions((prevState) => ({
            ...prevState,
            [Date().toString()]: optTemplate,
        }));
    };
    const removeOption = (tmpOptId: string) => {
        setItemOptions((prevState) => {
            const newState = { ...prevState };
            delete newState[tmpOptId];
            return newState;
        });
    };

    // Option Modal
    const [optionModalOpen, setOptionModalOpen] = useState(false);
    const openOptionModal = () => setOptionModalOpen(true);
    const closeOptionModal = () => setOptionModalOpen(false);
    const chooseOption = (
        e: React.ChangeEvent<HTMLSelectElement>,
        tmpOptId: string,
    ) => {
        const optionId = e.target.value;
        // // Add new option!!
        if (optionId === '') {
            setItemOptions((prevState) => ({
                ...prevState,
                [tmpOptId]: {
                    ...prevState[tmpOptId],
                    optionId: optionId,
                },
            }));
            openOptionModal();
            return;
        }

        // Valid Option
        const option = store.options.find((opt) => opt._id === optionId);
        if (option === undefined) {
            throw new Error('Cannot find selected option.');
        }
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                optionId: optionId,
            },
        }));
    };
    const changeOptionMin = (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => {
        const value = e.target.value;
        // TODO: Only ints
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                min: parseInt(value, 10),
            },
        }));
    };
    const changeOptionMax = (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => {
        const value = e.target.value;
        // TODO: Only ints
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                max: parseInt(value, 10),
            },
        }));
    };
    const changeOptionHidden = (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => {
        const checked = e.target.checked;
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                hidden: checked,
            },
        }));
    };
    const changeOptionImpt = (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => {
        const checked = e.target.checked;
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                impt: checked,
            },
        }));
    };
    const changeOptionHidePrice = (e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string) => {
        const checked = e.target.checked;
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                hidePrice: checked,
            },
        }));
    };

    const [selectedOption, setSelectedOption] = useState<IOption | null>(null);
    const [optIdRef, setOptIdRef] = useState('');
    const [oElemModalOpen, setOElemModalOpen] = useState(false);
    const openOElemModal = () => setOElemModalOpen(true);
    const closeOElemModal = () => setOElemModalOpen(false);
    const addOptionElem = (tmpOptId: string, optionId: string) => {
        const option = store.options.find((opt) => opt._id === optionId);
        if (option === undefined) throw new Error('cannot find option');
        setOptIdRef(tmpOptId);
        setSelectedOption(option);
        openOElemModal();
    };
    const removeOptionElem = (tmpOptId: string, optionElemId: string) => {
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                optionElemRefs: prevState[tmpOptId].optionElemRefs.filter((optElem) => (
                    optElem.optionElemId !== optionElemId
                )),
            },
        }));
    };
    const chooseOptionElem = (tmpOptId: string, optionElemId: string) => {
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                optionElemRefs: [
                    ...prevState[tmpOptId].optionElemRefs,
                    {
                        optionElemId: optionElemId,
                        hidden: false,
                        isDflt: false,
                        oePrices: store.priceKeys.map((pK) => ({
                            priceKeyId: pK._id,
                            amount: 0,
                        })),
                        ingrMap: {},
                    },
                ],
            },
        }));
        setSelectedOption(null);
        closeOElemModal();
    };
    const changeOptionElemHidden = (
        e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string, optionElemId: string,
    ) => {
        const targetOptionElem = itemOptions[tmpOptId].optionElemRefs.filter((optElem) => (
            optElem.optionElemId === optionElemId
        ));
        if (targetOptionElem.length !== 1) throw new Error('Cannot find optionElem');
        const updatedOptionElem = {
            ...targetOptionElem[0],
            hidden: e.target.checked,
        };
        const toUpdateIdx = itemOptions[tmpOptId].optionElemRefs.findIndex((optElem) => (
            optElem.optionElemId === optionElemId));
        const tmpOptionElemRefs = [...itemOptions[tmpOptId].optionElemRefs];
        tmpOptionElemRefs[toUpdateIdx] = updatedOptionElem;
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                optionElemRefs: tmpOptionElemRefs,
            },
        }));
    };
    const changeOptionElemIsDflt = (
        e: React.ChangeEvent<HTMLInputElement>, tmpOptId: string, optionElemId: string,
    ) => {
        const targetOptionElem = itemOptions[tmpOptId].optionElemRefs.filter((optElem) => (
            optElem.optionElemId === optionElemId
        ));
        if (targetOptionElem.length !== 1) throw new Error('Cannot find optionElem');
        const updatedOptionElem: IOptionElemRef = {
            ...targetOptionElem[0],
            isDflt: e.target.checked,
        };
        const toUpdateIdx = itemOptions[tmpOptId].optionElemRefs.findIndex((optElem) => (
            optElem.optionElemId === optionElemId));
        const tmpOptionElemRefs = [...itemOptions[tmpOptId].optionElemRefs];
        tmpOptionElemRefs[toUpdateIdx] = updatedOptionElem;
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                optionElemRefs: tmpOptionElemRefs,
            },
        }));
    };
    const changeOptionElemPrice = (
        e: React.ChangeEvent<HTMLInputElement>,
        tmpOptId: string, optionElemId: string, priceKey: string,
    ) => {
        const targetOptionElem = itemOptions[tmpOptId].optionElemRefs.find((optElem) => (
            optElem.optionElemId === optionElemId
        ));
        if (targetOptionElem === undefined) throw new Error('Cannot find optionElem');
        const targetPriceKey = targetOptionElem.oePrices.find((pK) => (
            priceKey === pK.priceKeyId
        ));
        if (targetPriceKey === undefined) throw new Error('Cannot find priceKey from oePrices');
        const updatedPriceKey: IOePrice = {
            ...targetPriceKey,
            amount: parseFloat(e.target.value),
        };
        const toUpdateIdx = itemOptions[tmpOptId].optionElemRefs.findIndex((optElem) => (
            optElem.optionElemId === optionElemId));
        const tmpOptionElemRefs = [...itemOptions[tmpOptId].optionElemRefs];

        const oePriceIdx = tmpOptionElemRefs[toUpdateIdx].oePrices.findIndex((oePrice) => (
            oePrice.priceKeyId === priceKey
        ));
        tmpOptionElemRefs[toUpdateIdx].oePrices[oePriceIdx] = updatedPriceKey;

        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                optionElemRefs: tmpOptionElemRefs,
            },
        }));
    };

    const changeOptionElemIngrMap = (
        tmpOptId: string, optionElemId: string, tIngrMap: Dict<number>,
    ) => {
        const targetOptionElem = itemOptions[tmpOptId].optionElemRefs.filter((optElem) => (
            optElem.optionElemId === optionElemId
        ));
        if (targetOptionElem.length !== 1) throw new Error('Cannot find optionElem');
        const updatedOptionElem: IOptionElemRef = {
            ...targetOptionElem[0],
            ingrMap: tIngrMap,
        };
        const toUpdateIdx = itemOptions[tmpOptId].optionElemRefs.findIndex((optElem) => (
            optElem.optionElemId === optionElemId));
        const tmpOptionElemRefs = [...itemOptions[tmpOptId].optionElemRefs];
        tmpOptionElemRefs[toUpdateIdx] = updatedOptionElem;
        setItemOptions((prevState) => ({
            ...prevState,
            [tmpOptId]: {
                ...prevState[tmpOptId],
                optionElemRefs: tmpOptionElemRefs,
            },
        }));
    };
    // *************
    // END OPTIONS
    // *************

    // *************
    // COMBOS
    // *************
    const [itemCombos, setItemCombos] = useState<Dict<IComboRef>>({});
    const addCombo = () => {
        const comTemplate: IComboRef = {
            comboId: '',
            required: true,
            comboElemRefs: [],
        };
        setItemCombos((prevState) => ({
            ...prevState,
            [Date().toString()]: comTemplate,
        }));
    };
    const removeCombo = (tmpComId: string) => {
        setItemCombos((prevState) => {
            const newState = { ...prevState };
            delete newState[tmpComId];
            return newState;
        });
    };
    // Combo Modal
    const [comboModalOpen, setComboModalOpen] = useState(false);
    const openComboModal = () => setComboModalOpen(true);
    const closeComboModal = () => setComboModalOpen(false);

    const chooseCombo = (
        e: React.ChangeEvent<HTMLSelectElement>,
        tmpComId: string,
    ) => {
        const comboId = e.target.value;
        // // Add new option!!
        if (comboId === '') {
            setItemCombos((prevState) => ({
                ...prevState,
                [tmpComId]: {
                    ...prevState[tmpComId],
                    comboId: comboId,
                },
            }));
            openComboModal();
            return;
        }

        // Valid Option
        const combo = store.combos.find((com) => com._id === comboId);
        if (combo === undefined) {
            throw new Error('Cannot find selected combo.');
        }
        setItemCombos((prevState) => ({
            ...prevState,
            [tmpComId]: {
                ...prevState[tmpComId],
                comboId: comboId,
            },
        }));
    };

    const changeComboRequired = (
        e: React.ChangeEvent<HTMLInputElement>,
        tmpComId: string,
    ) => {
        const checked = e.target.checked;
        setItemCombos((prevState) => ({
            ...prevState,
            [tmpComId]: {
                ...prevState[tmpComId],
                required: checked,
            },
        }));
    };

    const [selectedCombo, setSelectedCombo] = useState<ICombo | null>(null);
    const [comIdRef, setComIdRef] = useState('');
    const [cElemModalOpen, setCElemModalOpen] = useState(false);
    const openCElemModal = () => setCElemModalOpen(true);
    const closeCElemModal = () => setCElemModalOpen(false);
    const addComboElem = (tmpComId: string, comboId: string) => {
        const combo = store.combos.find((com) => com._id === comboId);
        if (combo === undefined) throw new Error('cannot find combo');
        setComIdRef(tmpComId);
        setSelectedCombo(combo);
        openCElemModal();
    };
    const removeComboElem = (tmpComId: string, itemId: string) => {
        setItemCombos((prevState) => ({
            ...prevState,
            [tmpComId]: {
                ...prevState[tmpComId],
                comboElemRefs: prevState[tmpComId].comboElemRefs.filter((comElem) => (
                    comElem.itemId !== itemId
                )),
            },
        }));
    };
    const chooseComboElem = (tmpComId: string, itemId: string) => {
        setItemCombos((prevState) => ({
            ...prevState,
            [tmpComId]: {
                ...prevState[tmpComId],
                comboElemRefs: [
                    ...prevState[tmpComId].comboElemRefs,
                    {
                        itemId: itemId,
                        cePrices: store.priceKeys.map((pK) => ({
                            priceKeyId: pK._id,
                            amount: 0,
                        })),
                    },
                ],
            },
        }));
        setSelectedCombo(null);
        closeCElemModal();
    };
    const changeComboElemPrice = (
        e: React.ChangeEvent<HTMLInputElement>,
        tmpComId: string, itemId: string, priceKey: string,
    ) => {
        const targetComboElem = itemCombos[tmpComId].comboElemRefs.find((comElem) => (
            comElem.itemId === itemId
        ));
        if (targetComboElem === undefined) throw new Error('Cannot find comboElem');
        const targetPriceKey = targetComboElem.cePrices.find((pK) => (
            priceKey === pK.priceKeyId
        ));
        if (targetPriceKey === undefined) throw new Error('Cannot find priceKey from cePrices');
        const updatedPriceKey: IOePrice = {
            ...targetPriceKey,
            amount: parseFloat(e.target.value),
        };
        const toUpdateIdx = itemCombos[tmpComId].comboElemRefs.findIndex((optElem) => (
            optElem.itemId === itemId));
        const tmpComboElemRefs = [...itemCombos[tmpComId].comboElemRefs];

        const cePriceIdx = tmpComboElemRefs[toUpdateIdx].cePrices.findIndex((cePrice) => (
            cePrice.priceKeyId === priceKey
        ));
        tmpComboElemRefs[toUpdateIdx].cePrices[cePriceIdx] = updatedPriceKey;

        setItemCombos((prevState) => ({
            ...prevState,
            [tmpComId]: {
                ...prevState[tmpComId],
                comboElemRefs: tmpComboElemRefs,
            },
        }));
    };
    // *************
    // END COMBOS
    // *************

    // *************
    // SUBCATEGORY
    // *************
    const [itemSubItemIds, setItemSubItemIds] = useState<string[]>([]);
    const addSubItemId = () => {
        setItemSubItemIds((prevState) => [
            ...prevState,
            '',
        ]);
    };
    const removeSubItemId = (itemIdx: number) => {
        const tmpSubItemIds = [...itemSubItemIds];
        tmpSubItemIds.splice(itemIdx, 1);
        setItemSubItemIds(tmpSubItemIds);
    };
    const changeSubItemId = (e: React.ChangeEvent<HTMLSelectElement>, itemIdx: number) => {
        const tmpSubItemIds = [...itemSubItemIds];
        tmpSubItemIds[itemIdx] = e.target.value;
        setItemSubItemIds(tmpSubItemIds);
    };

    // *************
    // END SUBCATEGORY
    // *************

    const addItem = async () => {
        if (item !== undefined) throw new Error('Cannot add an existing item');
        const addItemResponse = await menuController.createItem(
            store._id,
            itemName,
            itemType,
            itemMName,
            itemDesc,
            itemMDesc,
            itemPrices,
            itemIns,
            itemIngrMap,
            itemOptions,
            itemCombos,
            itemSubItemIds,
            itemTaxes,
            itemNeedPrep,
            itemActive,
            currCategoryId === '' ? [] : [currCategoryId],
        );
        setStore(addItemResponse.store);
        deselectItem(currCategoryId);
    };

    const editItem = async () => {
        if (item === undefined) throw new Error('Cannot edit a new item');
        const editItemReponse = await menuController.editItem(
            store._id,
            item._id,
            itemType,
            itemName,
            itemMName,
            itemDesc,
            itemMDesc,
            itemPrices,
            itemIns,
            itemIngrMap,
            itemOptions,
            itemCombos,
            itemSubItemIds,
            itemTaxes,
            itemNeedPrep,
            itemActive,
        );
        setStore(editItemReponse.store);
        deselectItem(currCategoryId);
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleContinue = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        try {
            if (itemType !== IItemTypes.SUBCATEG && itemTaxes.length === 0) {
                alert('You have not entered any taxes.');
            }
            if (item !== undefined) {
                await editItem();
                return;
            }
            await addItem();
        } catch (err) {
            //
        }
        setHCLoading(false);
    };

    const [HATCLoading, setHATCLoading] = useState(false);
    const handleAddToCategory = async () => {
        if (HATCLoading) return;
        setHATCLoading(true);
        if (currCategoryId === '') throw new Error('cannot add to category when no category provided');
        const addItemsResponse = await menuController.categoryAddItemIds(
            store._id,
            currCategoryId,
            existingItemIds,
        );
        setStore(addItemsResponse.store);
        deselectItem(currCategoryId);
        setHATCLoading(false);
    };

    const handleCancel = async () => {
        deselectItem(currCategoryId);
    };

    useEffect(() => {
        if (item !== undefined) {
            setItemName(item.name.en);
            setItemMName(item.mName);
            setItemDesc(item.desc.en);
            setItemMDesc(item.mDesc);

            const tempPricing: Dict<{
                amount: number;
                _id: string | null;
            }> = {};
            item.itemPrices.forEach((itemPrice) => {
                tempPricing[itemPrice.priceKeyId] = {
                    amount: itemPrice.amount / 100,
                    _id: itemPrice._id,
                };
            });
            setItemPrices(tempPricing);
            setItemIns(item.instruction);
            setItemNeedPrep(item.needPrep);
            setItemActive(item.active);
            setItemType(item.itemType);

            setItemIngrMap(item.ingrMap);

            const tempOptions: Dict<IOptionRef> = {};
            item.optionRefs.forEach((tmpOptRef) => {
                tempOptions[tmpOptRef.optionId] = {
                    ...tmpOptRef,
                    optionElemRefs: tmpOptRef.optionElemRefs.map((oElRef) => (
                        {
                            ...oElRef,
                            oePrices: oElRef.oePrices.map((tmpPrice) => ({
                                ...tmpPrice,
                                amount: tmpPrice.amount / 100,
                            })),
                        }
                    )),
                };
            });
            setItemOptions(tempOptions);

            const tempCombos: Dict<IComboRef> = {};
            item.comboRefs.forEach((tmpComRef) => {
                tempCombos[tmpComRef.comboId] = {
                    ...tmpComRef,
                    comboElemRefs: tmpComRef.comboElemRefs.map((cElRef) => (
                        {
                            ...cElRef,
                            cePrices: cElRef.cePrices.map((tmpPrice) => ({
                                ...tmpPrice,
                                amount: tmpPrice.amount / 100,
                            })),
                        }
                    )),
                };
            });
            setItemCombos(tempCombos);

            setItemSubItemIds(item.subItemIds);
            setItemTaxes(item.taxIds);
        } else {
            const tempPricing: Dict<{
                amount: number;
                _id: string | null;
            }> = {};
            store.priceKeys.forEach((priceKey) => {
                tempPricing[priceKey._id] = {
                    amount: 0,
                    _id: null,
                };
            });
            setItemPrices(tempPricing);
        }
    }, [item]);

    useEffect(() => {
        if (currCategoryId === '' || item !== undefined) selectNewItem();
    }, [currCategoryId]);

    if (itemType !== IItemTypes.SUBCATEG && Object.keys(itemPrices).length === 0) {
        return <Spinner />;
    }
    if (ILMLoading) return <Spinner />;

    // TODO cleaner
    const validItemList = store.items.filter((tmpItem: IItem) => {
        if (tmpItem.itemType === IItemTypes.REG) return true;
        if (tmpItem.itemType === IItemTypes.SUBCATEG) return true;
        if (tmpItem.itemType === IItemTypes.COMBO) return true;
        return false;
    }).map((tmpItem: IItem) => ({
        value: tmpItem._id,
        label: tmpItem.name[i18n.language],
    }));

    return (
        <div className="addEditItemContainer">
            <AddEditOption
                open={optionModalOpen}
                option={null}
                closeModal={closeOptionModal}
            />
            <AddEditCombo
                open={comboModalOpen}
                combo={null}
                closeModal={closeComboModal}
            />
            <OptionElemSelector
                open={oElemModalOpen}
                optIdRef={optIdRef}
                optionRef={itemOptions[optIdRef]}
                option={selectedOption}
                handleContinue={chooseOptionElem}
                closeModal={closeOElemModal}
            />
            <ComboElemSelector
                open={cElemModalOpen}
                comIdRef={comIdRef}
                comboRef={itemCombos[comIdRef]}
                combo={selectedCombo}
                handleContinue={chooseComboElem}
                closeModal={closeCElemModal}
            />
            {screenType === ScreenType.INIT && (
                <div className="creationSelector">
                    <button type="button" onClick={selectNewItem}>Add New Item</button>
                    <button type="button" onClick={selectExistingItem}>Change List of Items</button>
                </div>
            )}
            {screenType === ScreenType.EXISTING_ITEM && (
                <div className="existingItem">
                    <div className="row">
                        <div className="inputBox">
                            <p>Items</p>
                            <Select
                                isMulti
                                className="itemSelector"
                                options={validItemList}
                                onChange={(e: any) => changeExistingItemIds(e)} // TODO
                            />
                        </div>
                    </div>
                    <div className="buttonsWrapper">
                        <div className="buttons">
                            <button
                                type="button"
                                className="cancel"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="confirm"
                                onClick={handleAddToCategory}
                                disabled={HATCLoading}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {screenType === ScreenType.NEW_ITEM
            && (
                <>
                    <div className="row">
                        <div className="inputBox half">
                            <p>Item Name</p>
                            <input
                                type="text"
                                value={itemName}
                                onChange={changeItemName}
                            />
                        </div>
                        <div className="space" />
                        <div className="inputBox half">
                            <p>Management Item Name</p>
                            <input
                                type="text"
                                value={itemMName}
                                onChange={changeItemMName}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="inputBox half">
                            <p>Item Description</p>
                            <textarea value={itemDesc} onChange={changeItemDesc} />
                        </div>
                        <div className="space" />
                        <div className="inputBox half">
                            <p>Management Item Description</p>
                            <textarea value={itemMDesc} onChange={changeItemMDesc} />
                        </div>
                    </div>
                    {item === undefined && (
                        <div className="row">
                            <div className="inputBox">
                                <p>Item Type</p>
                                <select onChange={changeItemType}>
                                    <option value={IItemTypes.REG}>Regular</option>
                                    <option value={IItemTypes.COMBO}>Combo</option>
                                    <option value={IItemTypes.SUBCATEG}>Subcategory</option>
                                </select>
                            </div>
                        </div>
                    )
                    }
                    {itemType !== IItemTypes.SUBCATEG && (
                        <>
                            <div className="priceKey">
                                <p>Pricing</p>
                                {store.priceKeys.map((priceKey) => (
                                    <div className="row">
                                        <div className="priceKeyInputBox">
                                            <div className="priceKeyName">{priceKey.mName}</div>
                                            <input
                                                value={itemPrices[priceKey._id].amount}
                                                data-priceKeyId={priceKey._id}
                                                type="number"
                                                onChange={changeItemPrice}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="row">
                                <div className="inputBox">
                                    <p>Instructions</p>
                                    <textarea value={itemIns} onChange={changeItemIns} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="inputBox">
                                    <p>Taxes</p>
                                    <Select
                                        isMulti
                                        className="taxSelector"
                                        defaultValue={itemTaxes.map((taxId) => ({
                                            value: taxId,
                                            label: getTaxNameById(taxId),
                                        }))}
                                        options={store.taxes.map((tax) => ({
                                            value: tax._id,
                                            label: tax.name[i18n.language],
                                        }))}
                                        onChange={(e: any) => changeItemTaxes(e)}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {(itemType === IItemTypes.REG || itemType === IItemTypes.COMBO)
                        && (
                            <ItemIngrModule
                                ingrLogMetas={ingrLogMetas}
                                itemIngr={itemIngrMap}
                                updateItemIngr={updateItemIngr}
                            />
                        )
                    }

                    {itemType === IItemTypes.REG
                && (
                    <ItemOptionModule
                        itemOptions={itemOptions}
                        ingrLogMetas={ingrLogMetas}
                        chooseOption={chooseOption}
                        changeOptionMin={changeOptionMin}
                        changeOptionMax={changeOptionMax}
                        changeOptionImpt={changeOptionImpt}
                        changeOptionHidden={changeOptionHidden}
                        changeOptionHidePrice={changeOptionHidePrice}
                        changeOptionElemIngrMap={changeOptionElemIngrMap}
                        addOptionElem={addOptionElem}
                        removeOptionElem={removeOptionElem}
                        changeOptionElemHidden={changeOptionElemHidden}
                        changeOptionElemIsDflt={changeOptionElemIsDflt}
                        changeOptionElemPrice={changeOptionElemPrice}
                        addOption={addOption}
                        removeOption={removeOption}
                    />
                )
                    }

                    {itemType === IItemTypes.COMBO
                && (
                    <ItemComboModule
                        itemCombos={itemCombos}
                        chooseCombo={chooseCombo}
                        changeComboRequired={changeComboRequired}
                        addComboElem={addComboElem}
                        removeComboElem={removeComboElem}
                        changeComboElemPrice={changeComboElemPrice}
                        addCombo={addCombo}
                        removeCombo={removeCombo}
                    />
                )
                    }

                    {itemType === IItemTypes.SUBCATEG
            && (
                <ItemSubItemModule
                    subItemIds={itemSubItemIds}
                    addSubItemId={addSubItemId}
                    removeSubItemId={removeSubItemId}
                    changeSubItemId={changeSubItemId}
                />
            )}

                    <div className="miscWrapper">
                        <p>Miscellaneous</p>
                        <div className="row">
                            {itemType === IItemTypes.REG && (
                                <>
                                    <div className="inputBox inputCheckBox half">
                                        <label htmlFor="needPrep">Needs Preparation</label>
                                        <input
                                            id="needPrep"
                                            type="checkbox"
                                            checked={itemNeedPrep}
                                            onChange={changeItemNeedPrep}
                                        />
                                    </div>
                                    <div className="space" />
                                </>
                            )}
                            <div className="inputBox inputCheckBox half">
                                <label htmlFor="active">Active</label>
                                <input
                                    id="active"
                                    type="checkbox"
                                    checked={itemActive}
                                    onChange={changeItemActive}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttonsWrapper">
                        <div className="buttons">
                            <button
                                type="button"
                                className="cancel"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="confirm"
                                onClick={handleContinue}
                                disabled={HCLoading}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default AddEditItem;
