export enum StaffFields {
    _id = '_id',
    email = 'email',
    phone = 'phone',
    fName = 'fName',
    lName = 'lName',
    password = 'password',
    profileImg = 'profileImg',
    role = 'role',
    lastLogin = 'lastLogin',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    code = 'code',

    // Router fields
    token = 'token',
}
