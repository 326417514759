import React, { useState, useEffect, useContext } from 'react';
import ReactModal from 'react-modal';
import { Factory } from '../../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../../controllers/Store/StoreContext';
import { IMenu } from '../../../../../model/menuGroup/menu/IMenu';
import './AddEditCategory.scss';

ReactModal.setAppElement('#root');
interface AddEditCategoryProps {
    open: boolean;
    menu: IMenu | null;
    closeModal: () => void;
}

const AddEditCategory: React.FC<AddEditCategoryProps> = ({
    open, closeModal, menu,
}) => {
    const { store, setStore } = useContext(StoreContext)!;
    const menuController = Factory.getMenuController();

    const [categoryName, setCategoryName] = useState('');
    const [categoryMName, setCategoryMName] = useState('');
    const [categoryDesc, setCategoryDesc] = useState('');
    const [categoryMDesc, setCategoryMDesc] = useState('');

    const createCategory = async () => {
        if (menu === null) throw new Error('cannot create category without menu');
        const menuResponse = await menuController.createCategory(
            store._id,
            categoryName,
            categoryMName,
            categoryDesc,
            categoryMDesc,
            menu._id,
        );
        setStore(menuResponse.store);
        closeModal();
    };

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditCategoryModal"
            overlayClassName="addEditCategoryModalOverlay"
        >
            <h2>Add Category</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Category Name</h4>
                    <input onChange={(e) => setCategoryName(e.target.value)} />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Category Name</h4>
                    <input onChange={(e) => setCategoryMName(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Description</h4>
                    <input onChange={(e) => setCategoryDesc(e.target.value)} />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Description</h4>
                    <input onChange={(e) => setCategoryMDesc(e.target.value)} />
                </div>
            </div>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={createCategory}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default AddEditCategory;
