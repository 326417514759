import React, { useReducer } from 'react';
import { ICorp } from '../../model/corpGroup/ICorp';

// https://stackoverflow.com/questions/49949099/react-createcontext-point-of-defaultvalue
export const CorpContext = React.createContext<
    { corp: ICorp; setCorp: React.Dispatch<ICorp>; } | undefined
>(undefined);

const reducer = (state: ICorp, pair: ICorp) => ({ ...state, ...pair });

interface CorpProviderProps {
    corpProp: ICorp;
    children: React.ReactNode;
}

export const CorpProvider: React.FC<CorpProviderProps> = ({
    corpProp, children,
}) => {
    const [corp, setCorp] = useReducer(reducer, corpProp);
    return (
        <CorpContext.Provider value={{ corp, setCorp }}>
            {children}
        </CorpContext.Provider>
    );
};
