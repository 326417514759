import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { ToastController } from '../../../../controllers/ToastController';
import { ICorpRawMatMeta } from '../../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { IRawMatLogMeta } from '../../../../model/IngrGroup/RawMatLog/IRawMatLog';
import { IRawMatReq } from '../../../../model/IngrGroup/RawMatReq/IRawMatReq';
import { IStore } from '../../../../model/storeGroup/store/IStore';
import './RequestMaterialsModal.scss';

interface RequestMaterialsModalProps {
    open: boolean;
    closeModal: () => void;
    store: IStore;
    rawMatMetas: IRawMatLogMeta[];
    corpRawMatMetas: ICorpRawMatMeta[];
}

const RequestMaterialsModal:React.FC<RequestMaterialsModalProps> = ({
    open, closeModal, store, rawMatMetas, corpRawMatMetas,
}) => {
    const { i18n } = useTranslation();

    const [selMatId, setSelMatId] = useState('');
    const selectRawMat = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value;
        setSelMatId(val);
    };

    const [units, setUnits] = useState(0);
    const changeUnits = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(e.target.value);
        setUnits(val);
    };

    const [rawMatMeta, setRawMatMeta] = useState<IRawMatLogMeta | null>(null);
    const getRawMatMeta = () => {
        if (selMatId === '') return;
        const target = rawMatMetas.find((meta) => meta._id === selMatId);
        if (target === undefined) throw new Error('cannot find raw mat');
        setRawMatMeta(target);
    };

    const [rawMatReqArr, setRawMatReqArr] = useState<IRawMatReq[]>([]);
    const getRawMetaReqArr = async () => {
        const resp = await Factory.RawMatReqController.getRawMatReqByStoreId(
            store._id,
        );
        setRawMatReqArr(resp);
    };

    const sendRequest = async () => {
        if (rawMatMeta === null || rawMatMeta.corpRawMatId === null) return;
        await Factory.RawMatReqController.createRawMatReq(
            store._id, store.corpId, rawMatMeta._id, rawMatMeta.corpRawMatId, units,
        );
        ToastController.success(`Successfully requested ${units} units of ${rawMatMeta.name[i18n.language]}`);
        await getRawMetaReqArr();
        closeModal();
    };

    useEffect(() => {
        getRawMetaReqArr();
    }, []);

    const getCorpRawMetaById = (metaId: string) => {
        const target = corpRawMatMetas.find((meta) => meta._id === metaId);
        if (target === undefined) throw new Error('Cannot find corp raw mat');
        return target;
    };

    const handleUpdateStatus = async (reqId: string, reqStatus: number) => {
        const corpReqArr = await Factory.RawMatReqController.updateStatus(
            reqId, reqStatus,
        );
        if (reqStatus === -1) ToastController.success('Successfully cancelled request');
        setRawMatReqArr(corpReqArr.data.rawMatReqs);
    };

    const renderActions = (req: IRawMatReq) => {
        if (req.reqStatus === 0) {
            return (
                <>
                    <button
                        type="button"
                        className="cancel"
                        onClick={() => handleUpdateStatus(req._id, -1)}
                    >
                        Cancel
                    </button>
                </>
            );
        }
        if (req.reqStatus === -1) {
            return (
                <button
                    type="button"
                    className="cancel"
                    disabled
                >
                    Cancelled
                </button>
            );
        }
        return (
            <button
                type="button"
                className="send"
                disabled
            >
                Sent
            </button>
        );
    };

    useEffect(() => {
        getRawMatMeta();
        setUnits(0);
    }, [selMatId]);

    useEffect(() => {
        setSelMatId('');
        setRawMatMeta(null);
        setUnits(0);
    }, [open]);

    if (!open) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="requestMaterialsModal"
            overlayClassName="requestMaterialsModalOverlay"
        >
            <h2>Request Raw Materials</h2>
            <div className="row">
                <div className="inputBox">
                    <select onChange={selectRawMat}>
                        <option value="" disabled selected hidden>Select a raw material</option>
                        {rawMatMetas.filter((meta) => (meta.corpRawMatId !== null)).map((meta) => (
                            <option value={meta._id}>{meta.name[i18n.language]}</option>
                        ))}
                    </select>
                </div>
            </div>
            {rawMatMeta !== null && (
                <>
                    <div className="row">
                        <div className="inputBox">
                            <h4>Units to request</h4>
                            <input value={units} onChange={changeUnits} />
                        </div>
                    </div>
                    <div className="buttonContainer">
                        <div className="buttonBox">
                            <button
                                type="button"
                                onClick={sendRequest}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </>
            )}
            <hr />

            {rawMatReqArr.map((req) => {
                const corpMat = getCorpRawMetaById(req.corpRawMatId);
                return (
                    <div className="request">
                        <h2>{store.name[i18n.language]}</h2>
                        {corpMat.name[i18n.language]}
                        <p>{`${req.unitsReqed} units`}</p>
                        <p>{req.updateSentDates[0].toLocaleString()}</p>
                        <div className="actions">
                            {renderActions(req)}
                        </div>
                    </div>
                );
            })}
        </ReactModal>
    );
};

export default RequestMaterialsModal;
