import React, { useEffect, useState } from 'react';
import { Factory } from '../../../controllers/ControllerFactory';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { IGalaDonation } from '../../../model/galaGroup/galaDonation/iGalaDonation';
import { amountToString } from '../../../utils/global';
import GalaDonationModal from './GalaDonationModal';
import './GalaDonationViewer.scss';

interface GalaDonationViewerProps{
    currGala: IGala;
}

export const GalaDonationViewer: React.FC<GalaDonationViewerProps> = ({
    currGala,
}) => {
    const [donations, setDonations] = useState<IGalaDonation[]>([]);
    const getDonations = async () => {
        const tDonations = await Factory.GalaController.getGalaDonations(currGala._id);
        tDonations.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        setDonations(tDonations);
    };
    useEffect(() => {
        getDonations();
    }, []);

    const [currDonation, setCurrTable] = useState<IGalaDonation | null>(null);
    const [donationModalOpen, setDonationModalOpen] = useState(false);
    const openModal = (tTable: IGalaDonation | null) => {
        setCurrTable(tTable);
        setDonationModalOpen(true);
    };
    const closeModal = () => {
        setCurrTable(null);
        setDonationModalOpen(false);
    };

    return (
        <div className="galaTableContainer">
            <GalaDonationModal
                open={donationModalOpen}
                closeModal={closeModal}
                galaDonation={currDonation}
            />
            <div className="headerRow">
                <div className="info">Name</div>
                <div className="info">Amount</div>
                <div className="info">Time</div>
                <div />
                {/* <div className="contact">Phone</div>
                <div className="contact">Email</div> */}
            </div>
            {donations.map((tDonation: IGalaDonation) => (
                <div className="attendeeRow">
                    <div className="info">{`${tDonation.fName} ${tDonation.lName}`}</div>
                    <div className="info">{`${amountToString(tDonation.amount)}`}</div>
                    <div className="info">{new Date(tDonation.createdAt).toLocaleString()}</div>
                    <div><button type="button" onClick={() => openModal(tDonation)}>View Details</button></div>
                    {/* <div className="contact">{attendee.phone}</div>
                    <div className="contact">{attendee.email}</div> */}
                </div>
            ))}
        </div>
    );
};
