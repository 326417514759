import React, { useState, useEffect } from 'react';
import {
    Route, Redirect, RouteProps, useHistory,
} from 'react-router-dom';
import { Factory } from '../../controllers/ControllerFactory';
import { StaffController } from '../../controllers/Staff/StaffController';

interface ProtectedRouteProps extends RouteProps {
    Component: React.ComponentType<RouteProps>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    Component, ...rest
}) => {
    const history = useHistory();
    const [auth, setAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const userController = Factory.getStaffController();

    useEffect(() => {
        try {
            // send jwt to API to see if it's valid
            userController.validateJWT(history).then((authSuccess) => {
                setAuth(authSuccess);
                setIsLoading(false);
            }).catch((err) => {
                history.push('/login');
            });
        } catch (err) {
            history.push('/login');
        }
    }, []);
    if (isLoading) return <div />;
    return (
        /* eslint-disable react/jsx-props-no-spreading */
        <Route
            {...rest}
            render={(props) => (auth ? <Component {...props} /> : <Redirect to="/login" />)}
        />
    );
};

export default ProtectedRoute;
