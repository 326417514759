import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './TaxEditor.scss';
import AddBar from '../components/AddBar/AddBar';
import { StoreContext } from '../../../controllers/Store/StoreContext';

import AddEditTax from './AddEditTax/AddEditTax';
import TaxCard from './TaxCard/TaxCard';
import { ITax } from '../../../model/storeGroup/tax/ITax';

interface TaxEditorProps{}

const TaxEditor: React.FC<TaxEditorProps> = () => {
    const { t } = useTranslation();
    const { store } = useContext(StoreContext)!;

    const [currTax, setCurrTax] = useState<ITax | null>(null);

    const [taxModalOpen, setTaxModalOpen] = useState(false);
    const openTaxModal = () => setTaxModalOpen(true);
    const closeTaxModal = () => setTaxModalOpen(false);

    const generateTaxes = () => store.taxes.map((tax) => (
        <TaxCard
            tax={tax}
            selectTax={() => {
                setCurrTax(tax);
                openTaxModal();
            }}
        />
    ));
    return (

        <div className="taxEditorContainer dashboardScreen">
            <AddEditTax
                tax={currTax}
                open={taxModalOpen}
                closeModal={closeTaxModal}
            />
            <header>
                <h2 className="screenTitle">
                    Tax Editor
                </h2>
            </header>
            <AddBar
                handleClick={() => {
                    setCurrTax(null);
                    openTaxModal();
                }}
                text="Add Tax"
            />
            <div className="taxContainer">
                {generateTaxes()}
            </div>
        </div>

    );
};

export default TaxEditor;
