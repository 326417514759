import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StaffContext } from '../../../../controllers/Staff/StoreContext';
import { IRawMatLogMeta } from '../../../../model/IngrGroup/RawMatLog/IRawMatLog';
import './RawMatStockModal.scss';

ReactModal.setAppElement('#root');
interface RawMatStockModalProps {
    open: boolean;
    rawMatMeta: IRawMatLogMeta | null;
    setRawMatMetas: React.Dispatch<React.SetStateAction<IRawMatLogMeta[]>>;
    closeModal: () => void;
}

const RawMatStockModal: React.FC<RawMatStockModalProps> = ({
    open, rawMatMeta, setRawMatMetas, closeModal,
}) => {
    const { i18n } = useTranslation();
    const { staff } = useContext(StaffContext)!;

    // TODO ERIC XIE ALLOW FOR DECIMAL DECIAMLS
    const [unitPrice, setUnitPrice] = useState(0);
    const changeUnitPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(e.target.value);
        setUnitPrice(val);
    };

    const [unitsStocked, setUnitsStocked] = useState(0);
    const changeUnitsStocked = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setUnitsStocked(value);
    };

    const [notes, setNotes] = useState('');
    const changeNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(e.target.value);
    };

    const restock = async () => {
        if (rawMatMeta === null) throw new Error('No Ingredient found');
        const ingrLog = await Factory.RawMatLogController.restock(
            rawMatMeta._id,
            staff._id,
            null,
            unitPrice,
            unitsStocked,
            notes,
        );
        closeModal();
        return ingrLog;
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleConfirm = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        try {
            const rawMatLogResp = await restock();
            setRawMatMetas(rawMatLogResp.data.rawMatLogMetas);
        } catch (err) {
            //
        }
        setHCLoading(false);
    };

    useEffect(() => {
        setUnitPrice(0);
        setUnitsStocked(0);
        setNotes('');
    }, [open]);

    if (rawMatMeta === null) return <></>;

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditIngrStockModal"
            overlayClassName="addEditIngrStockModalOverlay"
        >
            <h2>
                {`Add stock for ${rawMatMeta.name.en}`}
                <h3>{`Current Stock ${rawMatMeta.unitsAvail}`}</h3>
                <h3>{`Measurement Unit ${rawMatMeta.unitOfM}`}</h3>
            </h2>

            <div className="column">
                <div className="inputBox">
                    <h4>Stock Cost</h4>
                    <input
                        value={unitPrice}
                        type="number"
                        onChange={changeUnitPrice}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Units Stocked</h4>
                    <input
                        type="number"
                        value={unitsStocked}
                        onChange={changeUnitsStocked}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Notes</h4>
                    <input
                        value={notes}
                        onChange={changeNotes}
                    />
                </div>
            </div>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={handleConfirm} disabled={HCLoading}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default RawMatStockModal;
