import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Timeit } from 'react-timeit';
import ReactModal from 'react-modal';
import { DayPilot } from 'daypilot-pro-react';
import Select from 'react-select';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { IShiftStat } from '../../../../model/analyticsGroup/seshReport/shiftStat/IShiftStat';
import { IStaffReport } from '../../../../model/analyticsGroup/staffReport/IStaffReport';
import { IStoreStaffRefsSchema } from '../../../../model/storeGroup/store/storeStaffRefs/IStoreStaffRefs';
import { amountToString } from '../../../../utils/global';

import './AddEditEvent.scss';
import { Factory } from '../../../../controllers/ControllerFactory';
import { IStoreStaff } from '../../../../model/storeGroup/store/storeStaff/IStoreStaff';
// import 'react-timeit/dist/index.css';

interface AddEditEventProps {
    args: DayPilot.CalendarTimeRangeSelectedArgs | null;
    open: boolean;
    viewStart: Date | null;
    viewEnd: Date | null;
    storeStaffArr: IStoreStaff[];
    getShiftPlanner: () => Promise<void>;
    closeModal: () => void;
}

const AddEditEvent: React.FC<AddEditEventProps> = ({
    args, open, viewStart, viewEnd, storeStaffArr, getShiftPlanner, closeModal,
}) => {
    const { store } = useContext(StoreContext)!;
    const [time, setTime] = useState('');
    const [staffIds, setStaffIds] = useState<string[]>([]);

    const shiftPlannerController = Factory.getShiftPlannerController();

    const validStaffList = storeStaffArr.map((staffRef: IStoreStaff) => ({
        value: staffRef.staffId,
        label: `${staffRef.fName} ${staffRef.lName ? staffRef.lName : ''}`,
    }));
    const changeStaffIds = (itemIds: any[]) => {
        const tmpStaffIds = itemIds.map((multiValue) => multiValue.value);
        setStaffIds(tmpStaffIds);
    };

    const getHourList = () => {
        if (args === null) return [];
        return Array.from(Array.from(Array(args.start.getHours() + 1).keys()));
    };

    const getMinList = () => {
        if (args === null) return [];
        const arr = Array.from(Array(60).keys());
        const minList = arr.filter((x) => x < args.start.getMinutes() || x % 5 !== 0);
        return minList;
    };

    const handleConfirm = async () => {
        if (args === null || viewStart === null || viewEnd === null) return;
        const startTime = new Date(args.start.toString());
        const endTime = new Date(args.end.toString());
        const timeSplitted = time.split(':');
        const hour = timeSplitted[0];
        const min = timeSplitted[1];
        endTime.setHours(Number.parseInt(hour, 10));
        endTime.setMinutes(Number.parseInt(min, 10));

        await shiftPlannerController.createShiftPlan(
            staffIds, store._id, viewStart, viewEnd, startTime, endTime,
        );

        await getShiftPlanner();
        closeModal();
    };

    useEffect(() => {
        setTime('');
        setStaffIds([]);
    }, [open]);

    if (args === null) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditEventModal"
            overlayClassName="addEditEventModalOverlay"
        >
            <header>
                <h2>Add/Edit Shift</h2>
            </header>
            <div className="inputBox">
                <h3>Select Staffs</h3>
                <Select
                    isMulti
                    className="staffSelector"
                    options={validStaffList}
                    onChange={(e: any) => changeStaffIds(e)}
                />
            </div>
            <div className="inputBox timeit">
                <h3>Shift End Time</h3>
                <Timeit
                    notShowExclude
                    hourExclude={getHourList()}
                    minuteExclude={getMinList()}
                    onChange={(value) => setTime(value)}
                />
            </div>
            <div className="buttonWrapper">
                <button type="button" className="cancel" onClick={closeModal}>Cancel</button>
                <button type="button" className="confirm" onClick={handleConfirm}>Confirm</button>
            </div>

        </ReactModal>
    );
};

export default AddEditEvent;
