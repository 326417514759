import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRawMatLogMeta } from '../../../../model/IngrGroup/RawMatLog/IRawMatLog';
import './RawMatCard.scss';

interface RawMatCardProps{
    rawMatMeta: IRawMatLogMeta;
    selectIngrLog: () => void;
    openIngrStockModal: () => void;
    openIngrAdjModal: () => void;
    openIngrReportModal: () => void;
}

const RawMatCard: React.FC<RawMatCardProps> = ({
    rawMatMeta, selectIngrLog, openIngrStockModal, openIngrAdjModal, openIngrReportModal,
}) => {
    const { t, i18n } = useTranslation();

    const getCardName = () => {
        let stockStr : string = '';
        if (rawMatMeta.unitsAvail <= 0) stockStr = ' (Out of Stock)';
        else if (rawMatMeta.unitsAvail <= rawMatMeta.warningUnits) stockStr = ' (Stock Low)';
        return `${rawMatMeta.name[i18n.language]}${stockStr}`;
    };

    const generateCardStyle = () => {
        if (rawMatMeta.unitsAvail <= 0) return 'ingrLogCardContainer ingrLogCardOutOfStock';
        if (rawMatMeta.unitsAvail <= rawMatMeta.warningUnits) return 'ingrLogCardContainer ingrLogCardWarning';
        return 'ingrLogCardContainer';
    };

    return (

        <div
            className={generateCardStyle()}
            aria-hidden
        >
            <div className="info">
                <h3>{getCardName()}</h3>
                <div className="currentStock">
                    <div className="desc">{rawMatMeta.desc[i18n.language]}</div>
                    <div className="desc">{`Current Stock: ${rawMatMeta.unitsAvail}${rawMatMeta.unitOfM}`}</div>
                </div>
            </div>
            <div className="ingrLogRow">
                <button
                    type="button"
                    onClick={() => selectIngrLog()}
                >
                    Edit
                </button>
                <button
                    type="button"
                    onClick={() => openIngrStockModal()}
                >
                    Add Stock
                </button>
                <button
                    type="button"
                    onClick={() => openIngrAdjModal()}
                >
                    Add Adjustments
                </button>
                <button
                    type="button"
                    onClick={() => openIngrReportModal()}
                >
                    View Report
                </button>
            </div>
        </div>

    );
};

export default RawMatCard;
