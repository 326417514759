import { Config } from '../../Config';
import { Dict } from '../../model/helpers/Dict';
import { IIngrLogReport } from '../../model/IngrGroup/IIngrLogReport';
import { IIngrLog, IIngrLogMeta } from '../../model/IngrGroup/IngrLog/IIngrLog';
import { MeasurementEnum } from '../../model/IngrGroup/IngrLog/MeasurementEnum';
import { AxiosController, IResponseSchema } from '../AxiosController';

interface IngrLogResponse extends IResponseSchema {
    data: {
        ingrLog: IIngrLog,
    },
}

interface IngrLogReportResponse extends IResponseSchema {
    data: {
        ingrLogReport: IIngrLogReport,
    },
}

interface IngrLogAndMetasResponse extends IResponseSchema {
    data: {
        ingrLog: IIngrLog,
        ingrLogMetas: IIngrLogMeta[],
    },
}

interface IngrLogMetasResponse extends IResponseSchema {
    data: {
        ingrLogMetas: IIngrLogMeta[],
    }
}

export class IngrLogController extends AxiosController {
    public async getIngrLog(
        ingrLogId: string,
    ) : Promise<IIngrLog> {
        const resp : IngrLogResponse = await this.axiosGET(`${Config.SERVER_URL}/ingrLog`, {
            ingrLogId: ingrLogId,
        });
        const ingrLog: IIngrLog = resp.data.ingrLog;
        return ingrLog;
    }

    public async getIngrLogReport(
        ingrLogId: string,
        startTime: Date | null,
        endTime: Date | null,
    ) : Promise<IIngrLogReport> {
        const resp : IngrLogReportResponse = await this.axiosGET(`${Config.SERVER_URL}/ingrLog/report`, {
            ingrLogId: ingrLogId,
            startTime: startTime,
            endTime: endTime,
        });
        const ingrLog: IIngrLogReport = resp.data.ingrLogReport;
        return ingrLog;
    }

    public async getIngrLogMetas(
        storeId: string,
    ) : Promise<IIngrLogMeta[]> {
        const resp : IngrLogMetasResponse = await this.axiosGET(`${Config.SERVER_URL}/ingrLog/metas`, {
            storeId: storeId,
        });
        const ingrLogMetas: IIngrLogMeta[] = resp.data.ingrLogMetas;
        return ingrLogMetas;
    }

    public async createIngrLog(
        storeId: string,
        name: Dict<string>,
        desc: Dict<string>,
        mName: string,
        mDesc: string,
        unitOfM: string,
        warningUnits: number,
        recipeCost: number,
        recipeMap: Dict<number>,
    ) : Promise<IngrLogAndMetasResponse> {
        const resp : IngrLogAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/ingrLog`, {
            storeId: storeId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            unitOfM: unitOfM,
            warningUnits: warningUnits,
            recipeCost: recipeCost,
            recipeMap: recipeMap,
        });
        return resp;
    }

    public async editIngrLog(
        ingrLogId: string,
        storeId: string,
        name: Dict<string>,
        desc: Dict<string>,
        mName: string,
        mDesc: string,
        unitOfM: MeasurementEnum,
        warningUnits: number,
        recipeCost: number,
        recipeMap: Dict<number>,
    ) : Promise<IngrLogAndMetasResponse> {
        const resp : IngrLogAndMetasResponse = await this.axiosPUT(`${Config.SERVER_URL}/ingrLog`, {
            ingrLogId: ingrLogId,
            storeId: storeId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            unitOfM: unitOfM,
            warningUnits: warningUnits,
            recipeCost: recipeCost,
            recipeMap: recipeMap,
        });
        return resp;
    }

    public async restock(
        ingrLogId: string,
        staffId: string,
        supplierId: string | null,
        unitsStocked: number,
        notes: string,
    ) : Promise<IngrLogAndMetasResponse> {
        const resp : IngrLogAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/ingrLog/restock`, {
            ingrLogId: ingrLogId,
            staffId: staffId,
            supplierId: supplierId,
            unitsStocked: unitsStocked,
            notes: notes,
        });
        return resp;
    }

    public async addIngrAdj(
        ingrLogId: string,
        staffId: string,
        unitsAdj: number,
        notes: string,
    ) : Promise<IngrLogAndMetasResponse> {
        const resp : IngrLogAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/ingrLog/addIngrAdj`, {
            ingrLogId: ingrLogId,
            staffId: staffId,
            unitsAdj: unitsAdj,
            notes: notes,
        });
        return resp;
    }
}
