import React, { useEffect, useState } from 'react';
import { Factory } from '../../../controllers/ControllerFactory';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { IGalaTable } from '../../../model/galaGroup/galaTable/IGalaTable';
import GalaTableModal from './GalaTableModal';
import './GalaTableViewer.scss';

interface GalaTableViewerProps{
    currGala: IGala;
}

export const GalaTableViewer: React.FC<GalaTableViewerProps> = ({
    currGala,
}) => {
    const [tables, setTables] = useState<IGalaTable[]>([]);
    const getTables = async () => {
        const tTables = await Factory.GalaController.getGalaTablesByGalaId(currGala._id);
        tTables.sort((a, b) => a.tableName.localeCompare(b.tableName));
        setTables(tTables);
    };
    useEffect(() => {
        getTables();
    }, []);

    const [currTable, setCurrTable] = useState<IGalaTable | null>(null);
    const [tableModalOpen, setTableModalOpen] = useState(false);
    const openModal = (tTable: IGalaTable | null) => {
        setCurrTable(tTable);
        setTableModalOpen(true);
    };
    const closeModal = () => {
        setCurrTable(null);
        setTableModalOpen(false);
    };

    return (
        <div className="galaTableContainer">
            <GalaTableModal
                open={tableModalOpen}
                closeModal={closeModal}
                currGala={currGala}
                galaTable={currTable}
                refresh={getTables}
            />
            <div className="createButton"><button type="button" onClick={() => openModal(null)}>Create New Table</button></div>
            <div className="headerRow">
                <div className="status">Table Name</div>
                <div className="ticketNum">Number Seated</div>
                <div />
                {/* <div className="contact">Phone</div>
                <div className="contact">Email</div> */}
            </div>
            {tables.map((tTable: IGalaTable) => (
                <div className="attendeeRow">
                    <div className="status">{`${tTable.tableName}`}</div>
                    <div className="ticketNum">{`${tTable.galaAttendeeIds.length}`}</div>
                    <div><button type="button" onClick={() => openModal(tTable)}>View</button></div>
                    {/* <div className="contact">{attendee.phone}</div>
                    <div className="contact">{attendee.email}</div> */}
                </div>
            ))}
        </div>
    );
};
