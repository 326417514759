/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../../../model/helpers/Dict';
import { IComboRef } from '../../../../../../model/menuGroup/combo/IComboRef';
import ComboElemCard from './ComboElemCard/ComboElemCard';
import './ItemComboModule.scss';

interface ItemComboModuleProps {
    itemCombos: Dict<IComboRef>;
    chooseCombo: (
        e: React.ChangeEvent<HTMLSelectElement>,
        tmpComId: string,
    ) => void;
    changeComboRequired: (e: React.ChangeEvent<HTMLInputElement>, tmpComId: string) => void;

    addComboElem: (tmpComId: string, comboId: string) => void;
    removeComboElem: (tmpComId: string, itemId: string) => void;

    changeComboElemPrice: (
        e: React.ChangeEvent<HTMLInputElement>,
        tmpComId: string, itemId: string, priceKey: string,
    )=> void;

    addCombo: () => void;
    removeCombo: (tmpOptId: string) => void;
}

const ItemComboModule: React.FC<ItemComboModuleProps> = ({
    itemCombos, chooseCombo, changeComboRequired, addComboElem, removeComboElem,
    changeComboElemPrice, addCombo, removeCombo,
}) => {
    const { t, i18n } = useTranslation();
    const { store } = useContext(StoreContext)!;

    // Rendering
    const generateComboElems = (tmpComId: string, comRef: IComboRef) => comRef.comboElemRefs.map(
        (comboElemRef) => {
            const combo = store.combos.find(
                (opt) => opt._id
                                === comRef.comboId,
            );
            if (combo === undefined) throw new Error('Cannot find optionElem');
            const item = store.items.find((tmpItem) => (
                tmpItem._id === comboElemRef.itemId
            ));
            if (item === undefined) throw new Error('cannot find item from ref');
            return (
                <ComboElemCard
                    name={`${item.name[i18n.language]} (${item.mName})`}
                    comboElemRef={comboElemRef}
                    comIdRef={tmpComId}
                    removeComboElem={removeComboElem}
                    changeComboElemPrice={changeComboElemPrice}
                />
            );
        },
    );
    return (

        <div className="combos">
            <p>Combos</p>

            {Object.keys(itemCombos).map((tmpComId) => {
                const itemCom = itemCombos[tmpComId];
                return (
                    <div className="optionContainer">
                        <div className="row">
                            <div className="inputBox">
                                <select
                                    onChange={(e) => chooseCombo(e, tmpComId)}
                                >
                                    <option
                                        value=""
                                        selected={itemCom.comboId === ''}
                                        disabled
                                        hidden
                                    >
                                        Choose combo ...
                                    </option>
                                    {store.combos.map((com) => (
                                        <option
                                            value={com._id}
                                            selected={
                                                com._id === itemCom.comboId
                                            }
                                            hidden={
                                                Object.values(itemCombos)
                                                    .find((comRef) => (
                                                        comRef.comboId === com._id
                                                    )) !== undefined
                                            }
                                        >
                                            {`${com.name[i18n.language]} (${
                                                com.mName
                                            })`}
                                        </option>
                                    ))}
                                    <option value="">Add New</option>
                                </select>
                            </div>
                        </div>
                        {itemCom.comboId !== '' && (
                            <>
                                <div className="row">
                                    <div className="inputBox inputCheckBox">
                                        <label htmlFor={`${itemCom.comboId}_required`}>
                                            Required
                                        </label>
                                        <input
                                            id={`${itemCom.comboId}_required`}
                                            type="checkbox"
                                            checked={itemCom.required}
                                            onChange={(e) => changeComboRequired(e, tmpComId)}
                                        />
                                    </div>
                                </div>
                                <div className="optionElemList">
                                    {generateComboElems(tmpComId, itemCom)}
                                    <div
                                        aria-hidden
                                        className="optionElemCardContainer addOptionElem"
                                        onClick={() => addComboElem(
                                            tmpComId,
                                            itemCom.comboId,
                                        )}
                                    >
                                        +
                                    </div>
                                </div>
                            </>
                        )}
                        <div
                            className="removeOption"
                            aria-hidden
                            onClick={() => removeCombo(tmpComId)}
                        >
                            X
                        </div>
                    </div>
                );
            })}

            <button className="addCombo" type="button" onClick={addCombo}>
                +
            </button>
        </div>

    );
};

export default ItemComboModule;
