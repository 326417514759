import React, { useEffect, useState } from 'react';
import { Factory } from '../../../controllers/ControllerFactory';
import { IGalaTicket } from '../../../model/galaGroup/gala/galaTicket/iGalaTicket';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { IGalaAttendee } from '../../../model/galaGroup/galaAttendee/iGalaAttendee';
import { IGalaLive } from '../../../model/galaGroup/galaLive/IGalaLive';
import AttendeeModal from '../galaAttendeeViewer/AttendeeModal';
import './GalaCheckInViewer.scss';

enum CheckInStatus {
    checkedIn = 'Checked In',
    notCheckedIn = 'Not Checked In',
    all = 'All'
}

interface GalaCheckInViewerProps{
    currGala: IGala;
}

export const GalaCheckInViewer: React.FC<GalaCheckInViewerProps> = ({
    currGala,
}) => {
    const [attendees, setAttendees] = useState<IGalaAttendee[]>([]);
    const getAttendees = async () => {
        const tAttendees = await Factory.GalaController.getGalaAttendeesByGalaId(currGala._id);
        setAttendees(tAttendees);
    };
    const [galalLive, setGalaLive] = useState<IGalaLive | null>(null);
    const getGalaLive = async () => {
        const tGalaLive = await Factory.GalaController.getGalaLiveByGalaId(currGala._id);
        setGalaLive(tGalaLive);
    };
    useEffect(() => {
        getAttendees();
        getGalaLive();
    }, []);

    const [checkInStatus, setCheckInStatus] = useState<string>(CheckInStatus.all);
    const [ticketTypeId, setTicketType] = useState<string | null>(null);

    const [attendee, setAttendee] = useState<IGalaAttendee | null>(null);
    const [attendeeModalOpen, setAttendeeModalOpen] = useState(false);
    const openModal = (tAttendee: IGalaAttendee) => {
        setAttendee(tAttendee);
        setAttendeeModalOpen(true);
    };
    const closeModal = () => {
        setAttendee(null);
        setAttendeeModalOpen(false);
    };

    const filterAttendees = (ticketId: string | null) => {
        if (galalLive === null) return attendees;
        if (checkInStatus === CheckInStatus.checkedIn) {
            return attendees.filter((tAttendee) => {
                if (ticketId === null) return galalLive.checkedInAttenIds.includes(tAttendee._id);
                return galalLive.checkedInAttenIds.includes(tAttendee._id) && tAttendee.galaTicketId === ticketId;
            });
        }
        if (checkInStatus === CheckInStatus.notCheckedIn) {
            return attendees.filter((tAttendee) => {
                if (ticketId === null) return !galalLive.checkedInAttenIds.includes(tAttendee._id);
                return !galalLive.checkedInAttenIds.includes(tAttendee._id) && tAttendee.galaTicketId === ticketId;
            });
        }
        if (ticketId !== null) return attendees.filter((tAttendee) => tAttendee.galaTicketId === ticketId);
        return attendees;
    };

    const getTicketName = (ticketId: string) => {
        const ticket : IGalaTicket | undefined = currGala.galaTickets.find((tTicket) => tTicket._id === ticketId);
        if (!ticket) return 'Ticket Type Not Found';
        return ticket.name.en;
    };

    if (galalLive === null) return <></>;

    return (
        <div className="galaCheckInContainer">
            <AttendeeModal
                open={attendeeModalOpen}
                closeModal={closeModal}
                attendee={attendee}
                refresh={getAttendees}
            />
            <div className="attendeeStatus">
                <h3>{`Check In Status : ${checkInStatus}`}</h3>
                <div className="row">
                    <button
                        type="button"
                        onClick={() => setCheckInStatus(CheckInStatus.all)}
                        className={checkInStatus === CheckInStatus.all ? 'active' : ''}
                    >
                        {`${CheckInStatus.all} Attendees: ${attendees.length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setCheckInStatus(CheckInStatus.checkedIn)}
                        className={checkInStatus === CheckInStatus.checkedIn ? 'active' : ''}
                    >
                        {`${CheckInStatus.checkedIn} Attendees: ${attendees.filter((tAttendee) => galalLive.checkedInAttenIds.includes(tAttendee._id)).length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setCheckInStatus(CheckInStatus.notCheckedIn)}
                        className={checkInStatus === CheckInStatus.notCheckedIn ? 'active' : ''}
                    >
                        {`${CheckInStatus.notCheckedIn} Attendees: ${attendees.filter((tAttendee) => !galalLive.checkedInAttenIds.includes(tAttendee._id)).length}`}
                    </button>
                </div>
            </div>
            <div className="ticketType">
                <h3>Select Ticket Type</h3>
                <div className="row">
                    <button
                        type="button"
                        onClick={() => setTicketType(null)}
                    >
                        {`All Types: ${filterAttendees(null).length}`}
                    </button>
                    {currGala.galaTickets.map((currTicket : IGalaTicket) => (
                        <button
                            type="button"
                            onClick={() => setTicketType(currTicket._id)}
                            className={ticketTypeId === currTicket._id ? 'active' : ''}
                        >
                            {`${currTicket.name.en}: ${filterAttendees(currTicket._id).length}`}
                        </button>
                    ))}
                </div>
            </div>
            <div className="headerRow">
                <div className="status">Status</div>
                <div className="ticketNum">Ticket #</div>
                <div className="ticketTier">Tier</div>
                <div className="name">Name</div>
                <div />
                {/* <div className="contact">Phone</div>
                <div className="contact">Email</div> */}
            </div>
            {filterAttendees(ticketTypeId).map((tAttendee: IGalaAttendee) => (
                <div className="attendeeRow">
                    <div className="ticketNum">{tAttendee.ticketNum}</div>
                    <div className="ticketTier">{getTicketName(tAttendee.galaTicketId)}</div>
                    <div className="name">{tAttendee.registered ? `${tAttendee.fName} ${tAttendee.lName}` : 'N/A'}</div>
                    <div><button type="button" onClick={() => openModal(tAttendee)}>View</button></div>
                    {/* <div className="contact">{attendee.phone}</div>
                    <div className="contact">{attendee.email}</div> */}
                </div>
            ))}
        </div>
    );
};
