import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Factory } from '../../../controllers/ControllerFactory';
import { IGalaAttendee } from '../../../model/galaGroup/galaAttendee/iGalaAttendee';
import { IGalaTicketSale } from '../../../model/galaGroup/galaTicketSale/iGalaTicketSale';
import './AttendeeModal.scss';

interface AttendeeModalProps {
    open: boolean;
    closeModal: () => void;
    attendee: IGalaAttendee | null;
    refresh: () => Promise<void>;
}

const AttendeeModal: React.FC<AttendeeModalProps> = ({
    open, closeModal, attendee, refresh,
}) => {
    const a = 1;
    const galaController = Factory.GalaController;

    const [ticketSale, setTicketSale] = useState<IGalaTicketSale | null>(null);

    const [isEdit, setIsEdit] = useState(false);

    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [pName, setPName] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mealChoice, setMealChoice] = useState('');
    const [dietRestriction, setDietRestriction] = useState('');
    const [seatingNotes, setSeatingNotes] = useState('');
    const [notes, setNotes] = useState('');
    const [sponsor, setSponsor] = useState('');

    const onChangeVal = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, fn: React.Dispatch<React.SetStateAction<string>>) => {
        const val = e.target.value;
        fn(val);
    };

    const editSave = async () => {
        if (isEdit) {
            // save
            if (attendee === null) throw new Error('cannot edit attendee that is null');
            await galaController.editAttendee(
                attendee._id,
                attendee.registered,
                fName,
                pName,
                lName,
                address,
                postalCode,
                city,
                province,
                country,
                email,
                phone,
                mealChoice,
                dietRestriction,
                seatingNotes,
                notes,
                sponsor,
            );
            setIsEdit(false);
            await refresh();
            closeModal();
            return;
        }
        setIsEdit(true);
    };

    useEffect(() => {
        const getTicketSale = async (attendeeId: string) => {
            const tTicketSale = await galaController.getGalaTicketSaleByAttendeeId(attendeeId);
            setTicketSale(tTicketSale);
        };
        if (attendee !== null) {
            getTicketSale(attendee._id);
        } else setTicketSale(null);
    }, [attendee]);

    useEffect(() => {
        if (attendee !== null) {
            setFName(attendee.fName);
            setLName(attendee.lName);
            setPName(attendee.pName);
            setAddress(attendee.address);
            setPostalCode(attendee.postalCode);
            setCity(attendee.city);
            setProvince(attendee.province);
            setCountry(attendee.country);
            setEmail(attendee.email);
            setPhone(attendee.phone);
            setMealChoice(attendee.mealChoice);
            setDietRestriction(attendee.dietRestriction);
            setSeatingNotes(attendee.seatingNotes);
            setNotes(attendee.notes);
            setSponsor(attendee.sponsor);
        } else {
            setFName('');
            setLName('');
            setPName('');
            setAddress('');
            setPostalCode('');
            setCity('');
            setProvince('');
            setCountry('');
            setEmail('');
            setPhone('');
            setMealChoice('');
            setDietRestriction('');
            setSeatingNotes('');
            setNotes('');
            setSponsor('');
            setIsEdit(false);
        }
    }, [open]);

    if (attendee === null) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="attendeeModal"
            overlayClassName="optionStatModalOverlay"
        >
            <header>
                <h2>
                    #
                    {attendee.ticketNum}
                    {' '}
                    {`${attendee.registered ? `${attendee.fName} ${attendee.lName} - ${attendee.pName}` : 'Not Registered'}`}
                </h2>
                <button type="button" onClick={editSave}>
                    {isEdit ? 'Save' : 'Edit'}
                </button>
            </header>
            {!isEdit && (
                <>
                    <div className="infoWrapper">
                        <div className="info">
                            <p>Phone</p>
                            <p>{attendee.phone}</p>
                        </div>
                        <div className="info">
                            <p>Email</p>
                            <p>{attendee.email}</p>
                        </div>
                        <div className="info">
                            <p>Address</p>
                            {attendee.registered ? (
                                <address>
                                    {attendee.address}
                                    {' '}
                                    <br />
                                    {attendee.city}
                                    ,
                                    {' '}
                                    {attendee.province}
                                    {' '}
                                    <br />
                                    {attendee.country}
                                    ,
                                    {' '}
                                    {attendee.postalCode}
                                </address>
                            ) : ''}

                        </div>
                        <div className="info">
                            <p>Meal Choice</p>
                            <p>{attendee.mealChoice}</p>
                        </div>
                        <div className="info">
                            <p>Diet Restrictions</p>
                            <p>{attendee.dietRestriction}</p>
                        </div>
                        <div className="info">
                            <p>Seating Notes</p>
                            <p>{attendee.seatingNotes}</p>
                        </div>
                        <div className="info">
                            <p>Table Name</p>
                            <p>{attendee.tableName || 'Unassigned'}</p>
                        </div>
                        <div className="info">
                            <p>Access Code - Manual Entry</p>
                            <p>{attendee.accessCode.split('%')[attendee.accessCode.split('%').length - 1]}</p>
                        </div>
                        <div className="info">
                            <p>Access Code - QR Code</p>
                            <p>{attendee.accessCode}</p>
                        </div>
                        <div className="info">
                            <p>Notes</p>
                            <p>{attendee.notes}</p>
                        </div>
                    </div>

                    {ticketSale !== null && (
                        <>
                            <h2>Purchaser Information</h2>
                            <div className="infoWrapper">
                                <div className="info">
                                    <p>Name</p>
                                    <p>{`${ticketSale.fName} ${ticketSale.lName}`}</p>
                                </div>
                                <div className="info">
                                    <p>Phone</p>
                                    <p>{ticketSale.phone}</p>
                                </div>
                                <div className="info">
                                    <p>Email</p>
                                    <p>{ticketSale.email}</p>
                                </div>
                                <div className="info">
                                    <p>Address</p>
                                    <address>
                                        {ticketSale.address}
                                        {' '}
                                        <br />
                                        {ticketSale.city}
                                        ,
                                        {' '}
                                        {ticketSale.province}
                                        {' '}
                                        <br />
                                        {ticketSale.country}
                                        ,
                                        {' '}
                                        {ticketSale.postalCode}
                                    </address>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}

            {isEdit && (
                <div className="editContainer">
                    <div className="row">
                        <p>First Name</p>
                        <input value={fName} onChange={(e) => onChangeVal(e, setFName)} />
                    </div>
                    <div className="row">
                        <p>Last Name</p>
                        <input value={lName} onChange={(e) => onChangeVal(e, setLName)} />
                    </div>
                    <div className="row">
                        <p>Preferred Name</p>
                        <input value={pName} onChange={(e) => onChangeVal(e, setPName)} />
                    </div>
                    <div className="row">
                        <p>Address</p>
                        <input value={address} onChange={(e) => onChangeVal(e, setAddress)} />
                    </div>
                    <div className="row">
                        <p>Postal Code</p>
                        <input value={postalCode} onChange={(e) => onChangeVal(e, setPostalCode)} />
                    </div>
                    <div className="row">
                        <p>City</p>
                        <input value={city} onChange={(e) => onChangeVal(e, setCity)} />
                    </div>
                    <div className="row">
                        <p>Province</p>
                        <input value={province} onChange={(e) => onChangeVal(e, setProvince)} />
                    </div>
                    <div className="row">
                        <p>Country</p>
                        <input value={country} onChange={(e) => onChangeVal(e, setCountry)} />
                    </div>
                    <div className="row">
                        <p>Email</p>
                        <input value={email} onChange={(e) => onChangeVal(e, setEmail)} />
                    </div>
                    <div className="row">
                        <p>Phone</p>
                        <input value={phone} onChange={(e) => onChangeVal(e, setPhone)} />
                    </div>
                    <div className="row">
                        <p>Meal Choice</p>
                        <input value={mealChoice} onChange={(e) => onChangeVal(e, setMealChoice)} />
                    </div>
                    <div className="row">
                        <p>Diet Restriction</p>
                        <input value={dietRestriction} onChange={(e) => onChangeVal(e, setDietRestriction)} />
                    </div>
                    <div className="row">
                        <p>Seating Notes</p>
                        <textarea value={seatingNotes} onChange={(e) => onChangeVal(e, setSeatingNotes)} />
                    </div>
                    <div className="row">
                        <p>Notes</p>
                        <textarea value={notes} onChange={(e) => onChangeVal(e, setNotes)} />
                    </div>
                    {/* <div className="row">
                        <p>Sponsor</p>
                        <input value={sponsor} onChange={(e) => onChangeVal(e, setSponsor)} />
                    </div> */}
                </div>
            )}
        </ReactModal>
    );
};

export default AttendeeModal;
