import React from 'react';
import './TabbarLink.scss';

interface TabbarLinkProps {
    path: string,
    title: string,
    img: any,
    current: string,
    onClick: any,
    isSubTab: boolean;
}

const TabbarLink: React.FC<TabbarLinkProps> = ({
    path, title, img, current, onClick, isSubTab,
}) => (
    <div
        // className={current === path ? 'tabbarLink active' : 'tabbarLink'}
        className={`${current === path ? 'active' : ''} tabbarLink ${isSubTab ? 'subTab' : ''}`}
        onClick={() => { onClick(path); }}
        aria-hidden
    >
        <img src={img} alt="tabbar icon" />
        {title}
    </div>
);

export default TabbarLink;
