import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { StoreContext } from '../../../../../../../controllers/Store/StoreContext';
import { IOption } from '../../../../../../../model/menuGroup/option/IOption';
import { IOptionRef } from '../../../../../../../model/menuGroup/option/IOptionRef';

import './OptionElemSelector.scss';

ReactModal.setAppElement('#root');
interface OptionElemSelectorProps {
    open: boolean;
    option: IOption | null;
    optIdRef: string;
    optionRef: IOptionRef | undefined,
    closeModal: () => void;
    handleContinue: (tmpOptId: string, optionElemId: string) => void;
}

const OptionElemSelector: React.FC<OptionElemSelectorProps> = ({
    open, closeModal, optIdRef, optionRef, option, handleContinue,
}) => {
    const { i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;

    if (option === null) return <></>;
    const generateOptionElemList = () => {
        if (optionRef === undefined) return <></>;
        const retList: any = [];
        option.optionElems.forEach((optElem) => {
            const targetOptElem = optionRef.optionElemRefs.find((tmpOptRef) => (
                optElem._id === tmpOptRef.optionElemId
            ));
            if (targetOptElem === undefined) {
                retList.push(
                    <button
                        type="button"
                        onClick={() => {
                            handleContinue(optIdRef, optElem._id!);
                        }}
                    >
                        {optElem.name[i18n.language]}
                    </button>,
                );
            }
        });
        return retList;
    };
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="optionElemSelectorModal"
            overlayClassName="optionElemSelectorModalOverlay"
        >
            <h2>Option Elements</h2>
            {generateOptionElemList()}
        </ReactModal>
    );
};

export default OptionElemSelector;
