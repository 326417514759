export enum HttpStatusCode {
    // 200 SUCCESS CODES
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,

    // 400 ERRORS
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    CONFLICT = 409,

    // 500 ERRORS
    INTERNAL_SERVER = 500,
}
