import { Config } from '../../Config';
import { IStoreStaff, IStoreStaffArrResponse } from '../../model/storeGroup/store/storeStaff/IStoreStaff';
import { AxiosController } from '../AxiosController';

export class StoreStaffController extends AxiosController {
    private PATH = 'store/staff';

    public async getStoreStaffs(
        storeId: string,
    ) : Promise<IStoreStaffArrResponse> {
        const storeStaffArrResponse : IStoreStaffArrResponse = await this.axiosGET<IStoreStaffArrResponse>(
            `${Config.SERVER_URL}/${this.PATH}/all`,
            {
                storeId,
            },
        );
        return storeStaffArrResponse;
    }

    public async addStaffToStore(
        storeId: string,
        toAddStaffId: string,
        adderStaffId: string,
        roleId: string,
    ) : Promise<IStoreStaffArrResponse> {
        const storeResponse = await this.axiosPOST<IStoreStaffArrResponse>(
            `${Config.SERVER_URL}/${this.PATH}/addStaff`, {
                storeId,
                toAddStaffId,
                adderStaffId,
                roleId,
            },
        );

        return storeResponse;
    }

    public async editStoreStaff(
        storeId: string,
        storeStaff: IStoreStaff,
        staffPin: string,
        color: string,
        isEmployed: boolean,
    ) : Promise<IStoreStaffArrResponse> {
        const storeStaffArrResponse = await this.axiosPUT<IStoreStaffArrResponse>(
            `${Config.SERVER_URL}/${this.PATH}/`, {
                storeId,
                staffId: storeStaff.staffId,
                tipTier: storeStaff.tipTier,
                hourlyWage: storeStaff.hourlyWage,
                staffPin,
                color,
                isEmployed,
            },
        );

        return storeStaffArrResponse;
    }
}
