import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './ComboEditor.scss';
import AddBar from '../components/AddBar/AddBar';
import { StoreContext } from '../../../controllers/Store/StoreContext';
import { IOption } from '../../../model/menuGroup/option/IOption';
import AddEditCombo from './AddEditCombo/AddEditCombo';
import ComboCard from './ComboCard/ComboCard';
import { ICombo } from '../../../model/menuGroup/combo/ICombo';

interface ComboEditorProps{}

const OptionEditor: React.FC<ComboEditorProps> = () => {
    const { t } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;

    const [currCombo, setCurrCombo] = useState<ICombo | null>(null);

    const [comboModalOpen, setComboModalOpen] = useState(false);
    const openComboModal = () => setComboModalOpen(true);
    const closeComboModal = () => setComboModalOpen(false);

    const generateCombos = () => store.combos.map((combo) => (
        <ComboCard
            combo={combo}
            selectCombo={() => {
                setCurrCombo(combo);
                openComboModal();
            }}
        />
        // <></>
    ));
    return (

        <div className="comboEditorContainer dashboardScreen">
            <AddEditCombo
                combo={currCombo}
                open={comboModalOpen}
                closeModal={closeComboModal}
            />
            <header>
                <h2 className="screenTitle">
                    Combo Editor
                </h2>
            </header>
            <AddBar
                handleClick={() => {
                    setCurrCombo(null);
                    openComboModal();
                }}
                text="Add Combo"
            />
            <div className="comboContainer">
                {generateCombos()}
            </div>
        </div>

    );
};

export default OptionEditor;
