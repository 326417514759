export enum IItemTypes {
    REG = 'REG',
    CUSTOM = 'CUSTOM',
    GIFTCREDIT = 'GIFTCREDIT',
    COMBO = 'COMBO',
    COMBOELEM = 'COMBOELEM',
    SUBCATEG = 'SUBCATEG',
    DISCOUNT = 'DISCOUNT',
    SURCHARGE = 'SURCHARGE'
}
