import React, { useState } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { ShiftLocale } from './ShiftLocale';
import Calendar from './Calendar/Calendar';

import './Shift.scss';

const Shift: React.FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    return (
        <div className="shiftContainer dashboardScreen">
            <h2 className="screenTitle">{t(ShiftLocale.shiftManagement)}</h2>
            <div className="shift">
                <Calendar />
            </div>
        </div>
    );
};

export default Shift;
