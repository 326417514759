import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITax } from '../../../../model/storeGroup/tax/ITax';
import './TaxCard.scss';

interface TaxCardProps{
    tax: ITax;
    selectTax: () => void;
}

const TaxCard: React.FC<TaxCardProps> = ({
    tax, selectTax,
}) => {
    const { t, i18n } = useTranslation();
    return (

        <div
            className="taxCardContainer"
            aria-hidden
            onClick={selectTax}
        >
            <div className="info">
                <h3>{tax.name[i18n.language]}</h3>
                <div className="desc">{tax.desc[i18n.language]}</div>
            </div>
        </div>

    );
};

export default TaxCard;
