import axios from 'axios';
import { Config } from '../../Config';
import { ITableSchema } from '../../model/storeGroup/table/ITable';
import { TableFields } from './TableFields';

export class TableController {
    // TODO: Error Boundary
    public static async saveTables(storeId: string, tables: ITableSchema[]) {
        const response = await axios.post(`${Config.SERVER_URL}/store/tables`, {
            [TableFields.storeId]: storeId,
            [TableFields.tables]: tables,
        });
        // Status: 200
        if (response.status === 200) {
            return response.data;
        }
        return null;
    }
}
