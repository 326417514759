import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip,
} from 'chart.js';
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Spinner from '../../../components/Spinner/Spinner';
import { IAggregateReport } from '../../../model/analyticsGroup/IAggregateReport';
import { ISeshReport } from '../../../model/analyticsGroup/seshReport/ISeshReport';
import { IItemStat } from '../../../model/analyticsGroup/seshReport/itemStat/IItemStat';
import { ISeshOptionElemStat } from '../../../model/analyticsGroup/seshReport/seshOptionElemStat/ISeshOptionElemStat';
import { calcChartDimensions, generateChartOptions } from '../../../utils/global';
import './Inventory.scss';
import ItemStatModal from './ItemStatModal/ItemStatModal';
import OptionStatModal from './OptionStatModal/OptionStatModal';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

interface InventoryProps {
    aggregateData: IAggregateReport | null;
    seshData: ISeshReport | null;
}

enum ChartType {
    ITEM = 'item',
    OPTION = 'option',
}

interface IChartAxis {
    axis: 'x' | 'y'
}

const Inventory: React.FC<InventoryProps> = ({
    aggregateData, seshData,
}) => {
    const [chartType, setChartType] = useState(ChartType.ITEM);

    // Item Stat Modal
    const [itemStatModalOpen, setItemStatModalOpen] = useState(false);
    const [itemStat, setItemStat] = useState<IItemStat | null>(null);
    const openItemStatModal = (tmpItemStat: IItemStat) => {
        setItemStat(tmpItemStat);
        setItemStatModalOpen(true);
    };
    const closeItemStatModal = () => {
        setItemStatModalOpen(false);
        setItemStat(null);
    };
    // End Item Stat Modal

    // Option Stat Modal
    const [optionStatModalOpen, setOptionStatModalOpen] = useState(false);
    const [optionStat, setOptionStat] = useState<ISeshOptionElemStat | null>(null);
    const openOptionStatModal = (tmpOptionStat: ISeshOptionElemStat) => {
        setOptionStat(tmpOptionStat);
        setOptionStatModalOpen(true);
    };
    const closeOptionStatModal = () => {
        setOptionStatModalOpen(false);
        setOptionStat(null);
    };
    // End Option Stat Modal

    const handleItemChartClick = (evt: any, element: any) => {
        if (element.length > 0) {
            const itemIdx = element[0].index;
            if (seshData !== null) {
                openItemStatModal(seshData.itemStats[itemIdx]);
                return;
            }
            if (aggregateData !== null) {
                openItemStatModal(aggregateData.itemStats[itemIdx]);
                return;
            }
            throw new Error('[Item Click] No aggregate data or sesh data');
        }
    };

    const handleOptionChartClick = (evt: any, element: any) => {
        if (element.length > 0) {
            const optionIdx = element[0].index;
            if (seshData !== null) {
                openOptionStatModal(seshData.seshOptionElemStats[optionIdx]);
                return;
            }
            if (aggregateData !== null) {
                openOptionStatModal(aggregateData.optionElemStats[optionIdx]);
                return;
            }
            throw new Error('[Option Click] No aggregate data or sesh data');
        }
    };

    const generateItemData = (itemData: IItemStat[]) => ({
        labels: itemData.map((item) => item.name.en),
        datasets: [{
            label: 'Count',
            data: itemData.map((item) => item.totalQty),
            backgroundColor: '#E4545F9A',
        }],
    });

    const generateOptionData = (optionData: ISeshOptionElemStat[]) => ({
        labels: optionData.map((option: ISeshOptionElemStat) => option.name.en),
        datasets: [{
            label: 'Count',
            data: optionData.map((option: ISeshOptionElemStat) => option.qty),
            backgroundColor: '#E4545F9A',
        }],
    });

    const generateChart = () => {
        if (chartType === ChartType.ITEM) {
            if (seshData !== null) {
                return (
                    <Bar
                        data={generateItemData(seshData.itemStats)}
                        style={calcChartDimensions(seshData.itemStats, 30)}
                        options={generateChartOptions('Item Statistics', handleItemChartClick)}
                    />
                );
            }
            if (aggregateData !== null) {
                return (
                    <Bar
                        data={generateItemData(aggregateData.itemStats)}
                        style={calcChartDimensions(aggregateData.itemStats, 30)}
                        options={generateChartOptions('Item Statistics', handleItemChartClick)}
                    />
                );
            }
        }
        if (chartType === ChartType.OPTION) {
            if (seshData !== null) {
                return (
                    <Bar
                        data={generateOptionData(seshData.seshOptionElemStats)}
                        style={calcChartDimensions(seshData.seshOptionElemStats, 30)}
                        options={generateChartOptions('Option Statistics', handleOptionChartClick)}
                    />
                );
            }
            if (aggregateData !== null) {
                return (
                    <Bar
                        data={generateOptionData(aggregateData.optionElemStats)}
                        style={calcChartDimensions(aggregateData.optionElemStats, 30)}
                        options={generateChartOptions('Option Statistics', handleOptionChartClick)}
                    />
                );
            }
        }
        return 'N/A';
    };
    return (
        <>
            {aggregateData === null && seshData === null && <Spinner />}
            {(aggregateData !== null || seshData !== null) && (
                <div className="stats inventoryStatsContainer">
                    <ItemStatModal
                        open={itemStatModalOpen}
                        itemStat={itemStat}
                        closeModal={closeItemStatModal}
                    />
                    <OptionStatModal
                        open={optionStatModalOpen}
                        optionStat={optionStat}
                        closeModal={closeOptionStatModal}
                    />
                    <div className="chartTypeSelector">
                        <button
                            type="button"
                            onClick={() => setChartType(ChartType.ITEM)}
                            className={`${chartType === ChartType.ITEM ? 'active' : ''}`}
                        >
                            Items
                        </button>
                        <button
                            type="button"
                            onClick={() => setChartType(ChartType.OPTION)}
                            className={`${chartType === ChartType.OPTION ? 'active' : ''}`}
                        >
                            Options
                        </button>
                    </div>
                    <div className="chart">
                        {generateChart()}
                    </div>
                </div>
            )}
        </>
    );
};

export default Inventory;
