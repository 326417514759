import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICorpRawMatMeta } from '../../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import './CorpRawMatCard.scss';

interface CorpRawMatCardProps{
    corpRawMatMeta: ICorpRawMatMeta;
    selectIngrLog: () => void;
    openIngrStockModal: () => void;
    openIngrAdjModal: () => void;
    openIngrReportModal: () => void;
}

const CorpRawMatCard: React.FC<CorpRawMatCardProps> = ({
    corpRawMatMeta, selectIngrLog, openIngrStockModal, openIngrAdjModal, openIngrReportModal,
}) => {
    const { t, i18n } = useTranslation();

    const getCardName = () => {
        let stockStr : string = '';
        if (corpRawMatMeta.unitsAvail <= 0) stockStr = ' (Out of Stock)';
        else if (corpRawMatMeta.unitsAvail <= corpRawMatMeta.warningUnits) stockStr = ' (Stock Low)';
        return `${corpRawMatMeta.name[i18n.language]}${stockStr}`;
    };

    const generateCardStyle = () => {
        if (corpRawMatMeta.unitsAvail <= 0) return 'ingrLogCardContainer ingrLogCardOutOfStock';
        if (corpRawMatMeta.unitsAvail <= corpRawMatMeta.warningUnits) return 'ingrLogCardContainer ingrLogCardWarning';
        return 'ingrLogCardContainer';
    };

    return (

        <div
            className={generateCardStyle()}
            aria-hidden
        >
            <div className="info">
                <h3>{getCardName()}</h3>
                <div className="currentStock">
                    <div className="desc">{corpRawMatMeta.desc[i18n.language]}</div>
                    <div className="desc">{`Current Stock: ${corpRawMatMeta.unitsAvail}${corpRawMatMeta.unitOfM}`}</div>
                </div>
            </div>
            <div className="ingrLogRow">
                <button
                    type="button"
                    onClick={() => selectIngrLog()}
                >
                    Edit
                </button>
                <button
                    type="button"
                    onClick={() => openIngrStockModal()}
                >
                    Add Stock
                </button>
                <button
                    type="button"
                    onClick={() => openIngrAdjModal()}
                >
                    Add Adjustments
                </button>
                <button
                    type="button"
                    onClick={() => openIngrReportModal()}
                >
                    View Report
                </button>
            </div>
        </div>

    );
};

export default CorpRawMatCard;
