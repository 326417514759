import React from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import { IAggregateReport } from '../../../model/analyticsGroup/IAggregateReport';
import { ISeshReport } from '../../../model/analyticsGroup/seshReport/ISeshReport';
import { amountToString } from '../../../utils/global';

import './Transactions.scss';

interface TransactionsProps {
    aggregateData: IAggregateReport | null;
    seshData: ISeshReport | null;
}

const Transactions: React.FC<TransactionsProps> = ({
    aggregateData, seshData,
}) => {
    const getTransactionCols = () => {
        if (seshData !== null) {
            return seshData.transactionStats.map((transStat) => (
                <div className="transCol">
                    <h3>{transStat.paymentType}</h3>
                    <div className="transStat">
                        <h4>{transStat.qty}</h4>
                        <p>Transactions</p>
                    </div>
                    <div className="transStat">
                        <h4>{amountToString(transStat.revenue)}</h4>
                        <p>Billed Amount</p>
                    </div>
                    <div className="transStat">
                        <h4>{amountToString(transStat.total)}</h4>
                        <p>Received Amount</p>
                    </div>
                </div>
            ));
        }
        if (aggregateData !== null) {
            return aggregateData.transactionStats.map((transStat) => (
                <div className="transCol">
                    <h3>{transStat.paymentType}</h3>
                    <div className="transStat">
                        <h4>{transStat.qty}</h4>
                        <p>Transactions</p>
                    </div>
                    <div className="transStat">
                        <h4>{amountToString(transStat.revenue)}</h4>
                        <p>Billed Amount</p>
                    </div>
                    <div className="transStat">
                        <h4>{amountToString(transStat.total)}</h4>
                        <p>Received Amount</p>
                    </div>
                </div>
            ));
        }
        return 'N/A';
    };
    return (
        <>
            {aggregateData === null && seshData === null && <Spinner />}
            {(aggregateData !== null || seshData !== null) && (
                <div className="stats transactionStatsContainer">
                    {getTransactionCols()}
                </div>
            )}
        </>
    );
};

export default Transactions;
