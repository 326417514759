import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { IRawMatLogMeta } from '../../../../model/IngrGroup/RawMatLog/IRawMatLog';
import { IRawMatLogReport } from '../../../../model/IngrGroup/RawMatLog/IRawMatLogReport';
import './RawMatReportModal.scss';

ReactModal.setAppElement('#root');
interface RawMatReportModalProps {
    open: boolean;
    rawMatMeta: IRawMatLogMeta | null;
    closeModal: () => void;
}

const RawMatReportModal: React.FC<RawMatReportModalProps> = ({
    open, rawMatMeta, closeModal,
}) => {
    const { i18n } = useTranslation();

    // Get the report
    const [ingrLogReport, setIngrLogReport] = useState<IRawMatLogReport | null>(null);
    const [queryDate, setQueryDate] = useState<[Date, Date] | null>(null);

    const getReport = async () => {
        if (rawMatMeta === null) return;
        const startTime : Date | null = queryDate ? queryDate[0] : null;
        const endTime : Date | null = queryDate ? queryDate[1] : null;
        const report : IRawMatLogReport = await Factory.RawMatLogController
            .getIngrLogReport(rawMatMeta._id, startTime, endTime);
        setIngrLogReport(report);
    };
    // Load report
    useEffect(() => {
        getReport();
    }, [queryDate]);

    useEffect(() => {
        if (open) getReport();
        setQueryDate(null);
    }, [open]);

    // The current info
    const [currInfo, setCurrInfo] = useState<'adj' | 'stock' | 'usage'>('adj');

    // AdjInfo
    const getAdjInfos = () => {
        if (ingrLogReport === null || rawMatMeta === null) return <></>;
        if (ingrLogReport.adjInfos.length === 0) return <h3>No Adjustment Information Found</h3>;
        return (
            <table>
                <tr>
                    <th>Time</th>
                    <th>Staff Name</th>
                    <th>Units Adjusted</th>
                    <th>Notes</th>
                </tr>
                {ingrLogReport.adjInfos.map((adjInfo) => (
                    <tr>
                        <td>{new Date(adjInfo.createdAt).toLocaleString()}</td>
                        <td>{adjInfo.staffNickname}</td>
                        <td>{`${adjInfo.unitsAdj}${rawMatMeta.unitOfM}`}</td>
                        <td>{adjInfo.notes}</td>
                    </tr>
                ))}
            </table>
        );
    };

    const getStockInfos = () => {
        if (ingrLogReport === null || rawMatMeta === null) return <></>;
        if (ingrLogReport.stockInfos.length === 0) return <h3>No Stock Information Found</h3>;
        return (
            <table>
                <tr>
                    <th>Time</th>
                    <th>Staff Name</th>
                    <th>Stock Cost</th>
                    <th>Unit Stocked</th>
                    <th>Notes</th>
                </tr>
                {ingrLogReport.stockInfos.map((stockInfo) => (
                    <tr>
                        <td>{new Date(stockInfo.createdAt).toLocaleString()}</td>
                        <td>{stockInfo.staffNickname}</td>
                        <td>{`${stockInfo.costOfStock / 100} / ${rawMatMeta.unitOfM}`}</td>
                        <td>{`${stockInfo.unitsStocked}${rawMatMeta.unitOfM}`}</td>
                        <td>{stockInfo.notes}</td>
                    </tr>
                ))}
            </table>
        );
    };

    const getUsageInfos = () => {
        if (ingrLogReport === null || rawMatMeta === null) return <></>;
        if (ingrLogReport.usageInfos.length === 0) return <h3>No Usage Information Found</h3>;
        return (
            <table>
                <tr>
                    <th>Created Time</th>
                    <th>Ingredient Name</th>
                    <th>Starting Units</th>
                    <th>Units Used</th>
                    <th>Ingredient Cost</th>
                </tr>
                {ingrLogReport.usageInfos.map((usageInfo) => (
                    <tr>
                        <td>{new Date(usageInfo.createdAt).toLocaleDateString()}</td>
                        <td>{usageInfo.ingrLogName[i18n.language]}</td>
                        <td>{usageInfo.startingUnits}</td>
                        <td>{usageInfo.unitsUsed}</td>
                        <td>{`$${usageInfo.costOfUsed}`}</td>
                    </tr>
                ))}
            </table>
        );
    };

    const showCurrInfo = () => {
        if (currInfo === 'adj') return getAdjInfos();
        if (currInfo === 'stock') return getStockInfos();
        return getUsageInfos();
    };

    if (rawMatMeta === null || ingrLogReport === null) return <></>;

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="ingrReportModal"
            overlayClassName="ingrReportModalOverlay"
        >
            <h2>
                {`Report for ${rawMatMeta.name.en} - ${
                    queryDate ? `${queryDate[0].toDateString()} to ${queryDate[1].toDateString()}` : 'All Time'}
                `}
            </h2>

            <div className="row ingrReport">
                <div className="column">
                    <button
                        type="button"
                        className={`viewAll ${queryDate === null ? 'viewAllActive' : ''}`}
                        onClick={() => setQueryDate(null)}
                    >
                        View All Time
                    </button>
                    <Calendar
                        // @ts-ignore
                        onChange={(val: [Date, Date]) => { setQueryDate(val); }}
                        value={queryDate}
                        defaultValue={undefined}
                        selectRange
                        returnValue="range"
                    />
                </div>

                <div className="column reportStats">
                    <header className="viewSwitcher">
                        <button
                            type="button"
                            className={`viewStocks ${currInfo === 'stock' ? 'active' : ''}`}
                            onClick={() => setCurrInfo('stock')}
                        >
                            View Stocks
                        </button>
                        <button
                            type="button"
                            className={`viewAdj ${currInfo === 'adj' ? 'active' : ''}`}
                            onClick={() => setCurrInfo('adj')}
                        >
                            View Adjustments
                        </button>
                        <button
                            type="button"
                            className={`viewUsages ${currInfo === 'usage' ? 'active' : ''}`}
                            onClick={() => setCurrInfo('usage')}
                        >
                            View Usages
                        </button>
                    </header>
                    {showCurrInfo()}
                </div>
            </div>
        </ReactModal>
    );
};

export default RawMatReportModal;
