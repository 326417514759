import { Config } from '../../Config';
import { Dict } from '../../model/helpers/Dict';
import { IRawMatLog, IRawMatLogMeta } from '../../model/IngrGroup/RawMatLog/IRawMatLog';
import { IRawMatLogReport } from '../../model/IngrGroup/RawMatLog/IRawMatLogReport';
import { AxiosController, IResponseSchema } from '../AxiosController';

interface RawMatLogResponse extends IResponseSchema {
    data: {
        rawMatLog: IRawMatLog,
    },
}

interface RawMatLogReportResponse extends IResponseSchema {
    data: {
        rawMatLogReport: IRawMatLogReport,
    },
}

interface RawMatLogAndMetasResponse extends IResponseSchema {
    data: {
        rawMatLog: IRawMatLog,
        rawMatLogMetas: IRawMatLogMeta[],
    },
}

interface RawMatLogMetasResponse extends IResponseSchema {
    data: {
        rawMatLogMetas: IRawMatLogMeta[],
    }
}

export class RawMatLogController extends AxiosController {
    public async getRawMatLog(
        rawMatLogId: string,
    ) : Promise<IRawMatLog> {
        const resp : RawMatLogResponse = await this.axiosGET(`${Config.SERVER_URL}/rawMatLog`, {
            rawMatLogId: rawMatLogId,
        });
        const rawMatLog: IRawMatLog = resp.data.rawMatLog;
        return rawMatLog;
    }

    public async getIngrLogReport(
        rawMatLogId: string,
        startTime: Date | null,
        endTime: Date | null,
    ) : Promise<IRawMatLogReport> {
        const resp : RawMatLogReportResponse = await this.axiosGET(`${Config.SERVER_URL}/rawMatLog/report`, {
            rawMatLogId: rawMatLogId,
            startTime: startTime,
            endTime: endTime,
        });
        const rawMatLogReport: IRawMatLogReport = resp.data.rawMatLogReport;
        return rawMatLogReport;
    }

    public async getRawMatLogMetas(
        storeId: string,
    ) : Promise<RawMatLogMetasResponse> {
        const resp = await this.axiosGET<RawMatLogMetasResponse>(`${Config.SERVER_URL}/rawMatLog/metas`, {
            storeId: storeId,
        });
        // const rawMatLogMetas: IRawMatLogMeta[] = resp.data.rawMatLogMetas;
        return resp;
    }

    public async createRawMat(
        storeId: string,
        corpRawMatId: string | null,
        name: Dict<string>,
        desc: Dict<string>,
        mName: string,
        mDesc: string,
        unitOfM: string,
        warningUnits: number,
        contactStaffIds: string[],
        autoReq: boolean,
        autoReqUnits: number,
    ) : Promise<RawMatLogAndMetasResponse> {
        const resp : RawMatLogAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/rawMatLog`, {
            storeId: storeId,
            corpRawMatId: corpRawMatId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            unitOfM: unitOfM,
            warningUnits: warningUnits,
            contactStaffIds: contactStaffIds,
            autoReq,
            autoReqUnits,
        });
        return resp;
    }

    public async editRawMat(
        rawMatLogId: string,
        storeId: string,
        corpRawMatId: string | null,
        name: Dict<string>,
        desc: Dict<string>,
        mName: string,
        mDesc: string,
        unitOfM: string,
        warningUnits: number,
        contactStaffIds: string[],
        autoReq: boolean,
        autoReqUnits: number,
    ) : Promise<RawMatLogAndMetasResponse> {
        const resp : RawMatLogAndMetasResponse = await this.axiosPUT(`${Config.SERVER_URL}/rawMatLog`, {
            rawMatLogId: rawMatLogId,
            storeId: storeId,
            corpRawMatId: corpRawMatId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            unitOfM: unitOfM,
            warningUnits: warningUnits,
            contactStaffIds: contactStaffIds,
            autoReq,
            autoReqUnits,
        });
        return resp;
    }

    public async restock(
        rawMatLogId: string,
        staffId: string,
        supplierId: string | null,
        costOfStock: number,
        unitsStocked: number,
        notes: string,
    ) : Promise<RawMatLogAndMetasResponse> {
        const resp : RawMatLogAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/rawMatLog/restock`, {
            rawMatLogId: rawMatLogId,
            staffId: staffId,
            supplierId: supplierId,
            costOfStock: costOfStock * 100,
            unitsStocked: unitsStocked,
            notes: notes,
        });
        return resp;
    }

    public async addIngrAdj(
        rawMatLogId: string,
        staffId: string,
        unitsAdj: number,
        notes: string,
    ) : Promise<RawMatLogAndMetasResponse> {
        const resp : RawMatLogAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/rawMatLog/addRawMatAdj`, {
            rawMatLogId: rawMatLogId,
            staffId: staffId,
            unitsAdj: unitsAdj,
            notes: notes,
        });
        return resp;
    }
}
