import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import Select from 'react-select';
import { StoreContext } from '../../../../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../../../../model/helpers/Dict';
import { IIngrLogMeta } from '../../../../../../../model/IngrGroup/IngrLog/IIngrLog';
import { ICombo } from '../../../../../../../model/menuGroup/combo/ICombo';
import { IComboRef } from '../../../../../../../model/menuGroup/combo/IComboRef';
import './IngredientSelector.scss';

ReactModal.setAppElement('#root');
interface IngredientSelectorProps {
    open: boolean;
    ingrLogMetas: IIngrLogMeta[];

    itemIngrMap: Dict<number>;

    updateItemIngr: (ingrMap: Dict<number>) => void;
    closeModal: () => void;
}

const IngredientSelector: React.FC<IngredientSelectorProps> = ({
    open, ingrLogMetas, itemIngrMap, updateItemIngr, closeModal,
}) => {
    const { i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;

    const [ingrMap, setIngrMap] = useState<Dict<number>>({});

    const changeIngrVal = (ingrId: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(e.target.value);
        setIngrMap((prevState) => ({
            ...prevState,
            [ingrId]: val,
        }));
    };

    const updateIngrMap = (selectedIngrs: {
        value: string,
        label: string,
    }[]) => {
        // const taxIds = taxesList.map((multiValue) => multiValue.value);
        // setItemTaxes(taxIds);
        Object.keys(ingrMap).forEach((ingrId) => {
            const target = selectedIngrs.find((selectedIngr) => (
                selectedIngr.value === ingrId
            ));
            if (target === undefined) {
                setIngrMap((prevState) => {
                    const newState = { ...prevState };
                    delete newState[ingrId];
                    return newState;
                });
            }
        });

        selectedIngrs.forEach((selectedIngr) => {
            if (!(selectedIngr.value in ingrMap)) {
                setIngrMap((prevState) => ({
                    ...prevState,
                    [selectedIngr.value]: 0,
                }));
            }
        });
    };

    const getIngr = (ingrId: string) => {
        const target = ingrLogMetas.find((iLM) => iLM._id === ingrId);
        if (target === undefined) throw new Error('[generate] Cannot find ingr');
        return target;
    };
    const generateIngrElems = () => (
        <div className="ingrElems">
            {Object.keys(ingrMap).map((tIngr) => {
                const ingr = getIngr(tIngr);
                const val = ingrMap[tIngr];
                return (
                    <div className="ingr">
                        <div className="ingrName">
                            {ingr.name[i18n.language]}
                        </div>
                        <input
                            type="number"
                            value={val}
                            onChange={(e) => changeIngrVal(tIngr, e)}
                        />
                        <p>{ingr.unitOfM}</p>
                    </div>
                );
            })}
        </div>
    );

    const handleContinue = () => {
        updateItemIngr(ingrMap);
        closeModal();
    };

    useEffect(() => {
        if (open) {
            setIngrMap(itemIngrMap);
            return;
        }
        setIngrMap({});
    }, [open]);

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="ingredientSelectorModal"
            overlayClassName="ingredientSelectorModalOverlay"
        >
            <h2>Ingredients</h2>
            <Select
                isMulti
                className="ingrSelector"
                defaultValue={Object.keys(itemIngrMap).map((ingrId) => ({
                    value: ingrId,
                    label: getIngr(ingrId).name[i18n.language],
                }))}
                options={ingrLogMetas.map((ingrMeta) => ({
                    value: ingrMeta._id,
                    label: ingrMeta.name[i18n.language],
                }))}
                onChange={(e: any) => updateIngrMap(e)}
            />
            {generateIngrElems()}
            <div className="buttonWrapper">
                <button type="button" onClick={handleContinue}>Continue</button>
            </div>
        </ReactModal>
    );
};

export default IngredientSelector;
