import React, { useState, useEffect } from 'react';
import Hamburger from 'hamburger-react';

import './Header.scss';
import { useTranslation } from 'react-i18next';
import { IStoreTab } from '../Dashboard';
import { IStore } from '../../model/storeGroup/store/IStore';

interface HeaderProps{
    tabbarOpen: boolean;
    openCloseTabbar: () => void;
    stores: IStoreTab[]
    changeStore: (idx: number) => Promise<void>;
    currStore: IStore | undefined;
}

const Header: React.FC<HeaderProps> = ({
    tabbarOpen, openCloseTabbar, stores, changeStore, currStore,
}) => {
    const { i18n } = useTranslation();
    // Internal highlighting
    const [storeIdx, setStoreIdx] = useState(0);

    // Change stores to onload
    const handleClick = async (idx: number) => {
        await changeStore(idx);
    };

    useEffect(() => {
        if (currStore === undefined) return;
        const activeStore = stores.findIndex((storeMeta) => (
            storeMeta.storeId === currStore._id
        ));
        setStoreIdx(activeStore);
    }, [currStore]);
    const showHeader = !window.location.href.includes('corp');
    if (currStore === undefined) return <></>;

    return (
        <div className={tabbarOpen ? 'headerContainer headerOpen' : 'headerContainer'}>
            <div className="headerTop">
                <Hamburger color="#1C2D37" toggled={tabbarOpen} toggle={openCloseTabbar} />
            </div>
            <div className="headerBot">
                {showHeader && stores.map((store, idx: number) => (
                    <div
                        className={`headerStoreTabs ${idx === storeIdx && 'active'}`}
                        aria-hidden
                        onClick={() => handleClick(idx)}
                    >
                        {/* {store.storeName[i18n.language]} */}
                        Test
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Header;
