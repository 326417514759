import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Spinner from '../../components/Spinner/Spinner';
import { MenuController } from '../../controllers/Menu/MenuController';
import { StoreContext } from '../../controllers/Store/StoreContext';
import IFullMenu from '../../model/menuGroup/menu/IFullMenu';

import './Menu.scss';
import { MenuLocale } from './MenuLocale';
import MenuButton from './components/MenuButton/MenuButton';
import MenuViewer from './components/MenuViewer/MenuViewer';
import ItemViewer from './components/ItemViewer/ItemViewer';

const Menu: React.FC = () => {
    const { t } = useTranslation();

    const history = useHistory();
    const { store, setStore } = useContext(StoreContext)!;

    const [menuId, setMenuId] = useState<null | string>(null);
    const selectMenu = (id: string) => setMenuId(id);

    return (

        <div className="menuContainer dashboardScreen">
            {menuId === null && <MenuViewer menus={store.menus} selectMenu={selectMenu} />}
            {menuId !== null && <ItemViewer menuId={menuId} />}
        </div>

    );
};

export default Menu;
