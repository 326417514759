import React, { useEffect, useState } from 'react';
import { Factory } from '../../../controllers/ControllerFactory';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { AuctionStatusEnum, AuctionTypeEnum } from '../../../model/galaGroup/galaAuction/galaAuctionFields';
import { IGalaAuction } from '../../../model/galaGroup/galaAuction/IGalaAuction';
import { amountToString } from '../../../utils/global';
import AuctionModal from './AuctionModal';
import './GalaAuctionViewer.scss';

interface GalaAuctionViewerProps{
    currGala: IGala;
    auctionType: AuctionTypeEnum;
}

export const GalaAuctionViewer: React.FC<GalaAuctionViewerProps> = ({
    currGala, auctionType,
}) => {
    const [auctions, setAuctions] = useState<IGalaAuction[]>([]);
    const getAuctions = async () => {
        const tAuctions = await Factory.GalaAuctionController.getAllByGala(currGala._id, auctionType);
        setAuctions(tAuctions);
    };
    useEffect(() => {
        getAuctions();
    }, []);

    const [auctionStatus, setAuctionStatus] = useState<AuctionStatusEnum>(AuctionStatusEnum.all);

    const [auction, setAuction] = useState<IGalaAuction | null>(null);
    const [auctionModalOpen, setAuctionModalOpen] = useState(false);
    const openModal = (tAuction: IGalaAuction | null) => {
        setAuction(tAuction);
        setAuctionModalOpen(true);
    };
    const closeModal = () => {
        setAuction(auction);
        setAuctionModalOpen(false);
    };

    const filterAuctions = () => {
        if (auctionStatus === AuctionStatusEnum.all) return auctions;
        return auctions.filter((auc) => auc.auctionStatus === auctionStatus);
    };

    const auctionStatusToName = (tAucStatus : AuctionStatusEnum) => {
        if (tAucStatus === AuctionStatusEnum.open) return 'Open';
        if (tAucStatus === AuctionStatusEnum.unopen) return 'Unopen';
        if (tAucStatus === AuctionStatusEnum.closed) return 'Closed';
        return 'All';
    };

    return (
        <div className="galaAuctionContainer">
            <AuctionModal
                open={auctionModalOpen}
                closeModal={closeModal}
                auction={auction}
                currGala={currGala}
                refresh={getAuctions}
            />
            <div className="auctionStatus">
                <h3>{`Auction Status : ${auctionStatusToName(auctionStatus)}`}</h3>
                <div className="row">
                    <button
                        type="button"
                        onClick={() => setAuctionStatus(AuctionStatusEnum.all)}
                        className={auctionStatus === AuctionStatusEnum.all ? 'active' : ''}
                    >
                        {`All Auctions: ${auctions.length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setAuctionStatus(AuctionStatusEnum.unopen)}
                        className={auctionStatus === AuctionStatusEnum.unopen ? 'active' : ''}
                    >
                        {`Unopen Auctions: ${auctions.filter((auc) => auc.auctionStatus === AuctionStatusEnum.unopen).length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setAuctionStatus(AuctionStatusEnum.open)}
                        className={auctionStatus === AuctionStatusEnum.open ? 'active' : ''}
                    >
                        {`Open Auctions: ${auctions.filter((auc) => auc.auctionStatus === AuctionStatusEnum.open).length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setAuctionStatus(AuctionStatusEnum.closed)}
                        className={auctionStatus === AuctionStatusEnum.closed ? 'active' : ''}
                    >
                        {`Closed Auctions: ${auctions.filter((auc) => auc.auctionStatus === AuctionStatusEnum.closed).length}`}
                    </button>
                </div>
            </div>
            <div className="createButton"><button type="button" onClick={() => openModal(null)}>Create New Auction</button></div>
            <div className="headerRow">
                <div>Unique Label</div>
                <div>Name</div>
                <div>Start Price</div>
                <div>Current Price</div>
                <div>Bids Placed</div>
                <div />
                {/* <div>Phone</div>
                <div>Email</div> */}
            </div>
            {filterAuctions().map((tAuction: IGalaAuction) => (
                <div className="attendeeRow">
                    <div>{tAuction.uniqueLabel}</div>
                    <div>{tAuction.name.en}</div>
                    <div>{amountToString(tAuction.startPrice)}</div>
                    <div>{amountToString(tAuction.currPrice)}</div>
                    <div>{tAuction.galaAuctionBids.length}</div>
                    <div><button type="button" onClick={() => openModal(tAuction)}>View</button></div>
                </div>
            ))}
        </div>
    );
};
