import React, { useState, useEffect, useContext } from 'react';
import { IGalaTicket } from '../../../model/galaGroup/gala/galaTicket/iGalaTicket';
import { IGala } from '../../../model/galaGroup/gala/iGala';

import './GalaTicketViewer.scss';

interface GalaTicketViewerProps{
    currGala: IGala;
}

export const GalaTicketViewer: React.FC<GalaTicketViewerProps> = ({
    currGala,
}) => {
    const a = 1;

    return (
        <div className="galaTicketViewer">
            {currGala.galaTickets.map((galaTicket: IGalaTicket) => (
                <div className="info">
                    <div>
                        <p>Name</p>
                        <p>{galaTicket.name.en}</p>
                    </div>
                    <div>
                        <p>Desc</p>
                        <p>{galaTicket.desc.en}</p>
                    </div>
                    <div>
                        <p>Total Quantity</p>
                        <p>{galaTicket.totalQty}</p>
                    </div>
                    <div>
                        <p>Quantity Sold</p>
                        <p>{galaTicket.qtySold}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};
