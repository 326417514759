import React, { useState, useEffect, useContext } from 'react';
import { IGalaTicket } from '../../../model/galaGroup/gala/galaTicket/iGalaTicket';
import { IGalaTicketListing } from '../../../model/galaGroup/gala/galaTicketListing/iGalaTicketListingDoc';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { Dict } from '../../../model/helpers/Dict';
import { amountToString } from '../../../utils/global';

interface GalaTicketListingViewerProps{
    currGala: IGala;
}

export const GalaTicketListingViewer: React.FC<GalaTicketListingViewerProps> = ({
    currGala,
}) => {
    const a = 1;

    const ticketMapToStr = (ticketMap: Dict<number>) => {
        if (currGala === null) return 'None';
        let ret = '';
        Object.entries(ticketMap).forEach(([ticketId, qty] : [string, number]) => {
            const ticket = currGala.galaTickets.find((tTick) => tTick._id === ticketId);
            if (!ticket) return;
            ret += `${ticket.name.en} : ${qty}`;
        });
        return ret;
    };

    return (
        <div>
            {currGala.galaTicketListings.map((ticketListing: IGalaTicketListing) => (
                <div className="row">
                    <p>
                        _______________________________
                    </p>
                    <p>
                        {`Name: ${ticketListing.name.en}`}
                    </p>
                    <p>
                        {`Desc: ${ticketListing.desc.en}`}
                    </p>
                    <p>
                        {`Total Price: ${amountToString(ticketListing.totalPrice)}`}
                    </p>
                    <p>
                        {`Tax Receipt Amount: ${amountToString(ticketListing.taxReceiptAmount)}`}
                    </p>
                    <p>
                        {`Quantity Sold: ${ticketListing.qtySold}`}
                    </p>
                    <p>
                        {`Available: ${ticketListing.available}`}
                    </p>
                    <p>
                        {`Included Tickets: ${ticketMapToStr(ticketListing.galaTicketMap)}`}
                    </p>
                </div>
            ))}
        </div>
    );
};
