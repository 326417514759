import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../model/helpers/Dict';
import { ICorpRawMatMeta } from '../../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { IIngrLogMeta } from '../../../../model/IngrGroup/IngrLog/IIngrLog';
import { MeasurementEnum } from '../../../../model/IngrGroup/IngrLog/MeasurementEnum';
import './AddEditCorpRawMatLog.scss';
import { CorpContext } from '../../../../controllers/CorpGroup/CorpContext';

ReactModal.setAppElement('#root');
interface AddEditCorpRawMatProps {
    open: boolean;
    corpRawMatMeta: ICorpRawMatMeta | null;
    setCorpRawMatMetas: React.Dispatch<React.SetStateAction<ICorpRawMatMeta[]>>;
    closeModal: () => void;
}

const AddEditCorpRawMat: React.FC<AddEditCorpRawMatProps> = ({
    open, corpRawMatMeta, setCorpRawMatMetas, closeModal,
}) => {
    const { i18n } = useTranslation();
    const { corp } = useContext(CorpContext)!;

    const [ingrName, setIngrName] = useState<Dict<string>>({});
    const changeIngrName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpTaxName = e.target.value;
        const cLang = i18n.language;
        setIngrName((prevState) => ({
            ...prevState,
            [cLang]: tmpTaxName,
        }));
    };
    const [ingrMName, setIngrMName] = useState('');
    const changeIngrMName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIngrMName(e.target.value);
    };

    const [ingrDesc, setIngrDesc] = useState<Dict<string>>({});
    const changeIngrDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setIngrDesc((prevState) => ({
            ...prevState,
            [i18n.language]: val,
        }));
    };
    const [ingrMDesc, setIngrMDesc] = useState('');
    const changeIngrMDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIngrMDesc(e.target.value);
    };

    const [warningUnits, setWarningUnits] = useState(0);
    const changeWarningUnits = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setWarningUnits(value);
    };

    // TODO select from a drop down please
    const [unitOfM, setUnitOfM] = useState(MeasurementEnum.g);
    const changeUnitOfM = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as MeasurementEnum;
        setUnitOfM(value);
    };

    const [contactStaffIds, setContactStaffIds] = useState<string[]>([]);

    const validStaffList = corp.corpStaffRefs.map((staff) => ({
        value: staff.staffId,
        label: staff.nickName,
    }));
    const changeContactStaffIds = (staffIds: any[]) => {
        const tmpStaffIds = staffIds.map((multiValue) => multiValue.value);
        setContactStaffIds(tmpStaffIds);
    };

    const editCorpRawMatLog = async () => {
        if (corpRawMatMeta === null) throw new Error('cannot edit new corp raw mat');
        const ingrLogResponse = await Factory.CorpRawMatController.editCorpRawMat(
            corpRawMatMeta._id,
            corp._id,
            ingrName,
            ingrDesc,
            ingrMName,
            ingrMDesc,
            unitOfM,
            warningUnits,
            contactStaffIds,
        );
        closeModal();
        setCorpRawMatMetas(ingrLogResponse.data.corpRawMatMetas);
    };

    const createCorpRawMatLog = async () => {
        if (corpRawMatMeta !== null) throw new Error('cannot recreate');
        const ingrLogResponse = await Factory.CorpRawMatController.createCorpRawMat(
            corp._id,
            ingrName,
            ingrDesc,
            ingrMName,
            ingrMDesc,
            unitOfM,
            warningUnits,
            contactStaffIds,
        );
        closeModal();
        setCorpRawMatMetas(ingrLogResponse.data.corpRawMatMetas);
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleConfirm = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        try {
            if (corpRawMatMeta === null) await createCorpRawMatLog();
            else await editCorpRawMatLog();

            // Clear all settings
            setIngrName({
                [i18n.language]: '',
            });
            setIngrDesc({
                [i18n.language]: '',
            });
            setIngrMName('');
            setIngrMDesc('');
            setHCLoading(false);
        } catch (err) {
            setHCLoading(false);
        }
    };

    useEffect(() => {
        if (corpRawMatMeta === null) {
            setIngrName({
                [i18n.language]: '',
            });
            setIngrDesc({
                [i18n.language]: '',
            });
            setIngrMName('');
            setIngrMDesc('');
        } else {
            setIngrName(corpRawMatMeta.name);
            setIngrDesc(corpRawMatMeta.desc);
            setIngrMName(corpRawMatMeta.mName);
            setIngrMDesc(corpRawMatMeta.mDesc);
            setContactStaffIds(corpRawMatMeta.contactStaffIds);
        }
    }, [corpRawMatMeta]);

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditTaxModal"
            overlayClassName="addEditTaxModalOverlay"
        >
            <h2>Add/Edit Raw Material</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Raw Material Name</h4>
                    <input
                        value={ingrName[i18n.language]}
                        onChange={changeIngrName}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Raw Material Name</h4>
                    <input
                        type="text"
                        value={ingrMName}
                        onChange={changeIngrMName}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Raw Material Description</h4>
                    <input
                        value={ingrDesc[i18n.language]}
                        onChange={changeIngrDesc}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Raw Material Description</h4>
                    <input
                        type="text"
                        value={ingrMDesc}
                        onChange={changeIngrMDesc}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Measurement Units</h4>
                    {/* <h4>{unitOfM}</h4> */}
                    <select
                        // value={unitOfM}
                        onChange={changeUnitOfM}
                    >
                        {Object.keys(MeasurementEnum).map((mE) => (
                            <option value={mE} selected={mE === unitOfM}>{mE}</option>
                        ))}
                    </select>
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Warning Units</h4>
                    <input
                        value={warningUnits}
                        onChange={changeWarningUnits}
                        type="number"
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Contact Staffs</h4>
                    <Select
                        isMulti
                        className="contactStaffSelector"
                        options={validStaffList}
                        onChange={(e: any) => changeContactStaffIds(e)}
                    />
                </div>
            </div>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={handleConfirm} disabled={HCLoading}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default AddEditCorpRawMat;
