export enum ShiftPlannerFields {

    storeId = 'storeId',
    staffIds = 'staffIds',
    shiftId = 'shiftId',
    startTime = 'startTime',
    endTime = 'endTime',
    planStart = 'planStart',
    planEnd = 'planEnd',

}
