/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../../../../controllers/Store/StoreContext';
import { IItem } from '../../../../../../model/menuGroup/item/IItem';
import { IItemTypes } from '../../../../../../model/menuGroup/item/IItemTypes';
import './ItemSubItemModule.scss';

interface ItemSubItemModuleProps {
    subItemIds: string[];
    addSubItemId: () => void;
    removeSubItemId: (itemIdx: number) => void;
    changeSubItemId: (e: React.ChangeEvent<HTMLSelectElement>, itemIdx: number) => void;
}

const ItemSubItemModule: React.FC<ItemSubItemModuleProps> = ({
    subItemIds, addSubItemId, removeSubItemId, changeSubItemId,
}) => {
    const { t, i18n } = useTranslation();
    const { store } = useContext(StoreContext)!;

    const generateSubCategElems = () => {
        const availItems: IItem[] = [];
        store.items.forEach((item) => {
            if (!subItemIds.includes(item._id)) {
                availItems.push(item);
            }
        });
        return (subItemIds.map((subItemId, idx) => (
            <div className="subItem">
                <select
                    onChange={(e) => changeSubItemId(e, idx)}
                >
                    <option
                        value=""
                        selected={subItemId === ''}
                        disabled
                        hidden
                    >
                        Choose item ...
                    </option>
                    {store.items.filter(
                        (item) => item.itemType === IItemTypes.REG,
                    ).map((item) => (
                        <option
                            value={item._id}
                            selected={item._id === subItemId}
                            hidden={subItemIds.includes(item._id)}
                        >
                            {`${item.name[i18n.language]} (${
                                item.mName
                            })`}
                        </option>
                    ))}
                </select>
                <button type="button" className="removeSubItem" onClick={() => removeSubItemId(idx)}>
                    X
                </button>
            </div>
        )));
    };

    return (

        <div className="subItems">
            <p>Subcategory Items</p>

            {generateSubCategElems()}

            {subItemIds.length <= store.items.length && (
                <button className="addSubItem" type="button" onClick={addSubItemId}>
                    +
                </button>
            )}
        </div>

    );
};

export default ItemSubItemModule;
