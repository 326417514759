import React, { useState, useEffect, useContext } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { TransactionLoacle } from './TransactionLocale';
import { ITransaction } from '../../model/transactionGroup/ITransaction';
import { Factory } from '../../controllers/ControllerFactory';
import { StoreContext } from '../../controllers/Store/StoreContext';

import './Transaction.scss';
import { amountToString } from '../../utils/global';

const Transaction: React.FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { store } = useContext(StoreContext)!;
    const transactionController = Factory.getTransactionController();
    const [transList, setTransList] = useState<ITransaction[]>([]);

    const getTransactions = async () => {
        if (store !== undefined) {
            const transactions = await transactionController.getTransactions(
                store._id,
                new Date('2020/10/10'),
                new Date(),
            );
            setTransList(transactions.data.transactions.reverse());
        }
    };

    useEffect(() => {
        getTransactions();
    }, []);

    return (
        <div className="transactionContainer dashboardScreen">
            <h2 className="screenTitle">{t(TransactionLoacle.transHistory)}</h2>
            <div className="transList">
                {transList.map((trans) => (
                    <div className="transaction">
                        <p>{`Bill Amount: ${amountToString(trans.billAmount)}`}</p>
                        <p>{`Tips: ${amountToString(trans.paid - trans.billAmount)}`}</p>
                        <p>{new Date(trans.createdAt).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Transaction;
