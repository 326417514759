import React from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import { IAggregateReport } from '../../../model/analyticsGroup/IAggregateReport';
import { ISeshReport } from '../../../model/analyticsGroup/seshReport/ISeshReport';

import './Orders.scss';

interface OrdersProps {
    aggregateData: IAggregateReport | null;
    seshData: ISeshReport | null;
}

const Orders: React.FC<OrdersProps> = ({
    aggregateData, seshData,
}) => {
    const getClosedOrderCount = () => {
        if (seshData !== null) return seshData.closedOrderCount;
        if (aggregateData !== null) return aggregateData.closedOrderCount;
        return 'N/A';
    };

    const getCancelledOrderCount = () => {
        if (seshData !== null) return seshData.cancelledOrderCount;
        if (aggregateData !== null) return aggregateData.cancelledOrderCount;
        return 'N/A';
    };
    return (
        <>
            {aggregateData === null && seshData === null && <Spinner />}
            {(aggregateData !== null || seshData !== null) && (
                <div className="stats ordersStatsContainer">
                    <div className="ordersCol">
                        <h4>{getClosedOrderCount()}</h4>
                        <p>Closed Orders</p>
                    </div>
                    <div className="ordersCol">
                        <h4>{getCancelledOrderCount()}</h4>
                        <p>Cancelled Orders</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Orders;
