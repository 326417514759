import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import editImg from '../../../../../assets/edit.svg';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { ICombo } from '../../../../model/menuGroup/combo/ICombo';
import { IOption } from '../../../../model/menuGroup/option/IOption';
import './ComboCard.scss';

interface ComboCardProps{
    combo: ICombo;
    selectCombo: () => void;
}

const ComboCard: React.FC<ComboCardProps> = ({
    combo, selectCombo,
}) => {
    const { t, i18n } = useTranslation();
    return (

        <div
            className="comboCardContainer"
            aria-hidden
            onClick={selectCombo}
        >
            <div className="info">
                <h3>{`${combo.name[i18n.language]} (${combo.mName})`}</h3>
                <div className="desc">{`${combo.desc[i18n.language]}`}</div>
                {/* <p className="price">{amountToString(itemPrice)}</p> */}
            </div>
        </div>

    );
};

export default ComboCard;
