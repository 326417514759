import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './MenuButton.scss';
import { IMenu } from '../../../../model/menuGroup/menu/IMenu';

interface MenuButtonProps {
    menu: IMenu;
    selectMenu: (id: string) => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({
    menu, selectMenu,
}) => {
    const { t, i18n } = useTranslation();
    // console.log(menu);
    return (

        <div
            className="menuButton"
            aria-hidden
            onClick={() => selectMenu(menu._id)}
        >
            <div className="storeName">
                <h2>{menu.name[i18n.language]}</h2>
                <i className="backArrow" />
            </div>
        </div>

    );
};

export default MenuButton;
