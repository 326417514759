import { Config } from '../../Config';
import { IRawMatReq } from '../../model/IngrGroup/RawMatReq/IRawMatReq';
import { RawMatReqStatus } from '../../model/IngrGroup/RawMatReq/IRawMatReqFields';
import { AxiosController, IResponseSchema } from '../AxiosController';

interface RawMatReqResponse extends IResponseSchema {
    data: {
        rawMatReq: IRawMatReq,
    },
}

interface RawMatReqArrayResponse extends IResponseSchema {
    data: {
        rawMatReqs: IRawMatReq[],
    },
}

interface RawMatReqNotifyResponse extends IResponseSchema {
    data: {
        success: boolean,
        msg: string,
    }
}

export class RawMatReqController extends AxiosController {
    public async createRawMatReq(
        storeId: string,
        corpId: string,
        rawMatLogId: string,
        corpRawMatId: string,
        unitsReqed: number,
    ) : Promise<IRawMatReq> {
        const resp : RawMatReqResponse = await this.axiosPOST(`${Config.SERVER_URL}/rawMatReq`, {
            storeId: storeId,
            corpId: corpId,
            rawMatLogId: rawMatLogId,
            corpRawMatId: corpRawMatId,
            unitsReqed: unitsReqed,
        });
        const rawMatReq: IRawMatReq = resp.data.rawMatReq;
        return rawMatReq;
    }

    public async notify(
        rawMatReqId: string,
    ) : Promise<RawMatReqNotifyResponse> {
        const resp : RawMatReqNotifyResponse = await this.axiosPOST(`${Config.SERVER_URL}/rawMatReq/notify`, {
            rawMatReqId: rawMatReqId,
        });
        return resp;
    }

    public async editUnitsReqed(
        rawMatReqId: string,
        unitsReqed: number,
    ) : Promise<RawMatReqNotifyResponse> {
        const resp : RawMatReqNotifyResponse = await this.axiosPOST(`${Config.SERVER_URL}/rawMatReq/unitReqed`, {
            rawMatReqId: rawMatReqId,
            unitsReqed: unitsReqed,
        });
        return resp;
    }

    public async updateStatus(
        rawMatReqId: string,
        reqStatus: RawMatReqStatus,
    ) : Promise<RawMatReqArrayResponse> {
        const resp : RawMatReqArrayResponse = await this.axiosPUT(`${Config.SERVER_URL}/rawMatReq/reqStatus`, {
            rawMatReqId: rawMatReqId,
            reqStatus: reqStatus,
        });
        return resp;
    }

    public async getRawMatReqByCorpId(
        corpId: string,
    ) : Promise<IRawMatReq[]> {
        const resp : RawMatReqArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/rawMatReq/corp`, {
            corpId: corpId,
        });
        const rawMatReqs : IRawMatReq[] = resp.data.rawMatReqs;
        return rawMatReqs;
    }

    public async getRawMatReqByCorpRawMatId(
        corpRawMatId: string,
    ) : Promise<IRawMatReq[]> {
        const resp : RawMatReqArrayResponse = await this.axiosPOST(`${Config.SERVER_URL}/rawMatReq/corpRawMat`, {
            corpRawMatId: corpRawMatId,
        });
        const rawMatReqs : IRawMatReq[] = resp.data.rawMatReqs;
        return rawMatReqs;
    }

    public async getRawMatReqByStoreId(
        storeId: string,
    ) : Promise<IRawMatReq[]> {
        const resp : RawMatReqArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/rawMatReq/store`, {
            storeId: storeId,
        });
        const rawMatReqs : IRawMatReq[] = resp.data.rawMatReqs;
        return rawMatReqs;
    }

    public async getRawMatReqByRawMatLogId(
        rawMatLogId: string,
    ) : Promise<IRawMatReq[]> {
        const resp : RawMatReqArrayResponse = await this.axiosPOST(`${Config.SERVER_URL}/rawMatReq/rawMatLog`, {
            rawMatLogId: rawMatLogId,
        });
        const rawMatReqs : IRawMatReq[] = resp.data.rawMatReqs;
        return rawMatReqs;
    }
}
