import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StaffContext } from '../../../../controllers/Staff/StoreContext';
import { ICorpRawMatMeta } from '../../../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { IIngrLogMeta } from '../../../../model/IngrGroup/IngrLog/IIngrLog';
import './CorpRawMatAdjModal.scss';

ReactModal.setAppElement('#root');
interface CorpRawMatAdjModalProps {
    open: boolean;
    corpRawMatMeta: ICorpRawMatMeta | null;
    setCorpRawMatMetas: React.Dispatch<React.SetStateAction<ICorpRawMatMeta[]>>;
    closeModal: () => void;
}

const CorpRawMatAdjModal: React.FC<CorpRawMatAdjModalProps> = ({
    open, corpRawMatMeta, setCorpRawMatMetas, closeModal,
}) => {
    const { i18n } = useTranslation();
    const { staff } = useContext(StaffContext)!;

    // TODO ERIC XIE ALLOW FOR DECIMAL DECIAMLS
    const [unitsAdj, setUnitsAdj] = useState(0);
    const changeUnitsAdj = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setUnitsAdj(value);
    };

    const [notes, setNotes] = useState('');
    const changeNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(e.target.value);
    };

    const restock = async () => {
        if (corpRawMatMeta === null) throw new Error('No Ingredient found');
        const ingrLog = await Factory.CorpRawMatController.addIngrAdj(
            corpRawMatMeta._id,
            staff._id,
            unitsAdj,
            notes,
        );
        closeModal();
        return ingrLog;
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleConfirm = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        try {
            const ingrLogResp = await restock();
            setCorpRawMatMetas(ingrLogResp.data.corpRawMatMetas);
        } catch (err) {
            //
        }
        setHCLoading(false);
    };

    useEffect(() => {
        setUnitsAdj(0);
        setNotes('');
    }, [open]);

    if (corpRawMatMeta === null) return <></>;

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditIngrAdjModal"
            overlayClassName="addEditIngrAdjModalOverlay"
        >
            <h2>
                {`Add adjustment for ${corpRawMatMeta.name.en}`}
                <h3>{`Current Stock ${corpRawMatMeta.unitsAvail}`}</h3>
                <h3>{`Measurement Unit ${corpRawMatMeta.unitOfM}`}</h3>
            </h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Units Adjusted</h4>
                    <input
                        type="number"
                        value={unitsAdj}
                        onChange={changeUnitsAdj}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Notes</h4>
                    <input
                        value={notes}
                        onChange={changeNotes}
                    />
                </div>
            </div>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={handleConfirm} disabled={HCLoading}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default CorpRawMatAdjModal;
