import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../model/helpers/Dict';
import { IComboElem } from '../../../../model/menuGroup/combo/comboElem/IComboElem';
import { ICombo } from '../../../../model/menuGroup/combo/ICombo';
import { IItemTypes } from '../../../../model/menuGroup/item/IItemTypes';

import './AddEditCombo.scss';

ReactModal.setAppElement('#root');
interface AddEditComboProps {
    open: boolean;
    combo: ICombo | null;
    closeModal: () => void;
    handleContinue?: (newOptId: string) => void;
}

const AddEditCombo: React.FC<AddEditComboProps> = ({
    open, closeModal, combo, handleContinue,
}) => {
    const { i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;
    const menuController = Factory.getMenuController();

    const [comboName, setComboName] = useState<Dict<string>>({});
    const [comboMName, setComboMName] = useState('');
    const [comboDesc, setComboDesc] = useState<Dict<string>>({});
    const [comboMDesc, setComboMDesc] = useState('');

    const changeComboName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpComboName = e.target.value;
        const cLang = i18n.language;
        setComboName((prevState) => ({
            ...prevState,
            [cLang]: tmpComboName,
        }));
    };

    const changeComboDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpComboDesc = e.target.value;
        setComboDesc((prevState) => ({
            ...prevState,
            [i18n.language]: tmpComboDesc,
        }));
    };

    const [comboItemIds, setComboItemIds] = useState<string[]>([]);
    const addComboElem = () => {
        setComboItemIds([...comboItemIds, '']);
    };
    const removeComboElem = (idx: number) => {
        const tmpComboItemIds = [...comboItemIds];
        tmpComboItemIds.splice(idx, 1);

        setComboItemIds(tmpComboItemIds);
    };
    const changeComboItemIds = (e: React.ChangeEvent<HTMLSelectElement>, idx: number) => {
        const itemId = e.target.value;
        const tmpComboItemIds = [...comboItemIds];
        tmpComboItemIds[idx] = itemId;
        setComboItemIds(tmpComboItemIds);
    };

    const createCombo = async () => {
        if (combo !== null) throw new Error('cannot add existing option');
        const menuResponse = await menuController.createCombo(
            store._id,
            comboName,
            comboMName,
            comboDesc,
            comboMDesc,
            comboItemIds,
        );
        setStore(menuResponse.store);
        closeModal();
        return menuResponse.combo;
    };

    const editCombo = async () => {
        if (combo === null) throw new Error('cannot edit new option');
        const menuResponse = await menuController.editCombo(
            store._id,
            combo._id,
            comboName,
            comboMName,
            comboDesc,
            comboMDesc,
            comboItemIds,
        );
        setStore(menuResponse.store);
        closeModal();
        return menuResponse.combo;
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleConfirm = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        let tmpCombo: ICombo;
        if (combo === null) {
            tmpCombo = await createCombo();
        } else {
            tmpCombo = await editCombo();
        }
        if (handleContinue !== undefined) {
            handleContinue(tmpCombo._id);
        }
        setComboName({
            [i18n.language]: '',
        });
        setComboMName('');
        setComboDesc({
            [i18n.language]: '',
        });
        setComboMDesc('');
        setComboItemIds([]);
        setHCLoading(false);
    };

    useEffect(() => {
        if (combo === null) {
            setComboName({
                [i18n.language]: '',
            });
            setComboMName('');
            setComboDesc({
                [i18n.language]: '',
            });
            setComboMDesc('');
            setComboItemIds([]);
        } else {
            setComboName(combo.name);
            setComboMName(combo.mName);
            setComboDesc(combo.desc);
            setComboMDesc(combo.mDesc);
            setComboItemIds(combo.itemIds);
        }
    }, [open]);

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditComboModal"
            overlayClassName="addEditComboModalOverlay"
        >
            <h2>Add Combo</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Combo Name</h4>
                    <input
                        value={comboName[i18n.language]}
                        onChange={changeComboName}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Combo Name</h4>
                    <input
                        value={comboMName}
                        onChange={(e) => setComboMName(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Description</h4>
                    <input
                        value={comboDesc[i18n.language]}
                        onChange={changeComboDesc}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Description</h4>
                    <input value={comboMDesc} onChange={(e) => setComboMDesc(e.target.value)} />
                </div>
            </div>
            <h4 className="optionElemHeader">Combo Elements</h4>
            { comboItemIds.map((comboElemId, idx) => (
                <div className="row">
                    <div className="inputBox">
                        <h4>Combo Item</h4>
                        <select
                            onChange={(e) => changeComboItemIds(e, idx)}
                        >
                            <option
                                value=""
                                selected={comboElemId === ''}
                                disabled
                                hidden
                            >
                                Choose item ...
                            </option>
                            {store.items.filter(
                                (item) => item.itemType === IItemTypes.REG,
                            ).map((item) => (
                                <option
                                    value={item._id}
                                    selected={item._id === comboElemId}
                                    hidden={comboItemIds.includes(item._id)}
                                >
                                    {`${item.name[i18n.language]} (${
                                        item.mName
                                    })`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button type="button" onClick={() => removeComboElem(idx)} className="removeOptionElem">
                        X
                    </button>
                </div>
            ))}
            <div className="row">
                <button type="button" className="addOptionElem" onClick={addComboElem}>+</button>
            </div>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button
                        className="confirm"
                        type="button"
                        onClick={handleConfirm}
                        disabled={HCLoading}
                    >
                        Confirm
                    </button>
                </div>
            </div>

        </ReactModal>
    );
};

export default AddEditCombo;
