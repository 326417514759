import { Config } from '../../Config';
import { Dict } from '../../model/helpers/Dict';
import { ICorpRawMat, ICorpRawMatMeta } from '../../model/IngrGroup/CorpRawMat/ICorpRawMat';
import { ICorpRawMatReport } from '../../model/IngrGroup/CorpRawMat/ICorpRawMatReport';
import { AxiosController, IResponseSchema } from '../AxiosController';

interface CorpRawMatResponse extends IResponseSchema {
    data: {
        corpRawMat: ICorpRawMat,
    },
}

interface CorpRawMatReportResponse extends IResponseSchema {
    data: {
        corpRawMatReport: ICorpRawMatReport,
    },
}

interface CorpRawMatAndMetasResponse extends IResponseSchema {
    data: {
        corpRawMat: ICorpRawMat,
        corpRawMatMetas: ICorpRawMatMeta[],
    },
}

interface CorpRawMatMetasResponse extends IResponseSchema {
    data: {
        corpRawMatMetas: ICorpRawMatMeta[],
    }
}

export class CorpRawMatController extends AxiosController {
    public async getIngrLog(
        corpRawMatId: string,
    ) : Promise<ICorpRawMat> {
        const resp : CorpRawMatResponse = await this.axiosGET(`${Config.SERVER_URL}/corpRawMat`, {
            corpRawMatId: corpRawMatId,
        });
        const corpRawMat: ICorpRawMat = resp.data.corpRawMat;
        return corpRawMat;
    }

    public async getCorpRawMatReport(
        corpRawMatId: string,
        startTime: Date | null,
        endTime: Date | null,
    ) : Promise<ICorpRawMatReport> {
        const resp : CorpRawMatReportResponse = await this.axiosGET(`${Config.SERVER_URL}/corpRawMat/report`, {
            corpRawMatId: corpRawMatId,
            startTime: startTime,
            endTime: endTime,
        });
        const corpRawMatReport: ICorpRawMatReport = resp.data.corpRawMatReport;
        return corpRawMatReport;
    }

    public async getCorpLogMetas(
        corpId: string,
    ) : Promise<ICorpRawMatMeta[]> {
        const resp : CorpRawMatMetasResponse = await this.axiosGET(`${Config.SERVER_URL}/corpRawMat/metas`, {
            corpId: corpId,
        });
        const corpRawMatMetas: ICorpRawMatMeta[] = resp.data.corpRawMatMetas;
        return corpRawMatMetas;
    }

    public async createCorpRawMat(
        corpId: string,
        name: Dict<string>,
        desc: Dict<string>,
        mName: string,
        mDesc: string,
        unitOfM: string,
        warningUnits: number,
        contactStaffIds: string[],
    ) : Promise<CorpRawMatAndMetasResponse> {
        const resp : CorpRawMatAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/corpRawMat`, {
            corpId: corpId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            unitOfM: unitOfM,
            warningUnits: warningUnits,
            contactStaffIds: contactStaffIds,
        });
        return resp;
    }

    public async editCorpRawMat(
        corpRawMatId: string,
        corpId: string,
        name: Dict<string>,
        desc: Dict<string>,
        mName: string,
        mDesc: string,
        unitOfM: string,
        warningUnits: number,
        contactStaffIds: string[],
    ) : Promise<CorpRawMatAndMetasResponse> {
        const resp : CorpRawMatAndMetasResponse = await this.axiosPUT(`${Config.SERVER_URL}/corpRawMat`, {
            corpRawMatId: corpRawMatId,
            corpId: corpId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            unitOfM: unitOfM,
            warningUnits: warningUnits,
            contactStaffIds: contactStaffIds,
        });
        return resp;
    }

    public async restock(
        corpRawMatId: string,
        staffId: string,
        supplierId: string | null,
        costOfStock: number,
        unitsStocked: number,
        notes: string,
    ) : Promise<CorpRawMatAndMetasResponse> {
        const resp : CorpRawMatAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/corpRawMat/restock`, {
            corpRawMatId: corpRawMatId,
            staffId: staffId,
            supplierId: supplierId,
            costOfStock: costOfStock * 100,
            unitsStocked: unitsStocked,
            notes: notes,
        });
        return resp;
    }

    public async use(
        corpRawMatId: string,
        storeId: string,
        staffId: string,
        unitsUsed: number,
        priceCharged: number,
        notes: string,
    ) : Promise<CorpRawMatAndMetasResponse> {
        const resp : CorpRawMatAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/corpRawMat/use`, {
            corpRawMatId: corpRawMatId,
            storeId: storeId,
            staffId: staffId,
            unitsUsed: unitsUsed,
            priceCharged: priceCharged,
            notes: notes,
        });
        return resp;
    }

    public async addIngrAdj(
        corpRawMatId: string,
        staffId: string,
        unitsAdj: number,
        notes: string,
    ) : Promise<CorpRawMatAndMetasResponse> {
        const resp : CorpRawMatAndMetasResponse = await this.axiosPOST(`${Config.SERVER_URL}/corpRawMat/addCorpRawMatAdj`, {
            corpRawMatId: corpRawMatId,
            staffId: staffId,
            unitsAdj: unitsAdj,
            notes: notes,
        });
        return resp;
    }
}
