import axios from 'axios';
import { Config } from '../../Config';
import { Dict } from '../../model/helpers/Dict';
import { IComboResponse } from '../../model/menuGroup/combo/ICombo';
import { IComboRef } from '../../model/menuGroup/combo/IComboRef';
import { IItemResponse } from '../../model/menuGroup/item/IItem';
import { IItemPrice } from '../../model/menuGroup/item/IItemPrice';
import { IItemTypes } from '../../model/menuGroup/item/IItemTypes';
import { ItemFields } from '../../model/menuGroup/item/itemFields';
import { IMenuResponse } from '../../model/menuGroup/menu/IMenu';
import { IOptionResponse } from '../../model/menuGroup/option/IOption';
import { IOptionRef } from '../../model/menuGroup/option/IOptionRef';
import { IOptionElem } from '../../model/menuGroup/option/optionElem/IOptionElem';
import { ToastController } from '../ToastController';

export class MenuController {
    public async createMenu(
        storeId: string,
        name: string,
        mName: string,
        desc: string,
        mDesc: string,
        priceKeyId: string,
    ) : Promise<IMenuResponse> {
        const resp = await axios.post(`${Config.SERVER_URL}/store/menu`, {
            storeId: storeId,
            name: {
                en: name,
            },
            mName: mName,
            desc: {
                en: desc,
            },
            mDesc: mDesc,
            priceKeyId: priceKeyId,
        });
        const menuResponse: IMenuResponse = resp.data.data;
        ToastController.success(`Created ${name}`);
        return menuResponse;
    }

    public async createCategory(
        storeId: string,
        name: string,
        mName: string,
        desc: string,
        mDesc: string,
        menuId: string,
    ) : Promise<IMenuResponse> {
        const resp = await axios.post(`${Config.SERVER_URL}/store/category`, {
            storeId: storeId,
            name: {
                en: name,
            },
            mName: mName,
            desc: {
                en: desc,
            },
            mDesc: mDesc,
            active: true,
            itemIds: [],
            discountIds: [],
            menuIds: [menuId],
        });
        const menuResponse: IMenuResponse = resp.data.data;
        ToastController.success(`Created ${name}`);
        return menuResponse;
    }

    public async categoryAddItemIds(
        storeId: string,
        categoryId: string,
        itemIds: string[],
    ) : Promise<IMenuResponse> {
        const resp = await axios.put(`${Config.SERVER_URL}/store/category/addItems`, {
            storeId: storeId,
            categoryId: categoryId,
            itemIds: itemIds,
        });
        const menuResponse: IMenuResponse = resp.data.data;
        ToastController.success('Success');
        return menuResponse;
    }

    public async createItem(
        storeId: string,
        name: string,
        itemType: IItemTypes,
        mName: string,
        desc: string,
        mDesc: string,
        pricesDict: Dict<{
            amount: number,
            _id: string | null,
        }>,
        instruction: string,
        itemIngrMap: Dict<number>,
        optionsDict: Dict<IOptionRef>,
        combosDict: Dict<IComboRef>,
        subItemIds: string[],
        taxIds: string[],
        needPrep: boolean,
        active: boolean,
        categoryIds: string[],
        imgSrc?: string,
    ) : Promise<IItemResponse> {
        const itemPrices: IItemPrice[] = [];
        Object.keys(pricesDict).forEach((priceKeyId) => {
            const tempItemPrice: IItemPrice = {
                priceKeyId: priceKeyId,
                amount: pricesDict[priceKeyId].amount * 100,
                _id: pricesDict[priceKeyId]._id,
            };
            itemPrices.push(tempItemPrice);
        });
        const optionRefs: IOptionRef[] = Object.values(optionsDict).map(
            (tmpOptRef: IOptionRef) : IOptionRef => (
                {
                    ...tmpOptRef,
                    optionElemRefs: tmpOptRef.optionElemRefs.map((oElRef) => (
                        {
                            ...oElRef,
                            oePrices: oElRef.oePrices.map((tmpPrice) => ({
                                ...tmpPrice,
                                amount: tmpPrice.amount * 100,
                            })),
                        }
                    )),
                }
            ),
        );

        const comboRefs: IComboRef[] = Object.values(combosDict).map(
            (tmpComRef: IComboRef) : IComboRef => (
                {
                    ...tmpComRef,
                    comboElemRefs: tmpComRef.comboElemRefs.map((cElRef) => (
                        {
                            ...cElRef,
                            cePrices: cElRef.cePrices.map((tmpPrice) => ({
                                ...tmpPrice,
                                amount: tmpPrice.amount * 100,
                            })),
                        }
                    )),
                }
            ),
        );
        const resp = await axios.post(`${Config.SERVER_URL}/store/item`, {
            [ItemFields.storeId]: storeId,
            [ItemFields.name]: {
                en: name,
                zh: '测试',
            },
            [ItemFields.itemType]: itemType,
            [ItemFields.mName]: mName,
            [ItemFields.desc]: {
                en: desc,
                zh: '测试说明',
            },
            [ItemFields.mDesc]: mDesc,
            [ItemFields.itemPrices]: itemType === IItemTypes.SUBCATEG ? [] : itemPrices,
            [ItemFields.instruction]: instruction,
            [ItemFields.needPrep]: needPrep,
            [ItemFields.active]: active,
            [ItemFields.categoryIds]: categoryIds,
            [ItemFields.taxIds]: taxIds,
            [ItemFields.discountIds]: [],
            [ItemFields.surchargeIds]: [],
            [ItemFields.dfltRdConfigSchema]: {},
            [ItemFields.ingrMap]: itemIngrMap,
            [ItemFields.optionRefs]: itemType === IItemTypes.REG ? optionRefs : [],
            [ItemFields.comboRefs]: itemType === IItemTypes.COMBO ? comboRefs : [],
            [ItemFields.subItemIds]: itemType === IItemTypes.SUBCATEG ? subItemIds : [],
            [ItemFields.comboSugIds]: [],
            [ItemFields.imgId]: null,
            // [ItemFields.itemImg]: imgSrc,
        });
        ToastController.success(`Created ${name}`);
        const respData: IItemResponse = resp.data.data;
        return respData;
    }

    public async editItem(
        storeId: string,
        itemId: string,
        itemType: IItemTypes,
        name: string,
        mName: string,
        desc: string,
        mDesc: string,
        pricesDict: Dict<{
            amount: number,
            _id: string | null,
        }>,
        instruction: string,
        itemIngrMap: Dict<number>,
        optionsDict: Dict<IOptionRef>,
        combosDict: Dict<IComboRef>,
        subItemIds: string[],
        taxIds: string[],
        needPrep: boolean,
        active: boolean,
        imgSrc?: string,
    ) : Promise<IItemResponse> {
        const itemPrices: IItemPrice[] = [];
        Object.keys(pricesDict).forEach((priceKeyId) => {
            const tempItemPrice: IItemPrice = {
                priceKeyId: priceKeyId,
                amount: pricesDict[priceKeyId].amount * 100,
                _id: pricesDict[priceKeyId]._id,
            };
            itemPrices.push(tempItemPrice);
        });
        const optionRefs: IOptionRef[] = Object.values(optionsDict).map(
            (tmpOptRef) : IOptionRef => (
                {
                    ...tmpOptRef,
                    optionElemRefs: tmpOptRef.optionElemRefs.map((oElRef) => (
                        {
                            ...oElRef,
                            oePrices: oElRef.oePrices.map((tmpPrice) => ({
                                ...tmpPrice,
                                amount: tmpPrice.amount * 100,
                            })),
                        }
                    )),
                }
            ),
        );

        const comboRefs: IComboRef[] = Object.values(combosDict).map(
            (tmpCom) : IComboRef => (
                {
                    ...tmpCom,
                    comboElemRefs: tmpCom.comboElemRefs.map((cElRef) => (
                        {
                            ...cElRef,
                            cePrices: cElRef.cePrices.map((tmpPrice) => ({
                                ...tmpPrice,
                                amount: tmpPrice.amount * 100,
                            })),
                        }
                    )),
                }
            ),
        );
        const resp = await axios.put(`${Config.SERVER_URL}/store/item`, {
            [ItemFields.storeId]: storeId,
            [ItemFields.itemId]: itemId,
            [ItemFields.name]: {
                en: name,
                zh: '测试',
            },
            [ItemFields.mName]: mName,
            [ItemFields.desc]: {
                en: desc,
                zh: '测试说明',
            },
            [ItemFields.mDesc]: mDesc,
            [ItemFields.itemPrices]: itemType === IItemTypes.SUBCATEG ? [] : itemPrices,
            [ItemFields.instruction]: instruction,
            [ItemFields.needPrep]: needPrep,
            [ItemFields.active]: active,
            [ItemFields.taxIds]: taxIds,
            [ItemFields.discountIds]: [],
            [ItemFields.surchargeIds]: [],
            [ItemFields.dfltRdConfigSchema]: {},
            [ItemFields.ingrMap]: itemIngrMap,
            [ItemFields.optionRefs]: itemType === IItemTypes.REG ? optionRefs : [],
            [ItemFields.comboRefs]: itemType === IItemTypes.COMBO ? comboRefs : [],
            [ItemFields.subItemIds]: itemType === IItemTypes.SUBCATEG ? subItemIds : [],
            [ItemFields.comboSugIds]: [],
            // [ItemFields.itemImg]: imgSrc,
        });
        const respData: IItemResponse = resp.data.data;
        ToastController.success(`Edited ${name}`);
        return respData;
    }

    public async setItemActive(
        storeId: string,
        itemId: string,
        active: boolean,
    ) : Promise<IItemResponse> {
        const resp = await axios.post(`${Config.SERVER_URL}/store/item/active`, {
            [ItemFields.storeId]: storeId,
            [ItemFields.itemId]: itemId,
            [ItemFields.active]: active,
        });
        const respData: IItemResponse = resp.data.data;
        ToastController.success(`Item ${active ? 'activated' : 'deactivated'}`);
        return respData;
    }

    public async createOption(
        storeId: string,
        name: Dict<string>,
        mName: string,
        desc: Dict<string>,
        mDesc: string,
        optionElemsDict: Dict<IOptionElem>,
    ) : Promise<IOptionResponse> {
        const optionElems = Object.keys(optionElemsDict).map(
            (optionElemId) => ({
                ...optionElemsDict[optionElemId],
            }),
        );

        const resp = await axios.post(`${Config.SERVER_URL}/store/option`, {
            storeId: storeId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            optionElems: optionElems,
        });
        ToastController.success(`Created ${name.en}`);
        const optionResponse: IOptionResponse = resp.data.data;
        return optionResponse;
    }

    public async editOption(
        storeId: string,
        optionId: string,
        name: Dict<string>,
        mName: string,
        desc: Dict<string>,
        mDesc: string,
        optionElemsDict: Dict<IOptionElem>,
    ) : Promise<IOptionResponse> {
        const optionElems = Object.keys(optionElemsDict).map(
            (optionElemId) => ({
                ...optionElemsDict[optionElemId],
            }),
        );

        const resp = await axios.put(`${Config.SERVER_URL}/store/option`, {
            storeId: storeId,
            optionId: optionId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            optionElems: optionElems,
        });
        ToastController.success(`Edited ${name.en}`);
        const optionResponse: IOptionResponse = resp.data.data;
        return optionResponse;
    }

    public async createCombo(
        storeId: string,
        name: Dict<string>,
        mName: string,
        desc: Dict<string>,
        mDesc: string,
        itemIds: string[],
    ) : Promise<IComboResponse> {
        const resp = await axios.post(`${Config.SERVER_URL}/store/combo`, {
            storeId: storeId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            itemIds: itemIds,
        });

        const comboResponse: IComboResponse = resp.data.data;
        ToastController.success(`Created ${name.en}`);
        return comboResponse;
    }

    public async editCombo(
        storeId: string,
        comboId: string,
        name: Dict<string>,
        mName: string,
        desc: Dict<string>,
        mDesc: string,
        itemIds: string[],
    ) : Promise<IComboResponse> {
        const resp = await axios.put(`${Config.SERVER_URL}/store/combo`, {
            storeId: storeId,
            comboId: comboId,
            name: name,
            desc: desc,
            mName: mName,
            mDesc: mDesc,
            itemIds: itemIds,
        });

        const comboResponse: IComboResponse = resp.data.data;
        ToastController.success(`Edited ${name.en}`);
        return comboResponse;
    }
}
