import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import editImg from '../../../../../assets/edit.svg';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { IOption } from '../../../../model/menuGroup/option/IOption';
import './OptionCard.scss';

interface OptionCardProps{
    option: IOption;
    selectOption: () => void;
}

const OptionCard: React.FC<OptionCardProps> = ({
    option, selectOption,
}) => {
    const { t, i18n } = useTranslation();
    return (

        <div
            className="optionCardContainer"
            aria-hidden
            onClick={selectOption}
        >
            <div className="info">
                <h3>{`${option.name[i18n.language]} (${option.mName})`}</h3>
                <div className="desc">{`${option.desc[i18n.language]}`}</div>
                {/* <p className="price">{amountToString(itemPrice)}</p> */}
            </div>
        </div>

    );
};

export default OptionCard;
