import React, { useState } from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import { IAggregateReport } from '../../../model/analyticsGroup/IAggregateReport';
import { ISeshReport } from '../../../model/analyticsGroup/seshReport/ISeshReport';
import { IStaffReport } from '../../../model/analyticsGroup/staffReport/IStaffReport';
import { amountToString } from '../../../utils/global';

import './Staff.scss';
import StaffStatModal from './StaffStatModal/StaffStatModal';

interface StaffProps {
    aggregateData: IAggregateReport | null;
}

const Staff: React.FC<StaffProps> = ({
    aggregateData,
}) => {
    const [staffReport, setStaffReport] = useState<IStaffReport | null>(null);
    const [staffStatModalOpen, setStaffStatModalOpen] = useState(false);
    const openStaffStatModal = (tStaffReport: IStaffReport) => {
        setStaffReport(tStaffReport);
        setStaffStatModalOpen(true);
    };
    const closeStaffStatModal = () => {
        setStaffReport(null);
        setStaffStatModalOpen(false);
    };

    const msToHourStr = (duration : number) => {
        const minutes: number = Math.floor((duration / (1000 * 60)) % 60);
        const hours: number = Math.floor((duration / (1000 * 60 * 60)));
        const hoursStr: string = hours.toString().padStart(2, '0');
        const minutesStr: string = minutes.toString().padStart(2, '0');
        return `${hoursStr} Hours ${minutesStr} Minutes`;
    };

    return (
        <>
            {aggregateData === null && <Spinner />}
            {aggregateData !== null && (
                <div className="stats staffStatsContainer">
                    <StaffStatModal
                        open={staffStatModalOpen}
                        closeModal={closeStaffStatModal}
                        staffReport={staffReport}
                    />
                    {aggregateData.staffReports.map((tStaffReport) => (
                        <div
                            className="staffWrapper"
                            aria-hidden
                            onClick={() => openStaffStatModal(tStaffReport)}
                        >
                            <h2>{tStaffReport.nickName}</h2>
                            <div className="staffInnerCol">
                                <h3>Duration</h3>
                                <p>{msToHourStr(tStaffReport.totalTime)}</p>
                            </div>
                            <div className="staffInnerCol">
                                <h3>Tips</h3>
                                <p>{amountToString(tStaffReport.totalTips)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Staff;
