import { Config } from '../../Config';
import { AuctionTypeEnum } from '../../model/galaGroup/galaAuction/galaAuctionFields';
import { IGalaAuction } from '../../model/galaGroup/galaAuction/IGalaAuction';
import { Dict } from '../../model/helpers/Dict';
import { AxiosController, IResponseSchema } from '../AxiosController';

interface GalaAuctionResponse extends IResponseSchema {
    data: {
        galaAuction: IGalaAuction,
    }
}

interface GalaAuctionArrayResponse extends IResponseSchema {
    data: {
        galaAuctions: IGalaAuction[],
    }
}

export class GalaAuctionController extends AxiosController {
    public async getById(
        galaAuctionId: string,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/auction`, {
            galaAuctionId: galaAuctionId,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async getByUniqueLabel(
        galaId: string,
        uniqueLabel: string,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/auction/uniqueLabel`, {
            galaId: galaId,
            uniqueLabel: uniqueLabel,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async createAuction(
        galaId: string,
        uniqueLabel: string,
        auctionType: AuctionTypeEnum,
        name: Dict<string>,
        desc: Dict<string>,
        imgUrls: string[],
        startPrice: number,
        minIncrease: number,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosPOST(`${Config.SERVER_URL}/gala/auction`, {
            galaId: galaId,
            uniqueLabel: uniqueLabel,
            auctionType: auctionType,
            name: name,
            desc: desc,
            imgUrls: imgUrls,
            startPrice: startPrice,
            minIncrease: minIncrease,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async editAuction(
        galaAuctionId: string,
        uniqueLabel: string,
        name: Dict<string>,
        desc: Dict<string>,
        imgUrls: string[],
        startPrice: number,
        minIncrease: number,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosPUT(`${Config.SERVER_URL}/gala/auction`, {
            galaAuctionId: galaAuctionId,
            uniqueLabel: uniqueLabel,
            name: name,
            desc: desc,
            imgUrls: imgUrls,
            startPrice: startPrice,
            minIncrease: minIncrease,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async deleteAuction(
        galaAuctionId: string,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosDELETE(`${Config.SERVER_URL}/gala/auction`, {
            galaAuctionId: galaAuctionId,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async open(
        galaAuctionId: string,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosPUT(`${Config.SERVER_URL}/gala/auction/open`, {
            galaAuctionId: galaAuctionId,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async close(
        galaAuctionId: string,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosPUT(`${Config.SERVER_URL}/gala/auction/close`, {
            galaAuctionId: galaAuctionId,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async chooseWinner(
        galaAuctionId: string,
        winningBidId: string,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosPOST(`${Config.SERVER_URL}/gala/auction/chooseWinner`, {
            galaAuctionId: galaAuctionId,
            winningBidId: winningBidId,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async notifyWinner(
        galaAuctionId: string,
    ) : Promise<IGalaAuction> {
        const resp : GalaAuctionResponse = await this.axiosPOST(`${Config.SERVER_URL}/gala/auction/notifyWinner`, {
            galaAuctionId: galaAuctionId,
        });
        const galaAuction: IGalaAuction = resp.data.galaAuction;
        return galaAuction;
    }

    public async getAllByGala(
        galaId: string,
        auctionType: AuctionTypeEnum,
    ) : Promise<IGalaAuction[]> {
        const resp : GalaAuctionArrayResponse = await this.axiosGET(`${Config.SERVER_URL}/gala/auction/all`, {
            galaId: galaId,
            auctionType: auctionType,
        });
        const galaAuction: IGalaAuction[] = resp.data.galaAuctions;
        return galaAuction;
    }
}
