import React, { useState, useEffect } from 'react';
import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { IItemStat } from '../../../../model/analyticsGroup/seshReport/itemStat/IItemStat';
import { IItemOptionElemStat } from '../../../../model/analyticsGroup/seshReport/itemStat/itemOptionElemStat/IItemOptionElemStat';
import { amountToString, calcChartDimensions, generateChartOptions } from '../../../../utils/global';
import './ItemStatModal.scss';
import { Dict } from '../../../../model/helpers/Dict';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

ReactModal.setAppElement('#root');
interface ItemStatModalProps {
    open: boolean;
    itemStat: IItemStat | null;
    closeModal: () => void;
}

enum ChartType {
    OPTION = 'OPTION',
    TIME = 'TIME',
}

const ItemStatModal: React.FC<ItemStatModalProps> = ({
    open, closeModal, itemStat,
}) => {
    const { i18n } = useTranslation();
    const [chartType, setChartType] = useState(ChartType.OPTION);
    const [weekdayKey, setWeekdayKey] = useState<number | undefined>(undefined);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    useEffect(() => {
        setChartType(ChartType.OPTION);
        setWeekdayKey(undefined);
    }, [open]);

    const generateOptionData = (optionElems: IItemOptionElemStat[]) => ({
        labels: optionElems.map((option: IItemOptionElemStat) => option.name.en),
        datasets: [{
            label: 'Count',
            data: optionElems.map((option: IItemOptionElemStat) => option.qty),
            backgroundColor: '#E4545F9A',
        }],
    });

    const generateDailyData = (weekUnitMap: Dict<number[]>) => {
        const keys = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 7; i++) {
            if (i.toString() in weekUnitMap) {
                keys.push(i);
            }
        }

        return ({
            labels: keys.map((key) => days[key]),
            datasets: [{
                label: 'Count',
                data: keys.map((key) => (
                    weekUnitMap[key.toString()].reduce(
                        (agg, hourCount) => agg + hourCount,
                        0,
                    )
                )),
                backgroundColor: '#E4545F9A',
            }],
        });
    };

    const handleDailyChartClick = (evt: any, element: any) => {
        if (element.length > 0) {
            if (itemStat === null) throw new Error('[Daily Click] No itemstat data');
            const weekdayIdx = element[0].index;
            const keys = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < 7; i++) {
                if (i.toString() in itemStat.weekUnitMap) {
                    keys.push(i);
                }
            }

            setWeekdayKey(keys[weekdayIdx]);
        }
    };

    const toTwoDigits = (x: number) => {
        const s = x.toString();
        if (s.length === 2) return s;
        return `0${s}`;
    };
    const toHourString = (hour: number, minOffset: number) => `${hour.toString()}:${toTwoDigits(minOffset)}`;

    const generateHourlyData = (preTransHours: number[]) => {
        const postTransHours : number[] = [...preTransHours];
        const totalOffset = new Date().getTimezoneOffset();
        const offset = Math.floor(totalOffset / 60);
        const offsetMin = Math.abs(totalOffset % 60);

        if (offset > 0) {
            const shifted = postTransHours.splice(0, offset);
            postTransHours.concat(shifted);
        } else {
            const shifted = postTransHours.splice(0, postTransHours.length + offset);
            postTransHours.concat(shifted);
        }
        return ({
            labels: Array.from(Array(24).keys()).map((idx) => toHourString(idx, offsetMin)),
            datasets: [{
                label: 'Count',
                data: postTransHours,
                backgroundColor: '#E4545F9A',
            }],
        });
    };

    if (itemStat === null) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="itemStatModal"
            overlayClassName="itemStatModalOverlay"
        >
            <header>
                <div className="itemStats">
                    <h2>{itemStat.name[i18n.language]}</h2>
                    <h3>{`Qty: ${itemStat.totalQty}`}</h3>
                    <h3>{`Revenue: ${amountToString(itemStat.totalRevenue)}`}</h3>
                </div>
                <div className="chartSwitcher">
                    <button
                        type="button"
                        className={`${chartType === ChartType.OPTION ? 'active' : ''}`}
                        onClick={() => setChartType(ChartType.OPTION)}
                    >
                        Option
                    </button>
                    <button
                        type="button"
                        className={`${chartType === ChartType.TIME ? 'active' : ''}`}
                        onClick={() => setChartType(ChartType.TIME)}
                    >
                        Time
                    </button>
                </div>
            </header>
            {chartType === ChartType.OPTION
            && (
                <Bar
                    data={generateOptionData(itemStat.itemOptionElemStats)}
                    style={calcChartDimensions(itemStat.itemOptionElemStats, 0)}
                    options={generateChartOptions('Option Statistics')}
                />
            )}
            {chartType === ChartType.TIME && (
                <>
                    <Bar
                        data={generateDailyData(itemStat.weekUnitMap)}
                        style={calcChartDimensions(Object.keys(itemStat.weekUnitMap), 0)}
                        options={generateChartOptions('Daily Statistics', handleDailyChartClick)}
                    />
                    {weekdayKey !== undefined && (
                        <Bar
                            data={generateHourlyData(itemStat.weekUnitMap[weekdayKey])}
                            style={calcChartDimensions(itemStat.weekUnitMap[weekdayKey], 0)}
                            options={generateChartOptions('Hourly Statistics')}
                        />
                    )}
                </>
            )}

        </ReactModal>
    );
};

export default ItemStatModal;
