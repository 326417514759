export enum TabbarLocale {
    // Tabbar Component
    dashboard = 'tabbar.dashboard',
    analytics = 'tabbar.analytics',
    shiftManagement = 'tabbar.shiftManagement',
    staffManagement = 'tabbar.staffManagement',
    transHistory = 'tabbar.transHistory',
    editingTools = 'tabbar.editingTools',
    menuEditor = 'tabbar.menuEditor',
    floorplanEditor = 'tabbar.floorplanEditor',
}
