import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './CategoryCard.scss';
import { ICategory } from '../../../../../model/menuGroup/category/ICategory';

interface CategoryCardProps{
    category: ICategory;
    currCategoryId: string;
    selectCategory: (id: string) => void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({
    category, currCategoryId, selectCategory,
}) => {
    const { t } = useTranslation();

    return (

        <div
            className={`${currCategoryId === category._id ? 'active' : ''} categoryContainer`}
            aria-hidden
            onClick={() => selectCategory(category._id)}
        >
            {category.name.en}
        </div>

    );
};

export default CategoryCard;
