export enum StoreFields {
    _id = '_id',
    name = 'name',
    phone = 'phone',
    email = 'email',
    corporationId = 'corporationId',
    currMenuId = 'currMenuId',
    menus = 'menus',
    items = 'items',
    combos = 'combos',
    options = 'options',
    categories = 'categories',
    tables = 'tables',
    serviceConfigs = 'serviceConfigs',
    adminIds = 'adminIds',
    staffIds = 'staffIds',
    discounts = 'discounts',
    surcharges = 'surcharges',
    taxes = 'taxes',
    storeImg = 'storeImg',
    coverImg = 'coverImg',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',

    // Router Fields
    storeId = 'storeId',
    toAddStaffId = 'toAddStaffId',
    adderStaffId = 'adderStaffId',
    roleId = 'roleId',
}
