/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Factory } from '../../../controllers/ControllerFactory';
import { ToastController } from '../../../controllers/ToastController';
import { IGalaAttendee } from '../../../model/galaGroup/galaAttendee/iGalaAttendee';
import { GalaTicketPaymentStatus, IGalaTicketSale } from '../../../model/galaGroup/galaTicketSale/iGalaTicketSale';
import { amountToString } from '../../../utils/global';
import './TicketSaleModal.scss';

interface TicketSaleModalProps {
    open: boolean;
    closeModal: () => void;
    ticketSale: IGalaTicketSale | null;
    refresh: () => Promise<void>;
}

const TicketSaleModal: React.FC<TicketSaleModalProps> = ({
    open, closeModal, ticketSale, refresh,
}) => {
    const galaController = Factory.GalaController;
    const [galaAttendees, setGalaAttendees] = useState<IGalaAttendee[]>([]);
    const [isEdit, setIsEdit] = useState(false);

    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [sponsor, setSponsor] = useState('');

    const onChangeVal = (e: React.ChangeEvent<HTMLInputElement>, fn: React.Dispatch<React.SetStateAction<string>>) => {
        const val = e.target.value;
        fn(val);
    };

    const editSave = async () => {
        if (isEdit) {
            // save
            if (ticketSale === null) throw new Error('cannot edit ticket that is null');
            await galaController.editGalaTicketSale(
                ticketSale._id, fName, lName, address, postalCode, city, province, country, email, phone, sponsor,
            );
            setIsEdit(false);
            await refresh();
            closeModal();
            return;
        }
        setIsEdit(true);
    };

    useEffect(() => {
        const getGalaAttendees = async (tArr: string[]) => {
            if (tArr.length === 0) return;
            const tGalaAttendees = await galaController.getGalaAttendeeByIds(tArr);
            setGalaAttendees(tGalaAttendees);
        };
        if (ticketSale !== null) {
            getGalaAttendees(ticketSale.galaAttendeeIds);
        } else setGalaAttendees([]);
    }, [ticketSale]);

    const [approveBuffer, setApproveBuffer] = useState(4);
    const [approveTimerStarted, setApproveTimerStarted] = useState(false);

    const [HALoading, setHALoading] = useState(false);

    const handleApprove = async () => {
        if (HALoading || ticketSale === null) return;
        if (approveTimerStarted && approveBuffer !== 0) return;
        if (approveTimerStarted && approveBuffer === 0) {
            setHALoading(true);
            try {
                await galaController.payTicketSale(ticketSale._id, GalaTicketPaymentStatus.paid, '');
                await refresh();
            } catch (err) {
                //
            }
            setHALoading(false);
            closeModal();
            return;
        }
        setApproveTimerStarted(true);
    };

    const handleSendTickets = async () => {
        if (HALoading || ticketSale === null) return;
        if (approveTimerStarted && approveBuffer !== 0) return;
        if (approveTimerStarted && approveBuffer === 0) {
            setHALoading(true);
            try {
                await galaController.sendTicketSaleEmail(ticketSale._id);
                ToastController.success('Email Sent');
                await refresh();
            } catch (err) {
                //
            }
            setHALoading(false);
            closeModal();
            return;
        }
        setApproveTimerStarted(true);
    };

    useEffect(() => {
        if (approveTimerStarted) {
            setApproveBuffer((prev) => prev - 1);
        }
    }, [approveTimerStarted]);

    useEffect(() => {
        if (approveTimerStarted && approveBuffer > 0) {
            setTimeout(() => {
                setApproveBuffer((prev) => prev - 1);
            }, 1000);
        }
    }, [approveBuffer]);

    useEffect(() => {
        setApproveBuffer(4);
        setApproveTimerStarted(false);
        if (ticketSale !== null) {
            setFName(ticketSale.fName);
            setLName(ticketSale.lName);
            setAddress(ticketSale.address);
            setPostalCode(ticketSale.postalCode);
            setCity(ticketSale.city);
            setProvince(ticketSale.province);
            setCountry(ticketSale.country);
            setEmail(ticketSale.email);
            setPhone(ticketSale.phone);
            setSponsor(ticketSale.sponsor);
        } else {
            setFName('');
            setLName('');
            setAddress('');
            setPostalCode('');
            setCity('');
            setProvince('');
            setCountry('');
            setEmail('');
            setPhone('');
            setSponsor('');
            setIsEdit(false);
        }
    }, [open]);

    if (ticketSale === null) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="ticketSaleModal"
            overlayClassName="optionStatModalOverlay"
        >
            <header>
                <h2>
                    {`${ticketSale.fName} ${ticketSale.lName}`}
                </h2>
                <button type="button" onClick={editSave}>
                    {isEdit ? 'Save' : 'Edit'}
                </button>
            </header>
            {!isEdit && (

                <>
                    <div className="infoWrapper">
                        <div className="info">
                            <p>Payment Status</p>
                            <p>{ticketSale.paymentStatus === 1 ? 'Paid' : 'Unpaid'}</p>
                        </div>
                        <div className="info">
                            <p>Phone</p>
                            <p>{ticketSale.phone}</p>
                        </div>
                        <div className="info">
                            <p>Email</p>
                            <p>{ticketSale.email}</p>
                        </div>
                        <div className="info">
                            <p>Address</p>
                            <address>
                                {ticketSale.address}
                                {' '}
                                <br />
                                {ticketSale.city}
                                ,
                                {' '}
                                {ticketSale.province}
                                {' '}
                                <br />
                                {ticketSale.country}
                                ,
                                {' '}
                                {ticketSale.postalCode}
                            </address>
                        </div>
                        <div className="info">
                            <p>Ticket Amount</p>
                            <p>{amountToString(ticketSale.price)}</p>
                        </div>
                        <div className="info">
                            <p>Donation Amount</p>
                            <p>{amountToString(ticketSale.donationAmount)}</p>
                        </div>
                        <div className="info">
                            <p>Tax Receipt</p>
                            <p>{amountToString(ticketSale.taxReceiptAmount)}</p>
                        </div>
                        <div className="info">
                            <p>Sponsor</p>
                            <p>{ticketSale.sponsor}</p>
                        </div>
                        <div className="info">
                            <p>Notes</p>
                            <p>{ticketSale.notes}</p>
                        </div>
                        {ticketSale.paymentStatus === 1 && (
                            <button type="button" className="approvePayment" onClick={() => handleSendTickets()} disabled={HALoading}>
                                {/* Approve Payment */}
                                {!approveTimerStarted ? 'Send Tickets To Email' : (
                                    approveBuffer === 0 ? (HALoading ? 'Loading ...' : 'Confirm') : approveBuffer
                                )}
                            </button>
                        )}
                    </div>

                    <h2>Attendees</h2>
                    {galaAttendees.map((attendee) => (
                        <>
                            <div className="infoWrapper">
                                <div className="info">
                                    <p>{`#${attendee.ticketNum}`}</p>
                                    <p>{attendee.registered ? `${attendee.fName} ${attendee.lName}` : 'Not Registered'}</p>
                                </div>
                            </div>
                        </>
                    ))}

                    {ticketSale.paymentStatus === GalaTicketPaymentStatus.unpaid && (
                        <button type="button" className="approvePayment" onClick={() => handleApprove()} disabled={HALoading}>
                            {/* Approve Payment */}
                            {!approveTimerStarted ? 'Approve Payment' : (
                                approveBuffer === 0 ? (HALoading ? 'Loading ...' : 'Confirm') : approveBuffer
                            )}
                        </button>
                    )}
                </>
            )}

            {isEdit && (
                <div className="editContainer">
                    <div className="row">
                        <p>First Name</p>
                        <input value={fName} onChange={(e) => onChangeVal(e, setFName)} />
                    </div>
                    <div className="row">
                        <p>Last Name</p>
                        <input value={lName} onChange={(e) => onChangeVal(e, setLName)} />
                    </div>
                    <div className="row">
                        <p>Address</p>
                        <input value={address} onChange={(e) => onChangeVal(e, setAddress)} />
                    </div>
                    <div className="row">
                        <p>Postal Code</p>
                        <input value={postalCode} onChange={(e) => onChangeVal(e, setPostalCode)} />
                    </div>
                    <div className="row">
                        <p>City</p>
                        <input value={city} onChange={(e) => onChangeVal(e, setCity)} />
                    </div>
                    <div className="row">
                        <p>Province</p>
                        <input value={province} onChange={(e) => onChangeVal(e, setProvince)} />
                    </div>
                    <div className="row">
                        <p>Country</p>
                        <input value={country} onChange={(e) => onChangeVal(e, setCountry)} />
                    </div>
                    <div className="row">
                        <p>Email</p>
                        <input value={email} onChange={(e) => onChangeVal(e, setEmail)} />
                    </div>
                    <div className="row">
                        <p>Phone</p>
                        <input value={phone} onChange={(e) => onChangeVal(e, setPhone)} />
                    </div>
                    <div className="row">
                        <p>Sponsor</p>
                        <input value={sponsor} onChange={(e) => onChangeVal(e, setSponsor)} />
                    </div>
                </div>
            )}

        </ReactModal>
    );
};

export default TicketSaleModal;
