import { Config } from '../../Config';
import { IShiftPlanner } from '../../model/storeGroup/shiftPlanner/IShiftPlanner';
import { AxiosController, IResponseSchema } from '../AxiosController';
import { ToastController } from '../ToastController';
import { ShiftPlannerFields } from './ShiftPlannerFields';

interface ShiftPlannerResponse extends IResponseSchema {
    data: {
        shiftPlanner: IShiftPlanner,
    },
}

export class ShiftPlannerController extends AxiosController {
    public async getShiftPlanner(
        storeId: string,
        startTime: Date,
        endTime: Date,
    ) : Promise<IShiftPlanner> {
        const response : ShiftPlannerResponse = await this.axiosGET(
            `${Config.SERVER_URL}/shiftPlanner`,
            {
                [ShiftPlannerFields.storeId]: storeId,
                [ShiftPlannerFields.startTime]: startTime,
                [ShiftPlannerFields.endTime]: endTime,
            },
        );
        const shiftPlanner : IShiftPlanner = response.data.shiftPlanner;
        return shiftPlanner;
    }

    public async createShiftPlan(
        staffIds: string[],
        storeId: string,
        startTime: Date,
        endTime: Date,
        planStart: Date,
        planEnd: Date,
    ) : Promise<IShiftPlanner> {
        const response : ShiftPlannerResponse = await this.axiosPOST(
            `${Config.SERVER_URL}/shiftPlanner`,
            {
                [ShiftPlannerFields.staffIds]: staffIds,
                [ShiftPlannerFields.storeId]: storeId,
                [ShiftPlannerFields.startTime]: startTime,
                [ShiftPlannerFields.endTime]: endTime,
                [ShiftPlannerFields.planStart]: planStart,
                [ShiftPlannerFields.planEnd]: planEnd,
            },
        );
        // ToastController.success(`Created`);
        const shiftPlanner : IShiftPlanner = response.data.shiftPlanner;
        return shiftPlanner;
    }

    public async editShiftPlan(
        staffIds: string[],
        storeId: string,
        shiftId: string,
        startTime: Date,
        endTime: Date,
        planStart: Date | null,
        planEnd: Date | null,
    ) : Promise<IShiftPlanner> {
        const response : ShiftPlannerResponse = await this.axiosPUT(
            `${Config.SERVER_URL}/shiftPlanner`,
            {
                [ShiftPlannerFields.staffIds]: staffIds,
                [ShiftPlannerFields.storeId]: storeId,
                [ShiftPlannerFields.shiftId]: shiftId,
                [ShiftPlannerFields.startTime]: startTime,
                [ShiftPlannerFields.endTime]: endTime,
                [ShiftPlannerFields.planStart]: planStart,
                [ShiftPlannerFields.planEnd]: planEnd,
            },
        );
        const shiftPlanner : IShiftPlanner = response.data.shiftPlanner;
        return shiftPlanner;
    }

    public async sendShiftPlans(
        staffIds: string[],
        storeId: string,
        startTime: Date,
        endTime: Date,
    ) : Promise<IShiftPlanner> {
        const response : ShiftPlannerResponse = await this.axiosPOST(
            `${Config.SERVER_URL}/shiftPlanner/send`,
            {
                [ShiftPlannerFields.staffIds]: staffIds,
                [ShiftPlannerFields.storeId]: storeId,
                [ShiftPlannerFields.startTime]: startTime,
                [ShiftPlannerFields.endTime]: endTime,
            },
        );
        const shiftPlanner : IShiftPlanner = response.data.shiftPlanner;
        return shiftPlanner;
    }
}
