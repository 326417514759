export const amountToString = (amount: number) => {
    // if (amount === 0) return 'FREE';
    let retNum: number = 0;
    if (amount < 0) retNum = -Math.abs((amount / 100));
    else retNum = amount / 100;

    if (Number.isNaN(retNum) || retNum === undefined || retNum === null) retNum = 0;

    return `$${retNum.toFixed(2)}`;
};

// Used to calculate chart height, 100px buffer
export const calcChartDimensions = (arr: any[], size: number) => ({
    height: `${arr.length * size + 50}px`,
    width: '100%',
});

export const generateChartOptions = (
    chartTitle: string, handleClick?: (evt: any, element: any) => void,
) => (
    {
        indexAxis: 'y' as const,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: chartTitle,
            },
        },
        maintainAspectRatio: false,
        aspectRatio: 1,
        scaleShowValues: true,
        onClick: handleClick,
    }
);

export const msToTime = (duration: number) => {
    const seconds: number = Math.floor((duration / 1000) % 60);
    const minutes: number = Math.floor((duration / (1000 * 60)) % 60);
    const hours: number = Math.floor((duration / (1000 * 60 * 60)));

    const hoursStr: string = hours.toString().padStart(2, '0');
    const minutesStr: string = minutes.toString().padStart(2, '0');
    const secondsStr: string = seconds.toString().padStart(2, '0');

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
};
