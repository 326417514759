/* eslint-disable jsx-a11y/label-has-associated-control */
import i18next from 'i18next';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Factory } from '../../controllers/ControllerFactory';
import { StaffContext } from '../../controllers/Staff/StoreContext';
import { StoreContext } from '../../controllers/Store/StoreContext';
import { ToastController } from '../../controllers/ToastController';
import { IStoreStaff } from '../../model/storeGroup/store/storeStaff/IStoreStaff';
import Staff from './components/Staff/Staff';
import './StaffManagement.scss';

interface StaffManagementProps {

}

const StaffManagement: React.FC<StaffManagementProps> = () => {
    const { t, i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;
    const { staff } = useContext(StaffContext)!;
    const [storeStaffArr, setStoreStaffArr] = useState<IStoreStaff[]>([]);

    const [addingStaff, setAddingStaff] = useState(false);
    const [phone, setPhone] = useState('');

    const updatePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setPhone(val);
    };

    const staffController = Factory.getStaffController();
    const storeStaffController = Factory.StoreStaffController;

    const getStaffByPhone = async (tPhone: string) => {
        const staffResponse = await staffController.getByPhone(tPhone);
        return staffResponse;
    };

    const addStaffToStore = async (
        toAddStaffId: string,
    ) => {
        const storeStaffArrResponse = await storeStaffController.addStaffToStore(
            store._id, toAddStaffId, staff._id, store.storeRoles[0]._id, // TODO, add to super for SDC
        );

        setStoreStaffArr(storeStaffArrResponse.data.storeStaffs);
        ToastController.success('Added staff to store.');
    };

    const editStoreStaff = async (
        storeStaff: IStoreStaff,
        staffPin: string,
        color: string,
        isEmployed: boolean,
    ) => {
        const storeStaffArrResponse = await storeStaffController.editStoreStaff(
            store._id, storeStaff, staffPin, color, isEmployed,
        );
        setStoreStaffArr(storeStaffArrResponse.data.storeStaffs);
        ToastController.success('Editted staff');
    };

    const handleConfirm = async () => {
        const staffResp = await getStaffByPhone(phone);
        await addStaffToStore(staffResp.data.staff._id);
        setAddingStaff(false);
    };

    const handleCancel = () => {
        setAddingStaff(false);
        setPhone('');
    };

    const getStoreStaffArr = async () => {
        const staffArrResp = await storeStaffController.getStoreStaffs(
            store._id,
        );
        setStoreStaffArr(staffArrResp.data.storeStaffs);
    };

    useEffect(() => {
        getStoreStaffArr();
    }, []);

    return (
        <div className="staffManagementContainer dashboardScreen">
            <h2 className="screenTitle">Staff Management</h2>
            <div className="staffManagement">
                {storeStaffArr.filter((storeStaff) => storeStaff.isEmployed).map((storeStaff) => (
                    <Staff
                        storeStaff={storeStaff}
                        editStoreStaff={editStoreStaff}
                    />
                ))}
                <div className="addStaff">
                    {!addingStaff && (
                        <button
                            className="addStaffBtn"
                            onClick={() => setAddingStaff(true)}
                            type="button"
                        >
                            +
                        </button>
                    )}
                    {addingStaff && (
                        <div className="addStaffInputWrapper">
                            <input
                                placeholder="Staff phone"
                                onChange={updatePhone}
                                value={phone}
                            />
                            <button
                                type="button"
                                className="cancel"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="confirm"
                                onClick={handleConfirm}
                            >
                                Confirm
                            </button>
                        </div>
                    )}
                    <h2 className="pastStaff">Past Staff</h2>
                    {storeStaffArr.filter((storeStaff) => !storeStaff.isEmployed).map((storeStaff) => (
                        <Staff
                            storeStaff={storeStaff}
                            editStoreStaff={editStoreStaff}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StaffManagement;
