import { Config } from '../../Config';
import { AggregateReportResponse } from '../../model/analyticsGroup/IAggregateReport';
import { SeshReportResponse } from '../../model/analyticsGroup/seshReport/ISeshReport';
import { AxiosController } from '../AxiosController';

export class AnalyticsContoller extends AxiosController {
    public async getAggregateReport(
        storeId: string,
        startTime: Date,
        endTime: Date,
    ) : Promise<AggregateReportResponse> {
        const resp = await this.axiosGET<AggregateReportResponse>(
            `${Config.SERVER_URL}/storeReport/aggregate`, {
                storeId: storeId,
                startTime: startTime,
                endTime: endTime,
            },
        );
        return resp;
    }

    public async getSeshReport(
        storeId: string,
        storeSeshId: string,
    ) : Promise<SeshReportResponse> {
        const resp = await this.axiosGET<SeshReportResponse>(
            `${Config.SERVER_URL}/storeReport/storeSesh`, {
                storeId: storeId,
                storeSeshId: storeSeshId,
            },
        );
        return resp;
    }
}
