/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StaffContext } from '../../../../controllers/Staff/StoreContext';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { IStoreStaff } from '../../../../model/storeGroup/store/storeStaff/IStoreStaff';
import './Staff.scss';

interface StaffProps {
    storeStaff: IStoreStaff;
    editStoreStaff: (
        storeStaff: IStoreStaff,
        staffPin: string,
        color: string,
        isEmployed: boolean,
    ) => Promise<void>;
}

const Staff: React.FC<StaffProps> = ({
    storeStaff, editStoreStaff,
}) => {
    const { t, i18n } = useTranslation();

    const [pin, setPin] = useState(storeStaff.staffPin);
    const [color, setColor] = useState(storeStaff.color);
    const [hiddenPin, setHiddenPin] = useState(true);
    const [changingPin, setChangingPin] = useState(false);

    // let timer;
    const [timer, setTimer] = useState<any>();

    const renderPin = () => {
        if (hiddenPin) return '****';
        return storeStaff.staffPin;
    };

    const handleColorChange = async () => {
        await editStoreStaff(
            storeStaff,
            storeStaff.staffPin,
            color,
            storeStaff.isEmployed,
        );
    };

    const updateColor = async (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const val = e.target.value;
        clearInterval(timer);
        setTimer(setInterval(() => {
            if (val !== color) {
                setColor(val);
            }
        }, 750));
    };

    useEffect(() => {
        if (timer !== undefined) {
            clearInterval(timer);
            handleColorChange();
        }
    }, [color]);

    const handlePinChange = async () => {
        await editStoreStaff(
            storeStaff,
            pin,
            storeStaff.color,
            storeStaff.isEmployed,
        );
        setChangingPin(false);
    };

    const handleRemoveStaff = async () => {
        await editStoreStaff(
            storeStaff,
            storeStaff.staffPin,
            storeStaff.color,
            false,
        );
    };

    const handleAddStaff = async () => {
        await editStoreStaff(
            storeStaff,
            storeStaff.staffPin,
            storeStaff.color,
            true,
        );
    };

    const handleCancel = () => {
        setPin(storeStaff.staffPin);
        setChangingPin(false);
    };

    const updatePin = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if (val.length > 4) return;
        setPin(val);
    };

    return (
        <div className="staffCard">
            <h3>
                {storeStaff.isEmployed && (
                    <input
                        value={color}
                        type="color"
                        onChange={updateColor}
                        disabled={!storeStaff.isEmployed}
                    />
                )}
                {`${storeStaff.fName} ${storeStaff.lName ? storeStaff.lName : ''}`}
            </h3>
            {storeStaff.isEmployed && (
                <p>
                    {!changingPin && (
                        <>
                            <span>{renderPin()}</span>
                            <button
                                type="button"
                                onClick={() => setHiddenPin(!hiddenPin)}
                                className={hiddenPin ? '' : 'active'}
                            >
                                {/* Show/Hide */}
                                {hiddenPin ? 'Show' : 'Hide'}
                            </button>
                        </>
                    )}

                    {changingPin && (
                        <input value={pin} onChange={updatePin} />
                    )}
                </p>
            )}
            {storeStaff.isEmployed && (
                <div className="actions">
                    {!changingPin && (
                        <button
                            type="button"
                            onClick={() => setChangingPin(!changingPin)}
                            className="changePin"
                        >
                            Change Pin
                        </button>
                    )}

                    {changingPin && (
                        <>
                            <button type="button" className="confirm" onClick={handlePinChange}>Confirm</button>
                            <button type="button" className="cancel" onClick={handleCancel}>Cancel</button>
                        </>
                    )}
                </div>
            )}
            {storeStaff.isEmployed && (
                <button className="remove" type="button" onClick={handleRemoveStaff}>
                    X
                </button>
            )}
            {!storeStaff.isEmployed && (
                <button className="add" type="button" onClick={handleAddStaff}>
                    O
                </button>
            )}
        </div>
    );
};

export default Staff;
