import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import './Spinner.scss';

const Spinner: React.FC = () => (
    <div className="spinnerContainer">
        <Loader
            type="Oval"
            color="red"
            height={200}
            width={200}
        />
    </div>

);

export default Spinner;
