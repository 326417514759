import React, { useEffect, useState } from 'react';
import { Factory } from '../../../controllers/ControllerFactory';
import { IGalaTicket } from '../../../model/galaGroup/gala/galaTicket/iGalaTicket';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { IGalaAttendee } from '../../../model/galaGroup/galaAttendee/iGalaAttendee';
import { Dict } from '../../../model/helpers/Dict';
import AttendeeModal from './AttendeeModal';
import './GalaAttendeeViewer.scss';

enum RegistStatus {
    registered = 'Registered',
    notRegistered = 'Not Registered',
    all = 'All'
}

interface GalaAttendeeViewerProps{
    currGala: IGala;
}

export const GalaAttendeeViewer: React.FC<GalaAttendeeViewerProps> = ({
    currGala,
}) => {
    const [attendees, setAttendees] = useState<IGalaAttendee[]>([]);
    const getAttendees = async () => {
        const tAttendees = await Factory.GalaController.getGalaAttendeesByGalaId(currGala._id);
        tAttendees.sort((a, b) => a.ticketNum - b.ticketNum);
        setAttendees(tAttendees);
    };
    useEffect(() => {
        getAttendees();
    }, []);

    const [registStatus, setRegistStatus] = useState<RegistStatus>(RegistStatus.all);
    const [ticketTypeId, setTicketType] = useState<string | null>(null);

    const [attendee, setAttendee] = useState<IGalaAttendee | null>(null);
    const [attendeeModalOpen, setAttendeeModalOpen] = useState(false);
    const openModal = (tAttendee: IGalaAttendee) => {
        setAttendee(tAttendee);
        setAttendeeModalOpen(true);
    };
    const closeModal = () => {
        setAttendee(null);
        setAttendeeModalOpen(false);
    };

    const filterAttendees = (ticketId: string | null) => {
        if (registStatus === RegistStatus.registered) {
            return attendees.filter((tAttendee) => {
                if (ticketId === null) return tAttendee.registered;
                return tAttendee.registered && tAttendee.galaTicketId === ticketId;
            });
        }
        if (registStatus === RegistStatus.notRegistered) {
            return attendees.filter((tAttendee) => {
                if (ticketId === null) return !tAttendee.registered;
                return !tAttendee.registered && tAttendee.galaTicketId === ticketId;
            });
        }
        if (ticketId !== null) return attendees.filter((tAttendee) => tAttendee.galaTicketId === ticketId);
        return attendees;
    };

    const getTicketName = (ticketId: string) => {
        const ticket : IGalaTicket | undefined = currGala.galaTickets.find((tTicket) => tTicket._id === ticketId);
        if (!ticket) return 'Ticket Type Not Found';
        return ticket.name.en;
    };

    const filterMealCounts = () => {
        const bin : Dict<any> = {};
        attendees.forEach((tAtt) => {
            const tCount = bin[tAtt.mealChoice] || 0;
            bin[tAtt.mealChoice] = tCount + 1;
        });
        return bin;
    };

    return (
        <div className="galaAttendeeContainer">
            <AttendeeModal
                open={attendeeModalOpen}
                closeModal={closeModal}
                attendee={attendee}
                refresh={getAttendees}
            />
            <h3>Meal Choice Counts</h3>
            <div className="mealRow">
                {Object.entries(filterMealCounts()).map(([key, val]) => (
                    <div className="pair">
                        <div>{`${key}`}</div>
                        <div>{`${val}`}</div>
                    </div>
                ))}
                <div />
                {/* <div className="contact">Phone</div>
                <div className="contact">Email</div> */}
            </div>
            <div className="attendeeStatus">
                <h3>{`Registration Status : ${registStatus}`}</h3>
                <div className="row">
                    <button
                        type="button"
                        onClick={() => setRegistStatus(RegistStatus.all)}
                        className={registStatus === RegistStatus.all ? 'active' : ''}
                    >
                        {`${RegistStatus.all} Attendees: ${attendees.length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setRegistStatus(RegistStatus.registered)}
                        className={registStatus === RegistStatus.registered ? 'active' : ''}
                    >
                        {`${RegistStatus.registered} Attendees: ${attendees.filter((tAttendee) => tAttendee.registered).length}`}
                    </button>
                    <button
                        type="button"
                        onClick={() => setRegistStatus(RegistStatus.notRegistered)}
                        className={registStatus === RegistStatus.notRegistered ? 'active' : ''}
                    >
                        {`${RegistStatus.notRegistered} Attendees: ${attendees.filter((tAttendee) => !tAttendee.registered).length}`}
                    </button>
                </div>
            </div>
            <div className="ticketType">
                <h3>Select Ticket Type</h3>
                <div className="row">
                    <button
                        type="button"
                        onClick={() => setTicketType(null)}
                    >
                        {`All Types: ${filterAttendees(null).length}`}
                    </button>
                    {currGala.galaTickets.map((currTicket : IGalaTicket) => (
                        <button
                            type="button"
                            onClick={() => setTicketType(currTicket._id)}
                            className={ticketTypeId === currTicket._id ? 'active' : ''}
                        >
                            {`${currTicket.name.en}: ${filterAttendees(currTicket._id).length}`}
                        </button>
                    ))}
                </div>
            </div>
            <div className="headerRow">
                <div className="status">Table Name</div>
                <div className="ticketNum">Ticket #</div>
                <div className="ticketTier">Tier</div>
                <div className="name">Name</div>
                <div className="name">Access Code</div>
                <div />
                {/* <div className="contact">Phone</div>
                <div className="contact">Email</div> */}
            </div>
            {filterAttendees(ticketTypeId).map((tAttendee: IGalaAttendee) => (
                <div className="attendeeRow">
                    <div className="status">{tAttendee.tableName || 'Unassigned'}</div>
                    <div className="ticketNum">{tAttendee.ticketNum}</div>
                    <div className="ticketTier">{getTicketName(tAttendee.galaTicketId)}</div>
                    <div className="name">{tAttendee.registered ? `${tAttendee.fName} ${tAttendee.lName}` : 'N/A'}</div>
                    <div className="name">{tAttendee.accessCode.split('%')[tAttendee.accessCode.split('%').length - 1]}</div>
                    <div><button type="button" onClick={() => openModal(tAttendee)}>View</button></div>
                    {/* <div className="contact">{attendee.phone}</div>
                    <div className="contact">{attendee.email}</div> */}
                </div>
            ))}
        </div>
    );
};
