import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './OptionEditor.scss';
import AddBar from '../components/AddBar/AddBar';
import { StoreContext } from '../../../controllers/Store/StoreContext';
import { IOption } from '../../../model/menuGroup/option/IOption';
import AddEditOption from './AddEditOption/AddEditOption';
import OptionCard from './OptionCard/OptionCard';

interface OptionEditorProps{}

const OptionEditor: React.FC<OptionEditorProps> = () => {
    const { t } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;

    const [currOption, setCurrOption] = useState<IOption | null>(null);

    const [optionModalOpen, setOptionModalOpen] = useState(false);
    const openOptionModal = () => setOptionModalOpen(true);
    const closeOptionModal = () => setOptionModalOpen(false);

    const generateOptions = () => store.options.map((opt) => (
        // <div aria-hidden onClick={() => { setCurrOption(opt); openOptionModal(); }}>
        //     {' '}
        //     {opt.mName}
        //     {' '}
        // </div>
        <OptionCard
            option={opt}
            selectOption={() => {
                setCurrOption(opt);
                openOptionModal();
            }}
        />
    ));
    return (

        <div className="optionEditorContainer dashboardScreen">
            <AddEditOption
                option={currOption}
                open={optionModalOpen}
                closeModal={closeOptionModal}
            />
            <header>
                <h2 className="screenTitle">
                    Options Editor
                </h2>
            </header>
            <AddBar
                handleClick={() => {
                    setCurrOption(null);
                    openOptionModal();
                }}
                text="Add Option"
            />
            <div className="optionContainer">
                {generateOptions()}
            </div>
        </div>

    );
};

export default OptionEditor;
