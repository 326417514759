import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select';
import { Factory } from '../../../controllers/ControllerFactory';
import { IGalaTicket } from '../../../model/galaGroup/gala/galaTicket/iGalaTicket';
import { IGala } from '../../../model/galaGroup/gala/iGala';
import { IGalaAttendee } from '../../../model/galaGroup/galaAttendee/iGalaAttendee';
import { IGalaTable } from '../../../model/galaGroup/galaTable/IGalaTable';
import './GalaTableModal.scss';

interface GalaTableModalProps {
    open: boolean;
    closeModal: () => void;
    galaTable: IGalaTable | null;
    currGala: IGala;
    refresh: () => Promise<void>;
}

const GalaTableModal: React.FC<GalaTableModalProps> = ({
    open, closeModal, galaTable, currGala, refresh,
}) => {
    const galaController = Factory.GalaController;

    const [isEdit, setIsEdit] = useState(false);

    const [tableName, setTableName] = useState('');
    const [notes, setNotes] = useState('');
    const [galaAttendeeIds, setGalaAttendeeIds] = useState<string[]>([]);

    const onChangeVal = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, fn: React.Dispatch<React.SetStateAction<string>>) => {
        const val = e.target.value;
        fn(val);
    };

    // Get All Attendees
    const [attendees, setAttendees] = useState<IGalaAttendee[]>([]);
    const getAttendees = async () => {
        const tAttendees = await Factory.GalaController.getGalaAttendeesByGalaId(currGala._id);
        tAttendees.sort((a, b) => a.ticketNum - b.ticketNum);
        setAttendees(tAttendees);
    };

    useEffect(() => {
        getAttendees();
    }, []);

    const attendeeList = attendees.map((tAttendee: IGalaAttendee) => ({
        value: tAttendee._id,
        label: `${tAttendee.ticketNum}: ${tAttendee.fName} ${tAttendee.lName} - ${tAttendee.tableName || 'UnAssigned'}`,
    }));

    const defaultValue = attendees.filter((tAttendee: IGalaAttendee) => galaAttendeeIds.includes(tAttendee._id))
        .map((tAttendee: IGalaAttendee) => ({
            value: tAttendee._id,
            label: `${tAttendee.ticketNum}: ${tAttendee.fName} ${tAttendee.lName}`,
        }));

    const editSave = async () => {
        if (isEdit) {
            // save
            if (galaTable === null) {
                await galaController.createGalaTable(
                    tableName,
                    currGala._id,
                    galaAttendeeIds,
                    notes,
                );
                setIsEdit(false);
                await refresh();
                closeModal();
                return;
            }
            await galaController.editGalaTable(
                galaTable._id,
                tableName,
                galaAttendeeIds,
                notes,
            );
            setIsEdit(false);
            await refresh();
            closeModal();
            return;
        }
        setIsEdit(true);
    };

    const filterAttendees = () => {
        if (galaTable === null) return [];
        return attendees.filter((tAtt) => galaTable.galaAttendeeIds.includes(tAtt._id)).sort((ta, tb) => ta.ticketNum - tb.ticketNum);
    };

    useEffect(() => {
        if (galaTable !== null) {
            getAttendees();
            setGalaAttendeeIds(filterAttendees().map((att) => att._id));
        } else setGalaAttendeeIds([]);
    }, [galaTable]);

    useEffect(() => {
        if (galaTable !== null) {
            setTableName(galaTable.tableName);
            setNotes(galaTable.notes || '');
            setGalaAttendeeIds(galaTable.galaAttendeeIds);
            setIsEdit(false);
        } else {
            setTableName('');
            setNotes('');
            setGalaAttendeeIds([]);
            setIsEdit(true);
        }
    }, [open]);

    const getTicketName = (ticketId: string) => {
        const ticket : IGalaTicket | undefined = currGala.galaTickets.find((tTicket) => tTicket._id === ticketId);
        if (!ticket) return 'Ticket Type Not Found';
        return ticket.name.en;
    };

    const changeGalaAttIds = (galaAttIds: any[]) => {
        const tmpGalaAttIds = galaAttIds.map((multiValue) => multiValue.value);
        setGalaAttendeeIds(tmpGalaAttIds);
    };

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="galaTableModal"
            overlayClassName="optionStatModalOverlay"
        >
            <header>
                <h2>
                    {`${galaTable === null ? 'Create New Table' : `Editing ${galaTable.tableName}`}`}
                </h2>
                <button type="button" onClick={editSave}>
                    {isEdit ? 'Save' : 'Edit'}
                </button>
            </header>
            {(!isEdit && galaTable !== null) && (
                <>
                    <div className="infoWrapper">
                        <div className="info">
                            <p>Table Name</p>
                            <p>{galaTable.tableName}</p>
                        </div>
                        <div className="info">
                            <p>Number Seated</p>
                            <p>{galaTable.galaAttendeeIds.length}</p>
                        </div>
                        <div className="info">
                            <p>Notes</p>
                            <p>{galaTable.notes}</p>
                        </div>
                    </div>
                    <h2>{`Seating Information : ${galaAttendeeIds.length}`}</h2>
                    {
                        filterAttendees().map((tAttendee) => (
                            <div className="seatingWrapper">
                                <div className="seat">
                                    <p>Name</p>
                                    <p>{`${tAttendee.fName} ${tAttendee.lName}`}</p>
                                </div>
                                <div className="seat">
                                    <p>Ticket Number</p>
                                    <p>{tAttendee.ticketNum}</p>
                                </div>
                                <div className="seat">
                                    <p>Ticket Tier</p>
                                    <p>{getTicketName(tAttendee.galaTicketId)}</p>
                                </div>
                                <div className="seat">
                                    <p>Meal Choice</p>
                                    <p>{tAttendee.mealChoice || 'Not Registered Yet'}</p>
                                </div>
                                {/* <div><button type="button" onClick={() => openAttendeeModal(tAttendee)}>View</button></div> */}
                            </div>
                        ))
                    }
                </>
            )}

            {isEdit && (
                <div className="editContainer">
                    <div className="row">
                        <p>Table Name</p>
                        <input value={tableName} onChange={(e) => onChangeVal(e, setTableName)} />
                    </div>
                    <div className="row">
                        <p>Notes</p>
                        <input value={notes} onChange={(e) => onChangeVal(e, setNotes)} />
                    </div>
                    <div className="row">
                        <div className="inputBox">
                            <p>{`Attendees: ${galaAttendeeIds.length}`}</p>
                            <Select
                                isMulti
                                className="attendeeSelector"
                                options={attendeeList}
                                defaultValue={defaultValue}
                                onChange={(e: any) => changeGalaAttIds(e)} // TODO
                            />
                        </div>
                    </div>
                </div>
            )}
        </ReactModal>
    );
};

export default GalaTableModal;
