import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Factory } from '../../../controllers/ControllerFactory';
import { StoreContext } from '../../../controllers/Store/StoreContext';
import { IIngrLogMeta } from '../../../model/IngrGroup/IngrLog/IIngrLog';
import { IRawMatLogMeta } from '../../../model/IngrGroup/RawMatLog/IRawMatLog';
import { IStore } from '../../../model/storeGroup/store/IStore';
import AddBar from '../components/AddBar/AddBar';
import AddEditIngrLog from './AddEditIngrLog/AddEditIngrLog';
import IngrAdjModal from './ingrAdjModal/ingrAdjModal';
import IngrLogCard from './IngrCard/IngrLogCard';
import './IngrLogEditor.scss';
import IngrReportModal from './ingrReportModal/ingrReportModal';
import IngrStockModal from './ingrStockModal/ingrStockModal';

interface IngrLogEditorProps {
    store?: IStore;
}

const IngrLogEditor: React.FC<IngrLogEditorProps> = ({
    store,
}) => {
    const { t } = useTranslation();

    // Get store context
    const { store: storeFromContext } = useContext(StoreContext)!;

    const getStore = () => {
        if (store !== undefined) return store;
        return storeFromContext;
    };
    // Get ingr log
    const [ingrLogMetas, setIngrLogMetas] = useState<IIngrLogMeta[]>([]);
    const [rawMatLogMetas, setRawMatLogMetas] = useState<IRawMatLogMeta[]>([]);

    useEffect(() => {
        const getIngrLogMetas = async () => {
            const metas : IIngrLogMeta[] = await Factory.IngrLogController.getIngrLogMetas(
                getStore()._id,
            );
            setIngrLogMetas(metas);
        };
        const getRawMatLogMetas = async () => {
            const rawMatLogMetasResp = await Factory.RawMatLogController.getRawMatLogMetas(
                getStore()._id,
            );
            setRawMatLogMetas(rawMatLogMetasResp.data.rawMatLogMetas);
        };
        getIngrLogMetas();
        getRawMatLogMetas();
    }, []);

    const [currIngrLog, setCurrIngrLog] = useState<IIngrLogMeta | null>(null);

    const [addEditIngrOpen, setAddEditIngrOpen] = useState(false);
    const openAddEditIngr = () => setAddEditIngrOpen(true);
    const closeAddEditIngr = () => setAddEditIngrOpen(false);

    const [ingrStockModalOpen, setIngrStockModalOpen] = useState(false);
    const openIngrStockModal = () => setIngrStockModalOpen(true);
    const closeIngrStockModal = () => setIngrStockModalOpen(false);

    const [ingrAdjModalOpen, setIngrAdjModalOpen] = useState(false);
    const openIngrAdjModal = () => setIngrAdjModalOpen(true);
    const closeIngrAdjModal = () => setIngrAdjModalOpen(false);

    const [ingrReportModalOpen, setIngrReportModalOpen] = useState(false);
    const openIngrReportModal = () => setIngrReportModalOpen(true);
    const closeIngrReportModal = () => setIngrReportModalOpen(false);

    const generateIngrLogCards = () => {
        if (ingrLogMetas === null) return <></>;
        return ingrLogMetas.map((meta: IIngrLogMeta) => (
            <IngrLogCard
                ingrLogMeta={meta}
                selectIngrLog={() => {
                    setCurrIngrLog(meta);
                    openAddEditIngr();
                }}
                openIngrStockModal={() => {
                    setCurrIngrLog(meta);
                    openIngrStockModal();
                }}
                openIngrAdjModal={() => {
                    setCurrIngrLog(meta);
                    openIngrAdjModal();
                }}
                openIngrReportModal={() => {
                    setCurrIngrLog(meta);
                    openIngrReportModal();
                }}
            />
        ));
    };

    return (

        <div className="taxEditorContainer dashboardScreen">
            <AddEditIngrLog
                ingrLogMeta={currIngrLog}
                open={addEditIngrOpen}
                rawMatLogMetas={rawMatLogMetas}
                setIngrLogMetas={setIngrLogMetas}
                closeModal={closeAddEditIngr}
            />
            <IngrStockModal
                ingrLogMeta={currIngrLog}
                open={ingrStockModalOpen}
                setIngrLogMetas={setIngrLogMetas}
                closeModal={closeIngrStockModal}
            />
            <IngrAdjModal
                ingrLogMeta={currIngrLog}
                open={ingrAdjModalOpen}
                setIngrLogMetas={setIngrLogMetas}
                closeModal={closeIngrAdjModal}
            />
            <IngrReportModal
                ingrLogMeta={currIngrLog}
                open={ingrReportModalOpen}
                closeModal={closeIngrReportModal}
            />
            <header>
                <h2 className="screenTitle">
                    Ingrendient Editor
                </h2>
            </header>
            <AddBar
                handleClick={() => {
                    setCurrIngrLog(null);
                    openAddEditIngr();
                }}
                text="Add New Ingredient"
            />
            <div className="taxContainer">
                {generateIngrLogCards()}
            </div>
        </div>

    );
};

export default IngrLogEditor;
