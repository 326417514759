import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import peopleImg from '../../assets/people.svg';
import analyticsImg from '../../assets/analytics.svg';
import shiftImg from '../../assets/shift.svg';
import transactionImg from '../../assets/transaction.svg';
import floorplanImg from '../../assets/floorplan.svg';
import corpImg from '../../assets/corp.svg';
import rawImg from '../../assets/raw.svg';
import menuImg from '../../assets/menu.svg';
import itemsImg from '../../assets/items.svg';
import optionsImg from '../../assets/options.svg';

import TabbarLink from './components/TabbarLink';

import './Tabbar.scss';
import { TabbarLocale } from './TabbarLocale';
import { StaffContext } from '../../controllers/Staff/StoreContext';

interface TabbarProps{
    open: boolean
    openFloorplan: () => void;
}

const Tabbar: React.FC<TabbarProps> = ({
    open, openFloorplan,
}) => {
    const { t, i18n } = useTranslation();

    const { staff } = useContext(StaffContext)!;

    const history = useHistory();
    const [currentLink, setCurrentLink] = useState(history.location.pathname);
    const changeLink = (path: string) => {
        setCurrentLink(path);
        history.push(path);
    };
    const handleLogout = () => {
        localStorage.removeItem('token');
        history.push('/login');
    };
    return (
        <div className={open ? 'tabbarContainer open' : 'tabbarContainer'}>
            <div className="tabbarLinksContainer">
                <h3>{t(TabbarLocale.dashboard)}</h3>
                <TabbarLink
                    current={currentLink}
                    onClick={changeLink}
                    path="/dashboard"
                    title={t(TabbarLocale.analytics)}
                    img={analyticsImg}
                    isSubTab={false}
                />
                <TabbarLink
                    current={currentLink}
                    onClick={changeLink}
                    path="/dashboard/shift"
                    title={t(TabbarLocale.shiftManagement)}
                    img={shiftImg}
                    isSubTab={false}
                />
                <TabbarLink
                    current={currentLink}
                    onClick={changeLink}
                    path="/dashboard/staff"
                    title={t(TabbarLocale.staffManagement)}
                    img={peopleImg}
                    isSubTab={false}
                />
                <TabbarLink
                    current={currentLink}
                    onClick={changeLink}
                    path="/dashboard/gala"
                    title="Gala Management"
                    img={transactionImg}
                    isSubTab={false}
                />
            </div>
            {staff.staffCorpRefs.length !== 0 && (
                <div className="tabbarLinksContainer">
                    <h3>Corporation</h3>
                    <TabbarLink
                        current={currentLink}
                        onClick={changeLink}
                        path="/dashboard/corp/all"
                        title="View All Stores"
                        img={corpImg}
                        isSubTab={false}
                    />
                    <TabbarLink
                        current={currentLink}
                        onClick={changeLink}
                        path="/dashboard/corp/raw"
                        title="Raw Materials"
                        img={rawImg}
                        isSubTab={false}
                    />
                </div>
            )}
            <div className="tabbarLinksContainer">
                <h3>{t(TabbarLocale.editingTools)}</h3>
                <TabbarLink
                    current={currentLink}
                    onClick={changeLink}
                    path="/dashboard/menu"
                    title={t(TabbarLocale.menuEditor)}
                    img={menuImg}
                    isSubTab={false}
                />
                <div>
                    <TabbarLink
                        current={currentLink}
                        onClick={changeLink}
                        path="/dashboard/menu/items"
                        title="Items"
                        img={itemsImg}
                        isSubTab
                    />
                    <TabbarLink
                        current={currentLink}
                        onClick={changeLink}
                        path="/dashboard/menu/options"
                        title="Options"
                        img={optionsImg}
                        isSubTab
                    />
                    <TabbarLink
                        current={currentLink}
                        onClick={changeLink}
                        path="/dashboard/menu/combos"
                        title="Combos"
                        img={optionsImg}
                        isSubTab
                    />
                    <TabbarLink
                        current={currentLink}
                        onClick={changeLink}
                        path="/dashboard/menu/taxes"
                        title="Taxes"
                        img={optionsImg}
                        isSubTab
                    />
                    <TabbarLink
                        current={currentLink}
                        onClick={changeLink}
                        path="/dashboard/menu/ingredients"
                        title="Ingredients"
                        img={optionsImg}
                        isSubTab
                    />
                    <TabbarLink
                        current={currentLink}
                        onClick={changeLink}
                        path="/dashboard/menu/raw"
                        title="Raw Materials"
                        img={rawImg}
                        isSubTab
                    />
                </div>
                {/* <div
                    className="tabbarLink"
                    onClick={openFloorplan}
                    aria-hidden
                >
                    <img src={floorplanImg} alt="tabbar icon" />
                    {t(TabbarLocale.floorplanEditor)}
                </div> */}
                <button
                    className="logout"
                    onClick={handleLogout}
                    type="button"
                >
                    Logout
                </button>
            </div>
        </div>
    );
};

export default Tabbar;
