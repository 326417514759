import React, { useState } from 'react';
import {
    HashRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, Flip } from 'react-toastify';

import Dashboard from './Dashboard/Dashboard';
import './App.scss';
import Login from './Login/Login';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

import './locales/i18n';

const App: React.FC = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    // const { t, i18n } = useTranslation();

    // const changeLanguage = (lang: string) => {
    //     i18n.changeLanguage(lang);
    // };

    return (
        <div className="App">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                transition={Flip}
                limit={1}
                closeOnClick
                draggable
            />
            <Router>
                <Switch>

                    <ProtectedRoute path="/dashboard" Component={Dashboard} />
                    <Route path="/login">
                        <Login />
                    </Route>
                    <ProtectedRoute path="/" Component={Dashboard} />

                </Switch>
            </Router>
        </div>
    );
};

export default App;
