import React from 'react';
import ReactModal from 'react-modal';
import { IGalaDonation } from '../../../model/galaGroup/galaDonation/iGalaDonation';
import { amountToString } from '../../../utils/global';
import './GalaDonationModal.scss';

interface GalaDonationModalProps {
    open: boolean;
    closeModal: () => void;
    galaDonation: IGalaDonation | null;
}

const GalaDonationModal: React.FC<GalaDonationModalProps> = ({
    open, closeModal, galaDonation,
}) => {
    if (galaDonation === null) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="galaDonationModal"
            overlayClassName="optionStatModalOverlay"
        >
            <header>
                <h2>
                    {`Donation By ${galaDonation.fName} ${galaDonation.lName}`}
                </h2>
            </header>
            <div className="infoWrapper">
                <div className="info">
                    <p>Amount</p>
                    <p>{amountToString(galaDonation.amount)}</p>
                </div>
                <div className="info">
                    <p>Payment Type</p>
                    <p>{galaDonation.paymentType}</p>
                </div>
                <div className="info">
                    <p>Phone</p>
                    <p>{galaDonation.phone}</p>
                </div>
                <div className="info">
                    <p>Email</p>
                    <p>{galaDonation.email}</p>
                </div>
                <div className="info">
                    <p>Address</p>
                    <address>
                        {galaDonation.address}
                        {' '}
                        <br />
                        {galaDonation.city}
                        ,
                        {' '}
                        {galaDonation.province}
                        {' '}
                        <br />
                        {galaDonation.country}
                        ,
                        {' '}
                        {galaDonation.postalCode}
                    </address>
                </div>
                <div className="info">
                    <p>Notes</p>
                    <p>{galaDonation.notes}</p>
                </div>
                <div className="info">
                    <p>Time Stamp</p>
                    <p>{new Date(galaDonation.createdAt).toLocaleString()}</p>
                </div>
            </div>
        </ReactModal>
    );
};

export default GalaDonationModal;
