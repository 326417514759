/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../../../model/helpers/Dict';
import { IIngrLogMeta } from '../../../../../../model/IngrGroup/IngrLog/IIngrLog';
import { MeasurementEnum } from '../../../../../../model/IngrGroup/IngrLog/MeasurementEnum';
import { IItem } from '../../../../../../model/menuGroup/item/IItem';
import { IItemTypes } from '../../../../../../model/menuGroup/item/IItemTypes';
import IngredientSelector from './IngredientSelector/IngredientSelector';
import './ItemIngrModule.scss';

interface ItemIngrModuleProps {
    ingrLogMetas: IIngrLogMeta[];
    itemIngr: Dict<number>;

    updateItemIngr: (ingrMap: Dict<number>) => void;
}

const ItemIngrModule: React.FC<ItemIngrModuleProps> = ({
    ingrLogMetas, itemIngr, updateItemIngr,
}) => {
    const { t, i18n } = useTranslation();
    const { store } = useContext(StoreContext)!;

    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const getIngr = (ingrId: string) => {
        const target = ingrLogMetas.find((iLM) => iLM._id === ingrId);
        if (target === undefined) throw new Error('[generate] Cannot find ingr');
        return target;
    };

    return (

        <div className="subItems">
            <IngredientSelector
                open={modalOpen}
                closeModal={closeModal}
                updateItemIngr={updateItemIngr}
                ingrLogMetas={ingrLogMetas}
                itemIngrMap={itemIngr}
            />
            <p>Ingredients</p>

            <div className="ingrElems">
                {Object.keys(itemIngr).map((ingrId) => {
                    const ingr = getIngr(ingrId);
                    const val = itemIngr[ingrId];
                    return (
                        <div className="ingr">
                            <div className="ingrName">
                                {ingr.name[i18n.language]}
                            </div>
                            <div className="ingrVal">
                                {val}
                            </div>
                            <p>{ingr.unitOfM}</p>
                        </div>
                    );
                })}
            </div>

            <button className="addSubItem" type="button" onClick={openModal}>
                Edit Ingredients
            </button>

        </div>

    );
};

export default ItemIngrModule;
