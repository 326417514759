import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IStore, IStoreMeta } from '../../../../../model/storeGroup/store/IStore';
import './StoreMetaCard.scss';

interface StoreMetaCardProps {
    storeMeta: IStoreMeta;
    selectStore: (storeId: string) => void;
}

const StoreMetaCard:React.FC<StoreMetaCardProps> = ({
    storeMeta, selectStore,
}) => {
    const { i18n } = useTranslation();

    const handleViewClick = () => selectStore(storeMeta._id);

    return (
        <div className="storeMetaCardContainer">
            <h2>{storeMeta.name[i18n.language]}</h2>
            <p>{storeMeta.phone}</p>
            <p>{storeMeta.email}</p>
            <button type="button" onClick={handleViewClick}>View</button>
        </div>
    );
};

export default StoreMetaCard;
