import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { IShiftStat } from '../../../../model/analyticsGroup/seshReport/shiftStat/IShiftStat';
import { IStaffReport } from '../../../../model/analyticsGroup/staffReport/IStaffReport';
import { amountToString } from '../../../../utils/global';
import './StaffStatModal.scss';

interface StaffStatModalProps {
    open: boolean;
    staffReport: IStaffReport | null;
    closeModal: () => void;
}

const StaffStatModal: React.FC<StaffStatModalProps> = ({
    open, closeModal, staffReport,
}) => {
    const { i18n } = useTranslation();

    const getShiftStartEnd = (start: Date, end: Date | null) => {
        const dateOption: Intl.DateTimeFormatOptions = {
            hour: 'numeric', minute: 'numeric', month: 'short', day: 'numeric', weekday: 'short',
        };
        const endOption: Intl.DateTimeFormatOptions = {
            hour: 'numeric', minute: 'numeric',
        };
        const shiftStart = new Date(start).toLocaleTimeString('en-US', dateOption);
        if (end === null) return `${shiftStart} - On Shift`;
        const shiftEnd = new Date(end).toLocaleTimeString('en-US', endOption);
        return `${shiftStart} - ${shiftEnd}`;
    };

    const msToDuration = (totalMs: number) => {
        const toTwoDigits = (x: number) => {
            const s = x.toString();
            if (s.length === 2) return s;
            return `0${s}`;
        };
        const ms = totalMs % 1000;
        const s = Math.floor(totalMs / 1000) % 60;
        const m = Math.floor(totalMs / (1000 * 60)) % 60;
        const h = Math.floor(totalMs / (1000 * 60 * 60));

        return `${toTwoDigits(h)}:${toTwoDigits(m)}:${toTwoDigits(s)}`;
    };

    const getDuration = (start: Date, end: Date | null) => {
        const startTime = new Date(start);
        const endTime = end === null ? new Date() : new Date(end);
        const ms = endTime.getTime() - startTime.getTime();

        return msToDuration(ms);
    };

    const sortFn = (s1: IShiftStat, s2: IShiftStat) => (
        new Date(s2.startTime).getTime() - new Date(s1.startTime).getTime()
    );

    if (staffReport === null) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="staffStatModal"
            overlayClassName="staffStatModalOverlay"
        >
            <header>
                <div className="staffStats">
                    <h2>{staffReport.nickName}</h2>
                    <h3>{`Duration: ${msToDuration(staffReport.totalTime)}`}</h3>
                    <h3>{`Tips: ${amountToString(staffReport.totalTips)}`}</h3>
                </div>
            </header>
            <div className="staffShiftWrapper">
                {staffReport.shiftStats.sort(sortFn).map((shiftStat: IShiftStat) => (
                    <div className="staffShift">
                        <div className="staffShiftInnerCol">
                            <h3>Time</h3>
                            <p>{getShiftStartEnd(shiftStat.startTime, shiftStat.endTime)}</p>
                        </div>
                        <div className="staffShiftInnerCol">
                            <h3>Duration</h3>
                            <p>{getDuration(shiftStat.startTime, shiftStat.endTime)}</p>
                        </div>
                        <div className="staffShiftInnerCol">
                            <h3>Store Sales</h3>
                            <p>{amountToString(shiftStat.storeSales)}</p>
                        </div>
                        <div className="staffShiftInnerCol">
                            <h3>Tips</h3>
                            <p>{amountToString(shiftStat.tips)}</p>
                        </div>
                    </div>
                ))}
            </div>
        </ReactModal>
    );
};

export default StaffStatModal;
