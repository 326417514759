import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Factory } from '../../../controllers/ControllerFactory';
import { StaffContext } from '../../../controllers/Staff/StoreContext';
import { IStore, IStoreMeta, IStoreSchema } from '../../../model/storeGroup/store/IStore';
import Analytics from '../../Analytics/Analytics';
import IngrLogEditor from '../../Menu/IngrLogEditor/IngrLogEditor';
import RawMaterials from '../../Menu/RawMaterials/RawMaterials';
import './AllStores.scss';
import StoreMetaCard from './components/StoreMetaCard/StoreMetaCard';

enum ViewType {
    INIT = 'INIT',
    ANALYTICS = 'Analytics',
    INGREDIENTS = 'Ingredients',
    RAW_MATERIALS = 'Raw Materials',
}

const AllStores:React.FC = () => {
    const { i18n } = useTranslation();
    const storeController = Factory.getStoreController();
    const { staff } = useContext(StaffContext)!;

    const [storeMetas, setStoreMetas] = useState<IStoreMeta[]>([]);
    const [selectedStore, setSelectedStore] = useState<IStore | null>(null);

    const [viewType, setViewType] = useState<ViewType>(ViewType.INIT);

    const getStoreMetas = async () => {
        if (staff.staffCorpRefs.length === 0) return;
        const storeMetasResponse = await storeController.getStoreMetasByCorpId(
            staff.staffCorpRefs[0].corpId,
        );
        setStoreMetas(storeMetasResponse.data.storeMetas);
    };

    const selectStore = async (storeId: string) => {
        const storeResponse = await storeController.getById(storeId);
        setSelectedStore(storeResponse.data.store);
    };

    const deselectStore = () => {
        setSelectedStore(null);
        setViewType(ViewType.INIT);
    };

    const handleBack = () => {
        if (viewType === ViewType.INIT) {
            deselectStore();
            return;
        }
        setViewType(ViewType.INIT);
    };

    const renderHeading = () => {
        if (!selectedStore) return 'All Stores';
        if (selectedStore && viewType === ViewType.INIT) return selectedStore.name[i18n.language];
        return `${selectedStore.name[i18n.language]} > ${viewType}`;
    };

    useEffect(() => {
        getStoreMetas();
    }, []);

    return (
        <div className="allStoresContainer dashboardScreen">
            <h2 className="screenTitle">
                {selectedStore && (
                    <button
                        type="button"
                        onClick={handleBack}
                        className="back"
                    >
                        &lt;
                    </button>
                )}
                {renderHeading()}
            </h2>
            <div className="allStores">
                {!selectedStore && storeMetas.map((storeMeta) => (
                    <StoreMetaCard
                        storeMeta={storeMeta}
                        selectStore={selectStore}
                    />
                ))}
                {selectedStore && (
                    // <Analytics store={selectedStore} />
                    <>
                        {viewType === ViewType.INIT && (
                            <div className="viewSelection">
                                <button
                                    type="button"
                                    onClick={() => setViewType(ViewType.ANALYTICS)}
                                >
                                    Analytics
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setViewType(ViewType.INGREDIENTS)}
                                >
                                    Ingredients
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setViewType(ViewType.RAW_MATERIALS)}
                                >
                                    Raw Materials
                                </button>
                            </div>
                        )}
                        {viewType === ViewType.ANALYTICS && (
                            <Analytics store={selectedStore} />
                        )}
                        {viewType === ViewType.INGREDIENTS && (
                            <IngrLogEditor store={selectedStore} />
                        )}
                        {viewType === ViewType.RAW_MATERIALS && (
                            <RawMaterials store={selectedStore} />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default AllStores;
