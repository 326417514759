import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Factory } from '../../controllers/ControllerFactory';
import { StoreContext } from '../../controllers/Store/StoreContext';

import { IGala } from '../../model/galaGroup/gala/iGala';
import { GalaAttendeeViewer } from './galaAttendeeViewer/GalaAttendeeViewer';
import './GalaManagement.scss';
import { GalaTicketListingViewer } from './galaTicketListingViewer/GalaTicketListingViewer';
import { GalaTicketSaleViewer } from './galaTicketSaleViewer/galaTicketSaleViewer';
import { GalaTicketViewer } from './galaTicketViewer/GalaTicketViewer';
import { GalaAuctionViewer } from './galaAuctionViewer/GalaAuctionViewer';
import { AuctionTypeEnum } from '../../model/galaGroup/galaAuction/galaAuctionFields';
import { GalaCheckInViewer } from './galaCheckInViewer/GalaCheckInViewer';
import { GalaTableViewer } from './galaTableViewer/GalaTableViewer';
import { GalaDonationViewer } from './galaDonationViewer/GalaDonationViewer';

enum ScreenType {
    attendees = 'attendees',
    checkIn = 'checkIn',
    donations = 'donations',
    ticketSales = 'ticketSales',
    tickets = 'tickets',
    tables = 'tables',
    ticketListings = 'ticketListings',
    silentAuctions = 'silentAuctions',
}

const GalaManagement: React.FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { store } = useContext(StoreContext)!;
    const [galas, setGalas] = useState<IGala[]>([]);
    const [currGala, setCurrGala] = useState<IGala | null>(null);
    useEffect(() => {
        const getGalas = async () => {
            const tGalas : IGala[] = await Factory.GalaController.getGalasByStoreId(store._id);
            setGalas(tGalas);
        };
        getGalas();
    }, []);

    // Screens
    const [currScreen, setCurrScreen] = useState<ScreenType>(ScreenType.attendees);

    const noGalaFound = (
        <div className="galaManagementContainer dashboardScreen">
            <h2 className="screenTitle">Gala Management</h2>
            <div>No Gala Found</div>
        </div>
    );

    const noCurrGala = (
        <div className="galaManagementContainer dashboardScreen">
            <h2 className="screenTitle">Gala Management</h2>
            <div>
                <h3>Please Select a Gala</h3>
                <div className="row">
                    {galas.map((gala: IGala) => (
                        <button
                            type="button"
                            onClick={() => { setCurrGala(gala); }}
                        >
                            {gala.name.en}
                        </button>
                    ))}
                </div>

            </div>
        </div>
    );

    if (galas.length === 0) return noGalaFound;
    if (currGala === null) return noCurrGala;

    const showScreen = () => {
        switch (currScreen) {
            case ScreenType.attendees: return (
                <GalaAttendeeViewer
                    currGala={currGala}
                />
            );
            case ScreenType.checkIn: return (
                <GalaCheckInViewer
                    currGala={currGala}
                />
            );
            case ScreenType.donations: return (
                <GalaDonationViewer
                    currGala={currGala}
                />
            );
            case ScreenType.tables: return (
                <GalaTableViewer
                    currGala={currGala}
                />
            );
            case ScreenType.ticketSales: return (
                <GalaTicketSaleViewer
                    currGala={currGala}
                />
            );
            case ScreenType.tickets: return (
                <GalaTicketViewer
                    currGala={currGala}
                />
            );
            case ScreenType.ticketListings: return (
                <GalaTicketListingViewer
                    currGala={currGala}
                />
            );
            case ScreenType.silentAuctions: return (
                <GalaAuctionViewer
                    currGala={currGala}
                    auctionType={AuctionTypeEnum.silent}
                />
            );
            default: return <>Select a view type</>;
        }
    };

    return (
        <div className="galaManagementContainer dashboardScreen">
            <h2 className="screenTitle">{`Gala Management - ${currGala.name.en}`}</h2>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button
                        type="button"
                        onClick={() => { setCurrScreen(ScreenType.donations); }}
                        className={currScreen === ScreenType.donations ? 'active' : ''}
                    >
                        View Donations
                    </button>
                    <button
                        type="button"
                        onClick={() => { setCurrScreen(ScreenType.checkIn); }}
                        className={currScreen === ScreenType.checkIn ? 'active' : ''}
                    >
                        View Check In Status
                    </button>
                    <button
                        type="button"
                        onClick={() => { setCurrScreen(ScreenType.tables); }}
                        className={currScreen === ScreenType.tables ? 'active' : ''}
                    >
                        View Tables
                    </button>
                    <button
                        type="button"
                        onClick={() => { setCurrScreen(ScreenType.attendees); }}
                        className={currScreen === ScreenType.attendees ? 'active' : ''}
                    >
                        View Gala Attendees
                    </button>
                    <button
                        type="button"
                        onClick={() => { setCurrScreen(ScreenType.ticketSales); }}
                        className={currScreen === ScreenType.ticketSales ? 'active' : ''}
                    >
                        View Ticket Sales
                    </button>
                    <button
                        type="button"
                        onClick={() => { setCurrScreen(ScreenType.silentAuctions); }}
                        className={currScreen === ScreenType.silentAuctions ? 'active' : ''}
                    >
                        View Silent Auctions
                    </button>
                    {/* <button
                        type="button"
                        onClick={() => { setCurrScreen(ScreenType.tickets); }}
                        className={currScreen === ScreenType.tickets ? 'active' : ''}
                    >
                        View Tickets
                    </button>
                    <button
                        type="button"
                        onClick={() => { setCurrScreen(ScreenType.ticketListings); }}
                        className={currScreen === ScreenType.ticketListings ? 'active' : ''}
                    >
                        View Tickets Listings
                    </button> */}
                </div>
            </div>
            <div className="galaScreenWrapper">
                {showScreen()}
            </div>
        </div>
    );
    // return (
    //     <div className="galaManagementContainer dashboardScreen">
    //         <h2 className="screenTitle">Gala Management</h2>
    //         <div className="transList">
    //             {transList.map((trans) => (
    //                 <div className="transaction">
    //                     <p>{`Bill Amount: ${amountToString(trans.billAmount)}`}</p>
    //                     <p>{`Tips: ${amountToString(trans.paid - trans.billAmount)}`}</p>
    //                     <p>{new Date(trans.createdAt).toLocaleString()}</p>
    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // );
};

export default GalaManagement;
function amountToStr(totalPrice: number) {
    throw new Error('Function not implemented.');
}
