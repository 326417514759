import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../controllers/Store/StoreContext';
import { Dict } from '../../../../model/helpers/Dict';
import { ITax } from '../../../../model/storeGroup/tax/ITax';
import './AddEditTax.scss';

ReactModal.setAppElement('#root');
interface AddEditTaxProps {
    open: boolean;
    tax: ITax | null;
    closeModal: () => void;
}

const AddEditTax: React.FC<AddEditTaxProps> = ({
    open, closeModal, tax,
}) => {
    const { i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;
    const multiplierController = Factory.getMultiplierController();

    const [taxName, setTaxName] = useState<Dict<string>>({});
    const [taxDesc, setTaxDesc] = useState<Dict<string>>({});
    const [taxPercent, setTaxPercent] = useState(0);
    const [taxFlat, setTaxFlat] = useState(0);

    const changeTaxName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpTaxName = e.target.value;
        const cLang = i18n.language;
        setTaxName((prevState) => ({
            ...prevState,
            [cLang]: tmpTaxName,
        }));
    };

    const changeTaxDesc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tmpTaxDesc = e.target.value;
        setTaxDesc((prevState) => ({
            ...prevState,
            [i18n.language]: tmpTaxDesc,
        }));
    };

    const changeTaxPercent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dollarCents = e.target.value.split('.');
        if (parseInt(dollarCents[0], 10) > 100) return;
        if (dollarCents.length === 2 && dollarCents[1].length > 2) {
            e.preventDefault();
        } else {
            const value = parseFloat(e.target.value);
            setTaxPercent(value);
        }
    };

    const changeTaxFlat = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dollarCents = e.target.value.split('.');
        if (parseInt(dollarCents[0], 10) > 10000) return;
        if (dollarCents.length === 2 && dollarCents[1].length > 2) {
            e.preventDefault();
        } else {
            const value = parseFloat(e.target.value);
            setTaxFlat(value);
        }
    };

    const createTax = async () => {
        if (tax !== null) throw new Error('cannot add existing tax');
        const taxResponse = await multiplierController.createTax(
            store._id,
            taxName,
            taxDesc,
            taxPercent,
            taxFlat,
        );
        setStore(taxResponse.data.store);
        closeModal();
        return taxResponse.data.tax;
    };

    const editTax = async () => {
        if (tax === null) throw new Error('cannot edit new tax');
        const taxResponse = await multiplierController.editTax(
            store._id,
            tax._id,
            taxName,
            taxDesc,
            taxPercent,
            taxFlat,
        );
        setStore(taxResponse.data.store);
        closeModal();
        return taxResponse.data.tax;
    };

    const [HCLoading, setHCLoading] = useState(false);
    const handleConfirm = async () => {
        if (HCLoading) return;
        setHCLoading(true);
        let tmpTax: ITax;
        if (tax === null) {
            tmpTax = await createTax();
        } else {
            tmpTax = await editTax();
        }
        setTaxName({
            [i18n.language]: '',
        });
        setTaxDesc({
            [i18n.language]: '',
        });
        setTaxPercent(0);
        setTaxFlat(0);
        setHCLoading(false);
    };

    useEffect(() => {
        if (tax === null) {
            setTaxName({
                [i18n.language]: '',
            });
            setTaxDesc({
                [i18n.language]: '',
            });
            setTaxPercent(0);
            setTaxFlat(0);
        } else {
            setTaxName(tax.name);
            setTaxDesc(tax.desc);
            setTaxPercent(tax.percent);
            setTaxFlat(tax.flat / 100);
        }
    }, [tax]);

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditTaxModal"
            overlayClassName="addEditTaxModalOverlay"
        >
            <h2>Add/Edit Tax</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Tax Name</h4>
                    <input
                        value={taxName[i18n.language]}
                        onChange={changeTaxName}
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Tax Description</h4>
                    <input
                        value={taxDesc[i18n.language]}
                        onChange={changeTaxDesc}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Percentage</h4>
                    <input
                        value={taxPercent}
                        onChange={changeTaxPercent}
                        type="number"
                    />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Flat Amount</h4>
                    <input
                        value={taxFlat}
                        onChange={changeTaxFlat}
                        type="number"
                    />
                </div>
            </div>

            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={handleConfirm} disabled={HCLoading}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default AddEditTax;
