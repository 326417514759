import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { StoreContext } from '../../../../../../../controllers/Store/StoreContext';
import { ICombo } from '../../../../../../../model/menuGroup/combo/ICombo';
import { IComboRef } from '../../../../../../../model/menuGroup/combo/IComboRef';
import './ComboElemSelector.scss';

ReactModal.setAppElement('#root');
interface ComboElemSelectorProps {
    open: boolean;
    combo: ICombo | null;
    comIdRef: string;
    comboRef: IComboRef | undefined,
    closeModal: () => void;
    handleContinue: (tmpComId: string, comboElemId: string) => void;
}

const ComboElemSelector: React.FC<ComboElemSelectorProps> = ({
    open, closeModal, comIdRef, comboRef, combo, handleContinue,
}) => {
    const { i18n } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;

    if (combo === null) return <></>;
    const generateComboElemList = () => {
        if (comboRef === undefined) return <></>;
        const retList: any = [];
        combo.itemIds.forEach((itemId) => {
            const targetItem = store.items.find((tmpItem) => (
                itemId === tmpItem._id
            ));
            if (targetItem === undefined) throw new Error('cannot find item with itemid');
            const alreadySelected = comboRef.comboElemRefs.find((tmpComRef) => (
                tmpComRef.itemId === itemId
            ));
            if (alreadySelected === undefined) {
                retList.push(
                    <button
                        type="button"
                        onClick={() => {
                            handleContinue(comIdRef, itemId!);
                        }}
                    >
                        {`${targetItem.name[i18n.language]} (${targetItem.mName})`}
                    </button>,
                );
            }
        });
        return retList;
    };
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="comboElemSelectorModal"
            overlayClassName="comboElemSelectorModalOverlay"
        >
            <h2>Combo Elements</h2>
            {generateComboElemList()}
        </ReactModal>
    );
};

export default ComboElemSelector;
