import React from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import { IAggregateReport } from '../../../model/analyticsGroup/IAggregateReport';
import { ISeshReport } from '../../../model/analyticsGroup/seshReport/ISeshReport';
import { amountToString, msToTime } from '../../../utils/global';
import './Sales.scss';

interface SalesProps {
    aggregateData: IAggregateReport | null;
    seshData: ISeshReport | null;
}

const Sales: React.FC<SalesProps> = ({
    aggregateData, seshData,
}) => {
    const getTotalSales = () => {
        if (seshData !== null) return amountToString(seshData.total - seshData.giftCardPrice);
        if (aggregateData !== null) return amountToString(aggregateData.total - aggregateData.giftCardPrice);
        return 'N/A';
    };

    const getIngrCost = () => {
        if (seshData !== null) return amountToString(seshData.ingrCost);
        if (aggregateData !== null) return amountToString(aggregateData.ingrCost);
        return 'N/A';
    };

    const getTaxes = () => {
        if (seshData !== null) return amountToString(seshData.taxTotal);
        if (aggregateData !== null) return amountToString(aggregateData.taxTotal);
        return 'N/A';
    };

    const getGrossProfit = () => {
        if (seshData !== null) return amountToString(seshData.total - seshData.giftCardPrice - seshData.taxTotal);
        if (aggregateData !== null) return amountToString(aggregateData.total - aggregateData.giftCardPrice - aggregateData.taxTotal);
        return 'N/A';
    };

    const getAvgRevenuePerHour = () => {
        if (seshData !== null) {
            const start = new Date(seshData.startTime).getTime();
            const end = seshData.endTime ? new Date(seshData.endTime).getTime() : new Date().getTime();
            const hours = ((end - start) / (1000 * 60 * 60));
            return amountToString((seshData.total - seshData.giftCardPrice) / hours);
        }
        if (aggregateData !== null) {
            const hours = (aggregateData.totalSeshDur / (1000 * 60 * 60));
            return amountToString((aggregateData.total - aggregateData.giftCardPrice) / hours);
        }
        return 'N/A';
    };

    const getAvgProfitPerHour = () => {
        if (seshData !== null) {
            const start = new Date(seshData.startTime).getTime();
            const end = seshData.endTime ? new Date(seshData.endTime).getTime() : new Date().getTime();
            const hours = ((end - start) / (1000 * 60 * 60));
            return amountToString((seshData.total - seshData.giftCardPrice - seshData.taxTotal) / hours);
        }
        if (aggregateData !== null) {
            const hours = (aggregateData.totalSeshDur / (1000 * 60 * 60));
            return amountToString((aggregateData.total - aggregateData.giftCardPrice - aggregateData.taxTotal) / hours);
        }
        return 'N/A';
    };

    // const getAvgR

    const getSeshDuration = () => {
        if (seshData === null) return '';
        const start = new Date(seshData.startTime).getTime();
        const end = seshData.endTime ? new Date(seshData.endTime).getTime() : new Date().getTime();
        return msToTime(end - start);
    };

    const getClosedOrder = () => {
        if (seshData !== null) return seshData.closedOrderCount;
        if (aggregateData !== null) return aggregateData.closedOrderCount;
        return 'N/A';
    };

    const getCancelledOrder = () => {
        if (seshData !== null) return seshData.cancelledOrderCount;
        if (aggregateData !== null) return aggregateData.cancelledOrderCount;
        return 'N/A';
    };

    const getAvgOrderPerHour = () => {
        if (seshData !== null) {
            const start = new Date(seshData.startTime).getTime();
            const end = seshData.endTime ? new Date(seshData.endTime).getTime() : new Date().getTime();
            const hours = ((end - start) / (1000 * 60 * 60));
            return (seshData.closedOrderCount / hours).toFixed(2);
        }
        if (aggregateData !== null) {
            const hours = (aggregateData.totalSeshDur / (1000 * 60 * 60));
            return (aggregateData.closedOrderCount / hours).toFixed(2);
        }
        return 'N/A';
    };

    const getAvgBill = () => {
        if (seshData !== null) {
            return amountToString(
                (seshData.total - seshData.giftCardPrice) / seshData.closedOrderCount,
            );
        }
        if (aggregateData !== null) {
            return amountToString(
                (aggregateData.total - aggregateData.giftCardPrice) / aggregateData.closedOrderCount,
            );
        }
        return 'N/A';
    };

    const getItemRevenue = () => {
        if (seshData !== null) return amountToString(seshData.itemRevenue);
        if (aggregateData !== null) return amountToString(aggregateData.itemRevenue);
        return 'N/A';
    };

    const getItemCount = () => {
        if (seshData !== null) return seshData.itemCount;
        if (aggregateData !== null) return aggregateData.itemCount;
        return 'N/A';
    };

    const getAvgItemPrice = () => {
        if (seshData !== null) {
            return amountToString(
                seshData.itemRevenue / seshData.itemCount,
            );
        }
        if (aggregateData !== null) {
            return amountToString(
                aggregateData.itemRevenue / aggregateData.itemCount,
            );
        }
        return 'N/A';
    };

    const getAvgItemsPerOrder = () => {
        if (seshData !== null) return (seshData.itemCount / seshData.closedOrderCount).toFixed(2);
        if (aggregateData !== null) return (aggregateData.itemCount / aggregateData.closedOrderCount).toFixed(2);
        return 'N/A';
    };

    const getOptionRevenue = () => {
        if (seshData !== null) return amountToString(seshData.optionRevenue);
        if (aggregateData !== null) return amountToString(aggregateData.optionRevenue);
        return 'N/A';
    };

    const getOptionCount = () => {
        if (seshData !== null) return seshData.optionCount;
        if (aggregateData !== null) return aggregateData.optionCount;
        return 'N/A';
    };

    const getAvgOptionPrice = () => {
        if (seshData !== null) {
            return amountToString(
                seshData.optionRevenue / seshData.optionCount,
            );
        }
        if (aggregateData !== null) {
            return amountToString(
                aggregateData.optionRevenue / aggregateData.optionCount,
            );
        }
        return 'N/A';
    };

    const getAvgOptionsPerOrder = () => {
        if (seshData !== null) return (seshData.optionCount / seshData.closedOrderCount).toFixed(2);
        if (aggregateData !== null) return (aggregateData.optionCount / aggregateData.closedOrderCount).toFixed(2);
        return 'N/A';
    };

    const getDiscountAmount = () => {
        if (seshData !== null) return amountToString(seshData.discountAmount);
        if (aggregateData !== null) return amountToString(aggregateData.discountAmount);
        return 'N/A';
    };

    const getDiscountQty = () => {
        if (seshData !== null) return seshData.discountQty;
        if (aggregateData !== null) return aggregateData.discountQty;
        return 'N/A';
    };

    const getGiftCardPrice = () => {
        if (seshData !== null) return amountToString(seshData.giftCardPrice);
        if (aggregateData !== null) return amountToString(aggregateData.giftCardPrice);
        return 'N/A';
    };

    const getGiftCardValue = () => {
        if (seshData !== null) return amountToString(seshData.giftCardValue);
        if (aggregateData !== null) return amountToString(aggregateData.giftCardValue);
        return 'N/A';
    };

    const getGiftCardCount = () => {
        if (seshData !== null) return seshData.giftCardCount;
        if (aggregateData !== null) return aggregateData.giftCardCount;
        return 'N/A';
    };

    const getTips = () => {
        if (seshData !== null) return amountToString(seshData.tips);
        if (aggregateData !== null) return amountToString(aggregateData.tips);
        return 'N/A';
    };

    const getAvgTips = () => {
        if (seshData !== null) {
            return amountToString(
                seshData.tips / seshData.closedOrderCount,
            );
        }
        if (aggregateData !== null) {
            return amountToString(
                aggregateData.tips / aggregateData.closedOrderCount,
            );
        }
        return 'N/A';
    };

    return (
        <>
            {aggregateData === null && seshData === null && <Spinner />}
            {(aggregateData !== null || seshData !== null) && (
                <div className="stats salesStatContainer">
                    <h3>Income Overview</h3>
                    <div className="salesRow">
                        <div className="salesCard">
                            <h4>{getTotalSales()}</h4>
                            <p>Total Revenue</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getIngrCost()}</h4>
                            <p>Ingredient Cost</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getTaxes()}</h4>
                            <p>Taxes</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getGrossProfit()}</h4>
                            <p>Gross Profit</p>
                        </div>
                    </div>
                    <div className="salesRow">
                        <div className="salesCard">
                            <h4>{getAvgRevenuePerHour()}</h4>
                            <p>Hourly Revenue</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getAvgProfitPerHour()}</h4>
                            <p>Hourly Profit</p>
                        </div>
                    </div>
                    <h3>{seshData ? 'Store Sesh Overview' : 'Aggregate Overview'}</h3>
                    {(seshData !== null) && (
                        <div className="salesRow">
                            <div className="salesCard">
                                <h4>{getSeshDuration()}</h4>
                                <p>Duration</p>
                            </div>
                            <div className="salesCard">
                                <h4>{seshData.startedByStaffName}</h4>
                                <p>{`Started At ${new Date(seshData.startTime).toLocaleTimeString()} By`}</p>
                            </div>
                            <div className="salesCard">
                                <h4>{seshData.endedByStaffName}</h4>
                                <p>{`Ended At ${seshData.endTime ? new Date(seshData.startTime).toLocaleTimeString() : 'N/A'} By`}</p>
                            </div>
                        </div>
                    )}
                    {(aggregateData !== null && seshData === null) && (
                        <div className="salesRow">
                            <div className="salesCard">
                                <h4>{aggregateData.seshCount}</h4>
                                <p>Number of Sessions</p>
                            </div>
                            <div className="salesCard">
                                <h4>{`${(aggregateData.totalSeshDur / (1000 * 60 * 60)).toFixed(1)} Hours`}</h4>
                                <p>Hours Operated</p>
                            </div>
                        </div>
                    )}
                    <h3>Order Overview</h3>
                    <div className="salesRow">
                        <div className="salesCard">
                            <h4>{getClosedOrder()}</h4>
                            <p>Closed Orders</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getCancelledOrder()}</h4>
                            <p>Cancelled Orders</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getAvgOrderPerHour()}</h4>
                            <p>Average Per Hour</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getAvgBill()}</h4>
                            <p>Average Bill</p>
                        </div>
                    </div>
                    <h3>Item Overview</h3>
                    <div className="salesRow">
                        <div className="salesCard">
                            <h4>{getItemRevenue()}</h4>
                            <p>Item Price Sold</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getItemCount()}</h4>
                            <p>Item Count</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getAvgItemPrice()}</h4>
                            <p>Average Item Price</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getAvgItemsPerOrder()}</h4>
                            <p>Average Items Per Order</p>
                        </div>
                    </div>
                    <h3>Option Overview</h3>
                    <div className="salesRow">
                        <div className="salesCard">
                            <h4>{getOptionRevenue()}</h4>
                            <p>Option Price Sold</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getOptionCount()}</h4>
                            <p>Option Count</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getAvgOptionPrice()}</h4>
                            <p>Average Option Price</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getAvgOptionsPerOrder()}</h4>
                            <p>Average Option Per Order</p>
                        </div>
                    </div>
                    <h3>Discount Overview</h3>
                    <div className="salesRow">
                        <div className="salesCard">
                            <h4>{getDiscountAmount()}</h4>
                            <p>Discount Amount</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getDiscountQty()}</h4>
                            <p>Discount Quantity</p>
                        </div>
                    </div>
                    <h3>Gift Card Overview</h3>
                    <div className="salesRow">
                        <div className="salesCard">
                            <h4>{getGiftCardPrice()}</h4>
                            <p>Gift Price Sold</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getGiftCardValue()}</h4>
                            <p>Gift Values Sold</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getGiftCardCount()}</h4>
                            <p>Gift Cards Quanity</p>
                        </div>
                    </div>
                    <h3>Tips Overview</h3>
                    <div className="salesRow">
                        <div className="salesCard">
                            <h4>{getTips()}</h4>
                            <p>Tips</p>
                        </div>
                        <div className="salesCard">
                            <h4>{getAvgTips()}</h4>
                            <p>Average Tips</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Sales;
