/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../../../../../controllers/Store/StoreContext';
import { IComboElemRef } from '../../../../../../../model/menuGroup/combo/comboElem/IComboElemRef';
import './ComboElemCard.scss';

interface ComboElemCardProps {
    name: string;
    comboElemRef: IComboElemRef;
    comIdRef: string;
    removeComboElem: (tmpComId: string, itemId: string) => void;
    changeComboElemPrice: (
        e: React.ChangeEvent<HTMLInputElement>,
        tmpComId: string, itemId: string, priceKey: string,
    ) => void;
}

const ComboElemCard: React.FC<ComboElemCardProps> = ({
    name, comboElemRef, comIdRef, removeComboElem, changeComboElemPrice,
}) => {
    const { t } = useTranslation();
    const { store } = useContext(StoreContext)!;

    return (

        <div className="comboElemCardContainer">
            <h3>{name}</h3>
            <div className="priceKeys">
                {store.priceKeys.map((pK) => {
                    const val = comboElemRef.cePrices.find((cePrice) => (
                        cePrice.priceKeyId === pK._id
                    ));
                    if (val === undefined) throw new Error('Cannot find combo elem price');
                    return (
                        <div className="comboElemPriceKey">
                            <p>{pK.mName}</p>
                            <input
                                type="number"
                                value={val.amount}
                                onChange={(e) => changeComboElemPrice(
                                    e, comIdRef, comboElemRef.itemId, pK._id,
                                )}
                            />
                        </div>
                    );
                })}
            </div>
            <button
                className="removeComboElem"
                type="button"
                onClick={() => removeComboElem(comIdRef, comboElemRef.itemId)}
            >
                X
            </button>
        </div>

    );
};

export default ComboElemCard;
