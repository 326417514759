import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { StaffController } from '../controllers/Staff/StaffController';
import logoImg from '../assets/logo.svg';

import './Login.scss';
import { LoginLocale } from './LoginLocale';
import { Factory } from '../controllers/ControllerFactory';
import { ToastController } from '../controllers/ToastController';

const Login: React.FC = () => {
    const { t, i18n } = useTranslation();

    const [gotCode, setGotCode] = useState(false);
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');

    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [email, setEmail] = useState('');

    const [isSignUp, setIsSignUp] = useState(false);

    const userController = Factory.getStaffController();
    const twilioController = Factory.getTwilioController();

    const resetFields = () => {
        setGotCode(false);
        setPhone('');
        setCode('');
        setFName('');
        setLName('');
        setEmail('');
        setIsSignUp(false);
    };

    const history = useHistory();
    const handlePhoneInput = (p: string) => {
        setPhone(p);
    };

    const handleCodeInput = (c: string) => {
        setCode(c);
    };

    const handleFNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setFName(val);
    };

    const handleLNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setLName(val);
    };

    const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setEmail(val);
    };

    const validSignUp = () => {
        if (fName === '') return false;
        if (lName === '') return false;
        if (email === '') return false;
        return true;
    };

    const requestCode = async () => {
        if (!isSignUp) {
            const received = await userController.requestCode(phone);
            if (received.data.msg) {
                setGotCode(true);
                return;
            }
            setGotCode(false);
            return;
        }
        const received = await twilioController.createCode(phone);
        if (received.data.msg) {
            setGotCode(true);
            return;
        }
        setGotCode(false);
    };

    const showSignUp = () => {
        setIsSignUp(true);
        setGotCode(false);
    };

    const submitLogin = async (evt: any) => {
        evt.preventDefault();
        await userController.loginPassword(phone, code).then((tokenResponse) => {
            if (Object.keys(tokenResponse.data).length !== 0) {
                localStorage.setItem('token', tokenResponse.data.staffTok);
                history.push('/dashboard');
            }
        });
    };

    const handleSignUp = async () => {
        if (!validSignUp()) return;
        const staffResponse = await userController.createStaff(
            fName, lName, phone, email, code,
        );

        if (!staffResponse.twilSuccess) {
            ToastController.error('Invalid verification code. Please try again');
            return;
        }
        ToastController.success('Successfully created account!');
        resetFields();
    };

    useEffect(() => {
        userController.validateJWT(history).then((authSuccess: boolean) => {
            if (authSuccess) {
                history.push('/dashboard');
            }
        });
    }, []);

    return (
        <div className="loginContainer">
            <img src={logoImg} alt="logo" />
            {!isSignUp && (
                <form className="loginForm" onSubmit={(evt) => { submitLogin(evt); }}>
                    {!gotCode && (
                        <>
                            <input
                                className="loginInput getCode"
                                type="text"
                                placeholder="Email"
                                onChange={(evt) => handlePhoneInput(evt.target.value)}
                            />
                            <input
                                className="loginInput getCode"
                                type="password"
                                placeholder="Password"
                                onChange={(evt) => handleCodeInput(evt.target.value)}
                            />

                            <button type="submit">{t(LoginLocale.login)}</button>
                            {/* <button type="button" onClick={showSignUp} className="signUp">Sign up</button> */}
                        </>
                    )}
                    {gotCode && (
                        <>
                            <input
                                className="loginInput"
                                type="text"
                                placeholder={t(LoginLocale.code)}
                                onChange={(evt) => handleCodeInput(evt.target.value)}
                            />
                            <button type="submit">{t(LoginLocale.login)}</button>
                        </>
                    )}
                </form>
            )}
            {isSignUp && (
                <form>
                    <input placeholder="First Name" onChange={handleFNameInput} />
                    <input placeholder="Last Name" onChange={handleLNameInput} />
                    <input type="email" placeholder="Email" onChange={handleEmailInput} />
                    <input placeholder="Phone" onChange={(e) => handlePhoneInput(e.target.value)} />
                    {!gotCode && (
                        <button type="button" onClick={requestCode}>
                            {t(LoginLocale.getCode)}
                        </button>
                    )}
                    {gotCode && (
                        <>
                            <input placeholder="Verification Code" onChange={(e) => handleCodeInput(e.target.value)} />
                            <button
                                type="button"
                                disabled={!validSignUp()}
                                onClick={handleSignUp}
                            >
                                Sign Up
                            </button>
                        </>
                    )}
                </form>
            )}
        </div>
    );
};

export default Login;
