/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';

import { useTranslation } from 'react-i18next';

import {
    Stage, Layer, Rect, Transformer,
} from 'react-konva';

// import { Html } from 'react-konva-utils';

import { Html } from 'react-konva-utils';
import './FloorplanModal.global.scss';
import { ITableSchema } from '../../../model/storeGroup/table/ITable';
import { TableController } from '../../../controllers/Tables/TableController';
import { FloorplanLocale } from '../FloorplanLocale';

Modal.setAppElement('#root');

interface RectangleProps{
    stageWidth: number;
    stageHeight: number;
    scale: number;
    shapeProps: ITableSchema;
    draggable: boolean;
    isSelected: boolean;
    onSelect: any;
    onChange: any;
    updateRect: any;
    idx: number;
    fill: string;
  }

const Rectangle: React.FC<RectangleProps> = ({
    stageWidth, stageHeight, scale, shapeProps,
    draggable, isSelected, onSelect, onChange, updateRect, idx, fill,
}) => {
    const shapeRef = React.useRef(null);
    const trRef = React.useRef(null);
    const [isEditing, setIsEditing] = React.useState(false);

    const setCoord = (
        min: number,
        max: number,
        p1: number,
        p2: number,
        xy: number,
    ) => {
        if (Math.min(p1, p2) / scale < min / scale) return (xy - Math.min(p1, p2) / scale);
        if (Math.max(p1, p2) / scale > max / scale) return (max - Math.max(p1, p2)) / scale + xy;
        return xy;
    };

    const boundaryCheck = (rect: any) => {
        const c = [];
        const size = rect.size();

        // Now get the 4 corner points
        c[0] = { x: 0, y: 0 }; // top left
        c[1] = { x: size.width, y: 0 }; // top right
        c[2] = { x: size.width, y: size.height }; // bottom right
        c[3] = { x: 0, y: size.height }; // bottom left

        const tl = rect.getAbsoluteTransform().point(c[0]);
        const tr = rect.getAbsoluteTransform().point(c[1]);
        const br = rect.getAbsoluteTransform().point(c[2]);
        const bl = rect.getAbsoluteTransform().point(c[3]);

        if (
            (shapeProps.rotation >= 0 && shapeProps.rotation <= 90)
            || (shapeProps.rotation >= -180 && shapeProps.rotation <= -90)
        ) {
            rect.x(setCoord(0, stageWidth, tr.x, bl.x, rect.x()));
            rect.y(setCoord(0, stageHeight, tl.y, br.y, rect.y()));
        } else {
            rect.x(setCoord(0, stageWidth, br.x, tl.x, rect.x()));
            rect.y(setCoord(0, stageHeight, bl.y, tr.y, rect.y()));
        }
    };

    React.useEffect(() => {
        if (isSelected && trRef.current) {
            // we need to attach transformer manually
            (trRef.current as any).nodes([shapeRef.current]);
            (trRef.current as any).getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <>
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                width={shapeProps.w}
                height={shapeProps.h}
                fill={fill}
                cornerRadius={16}
                {...shapeProps}
                draggable
                onDragStart={() => {
                    if (!draggable) {
                        const rect = shapeRef.current as any;
                        rect.stopDrag();
                    } else {
                        setIsEditing(true);
                    }
                }}
                onDragMove={() => {
                    const rect = shapeRef.current as any;
                    boundaryCheck(rect);
                }}
                onDragEnd={(e) => {
                    setIsEditing(false);
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                        rotation: e.target.rotation(),
                    });
                }}
                onTransformStart={() => {
                    setIsEditing(true);
                }}
                onTransformEnd={() => {
                    setIsEditing(false);
                    const rect = shapeRef.current as any;
                    boundaryCheck(rect);

                    const scaleX = rect.scaleX();
                    const scaleY = rect.scaleY();

                    rect.scaleX(1);
                    rect.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: rect.x(),
                        y: rect.y(),
                        rotation: rect.rotation(),
                        w: Math.max(5, rect.width() * scaleX),
                        h: Math.max(rect.height() * scaleY),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    anchorSize={20}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (
                            Math.abs(newBox.width) > 500 || Math.abs(newBox.height) > 500
                            || Math.abs(newBox.width) < 75 || Math.abs(newBox.height) < 75
                        ) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
            {!isEditing && (
                <Html divProps={{
                    style: {
                        position: 'absolute',
                        top: `${shapeProps.y * scale}px`,
                        left: `${shapeProps.x * scale}px`,
                        width: `${shapeProps.w * scale}px`,
                        height: `${shapeProps.h * scale}px`,
                        transform: `rotate(${shapeProps.rotation}deg)`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        pointerEvents: 'none',
                        color: 'black',
                    },
                }}
                >
                    <span
                        className="tableName"
                        style={{
                            fontSize: '20px',
                            fontWeight: 600,
                        }}
                    >
                        {shapeProps.name}
                    </span>
                    <span className="tableGuests">
                        {shapeProps.defaultSeat}
                    </span>
                </Html>
            )}

        </>
    );
};

interface FloorplanModalProps {
    storeId: string;
    open: boolean;
    closeModal: () => void;
    tables: ITableSchema[];
}

const FloorplanModal: React.FC<FloorplanModalProps> = ({
    storeId, open, closeModal, tables,
}) => {
    const { t, i18n } = useTranslation();

    const [rectangles, setRectangles] = useState<ITableSchema[]>(tables);
    const [selectedId, selectShape] = React.useState('');

    const POSWidth = 1216;
    const POSHeight = 648;

    const [scale, setScale] = useState(1);
    const [stageWidth, setStageWidth] = React.useState(1216);
    const [stageHeight, setStageHeight] = React.useState(648);

    const [editModeOpen, setEditModeOpen] = React.useState(false);

    const [name, setName] = React.useState('');
    const [guests, setGuests] = React.useState(0);

    const container = React.useRef(null);

    const checkDeselect = (e: any) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectShape('');
        }
    };

    const handleEditClick = () => {
        if (!editModeOpen) {
            setEditModeOpen(true);
        }
    };

    const handleCloseEdit = () => {
        setEditModeOpen(false);
    };

    const handleSave = () => {
        TableController.saveTables(storeId, rectangles);
        handleCloseEdit();
    };

    const checkSize = () => {
        if (container.current) {
            const width = (container.current as any).clientWidth;
            const height = (container.current as any).clientHeight;

            const canvasWidth = width;
            const canvasHeight = (canvasWidth * POSHeight) / POSWidth;

            if (canvasHeight < window.innerHeight) {
                setStageWidth(canvasWidth);
                setStageHeight(canvasHeight);
                setScale(canvasHeight / POSHeight);
            } else {
                setStageWidth((height * POSWidth) / POSHeight);
                setStageHeight(height);
                setScale(height / POSHeight);
            }
        }
    };

    const resize = () => {
        setStageWidth((container.current as any).clientWidth);
        setStageHeight((container.current as any).clientHeight);
        checkSize();
    };

    useEffect(() => {
        window.addEventListener('resize', checkSize);
        return () => window.removeEventListener('resize', checkSize);
    }, []);

    return (
        <Modal
            isOpen={open}
            onRequestClose={closeModal}
            className="floorplanModal"
            overlayClassName="floorplanOverlay"
            onAfterOpen={resize}
        >
            <button
                className="saveFloorplan"
                type="button"
                onClick={closeModal}
            >
                {t(FloorplanLocale.exit)}
            </button>
            <div
                className="stageWrapper"
                ref={container}
            >
                <Stage
                    width={stageWidth}
                    height={stageHeight}
                    onMouseDown={checkDeselect}
                    onTouchStart={checkDeselect}
                    scaleX={scale}
                    scaleY={scale}
                >
                    <Layer>
                        {rectangles && rectangles.map((rect, i) => (

                            <Rectangle
                                stageWidth={stageWidth}
                                stageHeight={stageHeight}
                                scale={scale}
                                updateRect={null}
                                idx={0}
                                key={rect._id}
                                shapeProps={rect}
                                fill="white"
                                isSelected={editModeOpen && rect._id === selectedId}
                                draggable={editModeOpen}
                                onSelect={() => {
                                    selectShape(rect._id!);
                                }}
                                onChange={(newAttrs: any) => {
                                    const rects = rectangles.slice();
                                    rects[i] = newAttrs;
                                    setRectangles(rects);
                                }}
                            />

                        ))}
                    </Layer>

                </Stage>
                <div
                    className={`editMode ${editModeOpen && 'active'}`}
                    aria-hidden
                    onClick={handleEditClick}
                >
                    {!editModeOpen && t(FloorplanLocale.editMode)}
                    {editModeOpen && (
                        <div className="editModeButtons">
                            <button type="button">{t(FloorplanLocale.addTable)}</button>

                            <button
                                type="button"
                                className="exitButton"
                                onClick={handleCloseEdit}
                            >
                                {t(FloorplanLocale.exitEditMode)}
                            </button>
                            <button
                                type="button"
                                onClick={handleSave}
                            >
                                {t(FloorplanLocale.saveChanges)}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </Modal>

    );
};

export default FloorplanModal;
