import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Factory } from '../../../../../controllers/ControllerFactory';
import { StaffContext } from '../../../../../controllers/Staff/StoreContext';
import { ToastController } from '../../../../../controllers/ToastController';
import { IRawMatReq } from '../../../../../model/IngrGroup/RawMatReq/IRawMatReq';

import './SendModal.scss';

interface SendModalProps {
    open: boolean;
    selectedReq: IRawMatReq | null;
    closeModal: () => void;
    handleUpdateStatus: (reqId: string, reqStatus: number) => Promise<void>;
}

const SendModal:React.FC<SendModalProps> = ({
    open, closeModal, selectedReq, handleUpdateStatus,
}) => {
    const { i18n } = useTranslation();
    const { staff } = useContext(StaffContext)!;

    const [unitsUsed, setUnitsUsed] = useState(0);
    const changeUnitsUsed = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setUnitsUsed(value);
    };

    const [priceCharged, setPriceCharged] = useState(0);
    const changePriceCharged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setPriceCharged(value);
    };

    const handleConfirm = async () => {
        if (selectedReq === null) return;
        await handleUpdateStatus(selectedReq._id, 1);
        await Factory.CorpRawMatController.use(
            selectedReq.corpRawMatId, selectedReq.storeId,
            staff._id, unitsUsed, priceCharged, 'Test',
        );
        ToastController.success('Successfully fulfilled request');
        closeModal();
    };

    useEffect(() => {
        setUnitsUsed(0);
        setPriceCharged(0);
    }, [open]);

    if (!open || selectedReq === null) return <></>;
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="SendModal"
            overlayClassName="SendModalOverlay"
        >
            <h2>Fulfill Send Request</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Units to Send</h4>
                    <input type="number" value={unitsUsed} onChange={changeUnitsUsed} />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Price to Charge</h4>
                    <input type="number" value={priceCharged} onChange={changePriceCharged} />
                </div>
            </div>

            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={handleConfirm}>Confirm</button>
                </div>
            </div>
        </ReactModal>
    );
};

export default SendModal;
