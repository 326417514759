import React, { useState, useEffect, createContext } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { StaffController } from '../controllers/Staff/StaffController';
import Tabbar from './Tabbar/Tabbar';
import Header from './Header/Header';

import Analytics from './Analytics/Analytics';
import Floorplan from './Floorplan/Floorplan';
import Menu from './Menu/Menu';
import Shift from './Shift/Shift';
import Transaction from './Transaction/Transaction';

import { StoreController } from '../controllers/Store/StoreController';
import { StoreProvider } from '../controllers/Store/StoreContext';

import './Dashboard.scss';
import { IStore } from '../model/storeGroup/store/IStore';
import { Log } from '../utils/Log';
import Spinner from '../components/Spinner/Spinner';
import FloorplanModal from './Floorplan/components/FloorplanModal';
import { Factory } from '../controllers/ControllerFactory';
import ItemEditor from './Menu/ItemEditor/ItemEditor';
import OptionEditor from './Menu/OptionEditor/OptionEditor';
import ComboEditor from './Menu/ComboEditor/ComboEditor';
import TaxEditor from './Menu/TaxEditor/TaxEditor';
import { Dict } from '../model/helpers/Dict';
import { IStaffStoreRef } from '../model/staffGroup/staffStoreRef/IStaffStoreRef';
import IngrLogEditor from './Menu/IngrLogEditor/IngrLogEditor';
import { IStaff } from '../model/staffGroup/IStaff';
import { StaffProvider } from '../controllers/Staff/StoreContext';
import StaffManagement from './StaffManagement/StaffManagement';
import AllStores from './CorpGroup/AllStores/AllStores';
import CorpRawMaterials from './CorpGroup/CorpRawMaterials/CorpRawMaterials';
import { ICorp } from '../model/corpGroup/ICorp';
import { CorpProvider } from '../controllers/CorpGroup/CorpContext';
import RawMaterials from './Menu/RawMaterials/RawMaterials';
import GalaManagement from './GalaManagement/GalaManagement';

export interface IStoreTab {
    storeId: string;
    storeName: Dict<string>;
}

const Dashboard: React.FC = () => {
    const { t, i18n } = useTranslation();

    const [tabbarOpen, setTabbarOpen] = useState(true);
    const [stores, setStores] = useState<IStoreTab[]>([]);
    const [currStore, setCurrStore] = useState<IStore | undefined>(undefined);
    const [currStaff, setCurrStaff] = useState<IStaff | undefined>(undefined);
    const [currCorp, setCurrCorp] = useState<ICorp | undefined>(undefined);

    const [loadingStore, setLoadingStore] = useState(false);
    const [loadingStaff, setLoadingStaff] = useState(true);

    const history = useHistory();
    const storeController = Factory.getStoreController();
    const userController = Factory.getStaffController();
    // Floorplan Open/Close
    const [floorplanOpen, setFloorplanOpen] = useState(false);
    const openModal = () => {
        setFloorplanOpen(true);
    };
    const closeModal = () => {
        setFloorplanOpen(false);
    };
    //

    const openCloseTabbar = () => {
        setTabbarOpen(!tabbarOpen);
    };

    const getCorp = async () => {
        if (currStaff === undefined) return;
        if (currStaff.staffCorpRefs.length === 0) return;
        const corpId = currStaff.staffCorpRefs[0].corpId;
        const corpResponse = await Factory.CorpController.getById(
            corpId,
        );
        setCurrCorp(corpResponse.data.corp);
    };

    useEffect(() => {
        Log.info('Dashboard Validate JWT');
        // send jwt to API to see if it's valid
        userController.validateJWT(history).then((authSuccess: boolean) => {
            if (!authSuccess) {
                history.push('/login');
            }
        });
    }, [history.location.pathname]);

    useEffect(() => {
        if (localStorage.token === 'undefined' || localStorage.token === 'null') {
            localStorage.removeItem('token');
            history.push('/login');
            return;
        }
        Log.info('Loading Staff');
        const loadStaff = async () => {
            const staff : IStaff = await userController.getByToken();
            setCurrStaff(staff);
            setLoadingStaff(false);
        };
        loadStaff();
    }, []);

    // Setup Store Metadata (Tabs)
    useEffect(() => {
        Log.info('Setting up Store MetaData (tabs)');
        if (currStaff === undefined) return;
        currStaff.staffStoreRefs.forEach((staffStoreRef: IStaffStoreRef) => {
            setStores((prevState) => [...prevState, {
                storeId: staffStoreRef.storeId,
                storeName: staffStoreRef.storeName,
            }]);
        });
        getCorp();
    }, [currStaff]);

    // Change store based on the index
    const changeStore = async (idx: number) => {
        setLoadingStore(true);
        if (stores.length > idx) {
            const storeResponse = await storeController.getById(stores[idx].storeId);
            const storeData = storeResponse.data.store;
            // eslint-disable-next-line no-param-reassign
            storeData._id = stores[idx].storeId;
            setCurrStore(storeData);
            setLoadingStore(false);
        }
    };

    useEffect(() => {
        changeStore(0);
    }, [stores]);

    const getClasses = () => {
        let style = 'dashboardContainer';
        if (tabbarOpen) {
            style += ' tabbarOpen';
        }
        if (floorplanOpen) {
            style += ' floorplanOpen';
        }
        return style;
    };

    if (loadingStore || loadingStaff) return (<Spinner />);

    return (
        <StaffProvider staffProp={currStaff!}>
            <div>
                <Tabbar
                    open={tabbarOpen}
                    openFloorplan={openModal}
                />
                <Header
                    tabbarOpen={tabbarOpen}
                    openCloseTabbar={openCloseTabbar}
                    stores={stores}
                    changeStore={changeStore}
                    currStore={currStore}
                />
                {currStore === undefined && <Spinner />}
                {currStore !== undefined && (
                    <StoreProvider storeProp={currStore!}>
                        <div className={getClasses()}>
                            {currStore && (
                                <FloorplanModal
                                    storeId={currStore._id}
                                    open={floorplanOpen}
                                    closeModal={closeModal}
                                    tables={currStore!.tables!}
                                />
                            )}
                            {loadingStore ? <Spinner />
                                : (
                                    <>
                                        <Route exact path="/dashboard">
                                            <Analytics />
                                        </Route>
                                        <Route path="/dashboard/shift">
                                            <Shift />
                                        </Route>
                                        <Route path="/dashboard/staff">
                                            <StaffManagement />
                                        </Route>
                                        <Route path="/dashboard/transaction">
                                            <Transaction />
                                        </Route>
                                        <Route path="/dashboard/gala">
                                            <GalaManagement />
                                        </Route>
                                        <Route exact path="/dashboard/menu">
                                            <Menu />
                                        </Route>
                                        <Route path="/dashboard/menu/items">
                                            <ItemEditor />
                                        </Route>
                                        <Route path="/dashboard/menu/options">
                                            <OptionEditor />
                                        </Route>
                                        <Route path="/dashboard/menu/combos">
                                            <ComboEditor />
                                        </Route>
                                        <Route path="/dashboard/menu/taxes">
                                            <TaxEditor />
                                        </Route>
                                        <Route path="/dashboard/menu/ingredients">
                                            <IngrLogEditor />
                                        </Route>
                                        <Route path="/dashboard/menu/raw">
                                            <RawMaterials />
                                        </Route>
                                        {currCorp !== undefined && (
                                            <CorpProvider corpProp={currCorp}>
                                                <Route path="/dashboard/corp/all">
                                                    <AllStores />
                                                </Route>
                                                <Route path="/dashboard/corp/raw">
                                                    <CorpRawMaterials />
                                                </Route>
                                            </CorpProvider>
                                        )}

                                        {/* <Route path="/dashboard/floorplan">
                                            <Floorplan />
                                        </Route> */}

                                        <div className="languageSelect">
                                            <button className="langButton" type="button" onClick={() => { i18n.changeLanguage('en'); }}>English</button>
                                            <button className="langButton" type="button" onClick={() => { i18n.changeLanguage('zh'); }}>中文</button>
                                        </div>
                                    </>
                                )}

                        </div>
                    </StoreProvider>
                )}
            </div>
        </StaffProvider>
    );
};

export default Dashboard;
