import React, { useState, useEffect, useContext } from 'react';
import ReactModal from 'react-modal';
import { Factory } from '../../../../../controllers/ControllerFactory';
import { StoreContext } from '../../../../../controllers/Store/StoreContext';
import './AddEditMenu.scss';

ReactModal.setAppElement('#root');
interface AddEditMenuProps {
    open: boolean;
    closeModal: () => void;
}

const AddEditMenu: React.FC<AddEditMenuProps> = ({
    open, closeModal,
}) => {
    const { store, setStore } = useContext(StoreContext)!;
    const menuController = Factory.getMenuController();

    const [menuName, setMenuName] = useState('');
    const [menuMName, setMenuMName] = useState('');
    const [menuDesc, setMenuDesc] = useState('');
    const [menuMDesc, setMenuMDesc] = useState('');
    const [menuPriceKey, setMenuPriceKey] = useState('');

    const createMenu = async () => {
        const menuResponse = await menuController.createMenu(
            store._id,
            menuName,
            menuMName,
            menuDesc,
            menuMDesc,
            menuPriceKey,
        );
        setStore(menuResponse.store);
        closeModal();
    };

    useEffect(() => {
        if (store.priceKeys.length === 0) throw new Error('Need to have a pricing key');
        setMenuPriceKey(store.priceKeys[0]._id);
    });

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="addEditMenuModal"
            overlayClassName="addEditMenuModalOverlay"
        >
            <h2>Create Menu</h2>
            <div className="row">
                <div className="inputBox">
                    <h4>Menu Name</h4>
                    <input onChange={(e) => setMenuName(e.target.value)} />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Menu Name</h4>
                    <input onChange={(e) => setMenuMName(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="inputBox">
                    <h4>Description</h4>
                    <input onChange={(e) => setMenuDesc(e.target.value)} />
                </div>
                <div className="space" />
                <div className="inputBox">
                    <h4>Management Description</h4>
                    <input onChange={(e) => setMenuMDesc(e.target.value)} />
                </div>
            </div>
            <div className="inputBox">
                <h4>Pricing Key</h4>
                <select onChange={(e) => setMenuPriceKey(e.target.value)}>
                    {store.priceKeys.map((priceKey) => (
                        <option value={priceKey._id}>{priceKey.mName}</option>
                    ))}
                </select>
            </div>
            <div className="buttonContainer">
                <div className="buttonBox">
                    <button className="cancel" type="button" onClick={closeModal}>Cancel</button>
                    <button className="confirm" type="button" onClick={createMenu}>Confirm</button>
                </div>
            </div>

        </ReactModal>
    );
};

export default AddEditMenu;
