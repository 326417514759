import React, { useState, useContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Switch, Route, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './ItemEditor.scss';
import AddBar from '../components/AddBar/AddBar';
import AddEditItem from '../components/ItemViewer/AddEditItem/AddEditItem';
import { StoreContext } from '../../../controllers/Store/StoreContext';
import ItemCard from '../components/ItemViewer/ItemCard/ItemCard';
import { IItem } from '../../../model/menuGroup/item/IItem';
import { IItemTypes } from '../../../model/menuGroup/item/IItemTypes';

interface ItemEditorProps{}

const ItemEditor: React.FC<ItemEditorProps> = () => {
    const { t } = useTranslation();
    const { store, setStore } = useContext(StoreContext)!;

    const [currItem, setCurrItem] = useState<IItem | undefined | null>(null);
    const selectItem = (tmpItem: IItem) => setCurrItem(tmpItem);
    const deselectItem = () => setCurrItem(null);

    const generateItemCards = () => store.items.map((tmpItem) => {
        if (tmpItem.itemType === IItemTypes.GIFTCREDIT
            || tmpItem.itemType === IItemTypes.CUSTOM
            || tmpItem.itemType === IItemTypes.SURCHARGE
            || tmpItem.itemType === IItemTypes.DISCOUNT) return <></>;
        return (
            <ItemCard
                item={tmpItem}
                selectItem={selectItem}
                priceKeyId={store.priceKeys[0]._id}
            />
        );
    });
    return (

        <div
            className="itemEditorContainer dashboardScreen"
        >
            <header>
                { currItem !== null && <button type="button" onClick={() => setCurrItem(null)}>&#8249;</button>}
                <h2 className="screenTitle">
                    {`Item Editor
                        ${currItem !== null && currItem !== undefined ? `> ${currItem.name.en}` : ''}
                        ${currItem === undefined ? '> Add Item' : ''}

                    `}
                </h2>
            </header>
            { currItem === null
            && (
                <>
                    <AddBar handleClick={() => setCurrItem(undefined)} text="Add Item" />
                    <div className="itemContainer">
                        {generateItemCards()}
                    </div>
                </>
            )}
            {currItem !== null
            && (
                <AddEditItem
                    currCategoryId=""
                    item={currItem}
                    deselectItem={deselectItem}
                />
            )}
        </div>

    );
};

export default ItemEditor;
